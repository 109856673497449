import { TabLabelToPath } from 'components/ViewHeader';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export function useAnalysisTabs() {
  const clientId = useClientId();
  const vesselProperties = useVesselFeatures(clientId);

  const tabs: TabLabelToPath[] = [
    ...(vesselProperties?.includes('analysisVessel') ? [{ label: 'Vessel', toPath: 'vessel' }] : []),
    { label: 'Engine', toPath: 'engine' },
    ...(vesselProperties?.includes('vibrationSensor') ? [{ label: 'Vibration', toPath: 'vibration' }] : []),
    ...(vesselProperties?.includes('analysisTrips') ? [{ label: 'Trips', toPath: 'trips' }] : []),
    ...(vesselProperties?.includes('analysisEmissions') ? [{ label: 'Emissions', toPath: 'emissions' }] : []),
  ];

  return tabs;
}
