import { Container, Typography } from '@mui/material';
import { APP_BAR_HEIGHT } from 'helpers/constants';

const ContactAdmin = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        flexDirection: 'column',
      }}>
      <Typography
        variant="h4"
        sx={{
          mb: 3,
          fontWeight: 'bold',
        }}>
        Contact your admin
      </Typography>
      <Typography>
        Your are currently not member of a company,
        <b> you need to contact your company admin and provide them with your registered email address</b> in order to be invited into the
        company.
      </Typography>
    </Container>
  );
};

export default ContactAdmin;
