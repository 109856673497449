import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import { useMyCompanies } from 'hooks/companies/useMyCompanies';
import { useAuth } from 'hooks/useAuth';

export function UserManagementButton() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data: companies } = useMyCompanies({ enabled: !!user });

  if (user?.isSuperUser || (companies && companies.length > 0 && companies.some(membership => membership.role === 'admin'))) {
    return (
      <Box
        sx={{
          display: 'flex',
          //width: '100%',
          alignItems: 'center',
          alignSelf: 'begin',
          justifyContent: 'center',
          color: 'text.primary',
          borderRadius: 1,
        }}>
        <IconButton sx={{ ml: 1 }} onClick={() => navigate('/company')} color="inherit">
          <SupervisedUserCircleOutlinedIcon />
        </IconButton>
      </Box>
    );
  } else {
    return null;
  }
}
