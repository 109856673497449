// src/components/Emissions/RatingChart.tsx
import type { EmissionStats, EmissionConfig } from 'helpers/emissions';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import 'chartjs-adapter-moment';
import ChartJS from 'chart.js/auto';
import { RatingCalculator } from './RatingCalculator';
import EmissionChart from './EmissionChart';
import BoundaryBoxes from './BoundaryBoxes';

ChartJS.register([]);

type EmissionRatingChartProps = {
  chartData: EmissionStats[];
  emissionConfig: EmissionConfig;
};

export function EmissionRatingChart({ chartData, emissionConfig }: EmissionRatingChartProps) {
  const calculator = useMemo(() => new RatingCalculator(chartData, emissionConfig), [chartData, emissionConfig]);

  return (
    <Box sx={{ display: 'flex', height: '300px', gap: 0.5, p: 2 }}>
      <BoundaryBoxes calculator={calculator} />
      <Box sx={{ width: '100%' }}>
        <EmissionChart calculator={calculator} />
      </Box>
    </Box>
  );
}
