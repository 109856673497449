import { Navigate } from 'react-router-dom';
import { BoilerplateV2 } from '../NavigationBoilerplate/BoilerplateV2';
import { LinearProgress, Typography } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { useMyCompanies } from 'hooks/companies/useMyCompanies';

export function CompanyRedirect() {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();
  const { data: myCompanies, isLoading, isError } = useMyCompanies();

  if (isLoadingAuth) return <></>;
  if (!isAuthenticated) return <Navigate to="/login" replace />;
  if (isLoading || isError)
    return (
      <BoilerplateV2 overrideBreadcrumbTitle="User Management" disableTabs>
        {isLoading && <LinearProgress />}
        {isError && <p>An error occurred.</p>}
      </BoilerplateV2>
    );
  if (myCompanies?.length === 1) {
    return <Navigate to={`../company/${myCompanies[0].company_id}`} replace />;
  } else if (myCompanies?.length === 0) {
    return (
      <BoilerplateV2 overrideBreadcrumbTitle="User Management" disableTabs>
        <Typography variant="h4">You are not a member of a company.</Typography>
      </BoilerplateV2>
    );
  } else {
    return <Navigate to="../my-companies" replace />;
  }
}
