import { Box } from '@mui/material';
import ViewHeader, { TabLabelToPath } from 'components/ViewHeader';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import { byEngineDisplayOrder, useVesselConfig } from 'context/vesselConfig';
import usePathChild from 'hooks/usePathChild';
import { Navigate, Outlet } from 'react-router-dom';

export default function LoadProfileBoilerplate() {
  const value = usePathChild();
  const dateRange = useAnalysisDateRange();
  const config = useVesselConfig();
  const enginesSorted = Object.entries(config?.engines).sort(byEngineDisplayOrder);

  const tabs: TabLabelToPath[] = enginesSorted.map(([_, config]) => ({
    label: config.name,
    toPath: config.objectId,
  }));

  if (!value && tabs.length > 0) {
    return <Navigate to={tabs[0].toPath} replace />;
  }

  return (
    <Box>
      <ViewHeader tabs={tabs} value={value || false} />
      <Box sx={{ pt: 2 }}>
        <Outlet context={dateRange} />
      </Box>
    </Box>
  );
}
