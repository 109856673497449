import { Box, Paper, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

interface ValueBoxProps {
  values: { label: string; value: number | string; unit?: string }[];
  direction?: 'row' | 'column';
  paper?: boolean;
}

function ValueBox({ values, direction = 'row', paper = true }: ValueBoxProps) {
  const Content = (
    <>
      {values.map((item, index) => (
        <Stack
          key={index}
          spacing={direction === 'row' ? 1 : 0}
          alignItems="center"
          justifyContent="center"
          direction={direction}
          sx={{
            p: 1,
            borderBottom: index !== values.length - 1 ? `1px solid ${grey[200]}` : 'none',
            whiteSpace: 'nowrap',
          }}>
          {direction === 'row' ? (
            <>
              <Typography variant="h5" align="center" color={grey[500]}>
                {item.label}
              </Typography>
              <Typography variant="h5" align="center" color="textSecondary">
                {item.value} {item.unit}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h5" align="center" color="textSecondary">
                {item.value} {item.unit}
              </Typography>
              <Typography variant="h5" align="center" color={grey[500]}>
                {item.label}
              </Typography>
            </>
          )}
        </Stack>
      ))}
    </>
  );

  return paper ? (
    <Paper elevation={3} sx={{ minWidth: 'min-content' }}>
      {Content}
    </Paper>
  ) : (
    <Box sx={{ minWidth: 'min-content', borderLeft: `1px solid ${grey[200]}` }}>{Content}</Box>
  );
}

export default ValueBox;
