export type BinResult = {
  labels: number[];
  counts: number[];
  values: number[][];
  unused: number[];
  counterDistribution: number[];
  valueDistribution: number[];
};

export type BinArrResult = {
  labels: number[];
  counts: number[];
  values: any[][];
  unused: number[];
  counterDistribution: number[][];
  valueDistribution: number[][];
};

export function calcBins(data: number[], binRange: number, noOfBins: number): BinResult | null {
  if (binRange === 0 || noOfBins === 0) return null;

  const bin: BinResult = {
    labels: [],
    counts: [],
    values: [],
    unused: [],
    counterDistribution: [],
    valueDistribution: [],
  };

  for (let cnt = 0; cnt < noOfBins; cnt++) {
    bin.counts.push(0);
    bin.values.push([]);
    bin.labels.push(cnt * binRange);
  }

  let totalCount = 0;
  let totalValue = 0;

  // TODO : possibility to create negative bins is requiered
  for (const item of data) {
    const value = Math.round(item / binRange);
    if (value < noOfBins && value >= 0) {
      bin.counts[value] = bin.counts[value] + 1;
      if (item) bin.values[value].push(item);
      totalCount++;
      totalValue += item;
    } else {
      if (item) bin.unused.push(item);
    }
  }

  for (let cnt = 0; cnt < noOfBins; cnt++) {
    bin.counterDistribution.push(bin.counts[cnt] / totalCount);
    let sum = 0;
    for (const value of bin.values[cnt]) {
      sum += value;
    }

    bin.valueDistribution.push(sum / totalValue);
  }
  return bin;
}
