import { ChargingStationOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import BatteryChargingFullOutlinedIcon from '@mui/icons-material/BatteryChargingFullOutlined';
import Battery90OutlinedIcon from '@mui/icons-material/Battery90Outlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { getRatingColor } from 'helpers/getRatingColor';
import TitledPaper from 'components/TitledPaper';
import SkeletonOnUndefined from '../../components/SkeletonOnUndefined';
import { random, round } from 'mathjs';
import { useEffect, useState } from 'react';

export type BatteryStats = {
  id: number;
  name: string;
  operatingState?: string;
  soh?: number;
  soc?: number;
  dod?: number;
  rte?: number;
  equivalentFullCycles: number;
  currentCRate?: number;
  availablePower?: number;
  input?: number;
  output?: number;
};

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'operatingState',
    headerName: 'Operating State',
    flex: 1,
  },
  {
    field: 'soh',
    headerName: 'SOH',
    flex: 1,
    renderCell: params => {
      const color = getRatingColor(params.value);
      return (
        <SkeletonOnUndefined value={params.value}>
          <Box
            component="span"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              color,
              alignItems: 'center',
            }}>
            <HealthAndSafetyOutlinedIcon />
            <Typography>{params.value?.toFixed(2)}%</Typography>
          </Box>
        </SkeletonOnUndefined>
      );
    },
  },
  {
    field: 'soc',
    headerName: 'SOC',
    flex: 1,
    renderCell: params => {
      return (
        <SkeletonOnUndefined value={params.value}>
          <Box sx={{ height: '100%', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <BatteryChargingFullOutlinedIcon
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
            <Typography>{params.value?.toFixed(2)}%</Typography>
          </Box>
        </SkeletonOnUndefined>
      );
    },
  },
  {
    field: 'dod',
    headerName: 'DOD',
    flex: 1,
    renderCell: params => {
      return (
        <SkeletonOnUndefined value={params.value}>
          <Box sx={{ height: '100%', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Battery90OutlinedIcon
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
            <Typography>{params.value?.toFixed(2)}%</Typography>
          </Box>
        </SkeletonOnUndefined>
      );
    },
  },
  {
    field: 'rte',
    headerName: 'RTE',
    flex: 1,
    renderCell: params => {
      return (
        <SkeletonOnUndefined value={params.value}>
          <Box sx={{ height: '100%', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <ChangeCircleOutlinedIcon
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
            <Typography>{params.value?.toFixed(2)}%</Typography>
          </Box>
        </SkeletonOnUndefined>
      );
    },
  },
  {
    field: 'currentCRate',
    headerName: 'C-Rate',
    flex: 1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(1) + 'C'}</SkeletonOnUndefined>;
    },
  },
  {
    field: 'equivalentFullCycles',
    headerName: 'Equivalent Full Cycles',
    flex: 1.1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(0)}</SkeletonOnUndefined>;
    },
  },
  {
    field: 'availablePower',
    headerName: 'Available Power',
    flex: 1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(0) + 'kW'}</SkeletonOnUndefined>;
    },
  },
  {
    field: 'input',
    headerName: 'Input',
    flex: 1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(0) + 'kW'}</SkeletonOnUndefined>;
    },
  },
  {
    field: 'output',
    headerName: 'Output',
    flex: 1,
    renderCell: params => {
      return <SkeletonOnUndefined value={params.value}>{params.value?.toFixed(0) + 'kW'}</SkeletonOnUndefined>;
    },
  },
];

/**
 * @deprecated for smm 2024
 */
export const VesselBatteryTable = () => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 30_000); // Update every 30 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const hour = now.getHours() - 5 + now.getMinutes() / 60;

  const soc_1 = 100 - hour * 3.97;
  const dod_1 = 100 - soc_1;
  const soc_2 = 100 - hour * 3.68;
  const dod_2 = 100 - soc_2;

  const stats: BatteryStats[] = [
    {
      id: 100,
      name: 'Christina M',
      operatingState: 'Discharging',
      soh: 98.34,
      soc: soc_1,
      dod: dod_1,
      rte: round(random(90, 91), 2),
      currentCRate: 0.5,
      equivalentFullCycles: now.getDate() + 77,
      availablePower: 4390,
      input: 0,
      output: 24,
    },
    {
      id: 101,
      name: 'Vittorio Morace',
      operatingState: 'Discharging',
      soh: 95.56,
      soc: soc_2,
      dod: dod_2,
      rte: round(random(92, 93), 2),
      currentCRate: 0.5,
      equivalentFullCycles: now.getDate() + 65,
      availablePower: 4390,
      input: 0,
      output: 24,
    },
  ];

  return (
    <TitledPaper
      title="BESS Units"
      icon={
        <ChargingStationOutlined
          sx={{
            transform: 'rotate(90deg)',
          }}
        />
      }>
      <DataGrid
        sx={{ border: 'none', flex: 1, cursor: 'pointer' }}
        columns={columns}
        rows={stats}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10]}
        autoHeight
      />
    </TitledPaper>
  );
};
