import { Box, Skeleton, SxProps, Typography, TypographyVariant } from '@mui/material';
import { CIIBoundary, ciiToRating, RatingColors, Rating } from 'helpers/emissions';

type RatingBoxProps = {
  boundary: CIIBoundary;
  cii?: number;
  sx?: SxProps;
};

export function RatingBox(props: RatingBoxProps) {
  if (props.cii == null) {
    return <Skeleton sx={props.sx} />;
  }

  const rating = ciiToRating(props.cii, props.boundary);

  return <RatingIcon rating={rating} sx={props.sx}></RatingIcon>;
}

type RatingIconProps = {
  rating: Rating;
  sx?: SxProps;
  variant?: TypographyVariant;
  square?: boolean;
};
export function RatingIcon({ rating, variant, sx, square }: RatingIconProps) {
  return (
    <Box
      sx={{
        backgroundColor: RatingColors[rating],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
        ...(square && { aspectRatio: '1' }),
      }}>
      <Typography color="white" variant={variant ?? 'h5'}>
        {rating}
      </Typography>
    </Box>
  );
}
