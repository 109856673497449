import { Autocomplete, Button, Box, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useBaselineGroupEditor } from 'hooks/useBaselineGroupEditor';

import { BaselineDisplayChart } from './components/BaselineDisplayChart';
import { BaselineGroupEditor } from './components/BaselineGroupEditor';
import { BaselineFamily, BaselineGroup, BaselineThresholdType } from './types';
import { ThresholdLevels } from './components/ThresholdLevels';

export function BaselineConfigEditor({ clientId, baselineGroupId }: { clientId: string; baselineGroupId: string }) {
  const { baselineGroup, createBaseline, setActiveBaseline, setThresholdType, setThresholdValue } = useBaselineGroupEditor({
    baselineGroupId,
    clientId,
  });

  const group: BaselineGroup | undefined = baselineGroup.data;
  if (group === undefined || group === null) {
    return <Box>No group with specified id found</Box>;
  }

  if (typeof group.family === 'string') {
    return <Box>Family not populated</Box>;
  }
  const family: BaselineFamily = group.family;

  const newBaseline = () => {
    const num = Object.keys(group.baselines).length + 1;
    const baseName = family.axes.x.label + ' ' + family.axes.y.label;
    let name = baseName + ' ' + num;
    for (let i = num; i < 100; i++) {
      name = baseName + ' ' + i;
      // eslint-disable-next-line no-loop-func
      if (!group.baselines.find(line => line.name === name)) {
        break;
      }
    }
    createBaseline({ name });
  };

  const selectedLine = group.activeBaseline;
  const thresholdOptions = group.baselines.map(baseline => ({ label: baseline.name, _id: baseline._id }));
  const threshold = thresholdOptions.find(option => option._id === group.activeBaseline?._id);
  const unitOptions: { label: string; value: BaselineThresholdType }[] = [
    { label: 'Value', value: 'value' },
    { label: 'Percent', value: 'percent' },
  ];
  const thresholdType = unitOptions.find(option => option.value === group.threshold.type);
  return (
    <Box sx={{ height: '89%', maxHeight: '89%', display: 'flex', gap: 1, flexDirection: 'column' }}>
      <Box
        sx={{
          flex: '1 1 0',
          display: 'grid',
          gridTemplateAreas: `
        'baseline-chart baseline-points'
        'baseline-units baseline-levels'
      `,
          gridTemplateRows: '1fr auto',
          gridTemplateColumns: '50% 50%',
          height: '100%',
          maxHeight: '100%',
          gridGap: '16px',
          overflow: 'hidden',
        }}>
        <Box sx={{ gridArea: 'baseline-chart', minHeight: 0, maxHeight: '100%', height: '100%', width: '100%' }}>
          <BaselineDisplayChart activeId={selectedLine?._id} lines={group.baselines} axes={family.axes} threshold={group.threshold} />
        </Box>
        <Box sx={{ gridArea: 'baseline-points', paddingRight: '17px', minHeight: 0, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ marginRight: '17px', paddingLeft: 1, paddingRight: 1, marginBottom: 1 }}>
            <Button sx={{ width: '100%' }} onClick={newBaseline} size="large" variant="outlined" startIcon={<AddCircleIcon />}>
              Add new Baseline
            </Button>
          </Box>
          <BaselineGroupEditor clientId={clientId} group={group} />
        </Box>
        <Box
          sx={{
            gridArea: 'baseline-units',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            gap: 2,
            alignSelf: 'end',
          }}>
          <Autocomplete
            disablePortal
            id="threshold-combo-box"
            value={threshold ?? null}
            onChange={(event, newValue) => setActiveBaseline(newValue?._id ?? null)}
            inputValue={threshold?.label ?? ''}
            options={thresholdOptions}
            sx={{ width: 300 }}
            renderInput={params => <TextField {...params} variant="filled" size="small" label="Define Threshold" />}
          />
          <Autocomplete
            disablePortal
            value={thresholdType ?? null}
            onChange={(event, newValue) => setThresholdType({ type: newValue?.value ?? null })}
            inputValue={thresholdType?.label ?? ''}
            id="unit-combo-box"
            options={unitOptions}
            sx={{ width: 300 }}
            renderInput={params => <TextField {...params} variant="filled" size="small" label="Select Unit" />}
          />
        </Box>
        <Box sx={{ gridArea: 'baseline-levels', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              gap: 1,
              width: '100%',
            }}>
            <ThresholdLevels
              type="Warning"
              minValue={group.threshold.warnMin}
              setMinValue={val => setThresholdValue({ key: 'warnMin', value: val ?? null })}
              maxValue={group.threshold.warnMax}
              setMaxValue={val => setThresholdValue({ key: 'warnMax', value: val ?? null })}
            />
            <ThresholdLevels
              type="Alarm"
              minValue={group.threshold.alarmMin}
              setMinValue={val => setThresholdValue({ key: 'alarmMin', value: val ?? null })}
              maxValue={group.threshold.alarmMax}
              setMaxValue={val => setThresholdValue({ key: 'alarmMax', value: val ?? null })}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
