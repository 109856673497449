import { useCompanyId } from 'context/company';
import { useChangeRoleMutation } from 'hooks/companies/useChangeRoleMutation';
import { useForm } from 'react-hook-form';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { MemberActionProps } from './index';

export function ChangeRoleModal({ member, onSuccess }: MemberActionProps) {
  const company_id = useCompanyId();
  const mutation = useChangeRoleMutation(company_id);
  const { register: registerForm, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    mutation.mutate(
      { user_id: member.id, role: data.role },
      {
        onSuccess: () => {
          onSuccess?.();
        },
      }
    );
  };

  return (
    <form action="#" onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Change Role</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <DialogContentText>Change Role of {member.email}</DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="changerole-select-label">Role</InputLabel>
          <Select labelId="changerole-select-label" id="changerole-select" label="Age" defaultValue="user" {...registerForm('role')}>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained">
          Change Role
        </Button>
      </DialogActions>
    </form>
  );
}
