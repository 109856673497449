import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridColDef, GridDensity, GridEventListener } from '@mui/x-data-grid';
import type { EngineConfig } from 'hooks/useEngineConfig';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatMomentDuration } from '../../helpers/format';
import { Vessel } from '../../hooks/fleetConfig';
import { NotificationFilter, NotificationItem, convertToNotificationItem, useNotifications } from '../../hooks/useNotifications';
import { getPriorityColor } from 'app/Notifications/NotificationDetails';

type NotificationListProps = {
  clientIds: string[];
  status?: NotificationFilter['status'];
  hideColumns?: string[];
  vesselInfos?: Vessel[];
  engines?: EngineConfig[];

  followNotificationOnClick?: boolean;
  rowCount?: number;
  rowsPerPageOptions?: number[];
  defaultPageSize: number;
  hideFooter?: boolean;
  autoHeight?: boolean;
  density?: GridDensity;
  disableColumnControls?: boolean;
};

const initialColumns: GridColDef[] = [
  {
    field: 'priority',
    headerName: '',
    width: 10,
    renderCell: param => {
      return (
        <Box
          sx={{
            width: '10px',
            height: '100%',
            backgroundColor: getPriorityColor(param.value),
            transform: 'translateX(-10px)',
          }}
        />
      );
    },
  },
  {
    field: 'vesselName',
    headerName: 'Vessel Name',
    width: 150,
    flex: 1,
  },
  {
    field: 'alarmTitle',
    headerName: 'Alarm Title',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'engineName',
    headerName: 'Engine Name',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'serialNo',
    headerName: 'Serial Number',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'latestOccurrenceTime',
    headerName: 'Latest Occurrence',
    minWidth: 200,
    flex: 1,
    renderCell: cellValues => {
      return moment(cellValues.value).format('HH:mm:ss, DD.MM.YYYY');
    },
  },
  {
    field: 'events',
    headerName: 'Events',
    minWidth: 100,
    flex: 0.5,
  },
  {
    field: 'totalDuration',
    headerName: 'Total Duration',
    minWidth: 150,
    flex: 0.5,
    renderCell: cellValues => {
      return formatMomentDuration(moment.duration(cellValues.value));
    },
  },
];

export default function NotificationList(props: NotificationListProps) {
  const navigate = useNavigate();
  const notifications = useNotifications({ clientIds: props.clientIds, status: props.status });
  const { isLoading, isError, data } = notifications;
  const rows = useMemo(() => {
    if (isLoading || isError || !data) {
      return [];
    }

    return data
      .map(notification => {
        const engines = props.engines?.filter(e => e.clientId === notification.clientId);

        return convertToNotificationItem(notification, engines, props.vesselInfos);
      })
      .sort((firstItem: NotificationItem, nextItem: NotificationItem) => {
        const firstItemLatestOccurrenceTime = firstItem?.latestOccurrenceTime?.getTime() ?? 0;
        const nextItemLatestOccurrenceTime = nextItem?.latestOccurrenceTime?.getTime() ?? 0;
        return nextItemLatestOccurrenceTime - firstItemLatestOccurrenceTime;
      });
  }, [data, isError, isLoading, props.engines, props.vesselInfos]);

  if (isLoading) {
    return <Skeleton variant="rectangular" height="30vh" />;
  }

  const onRowClick: GridEventListener<'rowClick'> = params => {
    navigate(`/vessel/${params.row.clientId}/notifications/details/${params.row.id}`);
  };

  const columns = initialColumns
    .filter(col => !props.hideColumns?.includes(col.field))
    .map(column => {
      column.sortable = !props.disableColumnControls;
      return column;
    });

  const defaultPagination = Array.from(new Set<number>([props.defaultPageSize, 10, 20, 50, 100].sort((a, b) => a - b)));
  return (
    <DataGrid
      sx={{ border: 'none' }}
      columns={columns}
      rows={rows}
      classes={{ row: 'clickable-row' }}
      initialState={{
        pagination: { paginationModel: { pageSize: props.defaultPageSize } },
      }}
      pageSizeOptions={props.rowsPerPageOptions ?? defaultPagination}
      rowCount={props.rowCount}
      onRowClick={props.followNotificationOnClick ? onRowClick : undefined}
      density={props.density}
      autoHeight={props.autoHeight}
      hideFooter={props.hideFooter}
      disableColumnMenu={props.disableColumnControls}
    />
  );
}
