import React from 'react';

import { Box, Button, TextField, Toolbar } from '@mui/material';

import TodayIcon from '@mui/icons-material/Today';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import moment from 'moment';

function TimeButton({
  active,
  onClick,
  icon,
  text,
  minWidth = '100px',
}: {
  active: boolean;
  onClick: () => void;
  text: string;
  icon: any;
  minWidth?: string;
}) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', minWidth: minWidth, height: '100%' }}>
      <Button size="small" variant={active ? 'outlined' : 'text'} onClick={onClick} endIcon={icon}>
        {text}
      </Button>
    </Box>
  );
}

type TimeRangeProps = {
  customStart: boolean;
  start: Date;
  end: Date;
  range: number;
  setRange: (range: number) => void;
  setStart: (start: Date) => void;
  setEnd: (end: Date) => void;
};

function TimeSettingsBar({ start, end, range, setRange, customStart, setStart, setEnd }: TimeRangeProps) {
  return (
    <Toolbar sx={{ gap: 1 }}>
      <TimeButton active={!customStart && range === 1} onClick={() => setRange(1)} icon={<TodayIcon />} text="1 Day" />
      <TimeButton active={!customStart && range === 5} onClick={() => setRange(5)} icon={<CalendarTodayIcon />} text="5 Days" />
      <TimeButton active={!customStart && range === 10} onClick={() => setRange(10)} icon={<DateRangeIcon />} text="10 Days" />
      <Box sx={{ flexGrow: 1 }}></Box>
      <TextField
        id="startDate"
        label="Start Date"
        type="datetime-local"
        variant="standard"
        size="small"
        value={moment(start).format('yyyy-MM-DDThh:mm')}
        onChange={e => setStart(new Date(e.target.value))}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        id="endDate"
        label="End Date"
        type="datetime-local"
        variant="standard"
        size="small"
        onChange={e => setEnd(new Date(e.target.value))}
        value={moment(end).format('yyyy-MM-DDThh:mm')}
        InputLabelProps={{ shrink: true }}
      />
    </Toolbar>
  );
}

export { TimeButton };

export default TimeSettingsBar;
