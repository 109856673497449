import TimeLineCard from 'components/TimeLineCard';
import type { ClientId } from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import { ChartData } from 'chart.js';
import { useTargetUnits } from 'context/settings';

function useWaterJetVibrationOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const vesselConfig = useVesselConfig();
  const units = useTargetUnits();
  const timeseriesQueries = Object.keys(vesselConfig.engines).map(engineNo => {
    const engineNumber = Number(engineNo);
    const query: TimeseriesSequenceQuery = {
      clientId,
      module: 'measurement',
      objectId: 'waterJet',
      objectIdNo: engineNumber,
      pvId: 'vibrationRms',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: units.vibrationDistance,
    };
    return query;
  });

  const [vibrationResults, loading, err] = useSequencesData(timeseriesQueries);

  const chartData: ChartData = {
    datasets: vibrationResults.map((data, index) => ({
      label: vesselConfig.engines[index].name,
      data: data?.map(queryItem => ({ x: queryItem.x, y: queryItem.y })) ?? [],
      borderColor: color[index],
      borderWidth: 1,
    })),
  };

  const options = {
    yAxisLabel: `Vibration [${units.vibrationDistance}]`,
    title: 'Water Jet',
    xAxisLabel: 'Time',
  };
  return { data: chartData, loading, err, options };
}

export default function VibrationRmsWaterJet({ from, to, clientId, resolution }: ChartProps) {
  const { data, loading, err, options } = useWaterJetVibrationOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={options} data={data} loading={loading} err={err} />;
}
