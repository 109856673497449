import { Navigate } from 'react-router-dom';
import { useAnalysisTabs } from './useAnalysisTabs';

export function AnalysisNavigate() {
  const tabs = useAnalysisTabs();

  if (tabs.length === 0) {
    return <p>Analysis has no tabs enabled</p>;
  }
  return <Navigate to={tabs[0].toPath} replace />;
}
