import { QueryStatsOutlined } from '@mui/icons-material';
import { Box, Paper, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import { StatCategory, Stat, StatsGrid } from 'app/Cockpit/StatComponents';
import Gauge from 'components/Gauges/DefaultGauge';
import TitledPaper from 'components/TitledPaper';
import React from 'react';
import LinearGauge from 'components/Gauges/LinearGauge';
import ValueBox from 'components/ValueBox';

type GensetsDetailProps = {
  title: string;
};

function GensetsDetail({ title }: GensetsDetailProps) {
  const largeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  return (
    <Paper
      elevation={3}
      sx={{
        p: 1,
      }}>
      <Stack spacing={6}>
        <Box>
          <Gauge value={1532} max={1800} subtitle="rpm" radius={largeScreen ? 85 : 70} icon={<SpeedIcon />} />
          <Typography
            sx={{ position: 'relative', bottom: '20px', display: 'flex', justifyContent: 'center' }}
            variant={largeScreen ? 'h5' : 'h6'}>
            {title}
          </Typography>
          <Typography variant="body2" textAlign="center">
            Engine Runtime <strong>500h</strong>
          </Typography>
        </Box>

        <StatsGrid>
          <StatCategory name="Lube Oil">
            <Stat name="Press." unit="bar" displayUnit="bar" places={2} value={2.65} />
          </StatCategory>

          <StatCategory name="T-Coolant">
            <Stat name="Temp." unit="degC" displayUnit="degC" places={1} value={84.6} />
            <Stat name="Coolant Level." unit="%" value={90} />
          </StatCategory>
        </StatsGrid>

        <Paper elevation={3}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 0,
            }}>
            <LinearGauge value={0} unit="kW" label="Total Effective Power" align="vertical" />
            <ValueBox
              paper={false}
              values={[
                { label: 'L1-L2', value: 0, unit: 'kW' },
                { label: 'L2-L3', value: 0, unit: 'kW' },
                { label: 'L3-L1', value: 0, unit: 'kW' },
              ]}
            />
          </Box>
        </Paper>

        <Paper elevation={3}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 0 }}>
            <LinearGauge value={0} unit="kVA" label="Total Apparent Power" align="vertical" />
            <ValueBox
              paper={false}
              values={[
                { label: 'L1-L2', value: 0, unit: 'V' },
                { label: 'L2-L3', value: 0, unit: 'V' },
                { label: 'L3-L1', value: 0, unit: 'V' },
              ]}
            />
          </Box>
        </Paper>

        <Paper elevation={3}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 0 }}>
            <LinearGauge value={0} unit="var" label="Total Reactive Power" align="vertical" />
            <ValueBox
              paper={false}
              values={[
                { label: 'L1-L2', value: 0, unit: 'A' },
                { label: 'L2-L3', value: 0, unit: 'A' },
                { label: 'L3-L1', value: 0, unit: 'A' },
              ]}
            />
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ p: 1 }}>
          <LinearGauge value={0} unit="Hz" label="Frequency" align="horizontal" />
        </Paper>

        <Paper elevation={3} sx={{ p: 1 }}>
          <LinearGauge value={0} unit="cap" label="Power Factor" align="horizontal" />
        </Paper>
      </Stack>
    </Paper>
  );
}

function Gensets() {
  return (
    <TitledPaper title="Gensets" icon={<QueryStatsOutlined />}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 4,
        }}>
        <GensetsDetail title="G1" />
        <GensetsDetail title="G2" />
      </Box>
    </TitledPaper>
  );
}

export default Gensets;
