import { importSPKI, jwtVerify } from 'jose';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useQuery } from 'react-query';
import { db } from './api';
import checkHasEnv, { Env } from './checkHasEnv';

export type DemoAccessStatus = 'success' | 'no_token' | 'invalid_token' | 'expired_token' | 'check_in_progress' | 'key_unavailable';

async function hasValidDemoAccessToken(token: string | null, publicKey?: string): Promise<DemoAccessStatus> {
  if (!token) {
    return 'no_token';
  }

  if (!publicKey) {
    return 'check_in_progress';
  }

  try {
    const importedKey = await importSPKI(publicKey ?? '', 'RS256');
    const { payload } = await jwtVerify(token, importedKey, {
      algorithms: ['RS256'],
    });

    if (!payload || !payload.exp) {
      return 'invalid_token';
    }

    return payload.exp > Date.now() ? 'success' : 'expired_token';
  } catch (error) {
    return 'invalid_token';
  }
}

function useDemoAccessStatus() {
  const [token, setToken] = useLocalStorage<string | null>('demoAccessToken', null);
  const [demoAccessStatus, setDemoAccessStatus] = useState(token ? 'check_in_progress' : ('no_token' as DemoAccessStatus));
  const [searchParams] = useSearchParams();

  const { data: publicKey, isError } = useQuery(['demoAccessPublicKey'], () => db.get<string>('/demo-access/verificationKey'), {
    enabled: checkHasEnv(Env.Demo),
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    if (searchParams.has('demoAccessToken')) {
      setToken(searchParams.get('demoAccessToken'));
    }
  }, [searchParams, setToken]);

  useEffect(() => {
    if (isError) {
      setDemoAccessStatus('key_unavailable');
    } else {
      hasValidDemoAccessToken(token, publicKey?.data).then(setDemoAccessStatus);
    }
  }, [token, publicKey?.data, isError]);

  return demoAccessStatus;
}

function saveDemoAccessTokenToLocalStorage(accessToken: string | null) {
  if (!accessToken) {
    return;
  }
  localStorage.setItem('demoAccessToken', accessToken);
}

export { hasValidDemoAccessToken, saveDemoAccessTokenToLocalStorage, useDemoAccessStatus };
