import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { ListItemText } from '@mui/material';

interface UserProfileProps {
  firstName: string;
  lastName: string;
  email: string;
  isOpen: boolean;
}

const UserProfile: React.FC<UserProfileProps> = ({ firstName, lastName, email }) => {
  return (
    <Box sx={{ height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Avatar variant="rounded" sx={{ mx: 1 }}>
        {firstName?.charAt(0)}
      </Avatar>
      {firstName || lastName ? (
        <ListItemText primary={`${firstName ?? ''} ${lastName ?? ''}`} secondary={email} />
      ) : (
        <ListItemText primary={email} />
      )}
    </Box>
  );
};
export default UserProfile;
