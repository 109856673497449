import { useCompanyId } from 'context/company';
import { useRemoveUserMutation } from 'hooks/companies/useRemoveUserMutation';
import { useForm } from 'react-hook-form';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { MemberActionProps } from './index';

export function RemoveModal({ member, onSuccess }: MemberActionProps) {
  const companyId = useCompanyId();
  const mutation = useRemoveUserMutation(companyId);
  const {
    register: registerForm,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const confirmText = watch('emailConfirm');
  const onSubmit = () => {
    mutation.mutate(member.id, {
      onSuccess: () => {
        onSuccess?.();
      },
    });
  };

  return (
    <form action="src/app/Companies/AdminViews#" onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Remove User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove {member.email} from the company? Confirm by typing in the users email address.
        </DialogContentText>
        <TextField
          {...registerForm('emailConfirm', {
            validate: {
              sameEmail: v => v === member.email,
            },
          })}
          error={!!errors.emailConfirm}
          helperText={errors.emailConfirm?.message}
          autoFocus
          margin="dense"
          variant="standard"
          label="Confirm Removal"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" color="error" disabled={confirmText !== member.email}>
          Remove User
        </Button>
      </DialogActions>
    </form>
  );
}
