import type { ClientId } from 'context/clientId';
import { useQuery } from 'react-query';
import type { DatumString, LiveValues } from './useLiveData';
import { datumToString } from './useLiveData';
import { cache, timeseries } from 'helpers/api';
import { useVesselConfig } from 'context/vesselConfig';
import { useMemo } from 'react';

export type RunningHours = { [key: number]: number };

async function getRunninghours(clientId: ClientId) {
  const engineIds = [0, 1, 2, 3];
  const topics: { [key: number]: DatumString } = Object.fromEntries(
    engineIds.map(engineNo => [
      engineNo,
      datumToString({
        clientId: clientId,
        source: 'measurement',
        objectId: 'combustion',
        objectIdNo: engineNo,
        pvId: 'runninghours',
        pvIdNo: 0,
      } as const),
    ])
  );
  const response = await cache.post<LiveValues>('api/cache/mget', Object.values(topics));

  const runninghours = Object.fromEntries(
    Object.entries(topics)
      .map(([key, topic]) => [key, response.data?.[topic]?.value])
      .filter(([, value]) => value !== undefined && value !== null)
  );
  return runninghours;
}

function useInstallationRunninghours(): RunningHours {
  const vesselConfig = useVesselConfig();
  const installationRunninghours = useMemo(() => {
    const obj: RunningHours = {};
    Object.entries(vesselConfig.engines).forEach(([key, element]) => {
      if (element.installationRunningHours) {
        obj[key as any] = element.installationRunningHours;
      }
    });
    return obj;
  }, [vesselConfig]);
  return installationRunninghours;
}

function useRunningHoursReactQuery(clientId: ClientId): RunningHours {
  const installationRunningHours = useInstallationRunninghours();
  const res = useQuery(['runninghours', { clientId }], () => getRunninghours(clientId), { staleTime: 60 * 1000 });
  return { ...installationRunningHours, ...(res.data ?? {}) };
}

type RunningHoursTimeSeriesResult = {
  runninghours: number;
};

type UseRunningHoursAtTimeOpts = {
  clientId: ClientId;
  time: Date;
  objectIdNo: number;
};

async function getRunninghoursTimeseries(opts: UseRunningHoursAtTimeOpts) {
  const response = await timeseries.get<RunningHoursTimeSeriesResult>('api/measurement/runninghours', {
    params: opts,
  });
  return response.data.runninghours;
}

export function useRunningHoursTimeseries(opts: UseRunningHoursAtTimeOpts) {
  return useQuery({ queryKey: ['runninghours', opts], queryFn: () => getRunninghoursTimeseries(opts) });
}

export default useRunningHoursReactQuery;
