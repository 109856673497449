import { Box, Typography } from '@mui/material';
import React, { ReactChild, ReactFragment, ReactPortal } from 'react';

type LabeledBoxProps = {
  title: string;
  children?: boolean | ReactChild | ReactFragment | ReactPortal | null;
};
export function LabeledBox(props: LabeledBoxProps) {
  return (
    <Box sx={{ backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', p: 1 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
        {props.title}
      </Typography>
      {props.children}
    </Box>
  );
}
