import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { Task } from 'hooks/useTasks';
import { ExpandMore } from '@mui/icons-material';

import TaskSummary from '../TaskSummary';
import TaskForm from '../TaskForm';
import { useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import TaskDetails from '../TaskDetails';

interface TaskItemProps {
  task: Task;
  handleUpdate: (task: Partial<Task>) => void;
  handleArchive: (id: string) => void;
}

const TaskItem: React.FC<TaskItemProps> = ({ task, handleUpdate, handleArchive }) => {
  const { isAuthenticated } = useAuth();
  const [taskValues, setTaskValues] = useState<Task>({ ...task });
  const [open, setOpen] = useState(false);

  const handleTaskUpdate = (task: Partial<Task>) => {
    setTaskValues(task as Task);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Accordion
        elevation={0}
        sx={{
          borderRadius: 1,
          marginBottom: 1,
          bgcolor: 'background.default',
          boxShadow: 'none',
        }}
        TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <TaskSummary task={taskValues} />
        </AccordionSummary>
        <AccordionDetails>
          <TaskDetails task={taskValues} />
          <TaskForm task={taskValues} isNew={false} setTaskValues={handleTaskUpdate} onSubmit={() => handleUpdate(taskValues)} />
        </AccordionDetails>
        <AccordionActions>
          <Tooltip
            title={
              !isAuthenticated
                ? 'You need to be logged in'
                : task.status !== 'DONE' && task.status !== 'REJECTED'
                ? 'Task Status needs to be Done or Rejected'
                : 'Archive Task'
            }>
            <span>
              <Button
                disabled={(task.status !== 'DONE' && task.status !== 'REJECTED') || !isAuthenticated}
                size="small"
                onClick={handleOpen}>
                Archive
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={isAuthenticated ? 'Update Task' : 'You need to be logged in'}>
            <span>
              <Button size="small" type="submit" form={`task-form-${task._id}`} disabled={!isAuthenticated}>
                Save
              </Button>
            </span>
          </Tooltip>
        </AccordionActions>
      </Accordion>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Archived Tasks will not be shown on the Board. You can revert these changes at a later point.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={() => handleArchive(task._id)} autoFocus>
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskItem;
