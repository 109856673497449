import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

/**
 * Scroll to top when location has changed.
 *
 * @returns {null}
 */
export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
}
