const myWindow = window as any;

// initialize the environment variable object for non browser environments e.g. test
myWindow.__iodock_env__ ||= {};

const missingEnvVariables = new Set<string>();

/**
 * Get environment variable from window.__iodock_env__ or process.env
 * @param {string} key
 * @returns {any}
 */
export const getEnv = (key: string) => {
  const envVariable = myWindow.__iodock_env__[key] || process.env[key];
  if (!envVariable && !missingEnvVariables.has(key)) {
    console.log(`Environment variable "${key}" is not defined`);
    missingEnvVariables.add(key);
  }
  return envVariable;
};

/**
 * Convenience object for environment variables.
 */
export const iodock = {
  env: {
    NODE_ENV: getEnv('NODE_ENV'),
    REACT_APP_ENV: getEnv('REACT_APP_ENV') || getEnv('NODE_ENV'),
    REACT_APP_AUTH_SERVER_URL: getEnv('REACT_APP_AUTH_SERVER_URL'),
    REACT_APP_DB_SERVER_URL: getEnv('REACT_APP_DB_SERVER_URL'),
    REACT_APP_DB_TIMESERIES_SERVER_URL: getEnv('REACT_APP_DB_TIMESERIES_SERVER_URL'),
    REACT_APP_CORE_CACHE_URL: getEnv('REACT_APP_CORE_CACHE_URL'),
  },
};

// TODO (05.06.2023): remove this function
export const printEnv = () => {
  Object.keys(myWindow.__iodock_env__).forEach(key => {
    console.log(`process.env.${key}=${process.env[key]} -> iodock.env.${key}=${myWindow.__iodock_env__[key]}`);
  });
};
