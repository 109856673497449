import { Grid, Paper, Box, Skeleton } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import ScrollableList from 'components/ScrollableList';
import useTasks, { filter, Task } from 'hooks/useTasks';

import Header from './components/Header';
import TaskItem from './components/TaskItem';
import TaskFilter from './components/TaskFilter';
import { useEffect, useState } from 'react';

export interface Params {
  key: string;
  value: string;
}

export default function Board() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<filter>({ archived: false });
  const { tasks, updateTask, addTask, archiveTask } = useTasks(filter);

  const { data, status, refetch } = tasks;

  const handleUpdate = (task: Partial<Task>) => {
    updateTask(task as Task);
  };

  const handleAdd = (task: Partial<Task>) => {
    addTask(task as Task);
  };

  const updateParams = (params: Params[]) => {
    params.forEach(({ key, value }) => {
      if (value === '' || value === undefined || value === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    });
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const level = searchParams.get('level');
    const objectId = searchParams.get('objectId');
    const objectIdNo = searchParams.get('objectIdNo');

    setFilter(old => ({
      ...old,
      ...(level === null || level === undefined || isNaN(parseInt(level, 10)) ? { level: undefined } : { level: parseInt(level, 10) }), //TODO: extract to isValid function
      ...(objectId ? { objectId } : { objectId: undefined }),
      ...(objectIdNo === null || objectIdNo === undefined || isNaN(parseInt(objectIdNo, 10))
        ? { objectIdNo: undefined }
        : { objectIdNo: parseInt(objectIdNo, 10) }),
    }));
    refetch();
  }, [searchParams, refetch]);

  return (
    <>
      <TaskFilter updateParams={updateParams} filter={filter} />
      <Box sx={{ mt: 1 }} />
      <Grid container spacing={1} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Header
              title="Upcoming"
              taskAmount={data?.filter(task => task.status === 'UPCOMING').length}
              status="UPCOMING"
              handleAdd={handleAdd}
            />
            {status === 'loading' ? (
              <Skeleton variant="rectangular" animation="pulse" sx={{ flexGrow: 1 }} />
            ) : (
              <ScrollableList>
                <Box sx={{ px: 1, pt: 2 }}>
                  {data
                    ?.filter(task => task.status === 'UPCOMING')
                    .map(task => (
                      <TaskItem key={task._id} task={task} handleUpdate={handleUpdate} handleArchive={archiveTask} />
                    ))}
                </Box>
              </ScrollableList>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Header
              title="In Progress"
              taskAmount={data?.filter(task => task.status === 'IN PROGRESS').length}
              status="IN PROGRESS"
              handleAdd={handleAdd}
            />
            {status === 'loading' ? (
              <Skeleton variant="rectangular" animation="pulse" sx={{ flexGrow: 1 }} />
            ) : (
              <ScrollableList>
                <Box sx={{ px: 1, pt: 2 }}>
                  {data
                    ?.filter(task => task.status === 'IN PROGRESS')
                    .map(task => (
                      <TaskItem key={task._id} task={task} handleUpdate={handleUpdate} handleArchive={archiveTask} />
                    ))}
                </Box>
              </ScrollableList>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Header
              title="Done/Rejected"
              status="DONE"
              taskAmount={data?.filter(task => task.status === 'DONE' || task.status === 'REJECTED').length}
              handleAdd={handleAdd}
            />
            {status === 'loading' ? (
              <Skeleton variant="rectangular" animation="pulse" sx={{ flexGrow: 1 }} />
            ) : (
              <ScrollableList>
                <Box sx={{ px: 1, pt: 2 }}>
                  {data
                    ?.filter(task => task.status === 'DONE' || task.status === 'REJECTED')
                    .map(task => (
                      <TaskItem key={task._id} task={task} handleUpdate={handleUpdate} handleArchive={archiveTask} />
                    ))}
                </Box>
              </ScrollableList>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Header
              title="On Hold"
              taskAmount={data?.filter(task => task.status === 'ON HOLD').length}
              status="ON HOLD"
              handleAdd={handleAdd}
            />
            {status === 'loading' ? (
              <Skeleton variant="rectangular" animation="pulse" sx={{ flexGrow: 1 }} />
            ) : (
              <ScrollableList>
                <Box sx={{ px: 1, pt: 2 }}>
                  {data
                    ?.filter(task => task.status === 'ON HOLD')
                    .map(task => (
                      <TaskItem key={task._id} task={task} handleUpdate={handleUpdate} handleArchive={archiveTask} />
                    ))}
                </Box>
              </ScrollableList>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
