import { useQuery } from 'react-query';
import { db } from 'helpers/api';
import { CompanyMembershipDto } from 'dtos/companies/company-membership.dto';

async function getMyCompaniesRequest() {
  const { data } = await db.get<CompanyMembershipDto[]>('company/my');
  return data;
}

export function useMyCompanies(options: { enabled?: boolean } = {}) {
  options.enabled ??= true;
  const { data, isLoading, isError, error } = useQuery(['my_companies'], getMyCompaniesRequest, { enabled: options.enabled });
  return { data, isLoading, isError, error };
}
