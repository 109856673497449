import { useCallback } from 'react';
import { useSnackbar } from 'context/snackbar/SnackbarContext';
import { auth } from 'helpers/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export interface PublicUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  isSuperUser: boolean;
}

const getMeRequest = async () => {
  try {
    try {
      await auth.get('users/refresh');
    } catch (e) {}
    const res = await auth.get<PublicUser>('users/me');
    return res.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      return undefined;
    }
    throw e;
  }
};

const logoutRequest = async () => {
  await auth.post('users/logout', {});
};

export function useAuth() {
  const queryClient = useQueryClient();
  const { addSnackbar } = useSnackbar();

  const { data: me, isLoading } = useQuery(['me'], () => getMeRequest(), {
    staleTime: 4.5 * 60 * 1000,
    retry: false,
  });

  const { mutateAsync: logout } = useMutation(logoutRequest, {
    onMutate: async () => {
      await queryClient.cancelQueries(['me']);
      const previousUser = queryClient.getQueryData<PublicUser | undefined>(['me']);
      return { previousUser };
    },
    onSuccess: async () => {
      queryClient.setQueryData<PublicUser | undefined>(['me'], () => undefined);
      await queryClient.invalidateQueries();
    },
    onError: (err, logoutArg, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData<PublicUser | undefined>(['me'], context.previousUser);
      }
      addSnackbar({ variant: 'error', message: 'failed to logout' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['me']);
    },
  });

  return {
    user: me,
    isAuthenticated: !!me,
    isLoading,
    logout,
  };
}

export function useInvalidateMeQuery() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(() => {
    queryClient.invalidateQueries(['me']);
  }, [queryClient]);
  return invalidate;
}
