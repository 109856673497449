import { db } from 'helpers/api';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'context/snackbar/SnackbarContext';

async function changeMembershipRole(company_id: string, user_id: string, role: 'user' | 'admin') {
  await db.post(`company/${company_id}/member/${user_id}/change_role`, { role });
}

export function useChangeRoleMutation(company_id: string) {
  const queryClient = useQueryClient();
  const { addSnackbar } = useSnackbar();

  const mutation = useMutation(
    ({ user_id, role }: { user_id: string; role: 'user' | 'admin' }) => changeMembershipRole(company_id, user_id, role),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['company_members', company_id]);
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'Changed Role!' });
      },
      onError: () => {
        addSnackbar({ variant: 'error', message: 'Could not change role...' });
      },
    }
  );

  return mutation;
}
