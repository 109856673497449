import TimeLineCard from 'components/TimeLineCard';
import type { ClientId } from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import { ChartData } from 'chart.js';
import { useTargetUnits } from 'context/settings';
import { useAlarmThreshold } from '../../../hooks/useAlarmThreshold';

function useEngineVibrationOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const vesselConfig = useVesselConfig();
  const units = useTargetUnits();
  const timeseriesQueries = Object.keys(vesselConfig.engines).map(engineNo => {
    const engineNumber = Number(engineNo);
    const query: TimeseriesSequenceQuery = {
      clientId,
      module: 'calculated',
      objectId: 'gearbox',
      objectIdNo: engineNumber,
      pvId: 'vibrationMax',
      pvIdNo: 0, // todo pvIdNo can be default 0 therefore optional
      resolution,
      from,
      to,
      sourceUnit: units.vibrationDistance,
    };
    return query;
  });

  const [maxVibrationResults, loading, err] = useSequencesData(timeseriesQueries);

  const chartData: ChartData = {
    datasets: maxVibrationResults.map((data, index) => ({
      label: vesselConfig.engines[index].name,
      data: data?.map(queryItem => ({ x: queryItem.x, y: queryItem.y })) ?? [],
      borderColor: color[index],
      borderWidth: 1,
    })),
  };

  const thresholdTopic = { clientId, objectId: 'gearbox', objectNo: `0`, pvId: `vibrationMax` };
  const { data: vibrationThreshold } = useAlarmThreshold(thresholdTopic);
  const options = {
    yAxisLabel: `Vibration [${units.vibrationDistance}]`,
    title: 'Gearbox',
    xAxisLabel: 'Time',
    warningLevel: vibrationThreshold?.warning,
    criticalLevel: vibrationThreshold?.critical,
  };
  return { data: chartData, loading, err, options };
}

export default function VibrationMaxRmsGearbox({ from, to, clientId, resolution }: ChartProps) {
  const { data, loading, err, options } = useEngineVibrationOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={options} data={data} loading={loading} err={err} />;
}
