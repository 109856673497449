import { db } from '../helpers/api';
import { useQuery, UseQueryResult } from 'react-query';

interface Threshold {
  warning: number;
  critical: number;
}

interface ThresholdTopic {
  clientId: string;
  objectId: string;
  objectNo: string;
  pvId: string;
}

export interface AlarmThreshold extends ThresholdTopic, Threshold {}

async function getAlarmThreshold(thresholdTopic: ThresholdTopic): Promise<AlarmThreshold | undefined> {
  const { data } = await db.get<AlarmThreshold[]>(`/config/alarm-threshold/${thresholdTopic.clientId}`);

  return data.find(
    alarmThreshold =>
      alarmThreshold.objectId === thresholdTopic.objectId &&
      alarmThreshold.objectNo === thresholdTopic.objectNo &&
      alarmThreshold.pvId === thresholdTopic.pvId
  );
}

export function useAlarmThreshold(thresholdTopic: ThresholdTopic): UseQueryResult<AlarmThreshold> {
  return useQuery(['alarmThreshold', thresholdTopic], () => getAlarmThreshold(thresholdTopic));
}
