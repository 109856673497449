import React from 'react';
import { Box, Button, Checkbox, Container, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'context/snackbar/SnackbarContext';
import { auth } from 'helpers/api';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { APP_BAR_HEIGHT, EMAIL_REGEX } from '../../helpers/constants';
import { useInvalidateMeQuery } from 'hooks/useAuth';
import { NautiqAppBar } from '../NavigationBoilerplate/BoilerplateV2';
import checkHasEnv, { Env } from '../../helpers/checkHasEnv';
import { useQueryClient } from 'react-query';

export interface LoginValues {
  email: string;
  password: string;
}

export function LoginForm() {
  const [searchParams, _] = useSearchParams();
  const { addSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const invalidateMe = useInvalidateMeQuery();
  const queryClient = useQueryClient();
  const {
    register: registerForm,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginValues>();

  const onSubmit = (data: LoginValues) => {
    auth
      .post('/users/login', data)
      .then(async data => {
        invalidateMe();
        await queryClient.invalidateQueries('my-companies');
        const redirectUrl = searchParams.get('redirectUrl');

        if (redirectUrl) {
          const url = new URL(decodeURIComponent(redirectUrl));
          let withoutHost = url.pathname.toString();
          if (url.searchParams.toString() !== '') {
            withoutHost += '?' + url.searchParams.toString();
          }
          navigate(withoutHost);
        } else {
          navigate('/');
        }
        return data;
      })
      .catch(e => {
        const message = ['Failed to login...', 'Reason: ', e?.response?.data?.message].join(' ');
        addSnackbar({ variant: 'error', message });
      });
  };

  return (
    <Box>
      <form
        method="post"
        action="app/Auth/Login#"
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', gap: 24, marginBottom: 24 }}>
        <TextField
          {...registerForm('email', {
            required: {
              value: true,
              message: 'Email is required',
            },
            pattern: {
              value: EMAIL_REGEX,
              message: 'Email is invalid',
            },
          })}
          id="email"
          label="Email"
          fullWidth
          variant="standard"
          type="string"
          error={!!errors.email}
          helperText={errors.email?.message}
          defaultValue={checkHasEnv(Env.Demo) ? 'max.mustermann@example.com' : ''}
        />
        <TextField
          {...registerForm('password', {
            required: {
              value: true,
              message: 'Password is required',
            },
          })}
          id="password"
          label="Password"
          fullWidth
          variant="standard"
          type="password"
          error={!!errors.password}
          helperText={errors.password?.message}
          defaultValue={checkHasEnv(Env.Demo) ? 'demo' : ''}
        />
        <FormControlLabel control={<Checkbox id="rememberMe" name="rememberMe" />} label="Remember me" />
        <Button type="submit" variant="contained" sx={{ width: '100%' }}>
          Log In
        </Button>
      </form>
      <Typography variant="body2">
        {/*
          // TODO: figure out how to do GDPR save register :)
          Not registered yet? <Link to="/register">Register!</Link>
        */}
      </Typography>
    </Box>
  );
}

export function LoginAppBar() {
  return <NautiqAppBar showSettings={false} showUserMngmt={false} />;
}

export function Login() {
  return (
    <>
      <Paper>
        <LoginAppBar />
      </Paper>
      <Box id={'route-box'} sx={{ height: `calc(100vh - ${APP_BAR_HEIGHT}px)` }}>
        <Container maxWidth="sm">
          <Box minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} display="flex" flexDirection="column" justifyContent="center">
            <Typography
              variant="h4"
              sx={{
                mb: 3,
                fontWeight: 'bold',
              }}>
              Login
            </Typography>
            <LoginForm />
            <Link to="/request-password-reset">Forgot your password?</Link>
          </Box>
        </Container>
      </Box>
    </>
  );
}
