import React, { useContext } from 'react';

export type AnalysisDateRangeCtx = {
  start: Date;
  end: Date;
  resolution: number;
  setCustom: (start: Date, end: Date) => void;
};

export const AnalysisDateRangeContext: React.Context<AnalysisDateRangeCtx> = React.createContext({
  start: new Date(),
  end: new Date(),
  resolution: 1,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCustom: (_start, _end) => {},
});

export function useAnalysisDateRange() {
  return useContext(AnalysisDateRangeContext);
}
