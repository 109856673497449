import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Typography, Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getOperatingStateMap } from '../../../helpers/battery';

type StatusCarouselProps = {
  operatingState: number;
};

const operatingStateMap = getOperatingStateMap();
const operatingStates = Object.values(operatingStateMap);

const StatusCarousel: React.FC<StatusCarouselProps> = ({ operatingState }) => {
  const sliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    if (sliderRef.current) {
      const statusIndex = Object.keys(operatingStateMap).indexOf(operatingState.toString());
      sliderRef.current.slickGoTo(statusIndex);
    }
  }, [operatingState]);

  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    arrows: false,
    focusOnSelect: false,
    variableWidth: true,
    swipe: false,
  };

  const translatedOperatingState = operatingStateMap[operatingState] ?? 'Unknown';

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        maskImage: 'linear-gradient(to right, transparent, black 30%, black 70%, transparent)',
      }}>
      <Slider ref={sliderRef} {...settings}>
        {operatingStates.map((status, index) => (
          <Typography
            key={index}
            variant="body1"
            p={1}
            sx={{
              textAlign: 'center',
              color: status === translatedOperatingState ? 'primary.main' : 'inherit',
              fontWeight: status === translatedOperatingState ? 'bold' : 'normal',
              textTransform: status === translatedOperatingState ? 'uppercase' : 'none',
              opacity: status === translatedOperatingState ? 1 : 0.5,
            }}>
            {status}
          </Typography>
        ))}
      </Slider>
    </Box>
  );
};

export default StatusCarousel;
