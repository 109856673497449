import React from 'react';

import { Grid } from '@mui/material';

//Lubrication
import LubeOilTempOverTime from '../Charts/LubeOilTempOverTime';
import LubeOilPressureOverTime from '../Charts/LubeOilPressureOverTime';
import LubeOilQualtiyOverTime from '../Charts/LubeOilQualityOverTime';
import { LubeOilPressureOverLoad } from '../Charts/LubeOilPressureOverLoad';
import { LubeOilTemperatureOverLoad } from '../Charts/LubeOilTemperatureOverLoad';
import EnginePowerOverTime from '../Charts/EnginePowerOverTime';
import MapPosition from '../MapPosition';

import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export default function EngineLubOil() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();
  const vesselProperties = useVesselFeatures(clientId);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={6}>
          <EnginePowerOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        {vesselProperties.includes('cockpitMap') && (
          <Grid item xs={12} lg={6}>
            <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <LubeOilTempOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LubeOilTemperatureOverLoad clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LubeOilPressureOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LubeOilPressureOverLoad clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LubeOilQualtiyOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
      </Grid>
    </>
  );
}
