import { Done, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';

interface FilterButtonProps {
  values: string[];
  setValue: (value: string) => void;
  selectedValue: string;
  label: string;
}

const FilterButton: React.FC<FilterButtonProps> = ({ values, selectedValue, setValue, label }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handelSelect = (value: string) => {
    setValue(value);
    handleClose();
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="outlined" onClick={openMenu} endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
        <Typography>{selectedValue ? selectedValue : label}</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        elevation={2}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MenuItem key="default" onClick={() => handelSelect('')}>
          {!selectedValue ? <Done fontSize="small" /> : <Box sx={{ width: 20 }} />}
          <Box sx={{ ml: 1 }}>
            <span>{label}</span>
          </Box>
        </MenuItem>
        {values.map(value => (
          <MenuItem key={value} onClick={() => handelSelect(value)}>
            {selectedValue === value ? <Done fontSize="small" /> : <Box sx={{ width: 20 }} />}
            <Box sx={{ ml: 1 }}>
              <span>{value}</span>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FilterButton;
