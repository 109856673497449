import { NotificationDetails } from 'app/Notifications/NotificationDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { PlantTabBar } from '../PlantTabBar';
import Box from '@mui/material/Box';

export function AlarmDetails() {
  const { alarmId, clientId } = useParams();
  const navigate = useNavigate();

  if (!clientId) {
    throw new Error('AlarmDetails :clientId path param is missing');
  }

  return (
    <>
      <PlantTabBar />
      <Box sx={{ p: 4 }}>
        <NotificationDetails clientId={clientId} id={alarmId} navigate={navigate} isEnergyAlarm />
      </Box>
    </>
  );
}
