import axios, { AxiosError } from 'axios';
import { iodock } from './environment';

const auth = axios.create({
  baseURL: iodock.env.REACT_APP_DB_SERVER_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

async function errorAuthRefresh(error: AxiosError) {
  if (error.config && error.response && error.response.status === 401) {
    await auth.get(`users/refresh`).catch(_error => {
      const loginUrl = `/login?redirectUrl=${encodeURIComponent(window.location.href)}`;
      if (window.location.href !== loginUrl) {
        window.location.assign(loginUrl);
      }
    });
    return axios.request(error.config);
  }
  return Promise.reject(error);
}

const cache = axios.create({
  baseURL: iodock.env.REACT_APP_CORE_CACHE_URL,
  withCredentials: true,
});
cache.interceptors.response.use(response => response, errorAuthRefresh);

const timeseries = axios.create({
  baseURL: iodock.env.REACT_APP_DB_TIMESERIES_SERVER_URL,
  withCredentials: true,
});
timeseries.interceptors.response.use(response => response, errorAuthRefresh);

const db = axios.create({
  baseURL: iodock.env.REACT_APP_DB_SERVER_URL,
  withCredentials: true,
});
db.interceptors.response.use(response => response, errorAuthRefresh);

export { auth, cache, timeseries, db };
