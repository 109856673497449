import { useParams } from 'react-router-dom';
import { Box, Skeleton, Typography } from '@mui/material';
import LoadProfileChart from './LoadProfileChart';
import useClientId from 'context/clientId';
import { useRunningHoursTimeseries } from 'hooks/useRunningHours';
import { BuildOutlined, FactCheckOutlined, QueryStatsOutlined } from '@mui/icons-material';
import TitledPaper from 'components/TitledPaper';
import { useVesselConfig } from 'context/vesselConfig';
import { useLoadProfile } from 'hooks/useLoadProfile';
import { calculateLoadFactor, calculateLoadIndicator } from './utils';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import WearParameter from './WearParameter';
import { useVesselFeatures } from 'hooks/useVesselFeatures';
import { DistributionTable } from './DistributionTable';

export default function LoadProfileDetails() {
  const { objectNo: objectNoStr } = useParams();
  const objectNo = objectNoStr ? parseInt(objectNoStr, 10) : 0;
  const clientId = useClientId();
  const { start, end } = useAnalysisDateRange();
  const config = useVesselConfig();
  const vesselProperties = useVesselFeatures(clientId);

  const startRunningHours = useRunningHoursTimeseries({ clientId, objectIdNo: objectNo, time: start });
  const endRunningHours = useRunningHoursTimeseries({ clientId, objectIdNo: objectNo, time: end });

  const { data, isLoading } = useLoadProfile({
    from: start,
    to: end,
    clientId,
    objectIdNo: objectNo,
  });

  if (!objectNoStr) {
    return <Typography>Missing object number</Typography>;
  }

  const loadFactor = data ? calculateLoadFactor(data) : undefined;
  const loadIndicator = data ? calculateLoadIndicator(data) : undefined;
  const advancedView = vesselProperties.includes('advancedLoadProfile');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TitledPaper
        icon={<QueryStatsOutlined />}
        title={`Load Profile ${config.engines[objectNo]?.name} ${config.engines[objectNo]?.serialNo}`}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}>
          {advancedView ? (
            <AdvancedLeftSide
              actualTBO="9k"
              agreedTBO="18k"
              endRunningHours={endRunningHours.data}
              startRunningHours={startRunningHours.data}
            />
          ) : (
            <BasicLeftSide endRunningHours={endRunningHours.data} startRunningHours={startRunningHours.data} />
          )}
          <Box
            sx={{
              width: '50%',
            }}>
            <LoadProfileChart data={data} isLoading={isLoading} currentLF={loadFactor} />
          </Box>
          <Box>
            {!loadFactor || !loadIndicator ? (
              <Skeleton sx={{ width: 150, height: 80 }} />
            ) : advancedView ? (
              <AdvancedRightSide loadFactor={loadFactor} loadIndicator={loadIndicator} />
            ) : (
              <BasicRightSide loadFactor={loadFactor} loadIndicator={loadIndicator} />
            )}
          </Box>
        </Box>
      </TitledPaper>
      {advancedView && <DistributionTable />}
    </Box>
  );
}

type BasicLeftSideProps = {
  startRunningHours?: number;
  endRunningHours?: number;
};

function BasicLeftSide({ startRunningHours, endRunningHours }: BasicLeftSideProps) {
  const operationHours = startRunningHours && endRunningHours ? `${endRunningHours - startRunningHours}h` : undefined;
  return <Stat icon={<FactCheckOutlined />} name="Operation Hours" value={operationHours} />;
}

type AdvancedLeftSideProps = BasicLeftSideProps & {
  agreedTBO?: string;
  actualTBO?: string;
};

function AdvancedLeftSide(props: AdvancedLeftSideProps) {
  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', justifyContent: 'space-between' }}>
      <Stat icon={<FactCheckOutlined />} name="Agreed TBO" value={props.agreedTBO} />
      <Stat icon={<BuildOutlined />} name="Actual TBO" value={props.actualTBO} />
      <BasicLeftSide endRunningHours={props.endRunningHours} startRunningHours={props.startRunningHours} />
    </Box>
  );
}

type StatProps = {
  name: string;
  value?: any;
  icon: React.ReactNode;
};

function Stat(props: StatProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
      <Typography>{props.name}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        {props.icon}
        {props.value ? (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}>
            {props.value}
          </Typography>
        ) : (
          <Skeleton height={32} width={70} />
        )}
      </Box>
    </Box>
  );
}

type BasicRightSideProps = {
  loadIndicator: number;
  loadFactor: number;
};

function BasicRightSide({ loadFactor, loadIndicator }: BasicRightSideProps) {
  return (
    <>
      <Typography>
        Load Indicator <b>{loadIndicator.toFixed(2)}</b>
      </Typography>
      <Typography>
        Load Factor <b>{loadFactor.toFixed(2)}</b>
      </Typography>
    </>
  );
}

type AdvancedRightSideProps = BasicRightSideProps;

function AdvancedRightSide({ loadFactor, loadIndicator }: AdvancedRightSideProps) {
  return (
    <>
      <Typography>Wear Parameter</Typography>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'end' }}>
        <Typography>Load Indicator</Typography>
        {loadIndicator && loadFactor && (
          <WearParameter
            currentLF={loadFactor}
            currentLI={loadIndicator}
            thresholds={[
              { LI: 73, LF: 22, name: '9K' } /*{ TODO: get thresholds and names from customers }*/,
              { LI: 62, LF: 12, name: '18K' },
              { LI: 38, LF: 3, name: '24K' },
              { LI: 0, LF: 0, name: '27K' },
            ]}
            customUpperBound={85}
          />
        )}
        <Typography>Load Factor</Typography>
      </Box>
    </>
  );
}
