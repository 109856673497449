import React from 'react';

import type { ClientId } from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';
import { useTargetUnits } from 'context/settings';

export enum Bank {
  A = 'A',
  B = 'B',
}

type ChartEngineProps = ChartProps & {
  bank: Bank;
};

function useMeanExhaustgasOverTime(clientId: ClientId, from: Date, to: Date, resolution: number, bank: Bank) {
  const units = useTargetUnits();
  const vesselConfig = useVesselConfig();
  const queries: TimeseriesSequenceQuery[] = Object.keys(vesselConfig.engines).map(engineNo => ({
    clientId,
    module: 'calculated',
    objectId: 'combustion',
    objectIdNo: Number(engineNo),
    pvId: `tempExhaustgasMeanBank${bank}`,
    pvIdNo: 0,
    resolution,
    from,
    to,
    sourceUnit: 'degC',
    targetUnit: units.temperature,
  }));

  const [datas, loading, err] = useSequencesData(queries);

  const data = {
    datasets: datas.map((data, i) => ({
      label: vesselConfig.engines[i].name,
      data: data ?? [],
      borderColor: color[i],
      borderWidth: 1,
    })),
  };

  const options = { yAxisLabel: `Temperature [${units.temperature}]`, xAxisLabel: 'Time', title: `Mean Exhaustgas Temp. Bank ${bank}` };
  return { data, loading, err, options };
}

function MeanExhaustgasOverTime({ clientId, from, to, resolution, bank }: ChartEngineProps) {
  const { data, loading, err, options } = useMeanExhaustgasOverTime(clientId, from, to, resolution, bank);
  return (
    <div style={{ marginBottom: 8 }}>
      <TimeLineCard options={options} aspectRatio={9} data={data} loading={loading} err={err} />
    </div>
  );
}

export default MeanExhaustgasOverTime;
