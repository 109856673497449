import React, { useMemo } from 'react';

import { useTheme } from '@mui/system';
import { Box, Paper, Skeleton, Typography } from '@mui/material';

import ClipCircle from 'components/ClipCircle';

import { byEngineDisplayOrder, useVesselConfig } from 'context/vesselConfig';
import type { EngineConfig } from 'hooks/useEngineConfig';
import useClientId from 'context/clientId';
import useRunningHours from 'hooks/useRunningHours';
import useWorkplans, { useWorkplansDueAt, Workplan, WorkplanDueMap } from 'hooks/useWorkplans';

interface MaintenanceStatus {
  remaining: number;
  limit: number;
}

const emptyStatus: MaintenanceStatus = { remaining: 0, limit: 0 };

type TBOViewProps = {
  status: MaintenanceStatus;
};

function TBOCircleView({ status }: TBOViewProps) {
  const theme = useTheme();

  const hours = status;
  const hoursStatus = {
    ...hours,
    unit: hours.remaining === 1 ? 'hour' : 'hours',
    percentage: (100 * hours.remaining) / hours.limit,
  };

  const calcLevel = (p: number) => (p < 15 ? 'critical' : p < 35 ? 'warning' : 'ok');
  const gradient = {
    ok: theme.palette.ok.light,
    warning: theme.palette.caution.light,
    critical: theme.palette.critical.light,
  }[calcLevel(hoursStatus.percentage)];
  const darkGradient = {
    ok: theme.palette.ok.main,
    warning: theme.palette.caution.main,
    critical: theme.palette.critical.main,
  }[calcLevel(hoursStatus.percentage)];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ClipCircle percent={hoursStatus.percentage} sx={{ minWidth: '7em' }} color={gradient} darkColor={darkGradient}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ textAlign: 'center' }}>{hoursStatus.remaining.toLocaleString()}</span>
          <span style={{ textAlign: 'center' }}>{hoursStatus.unit}</span>
        </div>
      </ClipCircle>
    </Box>
  );
}

function dueAt(interval: number, current: number): number {
  return Math.ceil((current - 1) / interval) * interval;
}

type DueReduce = {
  intervalRunninghours: number;
  dueRunninghours: number;
};

const minWorkplanData = (min: DueReduce | undefined, cur: DueReduce): DueReduce => {
  if (!min) {
    return cur;
  }
  return {
    intervalRunninghours: Math.min(min.intervalRunninghours, cur.intervalRunninghours),
    dueRunninghours: Math.min(min.dueRunninghours, cur.dueRunninghours),
  };
};

function calcWorkplanReduced(level: number, runningHours: number, wp: Workplan[], wpDueAt: WorkplanDueMap) {
  const res = wp
    .filter(e => e.items[0].level === level)
    .map(e => ({
      intervalRunninghours: e.items[0].intervalRunninghours ?? 0,
      dueRunninghours: wpDueAt[e._id]?.dueRunninghours ?? dueAt(e.items[0].intervalRunninghours ?? 0, runningHours),
    }))
    .reduce(minWorkplanData, undefined);

  if (!res) {
    return undefined;
  }
  return {
    limit: res.intervalRunninghours,
    remaining: res.dueRunninghours - runningHours,
  };
}

function EngineTBO({ engineConfig, objectIdNo }: { engineConfig: EngineConfig; objectIdNo: number }) {
  const clientId = useClientId();
  const currentRunningHours = useRunningHours(clientId);
  const runningHours = currentRunningHours[objectIdNo] ?? 0;

  const { workplans } = useWorkplans({ clientId, objectId: 'engine', objectIdNo, archived: false });
  const wpDueAt = useWorkplansDueAt({ clientId, objectId: 'engine', objectIdNo, archived: false });

  const ql1 = useMemo(
    () => calcWorkplanReduced(1, runningHours, workplans.data ?? [], wpDueAt.data ?? {}),
    [runningHours, workplans.data, wpDueAt.data]
  );
  const ql3 = useMemo(
    () => calcWorkplanReduced(3, runningHours, workplans.data ?? [], wpDueAt.data ?? {}),
    [runningHours, workplans.data, wpDueAt.data]
  );
  const ql4 = useMemo(
    () => calcWorkplanReduced(4, runningHours, workplans.data ?? [], wpDueAt.data ?? {}),
    [runningHours, workplans.data, wpDueAt.data]
  );

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography variant="h6" align="center">
        {engineConfig.name} {engineConfig.serialNo}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 1 }}>
        <span>Operating Hours</span>
        <div>{runningHours?.toLocaleString() ?? <Skeleton variant="text" />}</div>
        <span>h</span>
      </Box>
      <Maintenance status={ql1} name={'QL1'}></Maintenance>
      <Maintenance status={ql3} name={'QL3'}></Maintenance>
      <Maintenance status={ql4} name={'QL4'}></Maintenance>
    </Box>
  );
}

function Maintenance({ status, name }: { status?: MaintenanceStatus; name: string }) {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography sx={{ paddingBottom: 1 }} variant="body2" align="center">
        {name} Maintenance in
      </Typography>
      <TBOCircleView status={status ?? emptyStatus} />
    </Box>
  );
}

function TBO() {
  const vesselConfig = useVesselConfig();
  const enginesSorted = Object.entries(vesselConfig?.engines).sort(byEngineDisplayOrder);
  return (
    <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(280px, 1fr))" gap={2}>
      {enginesSorted.map(([key, engineConfig]) => {
        const objectIdNo = Number.parseInt(key);
        return (
          <Paper key={key} sx={{ minWidth: '250px' }}>
            <EngineTBO engineConfig={engineConfig} objectIdNo={objectIdNo}></EngineTBO>
          </Paper>
        );
      })}
    </Box>
  );
}

export default TBO;
