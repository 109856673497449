import { Box, InputAdornment, MenuItem, TextField } from '@mui/material';
import { Task } from 'hooks/useTasks';
import { Alarm } from '@mui/icons-material';
import EnginePicker from 'components/EnginePicker';
import useRunningHours from 'hooks/useRunningHours';
import useClientId from 'context/clientId';

interface TaskFormProps {
  task: Partial<Task>;
  isNew: boolean; // indicates weither the form is used to create a new Task or update an existing one
  setTaskValues: (task: Partial<Task>) => void;
  onSubmit: (task: Partial<Task>) => void;
}

const TaskForm: React.FC<TaskFormProps> = ({ task, isNew, setTaskValues, onSubmit }) => {
  const runningHours = useRunningHours(useClientId());
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    task &&
      setTaskValues({
        ...task,
        [event.target.name]: event.target.value,
      });
  };

  const handleIntChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    task &&
      setTaskValues({
        ...task,
        [event.target.name]: parseInt(event.target.value, 10),
      });
  };

  const handleEngineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    task &&
      setTaskValues({
        ...task,
        objectId: 'engine',
        objectIdNo: parseInt(event.target.value),
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(task);
  };

  return (
    <form id={task._id ? `task-form-${task._id}` : 'new-task-form'} onSubmit={handleSubmit}>
      {!task.wpId && (
        <TextField
          name="title"
          size="small"
          variant="standard"
          label="Title"
          value={task.title}
          disabled={!task.custom}
          onChange={handleChange}
          sx={{
            mb: 1,
          }}
          required
          fullWidth
        />
      )}
      {(isNew || task.details || task.custom) && (
        <TextField
          name="details"
          size="small"
          variant="standard"
          label="Details"
          value={task.details}
          disabled={!task.custom}
          onChange={handleChange}
          sx={{
            mb: 1,
          }}
          multiline
          fullWidth
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1,
        }}>
        <TextField
          size="small"
          name="status"
          variant="standard"
          label="Status"
          value={task.status}
          onChange={handleChange}
          inputProps={{
            name: 'status',
          }}
          fullWidth
          required
          select>
          <MenuItem value={'UPCOMING'}>Upcoming</MenuItem>
          <MenuItem value={'IN PROGRESS'}>In Progress</MenuItem>
          <MenuItem value={'DONE'}>Done</MenuItem>
          <MenuItem value={'REJECTED'}>Rejected</MenuItem>
          <MenuItem value={'ON HOLD'}>On Hold</MenuItem>
        </TextField>
        {(task.level || isNew) && (
          <TextField
            variant="standard"
            value={task.level || ''}
            size="small"
            label="QL"
            onChange={handleChange}
            disabled={!task.custom}
            inputProps={{
              name: 'level',
            }}
            sx={{
              ml: 1,
            }}
            required
            fullWidth
            select>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </TextField>
        )}
      </Box>
      <EnginePicker setEngine={handleEngineChange} engine={task.objectIdNo} disabled={!task.custom} />
      {task.objectIdNo !== undefined && task.objectIdNo !== null && (
        <TextField
          name="dueRunninghours"
          size="small"
          variant="standard"
          label="Due Engine Hours"
          value={task.dueRunninghours}
          disabled={!task.custom}
          onChange={handleIntChange}
          type="number"
          helperText={`Current Operating Hours: ${runningHours[task.objectIdNo]}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Alarm style={{ fontSize: 18 }} />
              </InputAdornment>
            ),
          }}
          sx={{
            mb: 1,
          }}
          fullWidth
          required
        />
      )}
    </form>
  );
};

export default TaskForm;
