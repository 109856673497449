import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import useClientId from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { useLiveDataMap } from 'hooks/useLiveData';
import { useMemo } from 'react';

type ChartProps = {
  percentage: number;
  sx?: SxProps<Theme>;
};

/// splits a percentage value (0 to 1) into `numCandles` boxes, which are proportionally filled
export function makeCandles(percentage: number, numCandles: number): { active: boolean; fillState: number }[] {
  const scale = percentage * numCandles;
  const candles = Array.from({ length: numCandles }).map((item, i) => {
    const active = Math.floor(scale) === i;
    let fillState = 0;
    if (scale > i) {
      fillState = 1;
    }
    if (active) {
      fillState = scale % 1;
    }
    return { active, fillState };
  });

  return candles;
}

function Chart({ percentage, sx = [] }: ChartProps) {
  const candles = useMemo(() => makeCandles(percentage, 10), [percentage]);

  return (
    <Box
      sx={[
        {
          width: '312px',
          height: '116px',
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '8px',
        },
      ]}>
      {candles.map(({ active, fillState }, i) => {
        const width = fillState;
        return (
          <div
            key={i}
            style={{
              position: 'relative',
              flexGrow: 1,
              width: '26px',
              height: active ? '100%' : '77.5%',
              backgroundColor: 'transparent',
              // border: '2px solid',
              boxShadow: '0px 0px 0px 2px rgba(120, 120, 120) inset',
              padding: '2px',
              transition: 'height 1s ease-in-out',
            }}>
            <div
              style={{
                boxSizing: 'border-box',
                alignSelf: 'flex-start',
                backgroundColor: 'rgb(120, 120, 120)',
                width: `${width * 100}%`,
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                transition: 'width 1s ease-in-out',
              }}
            />
          </div>
        );
      })}
    </Box>
  );
}

/// averages a list of numbers, returns undefined when empty
export function average(values: number[]): number | undefined {
  if (!values.length) {
    return undefined;
  }

  return values.reduce((v, c) => v + c, 0) / values.length;
}

// sums a list of numbers, returns undefined when empty
export function sum(values: number[]): number | undefined {
  if (!values.length) {
    return undefined;
  }

  return values.reduce((v, c) => v + c, 0);
}

/// a simple predicate to check if a value is a number instead of undefined / null
/// can be used with Array.filter to exclude undefined/null from array type
export function isNumber(v: number | undefined | null): v is number {
  return v !== undefined && v !== null;
}

export function useAverage(
  clientId: string,
  objectIdNos: string[],
  { source, objectId, pvId }: { source: 'measurement' | 'calculated'; objectId: string; pvId: string }
) {
  const [liveValues] = useLiveDataMap(
    Object.fromEntries(objectIdNos.map(objectIdNo => [`value${objectIdNo}`, { source, objectId, objectIdNo: +objectIdNo, pvId }])),
    { clientId }
  );
  const values = Object.values(liveValues).map(x => x?.value);
  return average(values.filter(isNumber));
}

export function useLiveSum(
  clientId: string,
  objectIdNos: string[],
  { source, objectId, pvId }: { source: 'measurement' | 'calculated'; objectId: string; pvId: string }
) {
  const [liveValues] = useLiveDataMap(
    Object.fromEntries(objectIdNos.map(objectIdNo => [`value${objectIdNo}`, { source, objectId, objectIdNo: +objectIdNo, pvId }])),
    { clientId }
  );
  const values = Object.values(liveValues).map(x => x?.value);
  return sum(values.filter(isNumber));
}

export function Indicator() {
  const clientId = useClientId();
  const vesselConfig = useVesselConfig();
  const engineIds = Object.keys(vesselConfig.engines);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const chartWidth = isSmall ? '280px' : '312px';
  const chartHeight = isSmall ? '88px' : '116px';
  const loadHeight = '90px';

  const [{ liveLoad }] = useLiveDataMap({ liveLoad: { source: 'calculated', objectId: 'total', pvId: 'load' } }, { clientId });
  const load = liveLoad?.value !== undefined ? Math.round(liveLoad.value * 100) : undefined;
  const rpm = useAverage(clientId, engineIds, { source: 'measurement', objectId: 'combustion', pvId: 'rpm' });
  const power = useLiveSum(clientId, engineIds, { source: 'calculated', objectId: 'combustion', pvId: 'power' });
  const torque = useLiveSum(clientId, engineIds, { source: 'calculated', objectId: 'combustion', pvId: 'torque' });
  const values = [
    {
      value: rpm !== undefined ? Math.round(rpm) : undefined,
      maxValue:
        average(
          Object.values(vesselConfig.engines)
            .map(engine => engine.nominalRPM)
            .filter(isNumber)
        ) ?? 0,
      unit: undefined,
      name: 'RPM',
    },
    {
      value: power !== undefined ? Math.round(power) : undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      maxValue:
        sum(
          Object.values(vesselConfig.engines)
            .map(engine => engine.nominalPower)
            .filter(isNumber)
        ) ?? 0,
      unit: 'kW',
      name: 'Power',
    },
    {
      value: torque !== undefined ? Math.round(torque) : undefined,
      maxValue:
        sum(
          Object.values(vesselConfig.engines)
            .map(engine => engine.nominalTorque)
            .filter(isNumber)
        ) ?? 0,
      unit: 'Nm',
      name: 'Torque',
    },
  ];

  return (
    <div>
      <Box
        sx={{
          position: 'relative',
          display: 'grid',
          gridGap: '1em',
          minWidth: `calc(${chartWidth} + 214px)`,
          gridTemplateColumns: `1fr minmax(${chartWidth}, 1fr)`,
        }}>
        {values.map(v => (
          <div key={v.name} style={{ display: 'contents' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
              <Typography variant="h3">
                {v.value ?? '???'} {v.unit}
              </Typography>
              <Typography variant="h5">{v.name}</Typography>
            </div>
            <Chart
              percentage={(v.value ?? 0) / v.maxValue}
              sx={{
                width: chartWidth,
                height: chartHeight,
              }}
            />
          </div>
        ))}
        <div
          style={{
            gridColumn: 2,
            position: 'absolute',
            top: 0,
            height: `calc(100% - ${loadHeight} - 1em)`,
            width: chartWidth,
            backgroundColor: 'transparent',
          }}>
          <div
            style={{
              position: 'absolute',
              width: '4px',
              top: 0,
              bottom: 0,
              left: `${load ?? 0}%`,
              transition: 'left 1s ease-in-out',
              backgroundImage: 'linear-gradient(to bottom, #1976D2 50%, transparent 50%)',
              backgroundRepeat: 'repeat-y',
              backgroundSize: '100% 48px',
              transform: 'translateX(-50%)',
            }}>
            <svg
              viewBox="0 0 8 8"
              style={{
                position: 'absolute',
                top: '100%',
                width: '16px',
                height: '16px',
                transform: 'translateX(calc(-50% + 2px))',
              }}>
              <polygon points="0,8 8,8 4,1" style={{ fill: '#1976D2', fillRule: 'evenodd' }} />
            </svg>
          </div>
        </div>
        <div
          style={{
            width: chartWidth,
            gridColumn: 2,
            height: loadHeight,
            justifySelf: 'left',
            color: '#1976D2',
            textAlign: 'center',
          }}>
          <Typography variant="h3">{load ?? '???'}%</Typography>
          <Typography variant="h5">Load</Typography>
        </div>
      </Box>
    </div>
  );
}
