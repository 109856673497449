import { Box, Paper, Skeleton, Slide, Tab, Tabs, Typography } from '@mui/material';
import { ProgressBar3D } from 'components/ProgressBar3D';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import HealthIcon from '@mui/icons-material/HealthAndSafety';
import RoundTripIcon from '@mui/icons-material/ChangeCircleOutlined';
import { useLiveDataMap } from 'hooks/useLiveData';
import { useBatteryConfig, useBatteryConfigs } from 'hooks/plant/useBatteryConfig';
import { getOperatingState } from 'helpers/battery';
import { useNavigate, useLocation } from 'react-router-dom';
import BatteryPlant from 'assets/battery_plant.png';
import { useCallback, useEffect, useRef, useState } from 'react';
import SkeletonOnUndefined from '../../components/SkeletonOnUndefined';
import { APP_BAR_HEIGHT, BREADCRUMBS_HEIGHT } from 'helpers/constants';
import { PlantTabBar } from './PlantTabBar';

type BatteryDetailsProps = {
  clientId: string;
  batteryIdNo: number;
};

function isDef<T>(v: T | undefined | null): v is T {
  return v !== undefined && v !== null;
}

function BatteryStat<T>({ value, unit, name, color }: { value: T; unit?: string; name: string; color?: string }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexBasis: 'max-content' }}>
      <Typography variant="h5" color={color}>
        {isDef(value) ? value : <Skeleton sx={{ display: 'inline-block' }} variant="text" width="3em" />} {unit}
      </Typography>
      <Box color={color}>{name}</Box>
    </Box>
  );
}

function BatteryNominals({ clientId, batteryIdNo }: { clientId: string; batteryIdNo: number }) {
  const { data: battery } = useBatteryConfig(clientId, batteryIdNo);

  return (
    <Box sx={{ display: 'flex', gap: 4, m: 2, flexWrap: 'wrap', boxSizing: 'border-box' }}>
      <Box sx={{ display: 'flex', gap: 4, flexGrow: 1, justifyContent: 'space-evenly' }}>
        <BatteryStat value={battery?.nominalCapacity?.toFixed(0)} unit="kWh" name="Nominal Capacity" color="mtu.light" />
        <BatteryStat value={battery?.nominalVoltage?.toFixed(1)} unit="V" name="Nominal Voltage" color="mtu.light" />
        <BatteryStat value={battery?.cRate} unit="C" name="Nominal C-Rate" color="mtu.light" />
      </Box>
    </Box>
  );
}

function BatteryIndicator<T>({
  name,
  icon,
  value,
  unit,
  left = 0,
  right = 100,
  color,
  textColor,
  places = 0,
}: {
  name: string;
  icon?: React.ReactElement;
  value: T;
  unit?: string;
  left?: number;
  right?: number;
  color: string;
  textColor?: string;
  places?: number;
}) {
  right = Math.max(Math.min(right, 100), 0);
  left = Math.max(Math.min(left, 100), 0);

  return (
    <Box sx={{ display: 'contents' }}>
      <Box sx={{ gridColumn: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {icon ? icon : undefined}
        <Typography variant="h3" color={textColor}>
          <SkeletonOnUndefined value={value}>
            {Number(value)?.toFixed(places)} {unit}
          </SkeletonOnUndefined>
        </Typography>
        <Typography variant="h5" color={textColor}>
          {name}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', height: '75%', alignSelf: 'end' }}>
        <ProgressBar3D perspective="800px" left={`${left}%`} right={`${100 - right}%`} barColor={color} />
      </Box>
    </Box>
  );
}

function BatteryIndicators({ clientId, batteryIdNo }: { clientId: string; batteryIdNo: number }) {
  const [{ stateOfCharge, stateOfHealth, depthOfDischarge, roundTripEfficiency }] = useLiveDataMap(
    {
      stateOfCharge: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'state_of_charge',
      },
      stateOfHealth: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'state_of_health',
      },
      depthOfDischarge: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'depth_of_discharge',
      },
      roundTripEfficiency: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'round_trip_efficiency',
      },
    },
    { clientId }
  );

  return (
    <Box
      sx={{
        my: 8,
        display: 'grid',
        gap: 4,
        rowGap: 8,
        gridTemplateColumns: '1fr 2fr 1fr',
        gridAutoRows: '200px',
        alignItems: 'center',
        justifyItems: 'center',
      }}>
      <BatteryIndicator
        name="State of Charge"
        value={stateOfCharge?.value}
        right={stateOfCharge?.value}
        unit="%"
        color="rgba(25, 118, 210, 0.6)"
        textColor="primary"
        icon={<BatteryChargingFullIcon fontSize="large" color="primary" sx={{ transform: 'rotate(90deg)' }} />}
        places={2}
      />
      <BatteryIndicator
        name="Depth of Discharge"
        value={depthOfDischarge?.value}
        left={stateOfCharge?.value}
        right={Math.min(100, stateOfCharge?.value + depthOfDischarge?.value)}
        unit="%"
        color="rgba(99, 100, 102, 0.6)"
        icon={<BatteryFullIcon fontSize="large" sx={{ transform: 'rotate(90deg)' }} />}
        places={2}
      />
      <BatteryIndicator
        name="State of Health"
        value={stateOfHealth?.value}
        right={stateOfHealth?.value}
        unit="%"
        color="rgba(46, 125, 50, 0.6)"
        textColor="success.main"
        icon={<HealthIcon fontSize="large" color="success" />}
        places={2}
      />
      <BatteryIndicator
        name="Round Trip Efficiency"
        value={roundTripEfficiency?.value}
        right={roundTripEfficiency?.value}
        unit="%"
        color="rgba(98, 99, 102, 0.6)"
        icon={<RoundTripIcon fontSize="large" />}
        places={2}
      />
    </Box>
  );
}

function BatteryInfos({ clientId, batteryIdNo }: { clientId: string; batteryIdNo: number }) {
  const [
    {
      operating_state,
      available_power,
      c_rate,
      total_effective_power,
      energy_throughput,
      equivalent_full_cycles,
      battery_rack_voltage_uniformity,
      battery_rack_average_rack_temperature,
      battery_rack_temperature_uniformity,
    },
  ] = useLiveDataMap(
    {
      operating_state: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'operating_state',
      },
      input: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'input',
      },
      output: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'output',
      },
      available_power: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'available_power',
      },
      c_rate: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'c_rate',
      },
      energy_throughput: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'energy_throughput',
      },
      equivalent_full_cycles: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'equivalent_full_cycles',
      },
      battery_rack_voltage_uniformity: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'battery_rack_voltage_uniformity',
      },
      battery_rack_average_rack_temperature: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'battery_rack_average_rack_temperature',
      },
      battery_rack_temperature_uniformity: {
        source: 'measurement',
        objectId: 'battery',
        objectIdNo: batteryIdNo,
        pvId: 'battery_rack_temperature_uniformity',
      },
    },
    { clientId }
  );
  const input = total_effective_power?.value >= 0 ? total_effective_power?.value.toFixed(0) : 0;
  const output = total_effective_power?.value < 0 ? total_effective_power?.value.toFixed(0) : 0;

  return (
    <Box sx={{ my: 8 }}>
      <Box>
        <BatteryStat value={getOperatingState(operating_state?.value)} name="Operating State" />
      </Box>
      <Box sx={{ display: 'flex', gap: 4, m: 4, flexWrap: 'wrap', boxSizing: 'border-box' }}>
        <Box sx={{ display: 'flex', gap: 4, flexGrow: 1, justifyContent: 'space-evenly' }}>
          <BatteryStat value={input} unit="kW" name="Input Power" />
          <BatteryStat value={available_power?.value?.toFixed(0)} unit="kW" name="Available Power" />
          <BatteryStat value={output} unit="kW" name="Output Power" />
        </Box>
        <Box sx={{ display: 'flex', gap: 4, flexGrow: 1, justifyContent: 'space-evenly' }}>
          <BatteryStat value={c_rate?.value?.toFixed(1)} unit="C" name="Current C-Rate" />
          <BatteryStat value={energy_throughput?.value?.toFixed(0)} unit="kWh" name="Energy Throughput PCS" />
          <BatteryStat value={equivalent_full_cycles?.value?.toFixed(0)} unit="" name="Equivalent Full Cycles" />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 4, m: 4, flexWrap: 'wrap', boxSizing: 'border-box' }}>
        <Box sx={{ display: 'flex', gap: 4, flexGrow: 1, justifyContent: 'center' }}>
          <BatteryStat value={battery_rack_voltage_uniformity?.value?.toFixed(3)} unit="V" name="Rack voltage uniformaity" />
          <BatteryStat value={battery_rack_average_rack_temperature?.value?.toFixed(1)} unit="°C" name="Average BESS temp" />
          <BatteryStat value={battery_rack_temperature_uniformity?.value?.toFixed(3)} unit="°C" name="Rack temp uniformity" />
        </Box>
      </Box>
    </Box>
  );
}

function BreadCrumbTabs() {
  return (
    <Paper
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: theme => theme.zIndex.drawer + 1,
        backdropFilter: 'blur(12px)',
      }}>
      <PlantTabBar />
    </Paper>
  );
}

export function BatteryDetails({ clientId, batteryIdNo }: BatteryDetailsProps) {
  const { data: batteries } = useBatteryConfigs(clientId);
  const [showContent, setShowContent] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [, , , , selectedTab] = pathname.split('/');
  const navigate = useNavigate();
  const currentBatteryName = batteries?.find(batt => batt.id === batteryIdNo)?.name;

  const BATTERY_ROUTE = (clientId: string, batteryId: number) => `/plant/${clientId}/battery/${batteryId}`;

  useEffect(() => {
    const contentTimer = setTimeout(() => setShowContent(true), 400);
    const scrollTimer = setTimeout(() => titleRef.current?.scrollIntoView({ behavior: 'smooth' }), 1000);
    return () => {
      clearTimeout(contentTimer);
      clearTimeout(scrollTimer);
    };
  }, []);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: number) => {
      if (batteries && newValue > 0 && newValue <= batteries.length) {
        navigate(BATTERY_ROUTE(clientId, newValue));
      }
    },
    [batteries, clientId, navigate]
  );

  return (
    <Box>
      {/* Breadcrumbs */}
      <Paper sx={{ position: 'sticky', top: 0, zIndex: theme => theme.zIndex.drawer + 1, backdropFilter: 'blur(12px)' }}>
        <BreadCrumbTabs />
      </Paper>

      {/* Tabs */}
      <Paper sx={{ boxShadow: 'none', m: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Tabs value={isNaN(Number(selectedTab)) ? 0 : parseInt(selectedTab)} onChange={handleTabChange}>
          {batteries?.map((b, index) => (
            <Tab key={index + 1} label={b.name} value={b.id} />
          ))}
        </Tabs>
      </Paper>

      {/* Content */}
      <Slide direction="up" in={showContent}>
        <Box
          sx={{
            mt: 4,
            p: 1,
            boxSizing: 'border-box',
            maxWidth: '1366px',
            margin: 'auto',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <img src={BatteryPlant} alt="" />
          </Box>

          <Box
            ref={titleRef}
            sx={{
              scrollMarginTop: APP_BAR_HEIGHT + BREADCRUMBS_HEIGHT,
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h3" color="mtu.main">
                mtu ENERGYPACK QG
              </Typography>
              <Typography variant="h4" color="mtu.light">
                {currentBatteryName}
              </Typography>
            </Box>
          </Box>

          <Box>
            <BatteryNominals clientId={clientId} batteryIdNo={batteryIdNo} />
            <BatteryInfos clientId={clientId} batteryIdNo={batteryIdNo} />
            <BatteryIndicators clientId={clientId} batteryIdNo={batteryIdNo} />
          </Box>
        </Box>
      </Slide>
    </Box>
  );
}
