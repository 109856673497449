import React from 'react';

import { useVesselConfig } from 'context/vesselConfig';
import type { ClientId } from 'context/clientId';
import { TimeseriesQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';
import { useTargetUnits } from 'context/settings';

function useEnginePowerOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const vesselConfig = useVesselConfig();
  const queries: TimeseriesQuery[] = Object.keys(vesselConfig.engines).map(engineNo => ({
    module: 'calculated',
    clientId,
    objectId: 'combustion',
    objectIdNo: Number(engineNo),
    pvId: 'power',
    pvIdNo: 0,
    resolution,
    from,
    to,
    sourceUnit: 'kW',
    targetUnit: units.power,
  }));

  const [datas, loading, err] = useSequencesData(queries);

  const data = {
    datasets: datas.map((data, i) => ({
      label: vesselConfig.engines[i].name,
      data: data ?? [],
      borderColor: color[i],
      borderWidth: 1,
    })),
  };

  const options = { yAxisLabel: `Power [${units.power}]`, xAxisLabel: 'Time', title: 'Engine Power' };
  return { data, loading, err, options };
}

function EnginePowerOverTime({ clientId, from, to, resolution }: ChartProps) {
  const { data, loading, err, options } = useEnginePowerOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={options} aspectRatio={9} data={data} loading={loading} err={err} />;
}

export default EnginePowerOverTime;
