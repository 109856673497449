import { CompanyDto } from 'dtos/companies/company.dto';
import { db } from 'helpers/api';
import { useQuery } from 'react-query';

async function getCompanyRequest(company_id: string) {
  const { data } = await db.get<CompanyDto>(`company/${company_id}`);
  return data;
}

export function useCompany(company_id: string) {
  const { data } = useQuery(['company', company_id], () => getCompanyRequest(company_id));
  return data;
}
