import { timeseries } from 'helpers/api';
import { useQuery } from 'react-query';

type UseLoadProfileParams = {
  clientId: string;
  from: Date;
  to: Date;
  objectIdNo: number;
};

export type LoadProfile = {
  [key: number]: number;
};

export function useLoadProfile(params: UseLoadProfileParams) {
  return useQuery({
    queryKey: ['loadProfile', params],
    queryFn: async () =>
      (
        await timeseries.get<LoadProfile>('/api/load', {
          params,
        })
      ).data,
  });
}
