import { UseQueryResult, useQuery } from 'react-query';
import { db } from 'helpers/api';

export interface BatteryConfig {
  id: number;
  name: string;
  plantId: string;
  nominalCapacity?: number | null;
  usableCapacity?: number | null;
  nominalVoltage?: number | null;
  cutoffVoltage?: number | null;
  cRate?: number | null;
  beginningOfLife?: Date | null;
}

async function getBatteries(plantId: string | undefined) {
  if (!plantId) {
    return [];
  }
  const res = await db.get<BatteryConfig[]>(`/config/battery/${plantId}`);
  return res.data;
}

async function getBattery(plantId: string, batteryId: string | number) {
  const res = await db.get<BatteryConfig>(`config/battery/${plantId}/${batteryId}`);
  return res.data;
}

export function useBatteryConfig(
  plantId: string,
  batteryId: string | number,
  options?: { enabled: boolean }
): UseQueryResult<BatteryConfig, unknown> {
  const batteryQuery = useQuery(['battery', plantId, batteryId], () => getBattery(plantId, batteryId), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    enabled: options?.enabled ?? true,
  });
  return batteryQuery;
}

export function useBatteryConfigs(plantId: string | undefined): UseQueryResult<BatteryConfig[], unknown> {
  const batteries = useQuery(['batteries', plantId], () => getBatteries(plantId), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });
  return batteries;
}
