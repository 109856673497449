import React from 'react';
import FilterButton from 'components/FilterButton';
import { filter } from 'hooks/useTasks';
import { useVesselConfig } from 'context/vesselConfig';
import { Box } from '@mui/system';
import { Params } from '../..';

interface TaskFilterProps {
  updateParams: (params: Params[]) => void;
  filter: filter;
}

const TaskFilter: React.FC<TaskFilterProps> = ({ updateParams, filter }) => {
  const vesselconfig = useVesselConfig();

  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
      <FilterButton
        label="QL"
        selectedValue={filter.level?.toString() || ''}
        setValue={value => {
          updateParams([{ key: 'level', value: value }]);
        }}
        values={['1', '3', '4']}
      />
      <FilterButton
        label="Engine"
        selectedValue={
          filter.objectIdNo !== undefined && filter.objectIdNo !== null
            ? `${vesselconfig.engines[filter.objectIdNo].name} ${vesselconfig.engines[filter.objectIdNo].serialNo}`
            : ''
        }
        setValue={value => {
          if (!value) {
            updateParams([
              { key: 'objectIdNo', value: '' },
              { key: 'objectId', value: '' },
            ]);
          }

          const engineSerialNoStr = value.split(' ')[1];

          const engine = Object.entries(vesselconfig.engines).find(([, engine]) => {
            return engine.serialNo === engineSerialNoStr;
          });
          if (!engine) return;

          updateParams([
            { key: 'objectIdNo', value: engine?.[0] },
            { key: 'objectId', value: 'engine' },
          ]);
        }}
        values={Object.entries(vesselconfig.engines).map(([, engine]) => `${engine.name} ${engine.serialNo}`)}
      />
    </Box>
  );
};

export default TaskFilter;
