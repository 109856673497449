import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';
import type { ClientId } from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { TimeseriesQuery, useSequencesData } from 'hooks/useQueriedData';

function useLubeOilQualityOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const vesselConfig = useVesselConfig();
  const queries: TimeseriesQuery[] = Object.keys(vesselConfig.engines).map(engineNo => ({
    clientId,
    objectId: 'combustion',
    objectIdNo: Number(engineNo),
    pvId: 'oilQuality',
    pvIdNo: 0,
    resolution,
    from,
    to,
  }));

  const [datas, loading, err] = useSequencesData(queries);

  const data = {
    datasets: datas.map((data, i) => ({
      label: vesselConfig.engines[i].name,
      data: data ?? [],
      borderColor: color[i],
      borderWidth: 1,
    })),
  };

  const options = {
    yAxisLabel: 'Quality Index',
    title: 'Lube Oil Quality',
    xAxisLabel: 'Time',
    maxYAxisValue: 20,
    minYAxisValue: 0,
    warningLevel: 14,
    criticalLevel: 18,
  };
  return { data, loading, err, options };
}

function LubeOilQualityOverTime({ clientId, from, to, resolution }: ChartProps) {
  const { data, loading, err, options } = useLubeOilQualityOverTime(clientId, from, to, resolution);

  return <TimeLineCard options={options} data={data} loading={loading} err={err} />;
}

export default LubeOilQualityOverTime;
