import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import GridView from '@mui/icons-material/GridView';
import LinearScale from '@mui/icons-material/LinearScale';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

export function PlantTabBar() {
  return (
    <Paper sx={{ position: 'sticky', top: 0, zIndex: theme => theme.zIndex.drawer + 1, backdropFilter: 'blur(12px)' }}>
      <BreadCrumbTabs />
      <TabBar />
    </Paper>
  );
}

function BreadCrumbTabs() {
  const { clientId } = useParams();
  return (
    <Paper
      sx={{
        boxShadow: 'none',
        height: '34px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <Button
        component={Link}
        to={`/plant/${clientId}`}
        sx={{
          padding: '0px 24px 0px 24px',
          height: '100%',
          color: 'text.primary',
          backgroundColor: 'background.default',
          textTransform: 'none',
        }}>
        Plant Overview
      </Button>
    </Paper>
  );
}

function TabBar() {
  const { clientId } = useParams();
  const { pathname } = useLocation();
  const [, , , selectedTab] = pathname.split('/');

  const isBatteryPath = pathname.includes(`/plant/${clientId}/battery`);

  const activeTab = isBatteryPath ? 'overview' : selectedTab || 'overview';

  return (
    <Paper sx={{ backgroundColor: 'background.default', boxShadow: 'none' }}>
      <Tabs value={activeTab}>
        <Tab
          {...{ variant: 'inlineIcon' }}
          sx={{ padding: '0px 24px 0px 24px', textTransform: 'uppercase', alignItems: 'center' }}
          label="Overview"
          value="overview"
          icon={<GridView sx={{ marginTop: '-2px' }} />}
          component={Link}
          to={`/plant/${clientId}`}
        />

        <Tab
          {...{ variant: 'inlineIcon' }}
          sx={{ padding: '0px 24px 0px 24px', textTransform: 'uppercase', alignItems: 'center' }}
          label="Single-Line"
          value="singleline"
          icon={<LinearScale sx={{ marginTop: '-1px' }} />}
          component={Link}
          to={`/plant/${clientId}/singleline`}
        />

        <Tab
          {...{ variant: 'inlineIcon' }}
          sx={{ padding: '0px 24px 0px 24px', textTransform: 'uppercase', alignItems: 'center' }}
          label="Alarms"
          value="alarms"
          icon={<NotificationsOutlinedIcon sx={{ marginTop: '-2px' }} />}
          component={Link}
          to={`/plant/${clientId}/alarms`}
        />
      </Tabs>
    </Paper>
  );
}
