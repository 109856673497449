import { Box, TextField } from '@mui/material';

function SingleLevel({ type, value, setValue }: { type: string; value?: number; setValue?: (val: number | undefined) => void }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        alignItems: 'center',
      }}>
      <TextField
        label={type}
        id="filled-hidden-label-small"
        value={value ?? ''}
        onChange={e => setValue?.(+e.target.value)}
        variant="standard"
        size="small"
        margin="none"
        type="number"
      />
    </Box>
  );
}

export function ThresholdLevels({
  type,
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
}: {
  type: string;
  minValue?: number;
  maxValue?: number;
  setMinValue?: (val: number | undefined) => void;
  setMaxValue?: (val: number | undefined) => void;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}>
      {type}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
        }}>
        <SingleLevel type="min" value={minValue} setValue={setMinValue} />
        <SingleLevel type="max" value={maxValue} setValue={setMaxValue} />
      </Box>
    </Box>
  );
}
