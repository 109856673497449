import React, { ReactChild, ReactFragment, ReactPortal } from 'react';

import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

import GaugeCalculator from './DefaultGaugeCalculator';
import usePerformanceLiveData, { usePerformanceLiveDataFleet } from '../../hooks/usePerformanceLiveData';
import { useHealthLiveDataFleet } from '../../hooks/useHealthLiveData';

type PropsBase = {
  radius?: number;
  strokeWidth?: number;
  cursorWidth?: number;
  caption?: string;
  icon?: ReactChild | ReactFragment | ReactPortal | null | boolean;
};

type PropsVessel = PropsBase & {
  clientId: string;
};

export const HealthGaugeFleet: React.FC<PropsBase> = ({ radius, strokeWidth, cursorWidth, icon, caption }) => {
  const fleetValues = useHealthLiveDataFleet();
  let indicatorValue;
  const fleetValuesArray = Object.values(fleetValues);
  const fleetValueSum = fleetValuesArray.reduce((previousValue, currentValue) => (previousValue ?? 0) + (currentValue ?? 0), 0);

  if (fleetValueSum !== undefined) {
    indicatorValue = fleetValueSum / fleetValuesArray.length;
  }
  return PerformanceGauge({ indicatorValue, radius, strokeWidth, cursorWidth, icon, caption });
};

export const PerformanceGaugeFleet: React.FC<PropsBase> = ({ radius, strokeWidth, cursorWidth, icon, caption }) => {
  const fleetValues = usePerformanceLiveDataFleet();
  let indicatorValue;
  const fleetValuesArray = Object.values(fleetValues);
  const fleetValueSum = fleetValuesArray.reduce((previousValue, currentValue) => (previousValue ?? 0) + (currentValue ?? 0), 0);

  if (fleetValueSum !== undefined) {
    indicatorValue = fleetValueSum / fleetValuesArray.length;
  }
  return PerformanceGauge({ indicatorValue, radius, strokeWidth, cursorWidth, icon, caption });
};

const PerformanceGaugeVessel: React.FC<PropsVessel> = ({ clientId, radius, strokeWidth, cursorWidth, icon, caption }) => {
  const indicatorValue = usePerformanceLiveData(clientId);
  return PerformanceGauge({ indicatorValue, radius, strokeWidth, cursorWidth, icon, caption });
};

export type PerformanceGaugeProps = PropsBase & {
  indicatorValue?: number;
};

export const PerformanceGauge: React.FC<PerformanceGaugeProps> = ({ indicatorValue, radius, strokeWidth, cursorWidth, icon, caption }) => {
  const theme = useTheme();

  const largeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  let cursorColour = theme.palette.ok.main;

  const viewbox_height = (radius ?? 0) * 2 + (cursorWidth ?? 0);
  const viewbox_width = (radius ?? 0) * 2 + (cursorWidth ?? 0);

  const dash = 2;
  const space = 5;
  const gapTickCountIndicator = 41;
  const gapTickCountBackground = 11;
  const backgroundCalculator = new GaugeCalculator(dash, space, gapTickCountBackground, 10, 10);
  if (Number.isNaN(indicatorValue) || indicatorValue === undefined || indicatorValue === null) {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
        }}>
        <svg data-testid="disabled-gauge" version="1.1" xmlns="http://www.w3.org/2000/svg" height={viewbox_height} width={viewbox_width}>
          <circle
            pathLength={360}
            strokeDasharray={backgroundCalculator.indicatorStrokeDashArray}
            stroke={theme.palette.grey[400]}
            fill="none"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth={strokeWidth}
            transform={`rotate(${backgroundCalculator.rotationDegrees}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
          />
        </svg>
      </Box>
    );
  } else {
    if (indicatorValue <= 2.5) cursorColour = theme.palette.critical.main;
    else if (indicatorValue <= 5) cursorColour = theme.palette.warn.main;
    else if (indicatorValue <= 7.5) cursorColour = theme.palette.caution.main;

    const criticalVal = indicatorValue < 2.5 ? indicatorValue : 2.5;
    const warnVal = indicatorValue <= 2.5 ? 0 : indicatorValue > 5 ? 2.5 : indicatorValue - 2.5;
    const cautionVal = indicatorValue <= 5 ? 0 : indicatorValue > 7.5 ? 2.5 : indicatorValue - 5;
    const okVal = indicatorValue <= 7.5 ? 0 : indicatorValue - 7.5;
    const gaugeCriticalCalculator = new GaugeCalculator(dash, space, gapTickCountIndicator, criticalVal, 2.5);
    const gaugeWarnCalculator = new GaugeCalculator(dash, space, gapTickCountIndicator, warnVal, 2.5);
    const gaugeCautionCalculator = new GaugeCalculator(dash, space, gapTickCountIndicator, cautionVal, 2.5);
    const gaugeokCalculator = new GaugeCalculator(dash, space, gapTickCountIndicator, okVal, 2.5);
    const cursorCalculator = new GaugeCalculator(dash, space, gapTickCountBackground, indicatorValue, 10);

    // 360 - Gap angle, is the same as the Total Tick degrees (tick angle)
    const colorAngleDegrees = backgroundCalculator.indicatorTickDegrees / 4;
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          pb: caption && caption !== '' ? 2 : 0,
          px: 1,
        }}>
        <Box sx={{ position: 'absolute', left: '50%', top: '30%', transform: 'translate(-50%, -50%)' }}>
          <Box sx={{ color: cursorColour }}>{icon}</Box>
        </Box>
        <Box sx={{ position: 'absolute', left: '50%', top: '90%', transform: 'translate(-50%, -50%)' }}>
          <Typography variant={largeScreen ? 'body1' : 'subtitle2'} sx={{ fontWeight: 'bold' }}>
            {caption}
          </Typography>
        </Box>
        <svg data-testid="active-gauge" version="1.1" xmlns="http://www.w3.org/2000/svg" height={viewbox_height} width={viewbox_width}>
          <circle
            pathLength={360}
            strokeDasharray={backgroundCalculator.indicatorStrokeDashArray}
            stroke={theme.palette.grey[400]}
            fill="none"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth={strokeWidth}
            transform={`rotate(${backgroundCalculator.rotationDegrees}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
          />
          <circle
            pathLength={360}
            strokeDasharray={gaugeCriticalCalculator.indicatorStrokeDashArray}
            stroke={theme.palette.critical.main}
            fill="none"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth={strokeWidth}
            transform={`rotate(${backgroundCalculator.rotationDegrees}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
          />
          <circle
            pathLength={360}
            strokeDasharray={gaugeWarnCalculator.indicatorStrokeDashArray}
            stroke={theme.palette.warn.main}
            fill="none"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth={strokeWidth}
            transform={`rotate(${backgroundCalculator.rotationDegrees + colorAngleDegrees}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
          />
          <circle
            pathLength={360}
            strokeDasharray={gaugeCautionCalculator.indicatorStrokeDashArray}
            stroke={theme.palette.caution.main}
            fill="none"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth={strokeWidth}
            transform={`rotate(${backgroundCalculator.rotationDegrees + colorAngleDegrees * 2}, ${viewbox_width / 2}, ${
              viewbox_height / 2
            })`}
          />
          <circle
            pathLength={360}
            strokeDasharray={gaugeokCalculator.indicatorStrokeDashArray}
            stroke={theme.palette.ok.main}
            fill="none"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth={strokeWidth}
            transform={`rotate(${341.5}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
          />
          <circle
            className="cursor"
            pathLength={360}
            strokeDasharray={cursorCalculator.cursorStrokeDashArray}
            stroke={cursorColour}
            fill="none"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth={cursorWidth}
            transform={`rotate(${cursorCalculator.rotationDegrees}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
          />
          {(indicatorValue || indicatorValue === 0) && (
            <text
              data-testid="active-gauge-value"
              textAnchor="middle"
              x="50%"
              y="61%"
              fill={cursorColour}
              style={{
                fill: theme.typography.h5.color,
                fontSize: `${viewbox_width / 4}px`,
                lineHeight: theme.typography.h5.lineHeight,
                letterSpacing: theme.typography.h5.letterSpacing,
                fontWeight: 'bold',
              }}>
              {Math.round(cursorCalculator.value)}
            </text>
          )}
        </svg>
      </Box>
    );
  }
};

PerformanceGaugeVessel.defaultProps = {
  strokeWidth: 8,
  cursorWidth: 18,
  radius: 30,
};

PerformanceGaugeFleet.defaultProps = {
  strokeWidth: 8,
  cursorWidth: 18,
  radius: 30,
};
HealthGaugeFleet.defaultProps = {
  strokeWidth: 8,
  cursorWidth: 18,
  radius: 30,
};

export default PerformanceGaugeVessel;
