export function formatMomentDuration(duration: moment.Duration): string {
  const seconds = Math.floor(duration.asSeconds());
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const result = [];
  if (days > 0) {
    result.push(`${days} days `);
  }

  result.push(
    `${(hours % 24).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:`
  );

  result.push(
    `${(minutes % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:`
  );

  result.push(
    `${(seconds % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`
  );

  return result.join('');
}
