import React from 'react';

import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';

import usePathChild from 'hooks/usePathChild';

import ViewHeader, { TabLabelToPath } from 'components/ViewHeader';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export default function Vessel() {
  const value = usePathChild(false);
  const dateRange = useAnalysisDateRange();
  const clientId = useClientId();
  const vesselProperties = useVesselFeatures(clientId);

  const tabs: TabLabelToPath[] = [
    { label: 'General', toPath: 'general' },
    { label: 'Consumption', toPath: 'consumption' },
    ...(vesselProperties.includes('oilSensor') ? [{ label: 'Lubrication', toPath: 'lubrication' }] : []),
    { label: 'Coolant', toPath: 'coolant' },
    { label: 'Turbo Charger', toPath: 'turbo_charger' },
    { label: 'Exhaust Gas', toPath: 'exhaustgas' },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mt: 2, mb: 2 }}>
        <ViewHeader tabs={tabs} value={value || false} />
      </Box>
      <Outlet context={dateRange} />
    </Box>
  );
}
