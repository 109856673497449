import { BoilerplateV2 } from '../../NavigationBoilerplate/BoilerplateV2';
import { LinearProgress } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import TitledPaper from 'components/TitledPaper';
import { useMyCompanies } from 'hooks/companies/useMyCompanies';

const columns: GridColDef[] = [
  {
    field: 'company_name',
    headerName: 'Company Name',
    flex: 1,
    valueGetter: (_, row) => row?.company?.name,
  },
  {
    field: 'role',
    headerName: 'Your Role',
    flex: 1,
    valueFormatter: (value?: string) => {
      if (typeof value !== 'string') return '';
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
];

export function MyCompaniesPage() {
  const { data, isLoading, isError } = useMyCompanies();
  const navigate = useNavigate();

  const onRowClick: GridEventListener<'rowClick'> = params => {
    navigate(`/company/${params.row.id}`);
  };

  return (
    <BoilerplateV2 overrideBreadcrumbTitle="My Companies" disableTabs>
      <TitledPaper title="My Companies" noPadding>
        {isLoading && <LinearProgress />}
        {isError && <p>An error occurred.</p>}
        {!isLoading && !isError && (
          <DataGrid
            columns={columns}
            rows={data?.map(el => ({ id: el.company_id, ...el })) ?? []}
            classes={{ row: 'clickable-row' }}
            onRowClick={onRowClick}
            autoHeight
            sx={{ border: 'none' }}
          />
        )}
      </TitledPaper>
    </BoilerplateV2>
  );
}
