import { db } from '../helpers/api';
import { useQuery } from 'react-query';

interface ResetTokenIsValidResponse {
  valid: boolean;
  message: string;
}

async function resetTokenIsValidQuery(token: string) {
  const res = await db.get<ResetTokenIsValidResponse>(`/users/check-password-reset-token/${token}`);
  return res.data;
}

export function useResetTokenIsValid(token: string) {
  return useQuery(['reset-token-is-valid', token], () => resetTokenIsValidQuery(token));
}
