import { Box } from '@mui/material';
import { CompanyContext } from 'context/company';

import { Navigate, useParams } from 'react-router-dom';
import { BoilerplateV2 } from '../../NavigationBoilerplate/BoilerplateV2';
import { useCompany } from 'hooks/companies/useCompany';
import { CompanyMembers } from './CompanyMembers';
import { useHasPermissionToManageCompany } from 'hooks/companies/useHasPermissionToManageCompany';
import { FEEDBACK_HEIGHT } from 'helpers/constants';

export function CompanyPage() {
  const { companyId } = useParams();
  const company = useCompany(companyId ?? '');
  const managementPermission = useHasPermissionToManageCompany(companyId ?? '');

  if (managementPermission === 'loading') return <></>;
  if (managementPermission === 'unauthenticated') return <Navigate to="/login" replace />;

  return (
    <BoilerplateV2 overrideBreadcrumbTitle={`User Management: ${company?.name ?? ''}`} disableTabs>
      <Box sx={{ position: 'relative', p: 2, pb: `${FEEDBACK_HEIGHT}px`, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <CompanyContext.Provider value={companyId as any}>
          {managementPermission === 'permitted' ? <CompanyMembers /> : <p>You are not an admin of this company.</p>}
        </CompanyContext.Provider>
      </Box>
    </BoilerplateV2>
  );
}
