import { useQuery } from 'react-query';
import { db } from 'helpers/api';
import { CompanyDto } from 'dtos/companies/company.dto';

async function getAllCompaniesRequest() {
  const { data } = await db.get<CompanyDto[]>('company/all');
  return data;
}

export function useAllCompanies() {
  const { data, isLoading, isError, error } = useQuery(['all_companies'], getAllCompaniesRequest);
  return { data, isLoading, isError, error };
}
