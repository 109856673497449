import { timeseries } from 'helpers/api';
import { useQuery } from 'react-query';

export enum Resolution {
  Monthly = 'monthly',
}

export enum AggregatedPvId {
  Sog = 'sog',
  Power = 'power',
}

export type UseAggregatedDataParams = {
  clientId: string;
  pvId: AggregatedPvId;
  from: Date;
  to: Date;
  resolution: Resolution;
};

type UseAggregatedDataResponse = {
  time: string;
  value: number;
};

async function getAggregatedData(params: UseAggregatedDataParams) {
  const response = await timeseries.get<UseAggregatedDataResponse[]>('api/aggregations', {
    params,
  });
  return response.data;
}

export function useAggregatedData(params: UseAggregatedDataParams) {
  return useQuery({
    queryKey: ['aggregatedDate', params],
    queryFn: () => getAggregatedData(params),
  });
}
