import { useCompanyId } from 'context/company';
import { useAddUserMutation } from 'hooks/companies/useAddUserMutation';
import { useForm } from 'react-hook-form';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { EMAIL_REGEX } from 'helpers/constants';

interface AddUserFormData {
  email: string;
  role: string;
}

interface AddUserFormProps {
  onSuccess?: () => void;
}

export function AddUserForm(props: AddUserFormProps) {
  const company_id = useCompanyId();
  const { mutate: addUser } = useAddUserMutation(company_id);
  const {
    register: registerForm,
    formState: { errors },
    handleSubmit,
  } = useForm<AddUserFormData>();

  const onSubmit = (data: AddUserFormData) => {
    addUser(
      { user_email: data.email, role: data.role as 'user' | 'admin' },
      {
        onSuccess: () => {
          props.onSuccess?.();
        },
      }
    );
  };

  return (
    <Box>
      <form action="#" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', gap: 4 }}>
          <TextField
            {...registerForm('email', {
              required: {
                value: true,
                message: 'Email is required',
              },
              pattern: {
                value: EMAIL_REGEX,
                message: 'Email is invalid',
              },
            })}
            id="email"
            label="Email"
            fullWidth
            variant="standard"
            type="string"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <FormControl fullWidth>
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select labelId="role-select-label" id="role-select" label="Age" defaultValue="user" {...registerForm('role')}>
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box marginTop={4} display="flex" flexDirection="row" justifyContent="end">
          <Button type="submit" variant="contained">
            Add User
          </Button>
        </Box>
      </form>
    </Box>
  );
}
