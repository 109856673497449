import React from 'react';

import { Grid } from '@mui/material';
//General
import EngineSpeedOverTime from '../Charts/EngineSpeedOverTime';
import EnginePowerOverTime from '../Charts/EnginePowerOverTime';
import { EngineRpmOverLoad } from '../Charts/EngineRpmOverLoad';
import SpeedOverTime from '../Charts/SpeedOverTime';
import FocOverTime from '../Charts/FocOverTime';
import MapPosition from '../MapPosition';

import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export default function EngineGeneral() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();
  const vesselProperties = useVesselFeatures(clientId);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={6}>
          <SpeedOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        {vesselProperties.includes('cockpitMap') && (
          <Grid item xs={12} lg={6}>
            <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <EnginePowerOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FocOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <EngineSpeedOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <EngineRpmOverLoad clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
      </Grid>
    </>
  );
}
