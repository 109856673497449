import { Box, SxProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

type SingleLineCircuitLineProps = {
  width?: string;
  height?: string;
  color?: string;
  marginTop?: string;
  marginLeft?: string;
  position?: string;
  left?: string;
  alignSelf?: string;
  sx?: SxProps<Theme>;
};

function SingleLineCircuitLine({
  width = '4px',
  height = '28px',
  color = grey[600],
  marginTop = '0px',
  marginLeft = '0px',
  position = 'static',
  left = '0%',
  alignSelf = 'auto',
  sx = {},
}: SingleLineCircuitLineProps) {
  return (
    <Box
      sx={[
        {
          width: width,
          height: height,
          backgroundColor: color,
          mt: marginTop,
          ml: marginLeft,
          position: position,
          left: left,
          alignSelf: alignSelf,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}

export default SingleLineCircuitLine;

export function LineVerticalBottom() {
  return <SingleLineCircuitLine marginTop="-4px" />;
}

export function LineVerticalTop() {
  return <SingleLineCircuitLine height="37px" sx={{ marginBottom: '-4px' }} />;
}

export function LineAngleRight() {
  return <SingleLineCircuitLine position="relative" left="50%" height="4px" width="50%" marginLeft="-2px" alignSelf="start" />;
}

export function LineAngleLeft() {
  return <SingleLineCircuitLine position="relative" left="0%" height="4px" width="50%" marginLeft="2px" alignSelf="start" />;
}

export function LineVerticalParallel() {
  return (
    <SingleLineCircuitLine
      width="20px"
      height="37px"
      position="relative"
      left="100%"
      alignSelf="start"
      color="transparent"
      sx={{ borderLeft: `4px solid ${grey[600]}`, borderRight: `4px solid ${grey[600]}`, margin: '-4px 0 -4px -2px' }}
    />
  );
}

export function LineVerticalBottomExtended() {
  return <SingleLineCircuitLine marginTop="-4px" position="relative" left="100%" marginLeft="6px" alignSelf="start" />;
}

export function LineHorizontalRed() {
  return <SingleLineCircuitLine width="100%" height="4px" color="error.main" />;
}

export function LineHorizontalGrey() {
  return <SingleLineCircuitLine width="100%" height="4px" color={grey[600]} />;
}
