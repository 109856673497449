import { db } from 'helpers/api';
import { useQueries, useQuery } from 'react-query';

export interface EngineConfig {
  clientId: string;
  objectId: string;
  name: string;
  nominalPower: number;
  nominalRPM?: number;
  nominalTorque?: number;
  serialNo: string;
  type: string;
  displayOrder?: number;
  installationRunningHours?: number;
}

async function getEngineConfig(clientId: string) {
  const res = await db.get<EngineConfig[]>(`/config/engine/getAll/${clientId}`);
  return res.data;
}

export function useEngineConfig(clientId: string) {
  return useQuery({ queryKey: ['engineConfig', clientId], queryFn: () => getEngineConfig(clientId) });
}

export function useMultiEngineConfigs(clientIds: string[]) {
  return useQueries(
    clientIds.map(id => {
      return {
        queryKey: ['engineConfig', id],
        queryFn: () => getEngineConfig(id),
      };
    })
  );
}
