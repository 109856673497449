import { db } from 'helpers/api';
import { CompanyMemberDto } from 'dtos/companies/company-member.dto';
import { useQuery } from 'react-query';

async function getMembersRequest(company_id: string) {
  const { data } = await db.get<CompanyMemberDto[]>(`company/${company_id}/members`);
  return data;
}

export function useCompanyMembers(company_id: string) {
  const { data, isLoading, isError, error } = useQuery(['company_members', company_id], () => getMembersRequest(company_id));
  return { data, isLoading, isError, error };
}
