import React from 'react';

import { Box, Skeleton } from '@mui/material';
import { toDisplayValue } from 'helpers/utils';
import valueConversion from 'helpers/valueConversion';

export type StatProps = {
  name: string;
  value?: number;
  unit: string;
  displayUnit?: string;
  places?: number;
  indicator?: any;
};

function Stat({ name, value, unit, displayUnit, places = 1, indicator }: StatProps) {
  const val = valueConversion(value, unit, displayUnit);
  const strVal = toDisplayValue(val, places);

  return (
    <Box sx={{ display: 'contents' }}>
      <span style={{ justifySelf: 'right', gridColumn: '2' }}>{name}</span>
      <span style={{ justifySelf: 'right', fontWeight: 'bold', textAlign: 'end', minWidth: '3em' }}>
        {' '}
        {strVal !== undefined ? strVal : <Skeleton variant="text" width="2em" />}
      </span>
      <span>{(displayUnit ?? unit).replace('deg', '°')}</span>
      {indicator}
    </Box>
  );
}

const FullWidth: React.FC = ({ children }) => {
  return <Box sx={{ gridColumn: '1 / span 5' }}>{children}</Box>;
};

function StatCategory({ name, children, hideName }: { name: string; children?: any; hideName?: boolean }) {
  return (
    <Box sx={{ display: 'contents' }}>
      {hideName ? null : (
        <span style={{ marginTop: '8px', marginBottom: '4px', gridColumn: '1 / span 5', borderBottom: '1px solid #aaa' }}>{name}</span>
      )}
      <Box sx={{ display: 'contents', fontSize: 'small' }}>{children}</Box>
    </Box>
  );
}

export type GridProps = {
  withIndicator?: boolean;
  columns?: string;
  indicatorColumn?: string;
};

const StatsGrid: React.FC<GridProps> = ({
  withIndicator,
  columns = '0px auto minmax(2em, min-content) auto',
  indicatorColumn = '1fr',
  children,
}) => {
  const templateColumns = withIndicator ? columns + ' ' + indicatorColumn : columns;
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: templateColumns, gridColumnGap: 8, alignItems: 'flex-start', height: 'min-content' }}>
      {children}
    </Box>
  );
};

function BarIndicator({ maxValue, currentValue }: { maxValue: number; currentValue: number | undefined }) {
  const indicatorValue: number | undefined = currentValue !== undefined ? Math.round((currentValue * 10) / maxValue) : undefined;
  const indicatorBase = {
    flexGrow: '1',
    height: '10px',
    margin: '1px',
    padding: '0',
  };
  const indicatorOn = { ...indicatorBase, backgroundColor: 'blue' };
  const indicatorOff = { ...indicatorBase, backgroundColor: 'rgb(182, 182, 252)' };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: '#e3e3e3',
        padding: '0',
        height: '13px',
        marginTop: '4px',
      }}>
      {[...Array(10)].map((_x, i: number) => {
        if (indicatorValue !== undefined && i < indicatorValue) return <div key={i} style={indicatorOn}></div>;
        else return <div key={i} style={indicatorOff}></div>;
      })}
    </div>
  );
}

export { Stat, StatCategory, StatsGrid, FullWidth, BarIndicator };
