import { createContext, useContext } from 'react';

import type { ClientId } from './clientId';
import type { Vessel } from '../hooks/fleetConfig';
import { useVesselQuery } from 'hooks/useVessel';
import { EngineConfig, useEngineConfig } from 'hooks/useEngineConfig';

const byEngineDisplayOrder = (a: [any, EngineConfig], b: [any, EngineConfig]) => (a[1].displayOrder ?? 0) - (b[1].displayOrder ?? 0);
export interface VesselConfig {
  engines: {
    [key: number]: EngineConfig;
  };
  vessel?: Vessel;
}

export const VesselConfigContext = createContext<VesselConfig | null>(null);

function useVesselConfig(): VesselConfig {
  const vesselConfig = useContext(VesselConfigContext);
  if (vesselConfig === null) {
    throw new Error("Can't use vessel config without a vessel config Provider");
  }
  return vesselConfig;
}

export function useVesselConfigQuery(clientId: ClientId) {
  const engineConfig = useEngineConfig(clientId);
  const { data: vesselQuery, isLoading, isLoadingError, isError, failureCount } = useVesselQuery(clientId);

  const groupedEngineConfig = (engineConfig.data ?? []).reduce((acc: any, cur: EngineConfig) => {
    acc[cur.objectId] ??= cur;
    return acc;
  }, {});

  const config: VesselConfig = {
    engines: groupedEngineConfig,
    vessel: vesselQuery,
  };

  const res = {
    config,
    isVesselLoading: isLoading,
    isVesselError: isError,
    isVesselLoadingError: isLoadingError,
    failureCount,
  };

  return res;
}

export { useVesselConfig, byEngineDisplayOrder };
