import { timeseries } from '../helpers/api';
import { useQuery } from 'react-query';

import joi from 'joi';
import { calculatePerformanceData } from './usePerformanceLiveData';

export const performanceHistorySchema = joi.array().items(
  joi.object({
    time: joi.string().required(),
    value: joi.number().required(),
  })
);

interface PerformanceHistoryQuery {
  clientId: string;
  from?: Date;
  to?: Date;
  minSpeed?: number;
  aggregationSec?: number;
}

interface PerformanceHistoryResult {
  time: string;
  value: number;
}

async function getPerformanceHistory(query: PerformanceHistoryQuery): Promise<PerformanceHistoryResult[]> {
  const { data } = await timeseries.get('/api/performance/vessel-performance-history', {
    params: {
      clientId: query.clientId,
      from: query.from?.toISOString(),
      to: query.to?.toISOString(),
      minSpeed: query.minSpeed,
      aggregationSec: query.aggregationSec,
    },
  });
  const values = await performanceHistorySchema.validateAsync(data);
  return values.map(item => ({
    time: item.time,
    value: calculatePerformanceData(item.value),
  }));
}

export function usePerformanceHistory(query: PerformanceHistoryQuery, options: { refetchInterval?: number } = {}) {
  const queryRes = useQuery(['performance-history', query], {
    queryFn: () => getPerformanceHistory(query),
    refetchInterval: options.refetchInterval ?? false,
  });
  return queryRes;
}
