import { createContext, useContext } from 'react';

export type ClientId = string;

export const ClientIdContext = createContext<ClientId>('101675');

function useClientId(): ClientId {
  const clientId = useContext(ClientIdContext);
  return clientId;
}

export default useClientId;
