import React from 'react';
import { Box, Grid, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AirIcon from '@mui/icons-material/Air';
import WaterIcon from '@mui/icons-material/Water';
import NavigationIcon from '@mui/icons-material/Navigation';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import Co2Icon from '@mui/icons-material/Co2';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SpeedIcon from '@mui/icons-material/Speed';

import PerformanceGaugeVessel from 'components/Gauges/PerformanceGauge';
import { toDisplayValue } from 'helpers/utils';
import useClientId from '../../context/clientId';
import { useTargetUnits } from 'context/settings';
import valueConversion from 'helpers/valueConversion';
import GeoPosition from './GeoPosition';

const Text: React.FC = ({ children }) => {
  return <Typography variant="subtitle2">{children}</Typography>;
};
type GridItemProps = {
  children: React.ReactNode;
  minWidth?: number;
};
const GridItem: React.FC<GridItemProps> = ({ children, minWidth = 0 }) => {
  return (
    <Grid item sx={{ minWidth: minWidth, ...classes.gridItem, ...classes.backgroundBlur }}>
      {children}
    </Grid>
  );
};
const GridParent: React.FC = ({ children }) => {
  return <Grid item>{children}</Grid>;
};

type StatProps = {
  name?: string;
  value?: number;
  unit?: string;
  places?: number;
  displayUnit?: string;
};

const Stat: React.FC<StatProps> = ({ name, value, unit, displayUnit, places = 1 }) => {
  // console.log({ name, unit, displayUnit });
  const val = valueConversion(value, unit, displayUnit);
  let str_val = toDisplayValue(val, places);
  if (unit === '°' && value !== undefined) {
    str_val = val + ' °';
    unit = '';
  }
  const largeText = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'baseline', pt: 0.5 }}>
        {
          <Typography variant={largeText ? 'h5' : 'h6'}>
            {str_val !== undefined ? str_val : <Skeleton variant="text" width="2em" />}
          </Typography>
        }
        {str_val !== undefined && (
          <Typography sx={{ ml: '3px' }} variant={largeText ? 'subtitle2' : 'subtitle2'}>
            {displayUnit ?? unit}
          </Typography>
        )}
      </Box>
      <Typography variant={largeText ? 'subtitle2' : 'subtitle2'}>{name}</Typography>
    </>
  );
};

const classes = {
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: 0,
    p: 0.5,
  },
  backgroundBlur: {
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    mx: 0.5,
  },
};

type Props = {
  sog: number;
  stw: number;
  cog?: number;
  waterDepth: number;
  powerTotal: number;
  powerSpecific: number;
  generalHealth: number;
  co2Mass: number;
  co2Specific: number;
  focVol: number;
  focMass: number;
  focSpecific: number;
  lat: number;
  lon: number;
  trueWindSpeed: number;
  trueWindDirection: number;
};

const MapOverlay: React.FC<Props> = ({
  sog,
  stw,
  cog,
  waterDepth,
  powerTotal,
  powerSpecific,
  co2Mass,
  co2Specific,
  focVol,
  focMass,
  focSpecific,
  lat,
  lon,
  trueWindSpeed,
  trueWindDirection,
}) => {
  const units = useTargetUnits();
  const largeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const clientId = useClientId();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={[
          {
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            zIndex: 2,
            pointerEvents: 'none',
            width: '100%',
          },
          largeScreen && {
            p: 1,
          },
          !largeScreen && {
            p: 0.5,
          },
        ]}>
        <Grid container rowSpacing={5} columnSpacing={1} direction={'row'}>
          <GridParent>
            <GridItem minWidth={90}>
              <DirectionsBoatIcon />
              <Text>Ship</Text>
              <NavigationIcon sx={{ transform: `rotate(${cog}deg)` }} />
              <Stat name="COG" value={cog} unit="°" places={0} />
              <Stat name="SOG" unit="kn" displayUnit={units.vesselSpeed} value={sog} places={1} />
              <Stat name="STW" unit="kn" displayUnit={units.vesselSpeed} value={stw} places={1} />
            </GridItem>
          </GridParent>
          <GridParent>
            <GridItem minWidth={95}>
              <AirIcon />
              <Text>True Wind</Text>
              <NavigationIcon sx={{ transform: `rotate(${trueWindDirection}deg)` }} />
              <Stat name="Direction" value={trueWindDirection} unit="°" places={0} />
              <Stat name="Speed" value={trueWindSpeed} unit="m/s" displayUnit={units.windSpeed} places={1} />
            </GridItem>
          </GridParent>
          <GridParent>
            <GridItem minWidth={80}>
              <WaterIcon />
              <Text>Water</Text>
              <DoubleArrowIcon sx={{ transform: 'rotate(90deg)' }} />
              <Stat name="Depth" value={waterDepth} unit="m" displayUnit={units.depth} places={1} />
            </GridItem>
          </GridParent>
        </Grid>
        <Grid container spacing={1} direction={'row'} justifyContent="flex-end">
          <GridParent>
            <GridItem minWidth={120}>
              <SettingsIcon />
              <Text>Engines</Text>
              <Stat name="Total Power" value={powerTotal} unit="kW" places={0} />
              <Stat name="Specific Power" value={powerSpecific} unit="kWh/nm" displayUnit={units.energyPerLength} places={0} />
            </GridItem>
          </GridParent>
          <GridParent>
            <GridItem minWidth={110}>
              <Co2Icon />
              <Text>Emissions</Text>
              <Stat name="CO2" value={co2Mass} unit="kg/h" displayUnit={units.massConsumption} places={0} />
              <Stat name="Specific CO2" value={co2Specific} unit="kg/nm" displayUnit={units.fuelMassPerLength} places={0} />
            </GridItem>
          </GridParent>
          <GridParent>
            <GridItem minWidth={110}>
              <LocalGasStationIcon />
              <Text>Consumption</Text>
              <Stat name="Volume" value={focVol} unit="ltr/h" displayUnit={units.volumeConsumption} places={0} />
              <Stat name="Mass" value={focMass} unit="kg/h" displayUnit={units.massConsumption} places={0} />
              <Stat name="Specific" value={focSpecific} unit="kg/nm" displayUnit={units.fuelMassPerLength} places={0} />
            </GridItem>
          </GridParent>
        </Grid>
      </Box>
      {lat && lon && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            mb: 1.0,
            px: 1.5,
            py: 0.5,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            ...classes.backgroundBlur,
          }}>
          <GeoPosition lat={lat} lon={lon} typographyVariant={largeScreen ? 'h5' : 'h6'} />
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          zIndex: 100,
          mt: largeScreen ? 1 : 0.5,
          left: '49%',
          transform: 'translateX(-50%)',
          pointerEvents: 'none',
          ...classes.backgroundBlur,
          pb: 0,
        }}>
        <PerformanceGaugeVessel
          clientId={clientId}
          icon={<SpeedIcon fontSize="small" />}
          cursorWidth={15}
          radius={largeScreen ? 45 : 36}
          caption="Performance"
        />
      </Box>
    </>
  );
};

export default MapOverlay;
