import React from 'react';

import type { ClientId } from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { useSequencesData } from 'hooks/useQueriedData';

import { addDataToScatterMap, ChartProps, color, dataSeriesFromScatterMap } from '.';
import ScatteredBaselineCard from './ScatteredBaselineCard';

function useTCSpeedCombined(clientId: ClientId, from: Date, to: Date, resolution: number, bank: number) {
  const vesselConfig = useVesselConfig();
  const meta = Object.keys(vesselConfig.engines).map(engineId => {
    const engineNo = Number(engineId);
    return {
      engineNo,
      loadLabel: `load${engineNo}`,
      valueLabel: vesselConfig.engines[engineNo].name,
    };
  });

  const loadQueries = meta.map(
    ({ engineNo }) =>
      ({
        module: 'calculated',
        objectId: 'combustion',
        objectIdNo: engineNo,
        pvId: 'load',
        pvIdNo: 0,
        clientId,
        resolution,
        from,
        to,
      } as const)
  );

  const valueQueries = meta.map(
    ({ engineNo }) =>
      ({
        module: 'measurement',
        clientId,
        objectId: 'combustion',
        objectIdNo: engineNo,
        pvId: 'tcRpm',
        pvIdNo: bank,
        resolution,
        from,
        to,
        sourceUnit: 'krpm',
      } as const)
  );

  const [loadData, loadLoading, loadErr] = useSequencesData(loadQueries);
  const [datas, loading, err] = useSequencesData(valueQueries);

  const map = new Map();

  meta.forEach(({ valueLabel, loadLabel }, i) => {
    addDataToScatterMap(map, valueLabel, datas[i] ?? []);
    addDataToScatterMap(map, loadLabel, loadData[i] ?? []);
  });

  const data = {
    datasets: meta.map(({ valueLabel, loadLabel }, i) => ({
      label: valueLabel,
      data: dataSeriesFromScatterMap(map, loadLabel, valueLabel),
      borderColor: color[i],
      borderWidth: 0.5,
      fill: false,
      radius: 2,
    })),
  };

  const retLoading = loading || loadLoading;
  const retErr = err || loadErr;
  const ret: [typeof data, typeof retLoading, typeof retErr] = [data, retLoading, retErr];
  return ret;
}

function TCSpeedCombinedA({ clientId, from, to, resolution }: ChartProps) {
  // TODO: display baselines
  // const { data: baselineThresholds, error } = useBaselineThresholds({ clientId, familyKey: 'speed_consumption' });
  const [data, loading, err] = useTCSpeedCombined(clientId, from, to, resolution, 0);
  return (
    <ScatteredBaselineCard
      name="T/C RPM - Bank A"
      data={data}
      loading={loading}
      err={err}
      options={{ yLabel: 'RPM [1000/min]', xLabel: 'Load [%]', yMin: 0 }}
    />
  );
}

function TCSpeedCombinedB({ clientId, from, to, resolution }: ChartProps) {
  // TODO: display baselines
  const [data, loading, err] = useTCSpeedCombined(clientId, from, to, resolution, 1);

  return (
    <ScatteredBaselineCard
      name="T/C RPM - Bank B"
      data={data}
      loading={loading}
      err={err}
      options={{ yLabel: 'RPM [1000/min]', xLabel: 'Load [%]', yMin: 0 }}
    />
  );
}

export { TCSpeedCombinedA, TCSpeedCombinedB };
