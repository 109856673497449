import React from 'react';
import { Box } from '@mui/material';

const AspectRatioBox: React.FC<{ aspectRatio: number | string; maxHeight?: number | string; minHeight?: number | string }> = ({
  aspectRatio,
  children,
  maxHeight = '100%',
  minHeight = '0',
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: 0,
        overflow: 'hidden',
        paddingTop: `min(calc(100% /  ${aspectRatio}), ${maxHeight})`,
        minHeight: `${minHeight}`,
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {children}
      </Box>
    </Box>
  );
};

export default AspectRatioBox;
