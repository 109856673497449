import { TabLabelToPath } from 'components/ViewHeader';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export function useStatusTabs() {
  const clientId = useClientId();
  const vesselProperties = useVesselFeatures(clientId);

  const tabs: TabLabelToPath[] = [
    ...(vesselProperties.includes('statusHealth') ? [{ label: 'Health', toPath: 'health' }] : []),
    ...(vesselProperties.includes('statusTBO') ? [{ label: 'Maintenance', toPath: 'tbo' }] : []),
    { label: 'Load Profile', toPath: 'load' },
  ];

  return tabs;
}
