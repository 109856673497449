import React from 'react';

import ChartJS from 'chart.js/auto';
import 'chartjs-adapter-moment';

import { ChartProps, Line } from 'react-chartjs-2';

import { Skeleton, useTheme } from '@mui/material';
import AspectRatioBox from 'components/AspectRatioBox';

ChartJS.register([]);

type SimpleLineCardProps = {
  name?: string;
  data: any;
  loading: boolean;
  err?: string;
  aspectRatio?: string | number;
  opts?: ChartProps<'line'>['options'];
};

const SimpleLine = ({ data, loading, err, aspectRatio = 16 / 9, opts }: SimpleLineCardProps) => {
  const theme = useTheme();

  const chartOptions: SimpleLineCardProps['opts'] = {
    animations: false as any,
    maintainAspectRatio: false,
    responsive: process.env.NODE_ENV !== 'test',
    elements: {
      point: { radius: 0 },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        type: 'time' as any,
      },
      y: {},
    },
    plugins: {
      legend: { display: true },
    },

    ...opts,
  };

  const grid = {
    color: theme.palette.chartGrid.main,
    borderColor: theme.palette.chartGrid.main,
  };
  if (chartOptions.scales?.x) {
    chartOptions.scales.x.grid = grid;
  }
  if (chartOptions.scales?.y) {
    chartOptions.scales.y.grid = grid;
  }

  return (
    <AspectRatioBox aspectRatio={aspectRatio}>
      {loading || err ? <Skeleton variant="rectangular" width="100%" height="100%" /> : <Line data={data} options={chartOptions} />}
    </AspectRatioBox>
  );
};

export default SimpleLine;
