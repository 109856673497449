import Box from '@mui/material/Box';
import ViewHeader, { TabLabelToPath } from 'components/ViewHeader';
import usePathChild from 'hooks/usePathChild';
import { Outlet } from 'react-router-dom';

export default function EPowerPlant() {
  const value = usePathChild(false);

  const tabs: TabLabelToPath[] = [
    { label: 'Overview', toPath: 'overview' },
    { label: 'Gensets', toPath: 'gensets' },
    { label: '24VDC System', toPath: '24vdc-system' },
  ];

  return (
    <Box sx={{ minWith: '500px', height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginBottom: 2, position: 'sticky', top: '112px', zIndex: 200 }}>
        <Box sx={{ flexGrow: 1 }}>
          <ViewHeader tabs={tabs} value={value || false} />
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
}
