import type { ClientId } from 'context/clientId';
import { cache } from 'helpers/api';
import { useEffect, useState } from 'react';

export type Datum = {
  source: 'measurement' | 'calculated';
  clientId: ClientId;
  objectId: string;
  objectIdNo: number;
  pvId: string;
  pvIdNo: number;
};

export type DatumString =
  `ioconnect/${Datum['source']}/${Datum['clientId']}/${Datum['objectId']}/${Datum['objectIdNo']}/${Datum['pvId']}/${Datum['pvIdNo']}`;

export function datumToString(datum: Datum): DatumString {
  return `ioconnect/${datum.source}/${datum.clientId}/${datum.objectId}/${datum.objectIdNo}/${datum.pvId}/${datum.pvIdNo}`;
}

export type LiveValue = {
  time: string;
  value: number;
  ttl?: string;
};

export type LiveValues = {
  [K in DatumString]: {
    time: string;
    value: number;
    ttl?: string;
  };
};

export type LiveDataConfig = {
  [k: string]: {
    source: 'measurement' | 'calculated';
    objectId: string;
    objectIdNo?: number;
    pvId: string;
    pvIdNo?: number;
    clientId?: ClientId;
  };
};

function useLiveData(topics: DatumString[]): [LiveValues | null, any] {
  const [data, setData] = useState<LiveValues | null>(null);
  const [err] = useState<string | null>(null);
  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await cache.post('api/cache/mget', topics);
      setData(response.data as any);
    }, 1000);
    return () => clearInterval(interval);
  }, [topics]);

  return [data, err];
}

function useLiveDataMapped(map: { [k: string]: DatumString }): [{ [k: string]: LiveValue }, any] {
  const [data, err] = useLiveData(Object.values(map));

  const nullEntries = Object.entries(map).map(([key, value]) => (data?.[value] ? [key, data?.[value]] : undefined));
  const entries = nullEntries.filter((x): x is [string, LiveValue] => x !== undefined);
  const newData = Object.fromEntries(entries.sort());
  return [newData, err];
}

function useLiveDataMap(map: LiveDataConfig, defaults: { pvIdNo?: number; objectIdNo?: number; clientId: ClientId }) {
  const topicMap = Object.fromEntries(
    Object.entries(map).map(([key, value]) => [
      key,
      datumToString({
        source: value.source,
        objectId: value.objectId,
        pvId: value.pvId,
        clientId: value.clientId ?? defaults.clientId,
        objectIdNo: value.objectIdNo ?? defaults.objectIdNo ?? 0,
        pvIdNo: value.pvIdNo ?? defaults.pvIdNo ?? 0,
      }),
    ])
  );
  return useLiveDataMapped(topicMap);
}

export { useLiveData, useLiveDataMap, useLiveDataMapped };
