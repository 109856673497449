import { useQuery } from 'react-query';

import type { ClientId } from 'context/clientId';
import { db } from 'helpers/api';
import type { EngineConfig } from 'hooks/useEngineConfig';
import { Vessel } from './fleetConfig';

type Status = 'TURNED_ON' | 'TURNED_OFF' | 'ACK';
type Priority = 'critical' | 'warning' | 'success' | 'unknown';

export interface Occurrence {
  _id: string;
  notificationId: string;
  status: Status;
  turnedOnTime?: string;
  turnedOffTime?: string;
  ackTime?: string;
  turnedOnRunninghours?: number;
  turnedOffRunninghours?: number;
  ackRunninghours?: number;
  duration?: number;
  durationToAck?: number;
}

export interface Notification {
  _id: string;
  priority: Priority;
  clientId: string;
  topic: string;
  title: string;
  description?: string;
  nonDismissedOccurrences: Occurrence[];
  events: number;
  dismissed: boolean;
  latestOccurrence: Occurrence;
  totalDuration: number;
}

export type NotificationFilter = {
  clientIds: ClientId[];
  status?: Status[];
  topic?: string;
};

interface SingleNotificationFilter {
  clientId: ClientId;
  notificationId: string;
}

interface QueryOptions {
  page: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
}

export type NotificationItem = {
  id: string;
  clientId: string;
  vesselName?: string;
  alarmTitle: string;
  alarmDescription?: string;
  latestOccurrenceTime?: Date;
  latestOccurrence?: Occurrence;
  engineName?: string;
  serialNo?: string;
  events: number;
  totalDuration: number;
  dismissed: boolean;
  priority: Priority;
  nonDismissedOccurrences: Occurrence[];
};

async function getNotificationRequest(params: NotificationFilter) {
  const query = {
    clientIds: params.clientIds.join(','),
    status: params.status?.join(','),
    topic: params.topic,
  };
  const { data } = await db.get<Notification[]>('notification', { params: query });
  return data;
}

async function getSingleNotificationRequest(params: SingleNotificationFilter) {
  const { data } = await db.get<Notification>('notification/getNotification', { params });
  return data;
}

export function useNotifications(params: NotificationFilter) {
  return useQuery(['notifications', params], () => getNotificationRequest(params));
}

export function useSingleNotification(params: SingleNotificationFilter) {
  const notification = useQuery(['notification', params], () => getSingleNotificationRequest(params));

  return {
    notification,
  };
}

export function convertToNotificationItem(notification: Notification, engines?: EngineConfig[], vesselInfo?: Vessel[]): NotificationItem {
  const engineName = engines?.find(e => e.objectId === (notification.topic.split('/')[1] as any))?.name;
  const serialNo = engines?.find(e => e.objectId === (notification.topic.split('/')[1] as any))?.serialNo;
  const vesselName = vesselInfo?.find(obj => obj.clientId === notification.clientId)?.name;

  return {
    id: notification._id,
    clientId: notification.clientId,
    vesselName,
    alarmTitle: notification.title,
    alarmDescription: notification.description,
    latestOccurrenceTime: notification.latestOccurrence?.turnedOnTime ? new Date(notification.latestOccurrence?.turnedOnTime) : undefined,
    latestOccurrence: notification.latestOccurrence,
    engineName,
    serialNo,
    events: notification.events,
    totalDuration: notification.totalDuration,
    dismissed: notification.dismissed,
    priority: notification.priority,
    nonDismissedOccurrences: notification.nonDismissedOccurrences,
  };
}

async function getOccurrencesOfNotificationRequest(params: SingleNotificationFilter, options: QueryOptions) {
  const { data } = await db.get<Occurrence[]>('notification/occurrences', {
    params: {
      ...params,
      ...options,
    },
  });
  return data;
}

export function useOccurrencesOfNotification(params: SingleNotificationFilter, options: QueryOptions) {
  return useQuery(['notification', params, options], () => getOccurrencesOfNotificationRequest(params, options));
}
