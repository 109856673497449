import { TabLabelToPath } from 'components/ViewHeader';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export function useTasksTabs() {
  const clientId = useClientId();
  const vesselProperties = useVesselFeatures(clientId);

  const tabs: TabLabelToPath[] = [
    ...(vesselProperties.includes('taskBoard') ? [{ label: 'Board', toPath: 'board' }] : []),
    { label: 'Workplan', toPath: 'workplan' },
  ];

  return tabs;
}
