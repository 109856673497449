import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AlarmList } from '../Alarms/AlarmList';
import { useNotifications } from 'hooks/useNotifications';

type SingleLineAlarmsProps = {
  clientId: string;
  batteryId: number;
};

export function SingleLineAlarms({ clientId, batteryId }: SingleLineAlarmsProps) {
  const topic = `battery/${batteryId}`;
  const activeNotifications = useNotifications({ clientIds: [clientId], topic, status: ['TURNED_ON', 'ACK'] });

  return (
    <Box gap={1}>
      <Typography
        sx={{
          p: 1,
          ...(activeNotifications.data && activeNotifications.data.length > 0 && { color: 'primary.main' }),
        }}>
        ACTIVE ALARMS ({activeNotifications.data?.length})
      </Typography>
      <AlarmList clientId={clientId} topic={topic} status={['TURNED_ON', 'ACK']} defaultPageSize={5} />
    </Box>
  );
}
