import { getEnv } from './environment';

export enum Env {
  Local = 'local',
  Development = 'development',
  Production = 'production',
  Demo = 'demo',
}

const checkHasEnv = (...allowed: Env[]): boolean => {
  const env: Env = getEnv('REACT_APP_ENV') || getEnv('NODE_ENV');
  return allowed.includes(env);
};

export default checkHasEnv;
