import { AlertColor } from '@mui/material';
import { createContext, useContext } from 'react';

export interface Snackbar {
  variant: AlertColor;
  message: string;
}

interface SnackbarContext {
  snackbars: Snackbar[];
  addSnackbar: (snackbar: Snackbar) => void;
}

const defaultState: SnackbarContext = {
  snackbars: [],
  addSnackbar: () => console.error('No SnackbarContext above component'),
};

const snackbarContext = createContext<SnackbarContext>(defaultState);

export const useSnackbar = () => useContext(snackbarContext);

export default snackbarContext;
