import { Box } from '@mui/material';

const ScrollableList: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        position: ' relative',
      }}>
      <Box
        sx={{
          position: ' absolute',
          overflowY: 'auto',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}>
        {children}
      </Box>
    </Box>
  );
};

export default ScrollableList;
