import Box from '@mui/material/Box';
import { PlantTabBar } from '../PlantTabBar';
import { useParams } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import { useNotifications } from 'hooks/useNotifications';
import { AlarmList } from './AlarmList';

export function Alarms() {
  const { clientId } = useParams();
  if (!clientId) {
    throw new Error('missing clientId, <Alarms/> should be placed inside /plant/:clientId/...');
  }

  const activeNotifications = useNotifications({ clientIds: [clientId], status: ['TURNED_ON', 'ACK'] });

  return (
    <>
      <PlantTabBar />
      <Box sx={{ p: 4, gap: 4, display: 'flex', flexDirection: 'column' }}>
        <Paper>
          <Box gap={1}>
            <Typography
              sx={{
                p: 1,
                ...(activeNotifications.data && activeNotifications.data.length > 0 && { color: 'primary.main' }),
              }}>
              ACTIVE ALARMS ({activeNotifications.data?.length})
            </Typography>
            <AlarmList clientId={clientId} status={['TURNED_ON', 'ACK']} defaultPageSize={5} />
          </Box>
          <Box gap={1}>
            <Typography sx={{ p: 1 }}>INACTIVE ALARMS</Typography>
            <AlarmList clientId={clientId} status={['TURNED_OFF']} defaultPageSize={5} />
          </Box>
          <Box gap={1}>
            <Typography sx={{ p: 1 }}>HISTORY (Active & Inactive)</Typography>
            <AlarmList clientId={clientId} status={['TURNED_OFF', 'TURNED_ON', 'ACK']} defaultPageSize={5} />
          </Box>
        </Paper>
      </Box>
    </>
  );
}
