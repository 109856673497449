import { Theme, Typography, useMediaQuery } from '@mui/material';
import Gauge from 'components/Gauges/DefaultGauge';
import type { EngineConfig } from 'hooks/useEngineConfig';
import React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import useClientId from 'context/clientId';
import { useLiveDataMap } from 'hooks/useLiveData';

type Props = {
  config: EngineConfig;
  objectIdNo: number;
};

const LiveEngineRPM: React.FC<Props> = ({ config, objectIdNo }) => {
  const largeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  const clientId = useClientId();
  const [data] = useLiveDataMap(
    {
      rpm: { source: 'measurement', objectId: 'combustion', pvId: 'rpm' },
    },
    { clientId, objectIdNo }
  );

  const gaugeValue = Math.round(data?.rpm?.value ?? 0);

  return (
    <>
      <Gauge value={gaugeValue} max={1800} subtitle="rpm" radius={largeScreen ? 85 : 70} icon={<SpeedIcon />} />
      <Typography
        sx={{ position: 'relative', bottom: '20px', display: 'flex', justifyContent: 'center' }}
        variant={largeScreen ? 'h5' : 'h6'}>{`${config.name}`}</Typography>
    </>
  );
};

export default LiveEngineRPM;
