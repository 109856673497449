import { useState, useEffect } from 'react';
import { Card, CardMedia } from '@mui/material';

import { TimeseriesQuery } from 'hooks/useQueriedData';
import type { ClientId } from 'context/clientId';
import MapRoute from 'components/MapRoute';
import AspectRatioBox from 'components/AspectRatioBox';
import { useMultipleSampledData } from 'hooks/useSampledData';
import { chartColors } from 'theme/chartColors';

interface MapPositionProps {
  clientId: ClientId;
  from: Date;
  to: Date;
  resolution: number;
  aspectRatio?: string | number;
  zoom?: number;
  maxZoom?: number;
  scrollZoom?: boolean;
  dragPan?: boolean;
  touchPitch?: boolean;
  touchZoomRotate?: boolean;
  lineColor?: string;
  lineWidth?: number;
}

function MapPosition({
  clientId,
  from,
  to,
  resolution,
  aspectRatio = 7,
  zoom = 8,
  maxZoom = 16,
  scrollZoom = true,
  dragPan = true,
  touchPitch = true,
  touchZoomRotate = true,
  lineColor = chartColors.main[0],
  lineWidth = 2,
}: MapPositionProps) {
  const [coordinates, setCoordinates] = useState<any>();

  const queries: TimeseriesQuery[] = [
    {
      module: 'measurement',
      objectId: 'position',
      objectIdNo: 0,
      pvId: 'lat',
      pvIdNo: 0,
      clientId,
      resolution: resolution,
      from: from,
      to: to,
    },
    {
      module: 'measurement',
      objectId: 'position',
      objectIdNo: 0,
      pvId: 'lon',
      pvIdNo: 0,
      clientId,
      resolution: resolution,
      from: from,
      to: to,
    },
  ];

  const [[lat, lon]] = useMultipleSampledData(queries);

  useEffect(() => {
    if (!lat || !lon) return;

    const tmpCoordinates = [];
    for (const item of lat) {
      const matchingLon = lon?.find(lonElem => lonElem._id.tsp === item._id.tsp);
      if (matchingLon !== undefined) {
        tmpCoordinates.push({ tsp: item._id.tsp, lon: matchingLon.value, lat: item.value });
      }
    }

    setCoordinates(tmpCoordinates);
  }, [lat, lon]);

  return (
    <Card>
      <CardMedia component="div" sx={{ pr: 2 }}>
        <AspectRatioBox aspectRatio={aspectRatio} minHeight="200px">
          <MapRoute
            coordinates={coordinates}
            zoom={zoom}
            maxZoom={maxZoom}
            scrollZoom={scrollZoom}
            dragPan={dragPan}
            touchPitch={touchPitch}
            touchZoomRotate={touchZoomRotate}
            lineColor={lineColor}
            lineWidth={lineWidth}
          />
        </AspectRatioBox>
      </CardMedia>
    </Card>
  );
}

export default MapPosition;
