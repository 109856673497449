"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPipeline = exports.Piping = exports.Pipeline = exports.pipe = exports.Pipe = exports.usePipe = exports.PipeProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
/**
 * The context holding the component to be rendered on {@link Pipe}.
 */
const PipeContext = (0, react_1.createContext)(null);
/**
 * A provider providing the component to be rendered on {@link Pipe}.
 *
 * @since 1.0.0
 */
function PipeProvider(props) {
    return (0, jsx_runtime_1.jsx)(PipeContext.Provider, Object.assign({ value: props.component }, { children: props.children }));
}
exports.PipeProvider = PipeProvider;
/**
 * Return the component to be rendered on {@link Pipe}.
 *
 * @since 1.0.0
 */
function usePipe() {
    return (0, react_1.useContext)(PipeContext);
}
exports.usePipe = usePipe;
/**
 * Render the next component in the pipeline.
 *
 * @since 1.0.0
 */
function Pipe() {
    const pipe = usePipe();
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: pipe });
}
exports.Pipe = Pipe;
/**
 * A shortcut to pass {@code <Pipe />} as the children prop.
 *
 * @since 1.0.0
 */
exports.pipe = {
    children: (0, jsx_runtime_1.jsx)(Pipe, {})
};
/**
 * Render the provided components with the last
 * component's pipe being the current pipe.
 *
 * Note: this component will add the current pipe
 * to the components list, enabling the last given
 * component to render the next component in the
 * parent pipeline.
 *
 * @since 1.0.0
 */
function Pipeline(props) {
    const { components = [], children = [] } = props;
    const pipe = usePipe();
    return createPipeline([
        ...components,
        ...Array.isArray(children) ? children : [children]
    ], pipe);
}
exports.Pipeline = Pipeline;
/**
 * Render the provided components.
 *
 * Note: this component won't pass the current pipe.
 * If this was in another piping, The `<Pipe />` component
 * in this piping won't render the next component in the parent piping.
 *
 * @since 1.0.0
 */
function Piping(props) {
    const { components = [], children = [] } = props;
    return createPipeline([
        ...components,
        ...Array.isArray(children) ? children : [children]
    ], props.fallback);
}
exports.Piping = Piping;
/**
 * Create a pipeline component from the provided components.
 *
 * Note: this component won't pass the current pipe.
 * If this was in another piping, The `<Pipe />` component
 * in this piping won't render the next component in the parent piping.
 *
 * @since 1.1.0
 */
function createPipeline(components, fallback) {
    const [currentComponent, ...nextComponents] = components;
    return (0, jsx_runtime_1.jsx)(PipeContext.Provider, { value: nextComponents.length > 0 ? createPipeline(nextComponents, fallback) : fallback !== null && fallback !== void 0 ? fallback : null, children: currentComponent });
}
exports.createPipeline = createPipeline;
