import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSettingsDialog } from 'context/settings';

export function OpenSettingsButton() {
  const { openDialog } = useSettingsDialog();
  return (
    <Box
      sx={{
        display: 'flex',
        //width: '100%',
        alignItems: 'center',
        alignSelf: 'begin',
        justifyContent: 'center',
        color: 'text.primary',
        borderRadius: 1,
        p: 1,
      }}>
      <IconButton sx={{ ml: 1 }} onClick={openDialog} color="inherit">
        <SettingsIcon />
      </IconButton>
    </Box>
  );
}
