import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'context/snackbar/SnackbarContext';
import { db } from 'helpers/api';

import { useBaselineGroup } from './useBaselineConfig';

type GroupReq = {
  clientId: string;
  baselineGroupId: string;
};

type BaselineGroupEditorHook = {
  clientId: string;
  baselineGroupId: string;
};

export type CreateBaselineReq = {
  clientId: string;
  baselineGroupId: string;
  name: string;
};

async function createBaselineRequest(params: GroupReq, name: string) {
  const { data } = await db.post(`config/baselines/addBaseline`, { name }, { params });
  return data;
}

async function updateActiveBaselineRequest(params: GroupReq, activeBaseline: string | null) {
  const { data } = await db.post('config/baselines/updateActiveBaseline', { activeBaseline }, { params });
  return data;
}

async function setThresholdTypeRequest(params: GroupReq, type: 'value' | 'percent' | null) {
  const { data } = await db.post('config/baselines/updateThresholdType', { type }, { params });
  return data;
}

async function setThresholdValueRequest(params: GroupReq, key: `${'alarm' | 'warn'}${'Min' | 'Max'}`, value: number | null) {
  const { data } = await db.post('config/baselines/updateThresholdValue', { key, value }, { params });
  return data;
}

export function useBaselineGroupEditor(params: BaselineGroupEditorHook) {
  const queryClient = useQueryClient();
  const { addSnackbar } = useSnackbar();

  const { clientId, baselineGroupId } = params;
  const baselineGroup = useBaselineGroup(params);

  const { mutateAsync: setActiveBaseline } = useMutation(
    (activeBaseline: string | null) => updateActiveBaselineRequest({ clientId, baselineGroupId }, activeBaseline),
    {
      onError: () => {
        addSnackbar({ variant: 'error', message: 'failed to update active baseline' });
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'updated active baseline' });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
      },
    }
  );

  const { mutateAsync: createBaseline } = useMutation(
    ({ name }: { name: string }) => createBaselineRequest({ clientId, baselineGroupId }, name),
    {
      onError: () => {
        addSnackbar({ variant: 'error', message: 'Failed to create baseline' });
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: `Created new Baseline` });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
      },
    }
  );

  const { mutateAsync: setThresholdType } = useMutation(
    ({ type }: { type: 'percent' | 'value' | null }) => setThresholdTypeRequest({ clientId, baselineGroupId }, type),
    {
      onError: () => {
        addSnackbar({ variant: 'error', message: 'failed to update threshold type' });
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'updated threshold type' });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
      },
    }
  );

  const { mutateAsync: setThresholdValue } = useMutation(
    ({ key, value }: { key: `${'alarm' | 'warn'}${'Min' | 'Max'}`; value: number | null }) =>
      setThresholdValueRequest({ clientId, baselineGroupId }, key, value),
    {
      onError: () => {
        addSnackbar({ variant: 'error', message: 'failed to update threshold' });
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'updated threshold' });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
      },
    }
  );

  return {
    baselineGroup,
    setActiveBaseline,
    setThresholdType,
    setThresholdValue,
    createBaseline,
  };
}
