import React, { useState, useEffect } from 'react';

function getFromLocalStorage<T>(storageKey: string, fallbackState: T, validateFunc?: (value: any) => T | undefined): T {
  let storageValue: any | null = null;
  try {
    storageValue = JSON.parse(localStorage.getItem(storageKey) as string);
  } catch (error) {
    console.error(error);
  }

  if (storageValue === null) {
    return fallbackState;
  }

  if (validateFunc) {
    let validated: T;
    try {
      validated = validateFunc(storageValue) ?? fallbackState;
    } catch (error) {
      console.error(error);
      return fallbackState;
    }
    return validated;
  }

  return storageValue as T;
}

export function useLocalStorage<T>(
  storageKey: string,
  fallbackState: T,
  validateFunc?: (value: any) => T | undefined
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const storageValue: T = getFromLocalStorage(storageKey, fallbackState, validateFunc);
  const [value, setValue] = useState(storageValue);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
}
