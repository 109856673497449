export type BaselineAxis = {
  label: string;
  unit: string;
  min?: number;
  max?: number;
  suggestedMin?: number;
  suggestedMax?: number;
};

export type BaselineAxes = {
  x: BaselineAxis;
  y: BaselineAxis;
};

export type BaselineFamily = {
  _id: string;
  clientId: string;
  name: string;
  remark: string;
  axes: BaselineAxes;
  tension?: number;
};

export type BaselinePoint = {
  x: number;
  y: number;
} & ({ _id: string } | { unsaved_id: string });

export function pointId(point: BaselinePoint) {
  if ('_id' in point) {
    return point._id;
  } else {
    return point.unsaved_id;
  }
}

export type Baseline = {
  _id: string;
  name: string;
  data: BaselinePoint[];
};

export type BaselineThresholdType = 'value' | 'percent';

export type BaselineThreshold = {
  type?: BaselineThresholdType;
  warnMin?: number;
  warnMax?: number;
  alarmMin?: number;
  alarmMax?: number;
};

export type BaselineGroup = {
  _id: string;
  family: string | BaselineFamily;
  engineObjectId?: string;
  activeBaseline?: Baseline;
  remark?: string;
  baselines: Baseline[];
  threshold: BaselineThreshold;
};
