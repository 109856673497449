import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard, { TimeLineCardOptions } from 'components/TimeLineCard';
import { useTargetUnits } from 'context/settings';

function useSpeedWindOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const queries: TimeseriesSequenceQuery[] = [
    {
      clientId,
      objectId: 'speed',
      objectIdNo: 0,
      pvId: 'sog',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'kn',
      targetUnit: units.vesselSpeed,
    },
    {
      clientId,
      objectId: 'speed',
      objectIdNo: 0,
      pvId: 'stw',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'kn',
      targetUnit: units.vesselSpeed,
    },
    {
      clientId,
      objectId: 'environment',
      objectIdNo: 0,
      pvId: 'waterDepth',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'm',
      targetUnit: units.depth,
    },
    {
      clientId,
      module: 'calculated',
      objectId: 'environment',
      objectIdNo: 0,
      pvId: 'trueWindSpeed',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'm/s',
      targetUnit: units.windSpeed,
    },
    {
      clientId,
      module: 'calculated',
      objectId: 'environment',
      objectIdNo: 0,
      pvId: 'trueWindDirection',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'deg',
    },
  ];

  const [queryData, loading, err] = useSequencesData(queries);

  const data = {
    datasets: [
      {
        label: 'SOG',
        data: queryData[0] ?? [],
        borderColor: color[0],
        borderWidth: 1,
        yAxisID: 'y',
        order: 1,
      },
      {
        label: 'STW',
        data: queryData[1] ?? [],
        borderColor: color[1],
        borderWidth: 1,
        yAxisID: 'y',
        order: 2,
      },

      {
        label: 'Water Depth',
        data: queryData[2] ?? [],
        borderColor: color[2],
        borderWidth: 1,
        yAxisID: 'y1',
        order: 3,
      },
      {
        label: 'True Wind Speed',
        data: queryData[3] ?? [],
        borderColor: color[3],
        borderWidth: 1,
        yAxisID: 'y2',
        order: 4,
      },
      {
        label: 'True Wind Direction',
        data: queryData[4] ?? [],
        borderWidth: 1,
        yAxisID: 'y3',
        fill: true,
        order: 5,
      },
    ],
  };

  const options = getOptions(units.vesselSpeed, units.windSpeed, units.depth);

  return { data, loading, err, options };
}

// TODO: why isn't this used?
/*
const dirTo180 = (dir: number): number => {
  return dir <= 180 ? dir : 360 - dir;
};
*/

// TODO: inline
function getOptions(vesselSpeed: string, windSpeed: string, depth: string): TimeLineCardOptions {
  return {
    title: 'Vessel Speed / Depth / Wind',
    legendPosition: 'top',
    yAxisLabel: `Speed [${vesselSpeed}]`,
    minYAxisValue: 0,
    additionalYAxis: [
      // todo can be either x or y
      {
        id: 'y1',
        type: 'linear',
        display: true,
        position: 'right',
        label: `Water Depth [${depth}]`,
        minYAxisValue: 0,
        maxYAxisValue: 100,
      },
      {
        id: 'y2',
        type: 'linear',
        display: true,
        position: 'right',
        label: `True Wind Speed [${windSpeed}]`,
        minYAxisValue: 0,
        maxYAxisValue: 30,
      },
      {
        id: 'y3',
        type: 'linear',
        display: true,
        position: 'right',
        label: 'True Wind Dir [°]',
        minYAxisValue: 0,
        maxYAxisValue: 180,
      },
    ],
  };
}

function SpeedWindOverTime({ clientId, from, to, resolution, aspectRatio }: ChartProps) {
  const { data, loading, err, options } = useSpeedWindOverTime(clientId, from, to, resolution);

  return <TimeLineCard options={options} data={data} loading={loading} err={err} aspectRatio={aspectRatio} />;
}

export default SpeedWindOverTime;
