import { Box, Typography } from '@mui/material';
import { Task } from 'hooks/useTasks';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Color from 'color';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QualificationLevel from 'components/QualificationLevel';
import { useVesselConfig } from 'context/vesselConfig';
import useRunningHours from 'hooks/useRunningHours';
import useClientId from 'context/clientId';

interface TaskSummaryProps {
  task: Task;
}
const TaskSummary: React.FC<TaskSummaryProps> = ({ task }) => {
  const vesselConfig = useVesselConfig();
  const runningHours = useRunningHours(useClientId());
  const theme = useTheme();

  // returns a single number or undefined for a custom task or a task from a workplan
  const dueHours =
    task.objectIdNo !== undefined && task.dueRunninghours !== undefined // needs to be explicit check against undefined since a user could input 0
      ? task.dueRunninghours - runningHours[task.objectIdNo]
      : undefined;

  const getClosingDaysText = () => {
    if (task.status === 'DONE') {
      return `Done ${moment(task.closed).fromNow()}`;
    } else if (task.status === 'REJECTED') {
      return `Rejected ${moment(task.closed).fromNow()}`;
    }
  };

  const getHoursText = () => {
    if (dueHours !== undefined && dueHours !== null && dueHours >= 0) {
      return `${dueHours} Runninghours left`;
    } else if (dueHours !== undefined && dueHours !== null && dueHours < 0) {
      return `Overdue for ${dueHours * -1} hours`;
    } else {
      return 'No Runninghours specified';
    }
  };

  return (
    <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <Box mb={1}>
        {task.workplan ? (
          <>
            <Typography variant="h6" noWrap>
              {`${vesselConfig.engines[task.workplan.objectIdNo]?.name} ${vesselConfig.engines[task.workplan.objectIdNo]?.serialNo}`}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              {task.workplan?.items.length} {task.workplan?.items.length === 1 ? 'Item' : 'Items'}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6" noWrap>
              {task.title}
            </Typography>
            {task.objectIdNo !== undefined &&
              task.objectId === 'engine' && ( // the objectIdNo can be 0 so we have to explicilty check to not be undefinded or null
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                  {`${vesselConfig.engines[task.objectIdNo]?.name} ${vesselConfig.engines[task.objectIdNo]?.serialNo}`}
                </Typography>
              )}
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <QualificationLevel level={task.level}>
          <Typography variant="body2" noWrap>
            QL {task.level}
          </Typography>
        </QualificationLevel>
        {dueHours !== undefined && task.status !== 'DONE' && task.status !== 'REJECTED' && (
          <Box
            marginLeft={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: 1,
              py: 0.25,
              borderRadius: 1,
              color:
                dueHours <= 0
                  ? theme.palette.error.main
                  : dueHours <= 100
                  ? theme.palette.warning.main
                  : Color(theme.typography.body1.color).alpha(0.5).string(),
              backgroundColor:
                dueHours <= 0
                  ? Color(theme.palette.error.main).alpha(0.3).string()
                  : dueHours <= 100
                  ? Color(theme.palette.warning.main).alpha(0.3).string()
                  : theme.palette.background.paper,
            }}>
            <AccessTimeIcon
              sx={{
                fontSize: theme.typography.body2.fontSize,
                marginRight: 0.5,
              }}
            />
            <Typography variant="body2" noWrap>
              {getHoursText()}
            </Typography>
          </Box>
        )}
        {task.closed && (task.status === 'DONE' || task.status === 'REJECTED') && (
          <Box
            marginLeft={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: 1,
              py: 0.25,
              borderRadius: 1,
              color: theme.palette.success.main,
              backgroundColor: Color(theme.palette.success.main).alpha(0.3).string(),
            }}>
            <AccessTimeIcon
              sx={{
                fontSize: theme.typography.body2.fontSize,
                marginRight: 0.5,
              }}
            />
            <Typography variant="body2" noWrap>
              {getClosingDaysText()}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TaskSummary;
