import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import ActiveInactiveNotificationList from 'components/Notification/ActiveInactiveNotificationList';
import TitledPaper from '../../components/TitledPaper';
import { useFleetConfig } from '../../hooks/fleetConfig';

export function NotificationsAndAlerts() {
  const fleetConfig = useFleetConfig();
  const clientIds = fleetConfig.map(item => item.clientId);

  return (
    <TitledPaper sx={{ flexGrow: 1, height: '100%' }} title="Notifications & Alerts" icon={<NotificationsOutlined />} noPadding>
      <ActiveInactiveNotificationList clientIds={clientIds} hideColumns={['serialNo']} vesselInfos={fleetConfig} />
    </TitledPaper>
  );
}
