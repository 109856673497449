import { DatumString, useLiveData, useLiveDataMap } from './useLiveData';
import { useFleetConfig } from './fleetConfig';

function usePerformanceLiveData(clientId: string) {
  const [data] = useLiveDataMap(
    {
      vesselPerformance: { source: 'calculated', objectId: 'performance', pvId: 'vessel' },
      vesselSpeed: { source: 'measurement', objectId: 'speed', pvId: 'sog' },
    },
    { clientId }
  );

  const minSpeed = 5;
  if (data?.vesselSpeed?.value === undefined) {
    return undefined;
  }
  if (data.vesselSpeed.value >= minSpeed) {
    return calculatePerformanceData(data?.vesselPerformance?.value);
  } else {
    return undefined;
  }
}

export function usePerformanceLiveDataFleet(): { [clientId: string]: number | undefined } {
  const fleetConfig = useFleetConfig();

  const performanceTopics: { [key: string]: DatumString } = {};
  fleetConfig
    .map(vessel => vessel.clientId)
    .forEach(clientId => {
      performanceTopics[clientId] = `ioconnect/calculated/${clientId}/performance/0/vessel/0` as DatumString;
    });

  const [data] = useLiveData(Object.values(performanceTopics));

  const result: { [clientId: string]: number | undefined } = {};
  Object.entries(performanceTopics).forEach(([clientId, topic]) => {
    result[clientId] = calculatePerformanceData(data?.[topic]?.value);
  });

  return result;
}

export function calculatePerformanceData(value: null): number;
export function calculatePerformanceData(value: undefined): undefined;
export function calculatePerformanceData(value: number | undefined): number | undefined;
export function calculatePerformanceData(value: number | undefined | null): number | undefined {
  if (Number.isNaN(value) || value === undefined || value === null) {
    return;
  }
  const vesselPerformanceValue = value;

  const bounded = (vesselPerformanceValue < 0 ? 0 : vesselPerformanceValue > 1 ? 1 : vesselPerformanceValue) * 10;
  return 10 - bounded;
}

export default usePerformanceLiveData;
