import { Box, Slider, SliderProps } from '@mui/material';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import { useLocalStorage } from 'hooks/useLocalStorage';
import ReactDOM from 'react-dom';
import { useThemeSettings } from 'context/themeSettings';

export default function BrightnessSlider() {
  const { theme } = useThemeSettings();
  const [brightness, setBrightness] = useLocalStorage('brightnessSliderOpacity', { dark: 0, light: 0 });

  const handleSliderChange: SliderProps['onChange'] = (event, newValue) => {
    if (typeof newValue === 'number') {
      setBrightness(prevBrightness => ({
        ...prevBrightness,
        [theme]: newValue / 100,
      }));
    }
  };

  const backdrop = (
    <Box
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 999,
        opacity: brightness[theme],
        backgroundColor: '#000',
        position: 'fixed',
        pointerEvents: 'none',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
      }}
    />
  );

  return (
    <>
      {ReactDOM.createPortal(backdrop, document.body)}
      <Box sx={{ display: 'flex', alignItems: 'center', width: 200, color: 'text.primary', gap: 2 }}>
        <Brightness4OutlinedIcon sx={{ opacity: 0.6 }} />
        <Slider
          aria-label="Brightness"
          aria-valuemin={0}
          aria-valuemax={90}
          aria-valuenow={brightness[theme] * 100}
          value={brightness[theme] * 100}
          defaultValue={brightness[theme] * 100}
          step={20}
          min={0}
          max={90}
          onChange={handleSliderChange}
        />
      </Box>
    </>
  );
}
