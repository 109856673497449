import React from 'react';
import { Typography } from '@mui/material';
import { convertDMS, toDisplayValue } from '../../helpers/utils';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

type GeoPositionProps = {
  lat: number;
  lon: number;
  typographyVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
};
export default function GeoPosition(props: GeoPositionProps) {
  const { latDMS, latitudeCardinal, longDMS, longitudeCardinal } = convertDMS(props.lat, props.lon);

  return (
    <Typography variant={props.typographyVariant} sx={{}}>
      {`${toDisplayValue(latDMS.degrees, 0)}° ${latDMS.minutes}' ${latDMS.seconds}" ${latitudeCardinal} ${toDisplayValue(
        longDMS.degrees,
        0
      )}° ${longDMS.minutes}' ${longDMS.seconds}" ${longitudeCardinal}`}
    </Typography>
  );
}
