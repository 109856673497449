import { timeseries } from 'helpers/api';
import { useQueries, useQuery } from 'react-query';
import { TimeseriesQuery } from './useQueriedData';

interface SampleQueryItem {
  value: number;
  _id: { tsp: string };
}
type SampleQueryResponse = SampleQueryItem[];

const sortByTsp = (val: SampleQueryResponse) =>
  val.sort((a: SampleQueryItem, b: SampleQueryItem) => new Date(a._id.tsp).getTime() - new Date(b._id.tsp).getTime());

async function querySamples({ queryKey, signal }: { queryKey: readonly unknown[]; signal?: any }) {
  if (queryKey.length !== 2) {
    throw new Error('unexpected query key length');
  }
  try {
    const query = queryKey[1] as TimeseriesQuery;
    const params = {
      module: query.module ?? 'measurement',
      clientId: query.clientId,
      objectId: query.objectId,
      objectIdNo: query.objectIdNo.toString(),
      pvId: query.pvId,
      pvIdNo: query.pvIdNo.toString(),
      resolution: query.resolution.toString(),
      to: query.to.toISOString(),
      from: query.from.toISOString(),
    };

    const res = await timeseries.get<SampleQueryItem[]>('api/measurement/sample', { params, signal });

    const sorted = sortByTsp(res.data);
    if (sorted === null) {
      throw new Error('null argument');
    }
    return sorted;
  } catch (err) {
    if (!(signal && signal.aborted)) {
      throw err;
    }
    return [];
  }
}

const cacheTime = 60 * 1000;
const staleTime = cacheTime;

function useSampledData(parameters: TimeseriesQuery) {
  const param = {
    queryKey: ['timeseries', 'sampled', parameters] as const,
    queryFn: querySamples,
    refetchOnWindowFocus: false,
    cacheTime,
    staleTime,
  };
  const query = useQuery(param);
  return query;
}

function useMultipleSampledData(parameters: readonly TimeseriesQuery[]): [(SampleQueryResponse | undefined)[], boolean, any] {
  const param = parameters.map(param => ({
    queryKey: ['timeseries', param] as const,
    queryFn: querySamples,
    refetchOnWindowFocus: false,
    cacheTime,
    staleTime,
  }));
  const results = useQueries(param);

  const loading = results.map(res => res.isLoading).some(i => i);
  const errMessages = results
    .map(res => res.error)
    .filter(e => e instanceof Error)
    .map(e => (e as Error).message);
  const errStrings = results.map(res => res.error).filter(e => typeof e === 'string');
  const errs = [...errMessages, ...errStrings];
  // todo typed/object return
  return [results.map(res => res.data), loading, errs.length ? errs.join(', ') : undefined];
}

export { useMultipleSampledData, useSampledData };
