import React from 'react';

import { useVesselConfig } from 'context/vesselConfig';
import { ChartProps, dataSeriesFromScatterMap, color, addDataToScatterMap } from '.';

import SimpleScatterCard from './SimpleScatterCard';
import { useSequencesData } from 'hooks/useQueriedData';
import type { ClientId } from 'context/clientId';
import { useChartConfig } from 'context/baselineConfig';
import { useTargetUnits } from 'context/settings';

function useCoolantPressureOverLoad(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  // TODO: correct chart config
  const chartConfig = useChartConfig('CoolantPressureOverLoad');
  const vesselConfig = useVesselConfig();
  const meta = Object.keys(vesselConfig.engines).map(engineId => {
    const engineNo = Number(engineId);
    return {
      engineNo,
      loadLabel: `load${engineNo}`,
      valueLabel: vesselConfig.engines[engineNo].name,
    };
  });

  const loadQueries = meta.map(
    ({ engineNo }) =>
      ({
        module: 'calculated',
        objectId: 'combustion',
        objectIdNo: engineNo,
        pvId: 'load',
        pvIdNo: 0,
        clientId,
        resolution,
        from,
        to,
      } as const)
  );

  const valueQueries = meta.map(
    ({ engineNo }) =>
      ({
        module: 'measurement',
        clientId,
        objectId: 'combustion',
        objectIdNo: engineNo,
        pvId: 'pressureCoolant',
        pvIdNo: 0,
        resolution,
        from,
        to,
        sourceUnit: 'bar',
        targetUnit: units.pressure,
      } as const)
  );

  const [loadData, loadLoading, loadErr] = useSequencesData(loadQueries);
  const [datas, loading, err] = useSequencesData(valueQueries);

  const map = new Map();

  meta.forEach(({ valueLabel, loadLabel }, i) => {
    addDataToScatterMap(map, valueLabel, datas[i] ?? []);
    addDataToScatterMap(map, loadLabel, loadData[i] ?? []);
  });

  const data = {
    datasets: meta.map(({ valueLabel, loadLabel }, i) => ({
      label: valueLabel,
      data: dataSeriesFromScatterMap(map, loadLabel, valueLabel),
      borderColor: color[i],
      borderWidth: 0.5,
      fill: false,
      radius: 2,
    })),
  };

  return {
    data,
    loading: loading || loadLoading,
    err: err || loadErr,
    chartConfig: { ...chartConfig, axes: { ...chartConfig?.axes, xLabel: `Load [%]`, yLabel: `Pressure [${units.pressure}]` } },
  };
}

function CoolantPressureOverLoad({ clientId, from, to, resolution }: ChartProps) {
  const { data, loading, err, chartConfig } = useCoolantPressureOverLoad(clientId, from, to, resolution);
  return <SimpleScatterCard name="Coolant Pressure" data={data} loading={loading} err={err} options={chartConfig?.axes} />;
}

export { CoolantPressureOverLoad };
