import { Alert, Snackbar as MuiSnackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import snackbarContext, { Snackbar } from './SnackbarContext';

const SnackbarProvider: React.FC = ({ children }) => {
  const [snackbars, setNotifications] = useState<Snackbar[]>([]);
  const [open, setOpen] = useState(false);

  const addSnackbar = (snackbar: Snackbar) => {
    setNotifications(old => [...old, snackbar]);
    setOpen(true);
  };

  const removeLatestSnackbar = () => {
    setNotifications(oldList => {
      setOpen(false);
      const newList = oldList;
      newList.shift();
      return newList;
    });
  };

  useEffect(() => {
    setTimeout(() => {
      removeLatestSnackbar();
    }, 3000);
  }, [snackbars]);

  return (
    <snackbarContext.Provider value={{ snackbars, addSnackbar }}>
      {children}
      {snackbars.length > 0 && (
        <MuiSnackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open}>
          <Alert severity={snackbars[0].variant}>{snackbars[0].message}</Alert>
        </MuiSnackbar>
      )}
    </snackbarContext.Provider>
  );
};

export default SnackbarProvider;
