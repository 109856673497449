import { BusBar, Grid } from 'app/Plant/SingleLine/SingleLineCircuit';
import { SingleLineGroup } from 'app/Plant/SingleLine/';
import { LiveDataConfig, useLiveDataMap } from '../useLiveData';

interface SingleLineDataProps {
  busBar: BusBar[];
  grid: Grid;
  alerts: string[];
}

export function useSingleLineData(clientId: string, groups: SingleLineGroup[]): SingleLineDataProps {
  // Definition of config (same as in useLiveData.tsx, pending export)
  const liveDataConfig: LiveDataConfig = {};

  // groups variable is necessary to create n frequencies and voltages per busBarIndex
  groups.forEach(group => {
    const busbar_index = group.busBarIndex;
    liveDataConfig[`frequency${busbar_index}`] = { source: 'measurement', objectId: 'busbar', objectIdNo: busbar_index, pvId: 'frequency' };
    liveDataConfig[`voltage${busbar_index}`] = { source: 'measurement', objectId: 'busbar', objectIdNo: busbar_index, pvId: 'voltage' };
  });

  const [busBarData] = useLiveDataMap(liveDataConfig, { clientId });

  const busBar: BusBar[] = groups.map((group, i) => ({
    frequency: busBarData[`frequency${i}`]?.value ?? 0,
    voltage: busBarData[`voltage${i}`]?.value ?? 0,
  }));

  const [gridData] = useLiveDataMap(
    {
      frequency: { source: 'measurement', objectId: 'grid', pvId: 'frequency' },
      voltage: { source: 'measurement', objectId: 'grid', pvId: 'voltage' },
      closed: { source: 'measurement', objectId: 'grid', pvId: 'closed' },
    },
    { clientId, objectIdNo: 0 }
  );

  const grid: Grid = {
    frequency: gridData?.frequency?.value ?? 0,
    voltage: gridData?.voltage?.value ?? 0,
    closed: gridData?.closed?.value === 1,
  };

  const alerts: string[] = [];

  // TODO: Replace with API call to fetch real data in the future

  return { alerts, busBar, grid };
}
