import { db } from 'helpers/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'context/snackbar/SnackbarContext';
import { Workplan } from './useWorkplans';
import useClientId, { ClientId } from 'context/clientId';

interface BaseTask {
  _id: string;
  clientId: ClientId;
  created: string;
  closed?: string;
  userId: string;
  status: 'UPCOMING' | 'IN PROGRESS' | 'DONE' | 'REJECTED' | 'ON HOLD';
  objectId?: string;
  objectIdNo?: number;
  wpId?: string;
  workplan?: Workplan;
  title?: string;
  details?: string;
  dueRunninghours: number;
  level: number;
  custom: boolean;
  archived: boolean;
}

export interface Task extends BaseTask {
  changes?: {
    user: string;
    action: BaseTask;
    date: string;
  };
  __v: number;
}

export interface filter {
  archived: boolean;
  clientId?: ClientId;
  status?: string;
  level?: number;
  objectId?: string;
  objectIdNo?: number;
}

const updateTaskRequest = async (task: Task) => {
  const { data } = await db.patch<Task>(`task/${task._id}`, task, { withCredentials: true });
  return data;
};

const archiveTaskRequest = async (id: string) => {
  const body = {
    archived: true,
  };
  const { data } = await db.patch<Task>(`task/${id}`, body, { withCredentials: true });
  return data;
};

const addTaskRequest = async (task: Task) => {
  const { data } = await db.post<Task>('task', task, { withCredentials: true });
  return data;
};

const getTasksRequest = async (params: filter) => {
  const { data } = await db.get<Task[]>('task', { params: params });
  return data;
};

export default function useTasks(params: filter) {
  const clientId = useClientId();
  params.clientId = clientId;

  const queryClient = useQueryClient();
  const { addSnackbar } = useSnackbar();

  const tasks = useQuery(['tasks', params], () => getTasksRequest(params));

  const { mutateAsync: updateTask } = useMutation(updateTaskRequest, {
    onMutate: async updatedTask => {
      await queryClient.cancelQueries(['tasks', params]);

      const previousTasks = queryClient.getQueryData<Task[]>(['tasks', params]);

      queryClient.setQueryData<Task[] | undefined>(['tasks', params], old =>
        old?.map(task => (task._id === updatedTask._id ? updatedTask : task))
      );

      return { previousTasks };
    },
    onError: (err, updatedTask, context: any) => {
      if (context?.previousTasks) {
        queryClient.setQueryData<Task[]>(['tasks', params], context.previousTasks);
      }
      addSnackbar({ variant: 'error', message: 'Failed to update Task' });
    },
    onSuccess: () => {
      addSnackbar({ variant: 'success', message: 'Update successfull' });
    },
    onSettled: () => {
      queryClient.invalidateQueries('tasks');
    },
  });

  const { mutateAsync: addTask } = useMutation(addTaskRequest, {
    onMutate: async newTask => {
      await queryClient.cancelQueries(['tasks', params]);

      const previousTasks = queryClient.getQueryData<Task[]>(['tasks', params]);

      if (previousTasks) {
        queryClient.setQueryData<Task[]>(['tasks', params], [...previousTasks, newTask]);
      }

      return { previousTasks };
    },
    onError: (err, variables, context: any) => {
      if (context?.previousTasks) {
        queryClient.setQueryData<Task[]>(['tasks', params], context.previousTasks);
      }
      addSnackbar({ variant: 'error', message: 'Failed to create Task' });
    },
    onSuccess: () => {
      addSnackbar({ variant: 'success', message: 'Task created' });
    },
    onSettled: () => {
      queryClient.invalidateQueries('tasks');
    },
  });

  const { mutateAsync: archiveTask } = useMutation(archiveTaskRequest, {
    onMutate: async taskId => {
      await queryClient.cancelQueries(['tasks', params]);

      const previousTasks = queryClient.getQueryData<Task[]>(['tasks', params]);

      queryClient.setQueryData<Task[] | undefined>(['tasks', params], old => old?.filter(task => task._id !== taskId));

      return { previousTasks };
    },
    onError: (err, variables, context: any) => {
      if (context?.previousTasks) {
        queryClient.setQueryData<Task[]>(['tasks', params], context.previousTasks);
      }
      addSnackbar({ variant: 'error', message: 'Failed to archive Task' });
    },
    onSuccess: () => {
      addSnackbar({ variant: 'success', message: 'Task archived' });
    },
    onSettled: () => {
      queryClient.invalidateQueries('tasks');
    },
  });

  return {
    tasks,
    updateTask,
    addTask,
    archiveTask,
  };
}
