import math from 'helpers/math';

export default function valueConversion(value: number, unit: string, displayUnit: string): number;
export default function valueConversion(value: number, unit?: string, displayUnit?: string): number;
export default function valueConversion(value?: number, unit?: string, displayUnit?: string): number | undefined;
export default function valueConversion(value?: number, unit?: string, displayUnit?: string) {
  if (value === undefined || value === null) {
    return value;
  }
  if (!displayUnit) {
    return value;
  }

  if (!unit) {
    throw new Error('cannot convert to without base unit');
  }

  const converted = math.unit(value, unit).to(displayUnit);
  return converted.toNumber();
}
