import { Typography, Paper } from '@mui/material';
import { grey } from '@mui/material/colors';

type TransformerLabelProps = {
  frequency: number;
  primaryVoltage: number;
  secondaryVoltage: number;
};

const TransformerLabel = ({ frequency, primaryVoltage, secondaryVoltage }: TransformerLabelProps) => {
  return (
    <Paper
      elevation={3}
      sx={{
        textAlign: 'center',
      }}>
      <Typography variant="h5" fontWeight="bold">
        Transformer
      </Typography>
      <Typography variant="h5" sx={{ background: grey[200] }}>
        {frequency} Hz
      </Typography>
      <Typography variant="h5">
        {primaryVoltage}/{secondaryVoltage}V
      </Typography>
    </Paper>
  );
};

type ConsumerLabelProps = {
  voltageField: number;
};

const ConsumerLabel = ({ voltageField }: ConsumerLabelProps) => {
  return (
    <Paper
      elevation={3}
      sx={{
        textAlign: 'center',
        borderTop: '4px solid #1976D2',
      }}>
      <Typography variant="h5" fontWeight="bold">
        Consumers
      </Typography>
      <Typography variant="h5" sx={{ background: grey[200] }}>
        {voltageField}V Field
      </Typography>
    </Paper>
  );
};

export { TransformerLabel, ConsumerLabel };
