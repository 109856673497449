// src/app/Plant/SingleLine/SingleLineCircuit.tsx
import { Box, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Transformer from 'assets/singleline/transformer.svg';
import TransformerRed from 'assets/singleline/transformer_red.svg';
import TransformerGrey from 'assets/singleline/transformer_grey.svg';
import { BatteryConfig } from 'hooks/plant/useBatteryConfig';
import { BatteryStats } from '../BatteryTable';
import SingleLineCircuitLine, {
  LineVerticalTop,
  LineVerticalBottom,
  LineAngleRight,
  LineVerticalParallel,
  LineVerticalBottomExtended,
  LineAngleLeft,
  LineHorizontalGrey,
  LineHorizontalRed,
} from './SingleLineCircuitLine';
import { SingleLineBESS } from './SingleLineBESS';
import CircuitBreaker from './CircuitBreaker';

const GREY_COLOR = grey[600];

type SingleLineCircuitProps = {
  BESSunits: BESS[];
  busBar: BusBar;
  grid: Grid;
  onBESSClick: (bess: BESS) => void;
};

export type BESS = {
  batteryConfig: BatteryConfig;
  batteryStats?: BatteryStats;
  alerts: string[];
};

export type BusBar = {
  frequency: number;
  voltage: number;
};

export type Grid = {
  frequency: number;
  voltage: number;
  closed: boolean;
};

export function SingleLineCircuit({ BESSunits, busBar, grid, onBESSClick }: SingleLineCircuitProps) {
  const sortedBESSunits = [...BESSunits].sort((a, b) => a.batteryConfig.id - b.batteryConfig.id);
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${BESSunits.length}, 1fr)`,
          justifyContent: 'space-evenly',
          pt: 2,
          gap: 2,
        }}>
        {sortedBESSunits.map((bess, index: number) => {
          return (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'top',
                color: GREY_COLOR,
                width: '100%',
              }}
              key={bess.batteryConfig.id}>
              <SingleLineBESS key={bess.batteryConfig.id} bess={bess} onClick={() => onBESSClick(bess)} />

              <SingleLineCircuitLine />

              {BESSunits.length === index + 1 && BESSunits.length % 2 !== 0 ? (
                <>
                  <LineVerticalTop />

                  <img src={Transformer} alt="" />

                  <LineVerticalBottom />
                </>
              ) : (
                <>
                  {index % 2 === 0 ? (
                    <>
                      <LineAngleRight />

                      <LineVerticalParallel />

                      <img
                        src={Transformer}
                        alt=""
                        style={{ position: 'relative', left: '100%', marginLeft: '-8px', alignSelf: 'start' }}
                      />

                      <LineVerticalBottomExtended />
                    </>
                  ) : (
                    <LineAngleLeft />
                  )}
                </>
              )}
            </Box>
          );
        })}
      </Box>

      {/* LineVerticalBusBar */}
      <Box
        sx={{
          width: `calc((100% - (${BESSunits.length - 1} * 16px)) / ${BESSunits.length} * ${BESSunits.length - 1} + ${
            BESSunits.length - 2
          } * 16px + 10px)`,
          height: '4px',
          backgroundColor: GREY_COLOR,
          alignSelf: 'end',
          marginLeft: 'auto',
        }}
      />

      <Box
        sx={{
          width: 'calc(50% + 2px)',
          display: 'flex',
          marginLeft: 'auto',
          justifyContent: 'stretch',
          alignItems: 'end',
          borderLeft: `4px solid ${GREY_COLOR}`,
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'stretch',
          }}>
          <LineHorizontalGrey />

          {/* MCB with Switch */}
          <CircuitBreaker label="MCB" closed={grid.closed} />

          {grid.closed ? <LineHorizontalGrey /> : <LineHorizontalRed />}

          {/* Transformer */}

          <img src={grid.closed ? TransformerGrey : TransformerRed} alt="" style={{ flexShrink: 0, transform: 'translateY(15px)' }} />

          {grid.closed ? <LineHorizontalGrey /> : <LineHorizontalRed />}
        </Box>
        <Box sx={{ mr: 0, ml: 'auto', whiteSpace: 'nowrap' }}>
          {/* BusBar */}
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              textAlign: 'center',
              flexDirection: 'column',
              gap: 1,
              borderTop: '4px solid #1976D2',
              mb: 8,
            }}>
            <Typography variant="h5">
              <strong>Bus Bar</strong>
            </Typography>
            <Typography variant="h5">{busBar.frequency.toFixed(2)} Hz Frequency</Typography>
            <Typography variant="h5">{busBar.voltage.toFixed(2)} V Voltage</Typography>
          </Paper>

          {/* Grid */}
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              textAlign: 'center',
              flexDirection: 'column',
              gap: 1,
              borderLeft: grid.closed ? `4px solid  ${GREY_COLOR}` : '4px solid #D32F2F',
              ml: 0.25,
              mb: -4,
            }}>
            <Typography variant="h5">
              <strong>Grid</strong>
            </Typography>
            <Typography variant="h5">{grid.frequency.toFixed(2)} Hz Frequency</Typography>
            <Typography variant="h5">{grid.voltage} V Voltage</Typography>
          </Paper>
        </Box>
      </Box>
    </>
  );
}
