import { FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useThemeSettings } from 'context/themeSettings';

export default function ThemeToggleSwitch() {
  const { theme, setTheme } = useThemeSettings();
  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={theme === 'dark'}
              onChange={e => setTheme(e.target.checked ? 'dark' : 'light')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Dark Mode"
        />
      </FormGroup>
    </FormControl>
  );
}
