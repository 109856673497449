import { Chart } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useFFTVibrationData } from 'hooks/useFFTVibrationData';
import useClientId from 'context/clientId';
import { chartColors } from 'theme/chartColors';

export function VibrationFFTChart({ objectId, objectIdNo }: { objectId: string; objectIdNo: number }) {
  const clientId = useClientId();

  const { data: fftVibrationData } = useFFTVibrationData(clientId, objectId, objectIdNo);

  const data = useMemo(() => {
    const pvIdNos = [
      { value: 2, label: 'LONGITUDINAL' },
      { value: 1, label: 'TRANSVERSAL' },
      { value: 0, label: 'VERTICAL' },
    ];
    const colors = chartColors.main;

    return {
      labels: Array(1000)
        .fill(1)
        .map((_, i) => i + 1),
      datasets: pvIdNos.map(pvIdNo => ({
        label: pvIdNo.label,
        data: fftVibrationData?.[pvIdNo.value]?.object?.fft,
        pointRadius: 0,
        borderWidth: 1,
        borderColor: colors[2 - pvIdNo.value],
      })),
    };
  }, [fftVibrationData]);

  const chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Frequency [Hz]',
        },
        type: 'linear',
        min: 0,
        max: 1000,
        ticks: {
          stepSize: 100,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Vibration [mm/sec]',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          font: {
            size: 8,
          },
        },
      },
    },
  };
  return (
    <Box sx={{ width: '100%', height: '13em', overflow: 'hidden' }}>
      <Chart type="line" data={data} options={chartOptions} />
    </Box>
  );
}
