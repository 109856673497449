import { RatingColors } from 'helpers/emissions';
import { RatingCalculator } from './RatingCalculator';
import { Chart } from 'react-chartjs-2';

type EmissionChartProps = {
  calculator: RatingCalculator;
};

export default function EmissionChart(props: EmissionChartProps) {
  const lines = props.calculator.getBoundaryLines();
  const emissionData = props.calculator.getEmissionLine();

  const data = {
    datasets: [
      {
        label: 'Emission Index',
        data: emissionData,
        borderColor: '#1976d2',
        fill: 'start',
        backgroundColor: '#4b4efc20',
        pointRadius: 0,
      },
      ...lines.map((line, i) => {
        return {
          data: line,
          stepped: 'before',
          borderColor: { 0: RatingColors.A, 1: RatingColors.B, 2: RatingColors.C, 3: RatingColors.D }[i],
          borderWidth: 2,
          pointRadius: 0,
        } as const;
      }),
    ],
  };

  const config = {
    options: {
      animation: {
        duration: 0,
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          type: 'time',
          min: emissionData?.[0]?.x as any,
          max: emissionData?.[emissionData.length - 1]?.x as any,
          time: { unit: 'month' },
          grid: { display: false },
        },
        y: {
          grid: { display: false, drawTicks: false },
          ticks: { display: false },
          min: props.calculator.getChartMin().y,
          max: props.calculator.getChartMax().y,
        },
      },
    },
  } as const;

  return <Chart type="line" data={data} options={config.options} />;
}
