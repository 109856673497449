import React from 'react';

import { Box, Button } from '@mui/material';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SpeedIcon from '@mui/icons-material/Speed';
import GasMeterOutlinedIcon from '@mui/icons-material/GasMeterOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import SettingsInputComponentOutlinedIcon from '@mui/icons-material/SettingsInputComponentOutlined';
import VibrationOutlinedIcon from '@mui/icons-material/VibrationOutlined';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';

import { LabeledBox } from './LabeledBox';
import TitledPaper from '../../components/TitledPaper';
import { Link } from 'react-router-dom';
import { RatingDisplay } from './RatingDisplay';
import { useLiveData, DatumString } from 'hooks/useLiveData';
import { Quantity } from 'context/settings';
import { useFleetConfig } from 'hooks/fleetConfig';
import { useVesselConfigQuery } from 'context/vesselConfig';

const watchlistIcons = {
  vesselPerformance: <SpeedIcon fontSize="small" />,
  sfoc: <GasMeterOutlinedIcon fontSize="small" />,
  load: <ArrowCircleDownOutlinedIcon fontSize="small" />,
  vibration: <VibrationOutlinedIcon fontSize="small" />,
  oilQuality: <OilBarrelOutlinedIcon fontSize="small" />,
} as const;

type RatingLevels = {
  asc: boolean;
  ok: number;
  caution: number;
  warn: number;
};

export type WatchlistItemProps = {
  clientId: string;
  title: string;
  ship: string;
  engineNum?: number;
  icon: keyof typeof watchlistIcons;
  value?: number;
  quantity?: Quantity;
  sourceUnit?: string;
  linkTo: string;
  ratingLevels?: RatingLevels;
};

function genWatchlistItems(
  vesselName: string,
  clientId: string,
  vesselProperties: string[]
): (WatchlistItemProps & { clientId: string; cacheDatum: DatumString })[] {
  const allVessels: (WatchlistItemProps & { clientId: string; cacheDatum: DatumString })[] = [
    {
      title: 'SFOC',
      ship: vesselName,
      clientId: clientId,
      icon: 'sfoc' as const,
      quantity: 'massPerEnergyConsumption',
      sourceUnit: 'g/kWh',
      linkTo: `/vessel/${clientId}/analysis/vessel/consumption`,
      cacheDatum: `ioconnect/calculated/${clientId}/total/0/focMassSpecificToPower/0`,
    },
    {
      title: 'LOAD',
      ship: vesselName,
      clientId,
      engineNum: 0,
      icon: 'load' as const,
      sourceUnit: '%',
      linkTo: `/vessel/${clientId}/cockpit`,
      cacheDatum: `ioconnect/calculated/${clientId}/total/0/load/0`,
    },
  ];
  const vibrationItems: (WatchlistItemProps & { clientId: string; cacheDatum: DatumString })[] = [
    {
      title: 'Vibration',
      ship: vesselName,
      clientId,
      engineNum: 1,
      icon: 'vibration' as const,
      quantity: 'vibrationDistance',
      sourceUnit: 'mm/sec',
      linkTo: `/vessel/${clientId}/analysis/vibration`,
      cacheDatum: `ioconnect/calculated/${clientId}/combustion/1/vibrationMax/0`,
      ratingLevels: { asc: true, ok: 3.2, caution: 4.5, warn: 7 },
    },
  ];
  const oilQualityItems: (WatchlistItemProps & { clientId: string; cacheDatum: DatumString })[] = [
    {
      title: 'Oil Quality',
      ship: vesselName,
      clientId,
      icon: 'oilQuality' as const,
      engineNum: 1,
      linkTo: `/vessel/${clientId}/analysis/engine/lubrication`,
      cacheDatum: `ioconnect/measurement/${clientId}/combustion/1/oilQuality/0`,
    },
  ];

  const lookup: { [vesselFeature: string]: (WatchlistItemProps & { clientId: string; cacheDatum: DatumString })[] } = {
    oilSensor: oilQualityItems,
    vibrationSensor: vibrationItems,
    flowMeter: [],
    shaftPowerMeter: [],
  };

  const items = (vesselProperties ?? []).map(feature => lookup[feature] ?? []).flat();
  return allVessels.concat(items);
}

export function WatchlistItem({
  title,
  ship,
  clientId,
  engineNum,
  icon,
  value,
  quantity,
  sourceUnit,
  linkTo,
  ratingLevels,
}: WatchlistItemProps) {
  let ratingColour = 'ok.main';
  if (value !== undefined && ratingLevels && ratingLevels.asc) {
    ratingColour =
      value <= ratingLevels.ok
        ? 'ok.main'
        : value <= ratingLevels.caution
        ? 'caution.main'
        : value <= ratingLevels.warn
        ? 'warn.main'
        : 'critical.main';
  }
  if (value !== undefined && value && ratingLevels && !ratingLevels.asc) {
    ratingColour =
      value >= ratingLevels.ok
        ? 'ok.main'
        : value >= ratingLevels.caution
        ? 'caution.main'
        : value >= ratingLevels.warn
        ? 'warn.main'
        : 'critical.main';
  }

  const engineConfig = useVesselConfigQuery(clientId).config.engines;
  const engine = engineNum ? engineConfig[engineNum]?.name : undefined;

  return (
    <Button component={Link} to={linkTo} sx={{ color: 'text.primary', textTransform: 'inherit' }}>
      <LabeledBox title={title}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          <DirectionsBoatIcon />
          {ship}
          {engine && (
            <Box component="span" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
              <SettingsInputComponentOutlinedIcon fontSize="small" />
              {engine}
            </Box>
          )}
          <RatingDisplay
            icon={watchlistIcons[icon]}
            value={value}
            quantity={quantity}
            sourceUnit={sourceUnit}
            ratingColour={ratingColour}
          />
        </Box>
      </LabeledBox>
    </Button>
  );
}

export function Watchlist() {
  const vessels = useFleetConfig();

  const watchlistItems = vessels.flatMap(vessel => {
    return genWatchlistItems(
      vessel.name,
      vessel.clientId,
      vessel.features?.map(feature => feature.key)
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data] = useLiveData(watchlistItems.map(item => item.cacheDatum));
  return (
    <TitledPaper title="Watchlist" icon={<WatchLaterOutlinedIcon />}>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 0 }}>
        {watchlistItems.map((item, i) => (
          <WatchlistItem
            key={i}
            title={item.title}
            ship={item.ship}
            clientId={item.clientId}
            engineNum={item.engineNum}
            icon={item.icon}
            value={data?.[item.cacheDatum]?.value}
            quantity={item.quantity}
            sourceUnit={item.sourceUnit}
            linkTo={item.linkTo}
            ratingLevels={item.ratingLevels}
          />
        ))}
      </Box>
    </TitledPaper>
  );
}
