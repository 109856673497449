type ValueName =
  | 'LubeOilPressure'
  | 'LubeOilTemp'
  | 'CrankcasePressure'
  | 'CoolantTemp'
  | 'TExhaustA'
  | 'TExhaustB'
  | 'TCSpeedA'
  | 'TCSpeedB'
  | 'InjectionQuantity'
  | 'CoolantPressure'
  | 'EngineRpm';
type GroupName = 'Load';
export type ChartName = `${ValueName}Over${GroupName}`;

export type Baseline = {
  type: 'line';
  label: string;
  tension?: number;
  data: { x: number; y: number }[];
};

export type AxisOptions = {
  yMin?: number;
  yMax?: number;
  ySuggestedMin?: number;
  ySuggestedMax?: number;
  xMin?: number;
  xMax?: number;
  xSuggestedMin?: number;
  xSuggestedMax?: number;
  xLabel?: string;
  yLabel?: string;
};

export interface ChartConfig {
  baselines: Baseline[];
  axes: AxisOptions;
}

export type BaselineConfig = {
  [key in ChartName]?: ChartConfig;
};

// ----- These are hardcoded demo values -------- //

const LubeOilPressureOverLoad: ChartConfig = {
  axes: {
    xMin: 0,
    xSuggestedMax: 100,
    yMin: 0,
    ySuggestedMax: 10,
    xLabel: 'Load [%]',
    yLabel: 'Pressure [bar]',
  },
  baselines: [
    {
      type: 'line',
      label: 'Baseline',
      tension: 0.4,
      data: [
        { x: 50, y: 2.5 },
        { x: 248, y: 2.5 },
        { x: 400, y: 3.5 },
        { x: 635, y: 5.25 },
        { x: 810, y: 6 },
      ],
    },
  ],
};

const LubeOilTempOverLoad: ChartConfig = {
  axes: {
    xMin: 0,
    xSuggestedMax: 100,
    yMin: 0,
    ySuggestedMax: 120,
    xLabel: 'Load [%]',
    yLabel: 'Temperature [°C]',
  },
  baselines: [],
};

const EngineRpmOverLoad: ChartConfig = {
  axes: {
    xMin: 0,
    xSuggestedMax: 100,
    yMin: 0,
    ySuggestedMax: 2500,
    xLabel: 'Load [%]',
    yLabel: 'Rpm [1/min]',
  },
  baselines: [],
};

const CrankcasePressureOverLoad: ChartConfig = {
  axes: {
    xMin: 0,
    xSuggestedMax: 100,
    yMin: -1,
    ySuggestedMax: 1,

    xLabel: 'Load [%]',
    yLabel: 'Pressure [mbar]',
  },
  baselines: [],
};

const InjectionQuantityOverLoad: ChartConfig = {
  axes: {
    xMin: 0,
    xSuggestedMax: 100,
    yMin: 0,
    ySuggestedMax: 120,
    xLabel: 'Load [%]',
    yLabel: 'Quantity [%]',
  },
  baselines: [],
};

const CoolantTempOverLoad: ChartConfig = {
  axes: {
    xMin: 0,
    xSuggestedMax: 100,
    yMin: 0,
    ySuggestedMax: 120,
    xLabel: 'Load [%]',
    yLabel: 'Temperature [°C]',
  },
  baselines: [],
};

const CoolantPressureOverLoad: ChartConfig = {
  axes: {
    xMin: 0,
    xSuggestedMax: 100,
    yMin: 0,
    ySuggestedMax: 10,
    xLabel: 'Load [%]',
    yLabel: 'Pressure [bar]',
  },
  baselines: [],
};

const TExhaustOverLoad: ChartConfig = {
  axes: {
    ySuggestedMin: 100,
    ySuggestedMax: 1000,
    xMin: 0,
    xLabel: 'Load [%]',
    yLabel: 'Temperature [°C]',
  },
  baselines: [],
};

const baselineConfig: BaselineConfig = {
  LubeOilPressureOverLoad,
  LubeOilTempOverLoad,
  CrankcasePressureOverLoad,
  EngineRpmOverLoad,
  InjectionQuantityOverLoad,
  CoolantTempOverLoad,
  CoolantPressureOverLoad,
  TExhaustAOverLoad: TExhaustOverLoad,
  TExhaustBOverLoad: TExhaustOverLoad,
};

function useBaselineConfig(): BaselineConfig {
  return baselineConfig;
}

function useChartConfig(key: ChartName): ChartConfig | undefined {
  const baselineConfig = useBaselineConfig();
  return baselineConfig[key];
}

export { useBaselineConfig, useChartConfig };
