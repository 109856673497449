import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard, { TimeLineCardOptions } from 'components/TimeLineCard';

const MAX_DISPLAY_VALUE = 10;

function useSpeedOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  // TODO: add units
  const queries: TimeseriesSequenceQuery[] = [
    {
      clientId,
      module: 'calculated',
      objectId: 'performance',
      objectIdNo: 0,
      pvId: 'vessel',
      pvIdNo: 0,
      resolution,
      from,
      to,
    },
    {
      clientId,
      module: 'calculated',
      objectId: 'performance',
      objectIdNo: 0,
      pvId: 'speedConsumption',
      pvIdNo: 0,
      resolution,
      from,
      to,
    },
    {
      clientId,
      module: 'calculated',
      objectId: 'performance',
      objectIdNo: 0,
      pvId: 'sfocLoad',
      pvIdNo: 0,
      resolution,
      from,
      to,
    },
  ];

  const [timeseriesData, loading, err] = useSequencesData(queries);

  const data = {
    datasets: [
      {
        label: 'Vessel',
        data: timeseriesData[0] ? timeseriesData[0].map(v => ({ x: v.x, y: Math.min(v.y, MAX_DISPLAY_VALUE) })) : [],
        borderColor: color[4],
        borderWidth: 1,
      },
      {
        label: 'Speed / Consumption',
        data: timeseriesData[1] ? timeseriesData[1].map(v => ({ x: v.x, y: Math.min(v.y, MAX_DISPLAY_VALUE) })) : [],
        borderColor: color[1],
        borderWidth: 1,
      },
      {
        label: 'SFOC / Load',
        data: timeseriesData[2] ? timeseriesData[2].map(v => ({ x: v.x, y: Math.min(v.y, MAX_DISPLAY_VALUE) })) : [],
        borderColor: color[2],
        borderWidth: 1,
      },
    ],
  };

  const options: TimeLineCardOptions = { yAxisLabel: 'Performance', title: 'Vessel Performance', xAxisLabel: 'Time' };
  return { data, loading, err, options };
}

function VesselPerformanceOverTime({ clientId, from, to, resolution, aspectRatio }: ChartProps) {
  const { data, loading, err, options } = useSpeedOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={options} data={data} loading={loading} err={err} aspectRatio={aspectRatio} />;
}

export default VesselPerformanceOverTime;
