import { useFleetConfig, VesselFeatureKey } from './fleetConfig';
import { useVessel } from './useVessel';

export function useVesselFeatures(clientId: string) {
  const vessel = useVessel(clientId);
  return vessel?.features?.map(f => f.key) ?? [];
}

export function useVesselHasFeature(clientId: string, feature: VesselFeatureKey) {
  const features = useVesselFeatures(clientId);
  return features.includes(feature);
}

export function useFleetVesselFeatures() {
  const config = useFleetConfig();
  return config.reduce((acc, cur) => {
    acc[cur.clientId] = cur.features?.map(f => f.key);
    return acc;
  }, {} as { [key: string]: string[] });
}
