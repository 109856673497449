import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  TabProps,
  Tabs,
  Toolbar,
  Tooltip,
} from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import LinearScaleIcon from '@mui/icons-material/LinearScale';

import brand_mid from 'assets/brand_mid.png';
import brand_mid_darkmode from 'assets/brand_mid_darkmode.png';
import brand_right from 'assets/brand_right.png';
import brand_right_darkmode from 'assets/brand_right_darkmode.png';

import { useStyles } from 'theme/styles';
import { useIsDarkTheme } from 'hooks/useThemeMode';
import { useAuth } from 'hooks/useAuth';
import usePathChild from 'hooks/usePathChild';

import UserProfile from './UserProfile';
import { OpenSettingsButton } from './OpenSettingsButton';
import { useVesselConfig } from 'context/vesselConfig';
import React from 'react';
import { UserManagementButton } from './UserManagementButton';
import { useFleetConfig } from 'hooks/fleetConfig';
import energetiq_logo from '../../assets/mtu_energetiq_forsight.png';
import { useVesselFeatures } from 'hooks/useVesselFeatures';
import useClientId from 'context/clientId';

export function RollsRoyceLogo() {
  const classes = useStyles();
  const isDarkTheme = useIsDarkTheme();
  const img = isDarkTheme ? brand_right_darkmode : brand_right;

  return <img src={img} alt={'IOconnect'} className={classes.brandImage} />;
}

export function EscapeHatch({ link, children }: { link: string; children: React.ReactNode }) {
  return <Link to={link}>{children}</Link>;
}

export function NautIQLogo() {
  const classes = useStyles();
  const isDarkTheme = useIsDarkTheme();
  const img = isDarkTheme ? brand_mid_darkmode : brand_mid;

  return <img src={img} alt={'IOconnect'} className={classes.brandImage} />;
}

function Profile() {
  const { user, logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const login = () => {
    navigate('/login');
  };

  return (
    <List sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: 'text.primary' }}>
      {isAuthenticated && user ? (
        <Tooltip title={'Logout'} placement="left">
          <ListItemButton
            onClick={e => {
              e.preventDefault();
              logout();
            }}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItemButton>
        </Tooltip>
      ) : (
        <ListItemButton onClick={login}>
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          <ListItemText>Login</ListItemText>
        </ListItemButton>
      )}
      {isAuthenticated && user && <UserProfile email={user.email} firstName={user.firstName} lastName={user.lastName} isOpen={true} />}
    </List>
  );
}

function Breadcrumbs({ sx = [], pageName }: { sx?: any; pageName: string }) {
  const fleetConfig = useFleetConfig();
  return (
    <Paper
      sx={[
        {
          boxShadow: 'none',
          height: '34px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          // justifyContent: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}>
      {fleetConfig.length > 1 ? (
        <Button component={Link} to={'/fleet'} sx={{ padding: '0px 16px 0px 16px', height: '100%', color: 'text.primary' }}>
          Fleet Overview
        </Button>
      ) : undefined}
      <Box
        sx={{
          padding: '0px 16px 0px 16px',
          backgroundColor: 'background.default',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}>
        {pageName}
      </Box>
    </Paper>
  );
}

export function BreadcrumbsWithVesselName() {
  const vesselConfig = useVesselConfig();
  return <Breadcrumbs pageName={vesselConfig.vessel?.name ?? ''} />;
}

function a11yProps(index: number | string) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

// This should have the same type as <Tab ... />
const LeftTab = (props: TabProps) => <Tab {...({ variant: 'inlineIcon' } as any)} {...props} />;

export function IconLabelTabs() {
  const navigate = useNavigate();
  const tab = usePathChild(false);
  const clientId = useClientId();
  const vesselProperties = useVesselFeatures(clientId);

  return (
    <Paper sx={{ backgroundColor: 'background.default', boxShadow: 'none' }}>
      <Tabs value={tab || 'cockpit'} onChange={(e, value) => navigate(value)} variant="fullWidth" aria-label="full width tabs example">
        <LeftTab {...a11yProps('cockpit')} label="Cockpit" value="cockpit" icon={<MapOutlinedIcon />} />
        <LeftTab {...a11yProps('status')} label="Status" value="status" icon={<NetworkCheckIcon />} />
        <LeftTab label="Analysis" value="analysis" {...a11yProps('analysis')} icon={<ShowChartIcon />} />
        <LeftTab {...a11yProps('notifications')} label="Notifications" value="notifications" icon={<NotificationsNoneOutlinedIcon />} />
        <LeftTab {...a11yProps('tasks')} label="Tasks" value="tasks" icon={<AssignmentOutlinedIcon />} />
        <LeftTab {...a11yProps('vessel-config')} label="Vessel Configuration" value="vessel-config" icon={<DirectionsBoatOutlinedIcon />} />
        {vesselProperties.includes('ePowerPlant') && (
          <LeftTab
            {...a11yProps('e-power-plant')}
            label="E-Power-Plant"
            value="e-power-plant"
            icon={<LinearScaleIcon sx={{ marginTop: '-1px' }} />}
          />
        )}
      </Tabs>
    </Paper>
  );
}

type NautiqAppBarProps = {
  showUserMngmt?: boolean;
  showSettings?: boolean;
  centerLogo?: React.ReactNode;
};

function CenterLogo() {
  const classes = useStyles();
  const isDarkTheme = useIsDarkTheme();
  const nautIQImg = isDarkTheme ? brand_mid_darkmode : brand_mid;
  const { pathname } = useLocation();

  if (pathname.startsWith('/plant')) {
    return <img src={energetiq_logo} alt={'IOconnect'} />;
  } else if (pathname.startsWith('/login')) {
    return <div style={{ width: 'auto' }}></div>;
  } else {
    return <img src={nautIQImg} alt={'IOconnect'} className={classes.brandImage} />;
  }
}

export function NautiqAppBar({ showUserMngmt = true, showSettings = true }: NautiqAppBarProps) {
  return (
    <AppBar sx={{ zIndex: theme => theme.zIndex.drawer + 2, boxShadow: 'none', backdropFilter: 'blur(12px)', position: 'inherit' }}>
      <Toolbar sx={{ display: 'grid', gridTemplateColumns: 'minmax(min-content, 1fr) auto minmax(min-content, 1fr)' }}>
        <EscapeHatch link="/">
          <RollsRoyceLogo />
        </EscapeHatch>
        <CenterLogo />
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          {showUserMngmt && <UserManagementButton />}
          {showSettings && <OpenSettingsButton />}
          <Profile />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export const BoilerplateV2: React.FC<{ disableBreadcrumbs?: boolean; overrideBreadcrumbTitle?: string; disableTabs?: boolean }> = ({
  children,
  disableBreadcrumbs,
  overrideBreadcrumbTitle,
  disableTabs,
}) => {
  return (
    <Box sx={{ display: 'flex', flexGrow: 1, minHeight: '100%', height: 'max-content', flexDirection: 'column' }}>
      {!disableBreadcrumbs && (
        <Paper sx={{ position: 'sticky', top: 0, zIndex: theme => theme.zIndex.drawer + 1, backdropFilter: 'blur(12px)' }}>
          {!overrideBreadcrumbTitle && <BreadcrumbsWithVesselName />}
          {overrideBreadcrumbTitle && <Breadcrumbs pageName={overrideBreadcrumbTitle} />}
          {!disableTabs && <IconLabelTabs />}
        </Paper>
      )}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
        {/* This box is needed so that the main content has intrinsic size */}
        <Box sx={{ height: 'calc(100vh - (34px + 64px))', width: 0 }}></Box>
        <Box component="main" sx={{ flexGrow: 1, p: { xs: 2, lg: 3 } }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
