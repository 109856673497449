// src/app/Plant/SingleLine/index.tsx
import { Box, Paper, Tab, Tabs } from '@mui/material';
import TitledPaper from 'components/TitledPaper';
import { BESS, SingleLineCircuit } from './SingleLineCircuit';
import { SingleLineAlerts } from './SingleLineAlerts';
import { useSingleLineData } from 'hooks/plant/useSingleLineData';
import { useBatteryConfigs } from 'hooks/plant/useBatteryConfig';
import { useBatteryStats } from '../../../hooks/plant/useBatteryStats';
import { BatteryStats } from '../BatteryTable';
import { useCallback, useEffect } from 'react';
import { ChevronLeft, QueryStatsOutlined } from '@mui/icons-material';
import SingleLineAsset from './Asset';
import { useSwipeable } from 'react-swipeable';
import { useNavigate, useParams } from 'react-router-dom';
import { PlantTabBar } from '../PlantTabBar';

type SingleLineProps = {
  clientId: string;
};

export type SingleLineGroup = {
  label: string;
  value: string;
  busBarIndex: number;
  batteryIds: number[];
};

const groups: SingleLineGroup[] = [
  { label: 'Trafigura', value: 'trafigura', busBarIndex: 0, batteryIds: [1, 2, 3, 4, 5] },
  { label: 'Essent', value: 'essent', busBarIndex: 1, batteryIds: [6, 7] },
];

export function SingleLine({ clientId }: SingleLineProps) {
  const { data: batteries } = useBatteryConfigs(clientId);
  const stats: BatteryStats[] = useBatteryStats(batteries ?? []);

  const { busBar, grid, alerts } = useSingleLineData(clientId, groups);

  const { group: groupParam, batteryid: batteryIdParam } = useParams<{ group: string; batteryid: string }>();
  const navigate = useNavigate();

  const selectedGroup = groups.find(group => group.value === groupParam) || groups[0];

  const filteredBESSunits: BESS[] | undefined = batteries
    ?.map((batteryData, index) => ({
      batteryConfig: batteryData,
      alerts: [alerts[index]],
      batteryStats: stats.find(stat => stat.id === batteryData.id),
    }))
    .filter(bess => selectedGroup.batteryIds.includes(bess.batteryConfig.id));

  const selectedBESS = filteredBESSunits?.find(bess => bess.batteryConfig.id.toString() === batteryIdParam);

  const handleGroupTabChange = (event: React.ChangeEvent<any>, newValue: string) => {
    const newGroup = groups.find(group => group.value === newValue);
    if (newGroup) {
      navigate(`/plant/${clientId}/singleline/${newGroup.value}`);
    }
  };

  const handleBESSTabChange = (event: React.ChangeEvent<any>, newValue: string) => {
    if (newValue === 'back') {
      navigate(`/plant/${clientId}/singleline/${selectedGroup.value}`);
    } else {
      const newBESS = filteredBESSunits?.find(bess => bess.batteryConfig.id.toString() === newValue);
      if (newBESS) {
        navigate(`/plant/${clientId}/singleline/${selectedGroup.value}/${newBESS.batteryConfig.id}`);
      }
    }
  };

  const handleBESSSelect = (bess: BESS) => {
    navigate(`/plant/${clientId}/singleline/${selectedGroup.value}/${bess.batteryConfig.id}`);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (selectedBESS && filteredBESSunits) {
        if (event.key === 'Escape') {
          navigate(`/plant/${clientId}/singleline/${selectedGroup.value}`);
        } else if (event.key === 'ArrowLeft') {
          const currentIndex = filteredBESSunits?.findIndex(bess => bess.batteryConfig.id === selectedBESS.batteryConfig.id) ?? -1;
          if (currentIndex > 0) {
            navigate(`/plant/${clientId}/singleline/${selectedGroup.value}/${filteredBESSunits[currentIndex - 1].batteryConfig.id}`);
          }
        } else if (event.key === 'ArrowRight') {
          const currentIndex = filteredBESSunits?.findIndex(bess => bess.batteryConfig.id === selectedBESS.batteryConfig.id) ?? -1;
          if (currentIndex < (filteredBESSunits?.length ?? 0) - 1) {
            navigate(`/plant/${clientId}/singleline/${selectedGroup.value}/${filteredBESSunits[currentIndex + 1].batteryConfig.id}`);
          }
        }
      } else {
        if (event.key === 'ArrowLeft') {
          const currentIndex = groups.findIndex(group => group.value === selectedGroup.value);
          if (currentIndex > 0) {
            navigate(`/plant/${clientId}/singleline/${groups[currentIndex - 1].value}`);
          }
        } else if (event.key === 'ArrowRight') {
          const currentIndex = groups.findIndex(group => group.value === selectedGroup.value);
          if (currentIndex < groups.length - 1) {
            navigate(`/plant/${clientId}/singleline/${groups[currentIndex + 1].value}`);
          }
        }
      }
    },
    [selectedBESS, filteredBESSunits, selectedGroup, navigate, clientId]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (selectedBESS && filteredBESSunits) {
        const currentIndex = filteredBESSunits.findIndex(bess => bess.batteryConfig.id === selectedBESS.batteryConfig.id) ?? -1;
        if (currentIndex < filteredBESSunits.length - 1) {
          navigate(`/plant/${clientId}/singleline/${selectedGroup.value}/${filteredBESSunits[currentIndex + 1].batteryConfig.id}`);
        }
      } else {
        const currentIndex = groups.findIndex(group => group.value === selectedGroup.value);
        if (currentIndex < groups.length - 1) {
          navigate(`/plant/${clientId}/singleline/${groups[currentIndex + 1].value}`);
        }
      }
    },
    onSwipedRight: () => {
      if (selectedBESS && filteredBESSunits) {
        const currentIndex = filteredBESSunits.findIndex(bess => bess.batteryConfig.id === selectedBESS.batteryConfig.id) ?? -1;
        if (currentIndex > 0) {
          navigate(`/plant/${clientId}/singleline/${selectedGroup.value}/${filteredBESSunits[currentIndex - 1].batteryConfig.id}`);
        }
      } else {
        const currentIndex = groups.findIndex(group => group.value === selectedGroup.value);
        if (currentIndex > 0) {
          navigate(`/plant/${clientId}/singleline/${groups[currentIndex - 1].value}`);
        }
      }
    },
  });

  return (
    <>
      <Box>
        <Paper sx={{ position: 'sticky', top: 0, zIndex: theme => theme.zIndex.drawer + 1, backdropFilter: 'blur(12px)' }}>
          <PlantTabBar />
        </Paper>
      </Box>
      <Box sx={{ p: 4, gap: 4, display: 'flex', flexDirection: 'column' }}>
        <Paper sx={{ boxShadow: 'none' }}>
          {selectedBESS ? (
            <Tabs value={selectedBESS.batteryConfig.id.toString()} onChange={handleBESSTabChange}>
              <Tab
                key="back"
                sx={{ textTransform: 'uppercase', minHeight: 0 }}
                label="Back"
                value="back"
                icon={<ChevronLeft />}
                iconPosition="start"
              />
              {filteredBESSunits &&
                filteredBESSunits.map(bess => (
                  <Tab
                    key={bess.batteryConfig.id}
                    sx={{ textTransform: 'uppercase' }}
                    label={bess.batteryConfig.name}
                    value={bess.batteryConfig.id.toString()}
                  />
                ))}
            </Tabs>
          ) : (
            <Tabs value={selectedGroup.value} onChange={handleGroupTabChange}>
              {groups.map(group => (
                <Tab key={group.value} sx={{ textTransform: 'uppercase' }} label={group.label} value={group.value} />
              ))}
            </Tabs>
          )}
        </Paper>
        {filteredBESSunits && (
          <TitledPaper
            title={selectedBESS ? `ESS ${selectedBESS.batteryConfig.name}` : `Single Line ${selectedGroup.label}`}
            icon={<QueryStatsOutlined />}>
            {selectedBESS ? (
              <Box {...swipeHandlers}>
                <SingleLineAsset battery={selectedBESS} />
              </Box>
            ) : (
              <Box {...swipeHandlers} sx={{ pb: 12 }}>
                <SingleLineAlerts BESSunits={filteredBESSunits} />
                <SingleLineCircuit
                  BESSunits={filteredBESSunits}
                  busBar={busBar[selectedGroup.busBarIndex]}
                  grid={grid}
                  onBESSClick={handleBESSSelect}
                />
              </Box>
            )}
          </TitledPaper>
        )}
      </Box>
    </>
  );
}
