import React from 'react';

import { Box } from '@mui/system';

type ClipCircleProps = {
  percent: number;
  sx?: any;
  color: string;
  darkColor?: string;
  children: React.ReactNode;
};

const ClipCircle = ({ darkColor, color, percent, sx, children }: ClipCircleProps) => {
  return (
    <Box sx={{ ...sx, position: 'relative' }}>
      <svg style={{ height: '100%' }} viewBox="0 0 16 16" fill="none">
        <circle cx="8" cy="8" r="7.5" stroke={color ?? '#0000'} data-fallback="edge" strokeWidth="0.9px" transform="rotate(-90, 8, 8)" />
        <circle
          cx="8"
          cy="8"
          r="7.5"
          stroke={darkColor}
          data-fallback="edge"
          strokeWidth="1px"
          transform="rotate(-90, 8, 8)"
          strokeDasharray={`${(1 - percent / 100) * 7.5 * 2 * 3.14}, 80`}
        />
      </svg>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div>{children}</div>
      </div>
    </Box>
  );
};

export default ClipCircle;
