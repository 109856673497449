import { Alert, AlertTitle, Box, Skeleton } from '@mui/material';
import TitledPaper from 'components/TitledPaper';
import { MonthlyEmissionsReport } from './MonthlyHistory';
import { useEmissionConfig, useEmissions } from 'hooks/emissions';
import { EmissionRatingChart } from './RatingChart';
import { Forecast } from './Forecast';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import useClientId from 'context/clientId';

export function EmissionsPage() {
  const clientId = useClientId();
  const { data: chartData } = useEmissions(clientId);
  const { data: emissionConfig, isError: isConfigError, error: configError } = useEmissionConfig(clientId);

  if (isConfigError || configError) {
    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Alert severity="info">
          <AlertTitle>Emission Analysis isn't configured for this vessel.</AlertTitle>
          {JSON.stringify((configError as any)?.message)}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TitledPaper icon={<QueryStatsIcon />} title="Emissions Forecast">
        <Forecast emissionConfig={emissionConfig} emissionData={chartData ?? []} />
      </TitledPaper>
      <TitledPaper icon={<DirectionsBoatIcon />} noPadding title="Emissions Rating">
        {emissionConfig && chartData ? (
          <EmissionRatingChart emissionConfig={emissionConfig} chartData={chartData} />
        ) : (
          <Skeleton variant="rectangular" sx={{ height: '300px', width: '100%' }} />
        )}
      </TitledPaper>
      <TitledPaper icon={<HistoryEduIcon />} noPadding title="Monthly Emissions History">
        <MonthlyEmissionsReport emissionData={chartData} emissionConfig={emissionConfig} />
      </TitledPaper>
    </Box>
  );
}
