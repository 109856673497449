import { useForm } from 'react-hook-form';
import { db } from '../../../helpers/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar/SnackbarContext';
import { APP_BAR_HEIGHT } from '../../../helpers/constants';
import { Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import { useResetTokenIsValid } from '../../../hooks/useResetTokenIsValid';

interface ResetPasswordValues {
  password: string;
  passwordConfirmation: string;
}

const errorMessageMapping = new Map<string, string>([
  ['token-invalid', 'The password reset link is invalid or has expired.'],
  ['token-usage-invalid', 'The link is not valid to reset a password.'],
  ['user-not-found', 'The user was not found.'],
  ['token-already-used', 'The password reset link has already been used.'],
]);

export function ResetPasswordError({ message }: { message?: string }) {
  return (
    <Container maxWidth="sm">
      <Box minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h4" fontWeight="bold" sx={{ mb: 3 }}>
          Reset your password
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {errorMessageMapping.get(message ?? '') ? errorMessageMapping.get(message ?? '') : 'An error occurred.'}
        </Typography>
      </Box>
    </Container>
  );
}

export function ResetPassword() {
  const {
    register: resetPaswordForm,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<ResetPasswordValues>();

  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbar();
  const { isLoading: resetTokenValidityCheckLoading, data: resetTokenValidityData } = useResetTokenIsValid(searchParams.get('token') || '');
  const email = searchParams.get('email');

  const onSubmit = (data: ResetPasswordValues) => {
    db.post('/users/reset-password', { ...data, token: searchParams.get('token') })
      .then(async data => {
        addSnackbar({ variant: 'success', message: 'Password reset successfully!' });
        await new Promise(r => setTimeout(r, 1000));
        navigate('/login');
        return data;
      })
      .catch(_e => {
        addSnackbar({ variant: 'error', message: 'Failed to reset password' });
      });
  };

  if (resetTokenValidityCheckLoading) {
    return (
      <Container maxWidth="sm">
        <Box minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex" flexDirection="row" justifyContent="center">
            <CircularProgress />
          </Box>
        </Box>
      </Container>
    );
  }

  if (!resetTokenValidityData?.valid) {
    return <ResetPasswordError message={resetTokenValidityData?.message} />;
  }

  return (
    <Container maxWidth="sm">
      <Box minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="h4"
          sx={{
            mb: 3,
            fontWeight: 'bold',
          }}>
          Reset your password
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Please set a new password {email && `for ${email} `}here.
        </Typography>
        <Box>
          <form
            method="post"
            action="#"
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', flexDirection: 'column', gap: 24, marginBottom: 24 }}>
            <TextField
              {...resetPaswordForm('password', {
                required: {
                  value: true,
                  message: 'Password is required',
                },
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long',
                },
              })}
              id="password"
              name="password"
              label="Password"
              fullWidth
              variant="standard"
              type="password"
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            <TextField
              {...resetPaswordForm('passwordConfirmation', {
                required: {
                  value: true,
                  message: 'Password confirmation is required',
                },
                validate: value => value === getValues('password') || 'Passwords do not match',
              })}
              id="password-confirmation"
              label="Repeat Password"
              fullWidth
              variant="standard"
              type="password"
              error={!!errors.passwordConfirmation}
              helperText={errors.passwordConfirmation?.message}
            />
            <Button type="submit" variant="contained" sx={{ width: '100%' }}>
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
}
