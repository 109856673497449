import { useCallback } from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useBaselineEditor } from 'hooks/useBaselineEditor';

import { pointId } from '../types';

const isDef = (x: any) => x !== undefined && x !== null;

export function BaselineEditor({
  clientId,
  baselineGroupId,
  baselineId,
}: {
  baselineGroupId: string;
  baselineId: string;
  clientId: string;
}) {
  const { baseline: baselineQuery, addPoint, updatePoint, removePoint } = useBaselineEditor({ clientId, baselineGroupId, baselineId });
  const baseline = baselineQuery.data;

  const gridColumns: GridColDef[] = [
    {
      field: 'x',
      type: 'number',
      editable: true,
    },
    {
      field: 'y',
      type: 'number',
      editable: true,
    },
    {
      field: 'empty',
      headerName: '',
      flex: 1,
      type: 'string',
      valueGetter: () => {
        return '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 80,
      cellClassName: 'actions',
      getActions: (params: GridRowParams) => {
        if (params.id === 'new') {
          return [];
        }
        return [
          <GridActionsCellItem icon={<DeleteForeverIcon />} label="Delete" onClick={() => removePoint({ pointId: params.id as string })} />,
        ];
      },
    },
  ];

  const rows: { id: string; x?: number; y?: number }[] = (baseline?.data ?? []).map(point => ({
    id: pointId(point),
    x: point.x,
    y: point.y,
  }));
  rows.push({ id: 'new' });

  const processRowUpdate = useCallback(
    async row => {
      const rowId = `${row.id}`;
      const xVal = row.getValue(rowId, 'x') as number;
      const yVal = row.getValue(rowId, 'y') as number;
      const { x, y } = row.row;
      if (!(isDef(x) && isDef(y))) {
        return;
      }
      if (row.id === 'new') {
        return addPoint({ x, y }).then(() => row);
      }
      return updatePoint({ x: xVal, y: yVal, pointId: rowId }).then(() => row);
    },
    [addPoint, updatePoint]
  );

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={gridColumns}
        initialState={{
          pagination: { paginationModel: { pageSize: 100 } },
        }}
        pageSizeOptions={[100]}
        editMode="row"
        onRowEditStop={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true } as any}
        disableRowSelectionOnClick
        autoHeight
      />
    </Box>
  );
}
