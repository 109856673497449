import ReactMapGL, { Marker } from 'react-map-gl';
import { useThemeMode } from 'hooks/useThemeMode';
import useClientId from 'context/clientId';
import { useLiveDataMap } from 'hooks/useLiveData';
import ship_3d from 'assets/3dship.png';
import React, { useEffect, useState } from 'react';

export function MapPerformance() {
  const themeMode = useThemeMode();
  const clientId = useClientId();
  const [bearing, setBearing] = useState(0);
  const [{ lat, lon, sog, cog }] = useLiveDataMap(
    {
      lat: { source: 'measurement', objectId: 'position', pvId: 'lat' },
      lon: { source: 'measurement', objectId: 'position', pvId: 'lon' },
      sog: { source: 'measurement', objectId: 'speed', pvId: 'sog' },
      cog: { source: 'measurement', objectId: 'direction', pvId: 'cog' },
    },
    { clientId }
  );

  useEffect(() => {
    if (cog?.value === undefined) {
      return;
    }
    if (sog?.value > 0.5) {
      setBearing(cog.value);
    }
  }, [sog?.value, cog?.value]);

  return (
    <ReactMapGL
      latitude={lat?.value ?? 0}
      longitude={lon?.value ?? 0}
      zoom={13.238401855436942}
      pitch={75.5}
      bearing={bearing}
      onLoad={e => {
        e.target.resize();
      }}
      mapStyle={`mapbox://styles/mapbox/${themeMode}-v10`}
      mapboxAccessToken={'pk.eyJ1IjoiZ2JlcmtlcyIsImEiOiJja3Yzc3J3ZXkwaDh4Mm9waHlnOXVsN2swIn0.fC1KfJL4JzkqB3GRYzNv8Q'}>
      <Marker key="Ship" latitude={lat?.value ?? 0} longitude={lon?.value ?? 0}>
        <img src={ship_3d} alt="Ship" />
      </Marker>
    </ReactMapGL>
  );
}
