import React from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import PublicIcon from '@mui/icons-material/Public';
import PoolIcon from '@mui/icons-material/Pool';
import Co2Icon from '@mui/icons-material/Co2';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import ForestIcon from '@mui/icons-material/Forest';
import SpeedIcon from '@mui/icons-material/Speed';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';

import { LabeledBox } from './LabeledBox';
import { HealthGaugeFleet, PerformanceGaugeFleet } from '../../components/Gauges/PerformanceGauge';
import TitledPaper from '../../components/TitledPaper';

type CompareProps = {
  leftIcon: any;
  rightIcon: any;
  leftText?: string;
  rightText?: string;
  leftNumber: number | string;
  rightNumber: number | string;
  leftUnit?: string;
  rightUnit?: string;
};

function Compare({ leftIcon, rightIcon, leftNumber, rightNumber, leftUnit, rightUnit, leftText, rightText }: CompareProps) {
  return (
    <Box
      sx={{
        mt: 1,
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center',
        placeItems: 'center',
      }}>
      <Box sx={{ width: 'min-content', gridColumn: '1' }}>{leftIcon}</Box>
      <Box sx={{ width: 'min-content', gridColumn: '3' }}>{rightIcon}</Box>
      <Box sx={{ ml: 1, mr: 1, width: 'min-content', gridRow: '2', gridColumn: '2' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 'x-large' }}>=</Typography>
      </Box>
      <Box sx={{ width: 'min-content', gridColumn: '1' }}>
        <span style={{ whiteSpace: 'nowrap' }}>
          {leftNumber} {leftUnit}
        </span>
      </Box>
      <Box sx={{ width: 'min-content', gridColumn: '3' }}>
        <span style={{ whiteSpace: 'nowrap' }}>
          {rightNumber} {rightUnit}
        </span>
      </Box>
      <Box sx={{ width: 'max-content', gridColumn: '1' }}>{leftText}</Box>
      <Box sx={{ width: 'max-content', gridColumn: '3' }}>{rightText}</Box>
    </Box>
  );
}

function NauticalMilesToEarths({ nauticalMiles }: { nauticalMiles: number }) {
  // As per: https://en.wikipedia.org/wiki/Nautical_mile
  // 1nm = 1 minute = 1/60 degree
  // earth = 360 degrees
  // earth / 1nm = 360 / (1/60) = 360 * 60 = 21600
  const earths = nauticalMiles / 21600;

  return (
    <Compare
      leftIcon={<DirectionsBoatIcon />}
      leftNumber={`${Math.round(nauticalMiles / 1000000)}`}
      leftText={`Mio. nm`}
      rightIcon={<PublicIcon />}
      rightNumber={`${Math.round(earths)}`}
      rightUnit="×"
      rightText="around the World"
    />
  );
}

function LitresToSwimmingPools({ liters }: { liters: number }) {
  // As per: https://en.wikipedia.org/wiki/Olympic-size_swimming_pool
  const numPools = liters / 2500000;

  return (
    <Compare
      leftIcon={<OilBarrelOutlinedIcon />}
      leftNumber={`${Math.round(liters / 1000000)}`}
      leftText="Mio. liters"
      rightIcon={<PoolIcon />}
      rightNumber={`${Math.round(numPools)}`}
      rightText="olympic pools"
    />
  );
}

function Co2ToTrees({ tonnes }: { tonnes: number }) {
  // As per: https://trees.org/wp-content/uploads/2022/07/Carbon-Brief-ExternalWEB.pdf
  // co2offest = #t / 2500 * 144.64
  // co2offset / 144.64 = = #t / 2500
  // co2offset / 144.64 * 2500 = #t
  const numTrees = (tonnes * 2500) / 144.64;

  return (
    <Compare
      leftIcon={<Co2Icon />}
      leftNumber={`${Math.round(tonnes)}`}
      leftUnit="t"
      rightIcon={<ForestIcon />}
      rightNumber={`${Math.round(numTrees)}`}
      rightText="planted trees"
    />
  );
}

export function OverallFleetStatistics() {
  const largeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  return (
    <TitledPaper title="Overall Fleet Statistics" icon={<QueryStatsIcon />}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
        <LabeledBox title="Fleet Performance">
          <PerformanceGaugeFleet icon={<SpeedIcon fontSize="small" />} cursorWidth={15} radius={largeScreen ? 45 : 36} />
        </LabeledBox>

        <LabeledBox title="Fleet Health">
          <HealthGaugeFleet icon={<HealthAndSafetyOutlinedIcon fontSize="small" />} cursorWidth={15} radius={largeScreen ? 45 : 36} />
        </LabeledBox>

        <LabeledBox title="Total Miles">
          <NauticalMilesToEarths nauticalMiles={9100000} />
        </LabeledBox>

        <LabeledBox title="Total Fuel">
          <LitresToSwimmingPools liters={18000000} />
        </LabeledBox>

        <LabeledBox title="Total CO2">
          <Co2ToTrees tonnes={5} />
        </LabeledBox>
      </Box>
    </TitledPaper>
  );
}
