import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Color from 'color';
import { Task } from 'hooks/useTasks';

import TaskDialog from '../TaskDialog';

interface HeaderProps {
  title: string;
  taskAmount?: number;
  status: 'UPCOMING' | 'IN PROGRESS' | 'DONE' | 'REJECTED' | 'ON HOLD';
  handleAdd: (task: Partial<Task>) => void;
}
const Header: React.FC<HeaderProps> = ({ title, taskAmount, status, handleAdd }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        py: 1,
        px: 2,
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Typography variant="h5" marginRight={1} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {title}
        </Typography>
        <Box
          sx={{
            height: 24,
            width: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            color: Color(theme.palette.text.primary).alpha(0.5).string(),
          }}>
          {taskAmount ?? 0}
        </Box>
      </Box>
      <TaskDialog status={status} handleAdd={handleAdd} />
    </Box>
  );
};

export default Header;
