import React from 'react';

import { Grid } from '@mui/material';
//General
import FocOverTime from '../Charts/FocOverTime';
import MapPosition from '../MapPosition';
import ConsumptionOverSog from '../Charts/ConsumptionOverSog';
import PowerOverSog from '../Charts/PowerOverSog';
import SpeedWindOverTime from '../Charts/SpeedWindOverTime';
import CurrentLogFactorOverTime from '../Charts/CurrentLogFactorOverTime';

import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export default function VesselSpeed() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();
  const vesselProperties = useVesselFeatures(clientId);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={6}>
          <SpeedWindOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        {vesselProperties.includes('cockpitMap') && (
          <Grid item xs={12} lg={6}>
            <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <CurrentLogFactorOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FocOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ConsumptionOverSog clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.15} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PowerOverSog clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.15} />
        </Grid>
      </Grid>
    </>
  );
}
