import { Navigate } from 'react-router-dom';
import { useTasksTabs } from './useTasksTabs';

export function TasksNavigate() {
  const tabs = useTasksTabs();

  if (tabs.length === 0) {
    return <p>Tasks has no tabs enabled</p>;
  }
  return <Navigate to={tabs[0].toPath} replace />;
}
