// src/app/Plant/SingleLine/SingleLineBESS.tsx
import { Box, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import BatteryDefault from 'assets/singleline/battery_default.svg';
import BatteryCharging from 'assets/singleline/battery_charging.svg';
import BatteryDischarging from 'assets/singleline/battery_discharging.svg';
import { BESS } from './SingleLineCircuit';
import { alertsConfig } from './SingleLineAlerts';

const GREY_COLOR = grey[600];
const TRANSPARENT_COLOR = 'transparent';

type SingleLineBESSProps = {
  bess: BESS;
  onClick: () => void;
  sx?: any;
};

export function SingleLineBESS({ bess, onClick, sx }: SingleLineBESSProps) {
  const alertKey = bess.alerts[0];

  const backgroundColor =
    alertsConfig[alertKey]?.color === GREY_COLOR ? TRANSPARENT_COLOR : alertsConfig[alertKey]?.color || TRANSPARENT_COLOR;
  const textColor = backgroundColor === TRANSPARENT_COLOR ? GREY_COLOR : 'white';
  const borderColor = alertsConfig[alertKey]?.color || TRANSPARENT_COLOR;

  const output = bess.batteryStats?.output ?? 0;
  const input = bess.batteryStats?.input ?? 0;
  const dischargeRate = output > 0 ? output : input > 0 ? input * -1 : 0;
  const voltage = bess.batteryConfig.nominalVoltage ?? 'N/A';
  const current =
    bess.batteryConfig.nominalCapacity && bess.batteryConfig.nominalVoltage
      ? (bess.batteryConfig.nominalCapacity / bess.batteryConfig.nominalVoltage).toFixed(2)
      : 'N/A';

  return (
    <Paper
      elevation={3}
      onClick={onClick} // Add this line
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pb: 3,
        pl: 0.25,
        gap: 2,
        border: `2px solid ${borderColor}`,
        maxWidth: '352px',
        width: '100%',
        '@media (max-width: 1372px)': {
          flexDirection: 'column-reverse',
          pb: 1,
          pl: 0,
          alignItems: 'stretch',
        },
        cursor: 'pointer',
        ...sx,
      }}>
      <Box sx={{ maxWidth: '100%', height: 'auto', flexShrink: 1, margin: '0 auto' }}>
        <img
          src={
            alertsConfig[alertKey]?.batteryImage ||
            (dischargeRate > 0 ? BatteryDischarging : dischargeRate < 0 ? BatteryCharging : BatteryDefault)
          }
          alt=""
          style={{ maxWidth: '100%', height: 'auto', flexShrink: 1 }}
        />
      </Box>

      <Box sx={{ color: GREY_COLOR }}>
        <Typography
          variant="h5"
          sx={{
            backgroundColor: backgroundColor,
            color: textColor,
            px: 0.5,
          }}>
          {bess.batteryConfig.name}
        </Typography>
        <Typography variant="h4" sx={{ px: 0.5, whiteSpace: 'nowrap' }}>{`${dischargeRate} kW`}</Typography>
        <Typography variant="h5" sx={{ px: 0.5, whiteSpace: 'nowrap' }}>
          {`${voltage} V ${current} A`}
        </Typography>
      </Box>
    </Paper>
  );
}
