import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import NavigationIcon from '@mui/icons-material/Navigation';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import { Fleet } from './Fleet';
import { useThemeMode } from 'hooks/useThemeMode';
import { useFleetConfig } from 'hooks/fleetConfig';
import { datumToString, useLiveDataMapped } from 'hooks/useLiveData';
interface ShipData {
  id: string;
  lat: number;
  lon: number;
  sog: number;
  cog: number;
}

const minWidth = 850;

export function FleetMap() {
  const themeMode = useThemeMode();
  const fleets = useFleetConfig();

  const topics = fleets.map(fleet => ({
    [fleet.clientId + '/lat']: datumToString({
      source: 'measurement',
      clientId: fleet.clientId,
      objectId: 'position',
      objectIdNo: 0,
      pvId: 'lat',
      pvIdNo: 0,
    }),
    [fleet.clientId + '/lon']: datumToString({
      source: 'measurement',
      clientId: fleet.clientId,
      objectId: 'position',
      objectIdNo: 0,
      pvId: 'lon',
      pvIdNo: 0,
    }),
    [fleet.clientId + '/sog']: datumToString({
      source: 'measurement',
      clientId: fleet.clientId,
      objectId: 'speed',
      objectIdNo: 0,
      pvId: 'sog',
      pvIdNo: 0,
    }),
    [fleet.clientId + '/cog']: datumToString({
      source: 'measurement',
      clientId: fleet.clientId,
      objectId: 'direction',
      objectIdNo: 0,
      pvId: 'cog',
      pvIdNo: 0,
    }),
  }));
  const topicMap = Object.assign({}, ...topics);

  const [data] = useLiveDataMapped(topicMap);

  const shipPositions: ShipData[] = [];
  fleets.forEach(ship => {
    if (data[ship.clientId + '/lat'] && data[ship.clientId + '/lon'] && data[ship.clientId + '/sog'] && data[ship.clientId + '/cog']) {
      const speedOverGround = Math.round(data[ship.clientId + '/sog'].value);
      const cogCorrected = speedOverGround > 0 ? data[ship.clientId + '/cog'].value ?? 0 : 0;

      shipPositions.push({
        id: ship.clientId,
        lat: data[ship.clientId + '/lat'].value,
        lon: data[ship.clientId + '/lon'].value,
        sog: speedOverGround,
        cog: cogCorrected,
      });
    }
  });
  // Hamburg Coordinates
  // const defaultLatitude = 53.54013;
  // const defaultLongtitude = 9.98756;

  // Middle hamburg and Trapani
  const defaultLatitude = 46.74289;
  const defaultLongtitude = 10.8972;
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        height: minWidth * 0.55,
        minWidth,
      }}>
      <ReactMapGL
        initialViewState={{
          longitude: defaultLongtitude,
          latitude: defaultLatitude,
          zoom: 3.5,
        }}
        cooperativeGestures={true}
        maxZoom={16}
        mapStyle={`mapbox://styles/mapbox/${themeMode}-v10`}
        mapboxAccessToken={'pk.eyJ1IjoiZ2JlcmtlcyIsImEiOiJja3Yzc3J3ZXkwaDh4Mm9waHlnOXVsN2swIn0.fC1KfJL4JzkqB3GRYzNv8Q'}>
        {shipPositions.map((ship, i) => (
          <Marker key={i} latitude={ship.lat} longitude={ship.lon}>
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant="caption"
                sx={{ position: 'absolute', whiteSpace: 'nowrap', top: -14, left: '50%', transform: 'translateX(-50%)' }}>
                {fleets.find(f => f.clientId === ship.id)?.name}
              </Typography>
              <NavigationIcon
                color={'primary'}
                sx={{
                  transform: `rotate(${ship.cog}deg)`,
                }}
              />
            </Box>
          </Marker>
        ))}
      </ReactMapGL>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          m: 1,
        }}>
        <Fleet />
      </Box>
    </Box>
  );
}
