import { cumulativeToNonCumulative, percentageOfRange } from 'helpers/utils';
import { RatingCalculator } from './RatingCalculator';
import { RatingIcon } from '../RatingBox';
import Box from '@mui/material/Box';

type BoundaryBoxesProps = {
  calculator: RatingCalculator;
};

const X_AXIS_LABEL_HEIGHT = '29px';

export default function BoundaryBoxes(props: BoundaryBoxesProps) {
  const boundaryLines = props.calculator.getBoundaryLines();
  const min = props.calculator.getChartMin();
  const max = props.calculator.getChartMax();

  const minXDate = min.x ? new Date(min.x) : new Date();
  const boundaries = boundaryLines.map(value => value.find(v => new Date(v.x).getUTCFullYear() === minXDate.getUTCFullYear())?.y);
  const cumulativeBoundaries = boundaries.map(b => {
    if (b) {
      const percentage = percentageOfRange(b, [0, max.y]);
      return Math.max(0, percentage);
    }
    return undefined;
  });
  const nonCumulativeBoundaries = cumulativeToNonCumulative(cumulativeBoundaries);
  const allBoundariesDefined = nonCumulativeBoundaries.slice(0, 4).every(b => b !== undefined);
  if (!allBoundariesDefined) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: X_AXIS_LABEL_HEIGHT }}>
      <RatingIcon
        rating="E"
        sx={{
          height:
            100 -
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            nonCumulativeBoundaries[3]! -
            nonCumulativeBoundaries[2]! -
            nonCumulativeBoundaries[1]! -
            nonCumulativeBoundaries[0]! +
            '%',
          px: 1,
        }}
        variant="h6"
      />
      <RatingIcon rating="D" sx={{ height: nonCumulativeBoundaries[3] + '%', px: 1 }} variant="h6" />
      <RatingIcon rating="C" sx={{ height: nonCumulativeBoundaries[2] + '%', px: 1 }} variant="h6" />
      <RatingIcon rating="B" sx={{ height: nonCumulativeBoundaries[1] + '%', px: 1 }} variant="h6" />
      <RatingIcon rating="A" sx={{ height: nonCumulativeBoundaries[0] + '%', px: 1 }} variant="h6" />
    </Box>
  );
}
