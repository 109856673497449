import background from 'assets/dark_mode_bg.png';
import { useIsDarkTheme } from 'hooks/useThemeMode';

const Background: React.FC = ({ children }) => {
  const isDarkTheme = useIsDarkTheme();
  return (
    <>
      <div
        id="background-overlay"
        style={{
          width: '100%',
          height: '100%',
          background: `url(${background})`,
          backgroundSize: 'cover',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: -1,
        }}>
        <div
          style={{ height: '100%', width: '100%', backgroundColor: isDarkTheme ? 'rgba(29,29,31,0.93)' : 'rgba(255,255,255,0.93)' }}></div>
      </div>
      {children}
    </>
  );
};

export default Background;
