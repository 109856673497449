import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function SingleBar({
  value = 0,
  warningLevel = 20,
  criticalLevel = 30,
  maxLevel = 30,
  label,
}: {
  value: number;
  warningLevel: number;
  criticalLevel: number;
  maxLevel?: number;
  label: string;
}) {
  const theme = useTheme();
  const defaultBackgroundColor = theme.palette.grey[300];
  const barColor = theme.palette.primary.main;
  const warningColor = theme.palette.warning.main;
  const criticalColor = theme.palette.error.main;

  const backgroundColor = value >= criticalLevel ? criticalColor : value >= warningLevel ? warningColor : barColor;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '0.5em', alignItems: 'center', width: 'min-content' }}>
      <Box
        sx={{
          height: '100%',
          width: '0.75em',
          backgroundColor: defaultBackgroundColor,
          display: 'flex',
          alignItems: 'flex-end',
        }}>
        <Box
          sx={{
            height: `${(Math.min(value, maxLevel) / maxLevel) * 100}%`,
            width: '100%',
            backgroundColor: backgroundColor,
          }}
        />
      </Box>
      <Box sx={{ height: '2em', width: '1em', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          {value.toFixed(0)}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
}
