import { Box, Skeleton } from '@mui/material';
import { CompanyDto } from 'dtos/companies/company.dto';
import { Link } from 'react-router-dom';
import { useAllCompanies } from 'hooks/companies/useAllCompanies';

function CompanyListEntry({ company }: { company: CompanyDto }) {
  return (
    <Box>
      <Link to={`../../company/${company.id}`}>{company.name}</Link>
    </Box>
  );
}

export function CompanyList() {
  const { data: all_companies, isLoading, isError } = useAllCompanies();
  return (
    <Box>
      <h1>Company List</h1>
      {isLoading && <Skeleton variant="rectangular" sx={{ height: '50vh' }} />}
      {isError && <p>An error occurred while loading the company list.</p>}
      {!isLoading && !isError && (
        <Box>
          {all_companies?.map(company => (
            <CompanyListEntry key={company.id} company={company} />
          ))}
        </Box>
      )}
    </Box>
  );
}
