import { useQuery } from 'react-query';
import { db } from 'helpers/api';
import { useMemo } from 'react';
import valueConversion from 'helpers/valueConversion';

const familyKeys = [
  'speed_consumption',
  'speed_power',
  'exhaust_gas_temp',
  'coolant_temp',
  'crankcase_pressure',
  'sfoc_iso',
  'lube_oil_pressure',
  'tc_curve',
] as const;
type FamilyKey = (typeof familyKeys)[number];

type BaselineThresholdReq = {
  clientId: string;
  familyKey: FamilyKey;
  objectId?: string;
};

type Line = { x: number; y: number }[];

export type BaselineThresholds = {
  baseline: Line;
  warnMin?: Line;
  warnMax?: Line;
  alarmMin?: Line;
  alarmMax?: Line;
};

async function getThresholdLines(params: BaselineThresholdReq) {
  const { data } = await db.get<BaselineThresholds>(`config/baselines/getThresholdLines`, { params });
  return data;
}

type BaselineUnitSettings = {
  xSourceUnit?: string;
  xTargetUnit?: string;
  ySourceUnit?: string;
  yTargetUnit?: string;
};

function applyUnitsToLine(line: Line, settings: BaselineUnitSettings): Line {
  return line.map(({ x, y }) => ({
    x: valueConversion(x, settings.xSourceUnit, settings.xTargetUnit),
    y: valueConversion(y, settings.ySourceUnit, settings.yTargetUnit),
  }));
}

function applyUnits(thresholds: BaselineThresholds, unitSettings: BaselineUnitSettings): BaselineThresholds {
  return Object.fromEntries(
    Object.entries(thresholds).map(([key, line]) => [key, applyUnitsToLine(line, unitSettings)])
  ) as BaselineThresholds;
}

export function useBaselineThresholds(params: BaselineThresholdReq, settings?: BaselineUnitSettings) {
  const res = useQuery(['baselineThresholds', params], () => getThresholdLines(params));
  res.data = useMemo(() => {
    if (settings && res.data) {
      return applyUnits(res.data, settings);
    }
    return res.data;
  }, [res.data, settings]);

  return res;
}
