import { deepOrange } from '@mui/material/colors';
import SimpleLine from 'components/SimpleLine/SimpleLine';
import { LoadProfile } from 'hooks/useLoadProfile';
import { loadProfileToPoints } from './utils';

type LoadProfileChartProps = {
  isLoading: boolean;
  data?: LoadProfile;
  currentLF?: number;
};

const DEFAULT_BINS = [5, 30, 50, 65, 80, 90, 100, 110];

export default function LoadProfileChart({ data, isLoading, currentLF }: LoadProfileChartProps) {
  const points = data ? loadProfileToPoints(data, DEFAULT_BINS) : [];
  const loadProfileData = {
    data: [{ x: 0, y: 110 }, ...points, { x: 100, y: 0 }],
    label: 'Current Load Profile',
    borderColor: '#9C27B0',
    borderWidth: 4,
    stepped: 'after',
  };

  const loadFactorData = {
    data: [
      { x: 0, y: currentLF },
      { x: 100, y: currentLF },
    ],
    label: 'Current Load Factor',
    borderColor: deepOrange['400'],
    borderWidth: 1,
  };

  return (
    <SimpleLine
      loading={isLoading}
      data={{
        datasets: [loadProfileData, loadFactorData],
      }}
      aspectRatio={2.7}
      opts={{
        scales: {
          x: {
            type: 'linear' as any,
            title: {
              display: true,
              text: 'Operating Time in %',
            },
            max: 100,
            min: 0,
          },
          y: {
            title: {
              display: true,
              text: 'Engine Load in %',
            },
            max: 110,
            min: 0,
            beginAtZero: true,
          },
        },
      }}
    />
  );
}
