export type EmissionStats = { time: string; liters: number | null; travelledDistance: number | null };
export type CIIBoundary = [number, number, number, number];
export type EmissionConfig = {
  ciiBoundaries: { [year: string]: CIIBoundary };
  co2ConversionFactor: number;
  capacity: number;
  fuelLiquidDensity: number;
};

export type Rating = 'A' | 'B' | 'C' | 'D' | 'E';
export const RatingColors: Record<Rating, string> = {
  A: '#1b5e20',
  B: '#2e7d32',
  C: '#ffb400',
  D: '#ed6c02',
  E: '#d32f2f',
};

export function ciiToRating(cii: number, boundary: CIIBoundary): Rating {
  if (cii < boundary[0]) {
    return 'A';
  } else if (cii < boundary[1]) {
    return 'B';
  } else if (cii < boundary[2]) {
    return 'C';
  } else if (cii < boundary[3]) {
    return 'D';
  } else {
    return 'E';
  }
}

export function slidingWindow<D, R>(data: D[], windowSize: number, projection: (ds: D[]) => R): R[] {
  const res = [];
  for (let i = windowSize; i <= data.length; i++) {
    const window = data.slice(Math.max(0, i - windowSize), i);
    if (!window.length) {
      continue;
    }
    res.push(projection(window));
  }
  return res;
}

export function ciiCalculation(
  { travelledDistanceNm, fuelConsumptionLiters }: { travelledDistanceNm: number; fuelConsumptionLiters: number },
  emissionConfig?: EmissionConfig
): number | undefined {
  if (!emissionConfig) {
    return undefined;
  }
  if (travelledDistanceNm === 0) {
    return undefined;
  }

  const result =
    (fuelConsumptionLiters * emissionConfig.fuelLiquidDensity * 1000 * emissionConfig.co2ConversionFactor) /
    (emissionConfig.capacity * travelledDistanceNm);
  return result;
}

export function emissionWindowAggregation(points: EmissionStats[]) {
  const totalDistance = points.reduce((acc, point) => acc + (point.travelledDistance ?? 0), 0);
  const totalLiters = points.reduce((acc, point) => acc + (point.liters ?? 0), 0);
  const time = points.length > 0 ? points[points.length - 1].time : undefined;
  return {
    time,
    travelledDistance: totalDistance,
    liters: totalLiters,
  };
}
