import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TaskForm from '../TaskForm';
import { Task } from 'hooks/useTasks';
import { useAuth } from 'hooks/useAuth';
import useClientId from 'context/clientId';

interface TaskDialogProps {
  status: 'UPCOMING' | 'IN PROGRESS' | 'DONE' | 'REJECTED' | 'ON HOLD';
  handleAdd: (task: Partial<Task>) => void;
}

const TaskDialog: React.FC<TaskDialogProps> = ({ status, handleAdd }) => {
  const { isAuthenticated } = useAuth();
  const clientId = useClientId();
  const [open, setOpen] = useState(false);
  const [task, setTask] = useState<Partial<Task>>({
    clientId,
    status: status,
    custom: true,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip title={isAuthenticated ? 'New Task' : 'You need to be logged in'}>
        <span>
          <IconButton aria-label="open" onClick={handleOpen} disabled={!isAuthenticated}>
            <AddIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create new Task</DialogTitle>
        <DialogContent>
          <TaskForm task={task} isNew={true} setTaskValues={setTask} onSubmit={handleAdd} />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            type="submit"
            form="new-task-form"
            disableElevation
            disabled={!isAuthenticated}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskDialog;
