import { useEffect, useMemo, useRef, useState } from 'react';

import { Alert, Card, CardMedia, Skeleton, Theme, useTheme } from '@mui/material';

import ChartJS, { ScatterDataPoint } from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import AspectRatioBox from 'components/AspectRatioBox';
import { AxisOptions } from 'context/baselineConfig';
import { chartColors } from '../../../theme/chartColors';
import { ChartData } from 'chart.js';

import { useZoomTimeRangeContext } from 'context/zoom/ZoomTimeRangeContext';
import { BaselineThresholds } from 'hooks/useBaseline';

ChartJS.register([]);

type SimpleMixScatterCardProps = {
  name?: string;
  data: ChartData<any, ScatterDataPoint[], any>;
  loading: boolean;
  err?: string;
  options?: AxisOptions;
  baselineGroup?: BaselineThresholds;
  aspectRatio?: number;
  renderIndex?: number;
};

function buildOptions(theme: Theme, axis: AxisOptions, title?: string, showLabels?: boolean): any {
  return {
    animations: false as any,
    maintainAspectRatio: false,
    responsive: process.env.NODE_ENV !== 'test',
    mode: 'nearest',
    elements: {
      point: { radius: 3 },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      xAxes: {
        min: axis.xMin,
        max: axis.xMax,
        suggestedMin: axis.xSuggestedMin,
        suggestedMax: axis.xSuggestedMax,
        beginAtZero: false,
        title: {
          display: true,
          text: axis.xLabel,
        },
        grid: {
          color: theme.palette.chartGrid.main,
          borderColor: theme.palette.chartGrid.main,
        },
      },
      yAxes: {
        min: axis.yMin,
        max: axis.yMax,
        suggestedMin: axis.ySuggestedMin,
        suggestedMax: axis.ySuggestedMax,
        beginAtZero: false,
        title: {
          display: true,
          text: axis.yLabel,
        },
        grid: {
          color: theme.palette.chartGrid.main,
          borderColor: theme.palette.chartGrid.main,
        },
      },
    },
    plugins: {
      title: {
        display: !!title,
        text: title,
      },
      legend: {
        display: true,
        labels: {
          filter: (item: any, chartData: any) => {
            return showLabels && chartData.datasets[item.datasetIndex].type !== 'line';
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = '(';

            const precision = 2;
            if (context.parsed.x) {
              label += Math.round(context.parsed.x * 10 ** precision) / 10 ** precision;
            }
            label += ', ';
            if (context.parsed.y) {
              label += Math.round(context.parsed.y * 10 ** precision) / 10 ** precision;
            }
            label += ')';
            return label;
          },
        },
      },
    },
  };
}

function getDataSetForBaseline(values: { y: number; x: any }[] | undefined, color: string) {
  if (!values) {
    return undefined;
  }

  const borderWidth = 1;
  const radius = 1;
  return {
    borderWidth,
    fill: false,
    radius,
    data: values,
    type: 'line',
    order: 1,
    borderColor: color,
  };
}

function setBaselineGroup(data: ChartData<any, ScatterDataPoint[], any>, thresholds: BaselineThresholds | undefined) {
  if (thresholds) {
    const colors = chartColors.baselineGroup;
    const datasetsWithBaselines = [
      getDataSetForBaseline(thresholds.baseline, colors.baseline),
      getDataSetForBaseline(thresholds.warnMin, colors.warn),
      getDataSetForBaseline(thresholds.warnMax, colors.warn),
      getDataSetForBaseline(thresholds.alarmMin, colors.alarm),
      getDataSetForBaseline(thresholds.alarmMax, colors.alarm),
    ].filter((x): x is Exclude<ReturnType<typeof getDataSetForBaseline>, undefined> => x !== undefined);
    return {
      datasets: datasetsWithBaselines.concat(data.datasets),
    };
  } else {
    return data;
  }
}

function ScatteredBaselineCard({ name, data, loading, err, options = {}, baselineGroup, aspectRatio = 7 }: SimpleMixScatterCardProps) {
  const theme = useTheme();
  const chartRef = useRef<ChartJS<'scatter', number[], string>>(null);
  const { zoomRange } = useZoomTimeRangeContext();
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    const { current: chart } = chartRef;
    if (zoomRange) {
      const tmp = JSON.parse(JSON.stringify(data));
      for (const item of tmp.datasets) {
        item.data = item.data.filter((obj: any) => {
          return obj.tsp >= zoomRange.min && obj.tsp <= zoomRange.max;
        });
      }
      setFilteredData(tmp);
    }
    chart?.render();
  }, [zoomRange, data]);

  const chartOptions = useMemo(() => {
    return buildOptions(theme, options, name, true);
  }, [name, options, theme]);

  const chartData = useMemo(() => {
    return setBaselineGroup(zoomRange && filteredData ? filteredData : data, baselineGroup);
  }, [baselineGroup, data, filteredData, zoomRange]);

  return (
    <Card>
      <CardMedia component="div">
        <AspectRatioBox aspectRatio={aspectRatio} minHeight="200px">
          {loading || err ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <Chart type="scatter" data={chartData} options={chartOptions} />
          )}
        </AspectRatioBox>
      </CardMedia>
      {err ? <Alert severity="error">{err}</Alert> : null}
    </Card>
  );
}

export default ScatteredBaselineCard;
