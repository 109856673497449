import React from 'react';

import { Grid } from '@mui/material';

//Coolant
import CoolantTempOverTime from '../Charts/CoolantTempOverTime';
import CoolantPressureOverTime from '../Charts/CoolantPressureOverTime';
import { CoolantPressureOverLoad } from '../Charts/CoolantPressureOverLoad';
import { CoolantTempOverLoad } from '../Charts/CoolantTempOverLoad';
import EnginePowerOverTime from '../Charts/EnginePowerOverTime';
import MapPosition from '../MapPosition';

import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export default function EngineCoolant() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();
  const vesselProperties = useVesselFeatures(clientId);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={6}>
          <EnginePowerOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        {vesselProperties.includes('cockpitMap') && (
          <Grid item xs={12} lg={6}>
            <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <CoolantTempOverTime clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={12} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CoolantTempOverLoad clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={13} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CoolantPressureOverTime clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={14} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CoolantPressureOverLoad clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={15} />
        </Grid>
      </Grid>
    </>
  );
}
