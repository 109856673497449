import { Box, Typography } from '@mui/material';
import { VibrationRmsChart } from './VibrationRmsChart';
import { VibrationFFTChart } from './VibrationFFTChart';

export function Vibration({ objectIdNo }: { objectIdNo: number }) {
  const boxStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5em',
    paddingTop: '1em',
    paddingBottom: '1em',
    alignItems: 'flex-end',
  };
  return (
    <Box sx={{ paddingTop: '3em', textAlign: 'center' }}>
      <Typography variant="body1">Current Vibration Engine</Typography>
      <Box sx={boxStyles}>
        <VibrationRmsChart objectId="combustion" objectIdNo={objectIdNo} />
        <VibrationFFTChart objectId="combustion" objectIdNo={objectIdNo} />
      </Box>
      <Typography variant="body1">Current Vibration Gearbox</Typography>
      <Box sx={boxStyles}>
        <VibrationRmsChart objectId="gearbox" objectIdNo={objectIdNo} />
        <VibrationFFTChart objectId="gearbox" objectIdNo={objectIdNo} />
      </Box>
    </Box>
  );
}
