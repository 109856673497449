import { List, ListItem, ListItemText } from '@mui/material';
import { Task } from 'hooks/useTasks';

interface TaskDetailsProps {
  task: Task;
}

const TaskDetails: React.FC<TaskDetailsProps> = ({ task }) => {
  return (
    <List>
      {task.workplan?.items.map(item => (
        <ListItem key={item._id}>
          <ListItemText primary={`${item.wpCode} ${item.title}`} secondary={item.description} />
        </ListItem>
      ))}
    </List>
  );
};

export default TaskDetails;
