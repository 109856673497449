import React from 'react';

import type { ClientId } from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';
import { useTargetUnits } from 'context/settings';

function useLubeOilPressureOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const vesselConfig = useVesselConfig();
  const queries: TimeseriesSequenceQuery[] = Object.keys(vesselConfig.engines).map(engineNo => ({
    clientId,
    objectId: 'combustion',
    objectIdNo: Number(engineNo),
    pvId: 'pressureLubeOil',
    pvIdNo: 0,
    resolution,
    from,
    to,
    sourceUnit: 'bar',
    targetUnit: units.pressure,
  }));

  const [datas, loading, err] = useSequencesData(queries);

  const data = {
    datasets: datas.map((data, i) => ({
      label: vesselConfig.engines[i].name,
      data: data ?? [],
      borderColor: color[i],
      borderWidth: 1,
    })),
  };

  const options = { yAxisLabel: `Pressure ${units.pressure}`, title: 'Lube Oil Pressure', xAxisLabel: 'Time' };
  return { data, loading, err, options };
}

function LubeOilPressureOverTime({ clientId, from, to, resolution }: ChartProps) {
  const { data, loading, err, options } = useLubeOilPressureOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={options} data={data} loading={loading} err={err} />;
}

export default LubeOilPressureOverTime;
