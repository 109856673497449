import { EmissionConfig, EmissionStats, ciiCalculation, emissionWindowAggregation, slidingWindow } from 'helpers/emissions';

type TimePoint = {
  x: string;
  y: number;
};

export class RatingCalculator {
  constructor(private readonly data: EmissionStats[], private readonly config: EmissionConfig) {}

  private emissionsLine: TimePoint[] | undefined;
  private boundaryLines: TimePoint[][] | undefined;

  /**
   * Calculates the cii boundary lines, displayed in the chart.
   */
  getBoundaryLines(): TimePoint[][] {
    if (!this.boundaryLines) {
      const boundaries = Object.keys(this.config.ciiBoundaries).map(key => {
        const date = new Date(+key, 0, 1); // 1. Jan.
        return {
          time: date.toISOString(),
          boundaries: this.config.ciiBoundaries[key],
        };
      });
      this.boundaryLines = [0, 1, 2, 3].map(index => boundaries.map(line => ({ x: line.time, y: line.boundaries?.[index] })));
    }
    return this.boundaryLines;
  }

  /**
   * Calculates the emission line, displayed in the chart.
   */
  getEmissionLine(): TimePoint[] {
    if (!this.emissionsLine) {
      const movingAvg = slidingWindow(this.data, 31, emissionWindowAggregation);
      this.emissionsLine = movingAvg.map((point: any) => {
        const cii = ciiCalculation({ travelledDistanceNm: point.travelledDistance, fuelConsumptionLiters: point.liters }, this.config);
        return {
          x: point.time,
          y: Math.max(cii ?? 0, 0),
        };
      });
    }
    return this.emissionsLine;
  }

  getChartMin() {
    return {
      y: 0,
      x: this.getEmissionLine()[0]?.x,
    };
  }

  getChartMax() {
    const line = this.getEmissionLine();
    return {
      y: grace(Math.max(...line.map(l => l.y), ...this.getBoundaryLines().flatMap(lines => lines.map(l => l.y))), 0.2),
      x: line[line.length - 1].x,
    };
  }
}

/**
 * Adds/Subtracts the specified percentage from `num`.
 */
export function grace(num: number, percentage: number) {
  return num * (1 + percentage);
}
