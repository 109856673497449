import { Box, Paper } from '@mui/material';
import { NautiqAppBar } from 'app/NavigationBoilerplate/BoilerplateV2';
import { APP_BAR_HEIGHT } from 'helpers/constants';
import { useEffect } from 'react';

export function PlantAppBar() {
  return <NautiqAppBar showSettings={false} showUserMngmt={false} />;
}

export const PlantContainer: React.FC<Record<string, unknown>> = ({ children }) => {
  useEffect(() => {
    const prev = document.title;
    document.title = 'EnergetIQ Foresight';

    return () => {
      document.title = prev;
    };
  }, []);

  return (
    <>
      <Paper>
        <PlantAppBar />
      </Paper>
      <Box id="route-box" sx={{ height: `calc(100vh - ${APP_BAR_HEIGHT}px)` }}>
        {children}
      </Box>
    </>
  );
};
