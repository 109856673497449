import { green, grey, orange, red, yellow } from '@mui/material/colors';
import { createTheme, PaletteColor, responsiveFontSizes } from '@mui/material/styles';

const { palette } = createTheme();

const dialogBackground = '#F8F8F9';
const paper = '#fff';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      paper: paper,
      default: '#F8F8F9',
    },
    text: {
      primary: '#3d3d3d',
    },
    secondary: {
      main: red[300], // tab highlight text text color
    },
    ql1: palette.augmentColor({
      color: {
        main: '#449DD1',
      },
    }),
    ql2: palette.augmentColor({
      color: {
        main: '#192BC2',
      },
    }),
    ql3: palette.augmentColor({
      color: {
        main: '#150578',
      },
    }),
    ql4: palette.augmentColor({
      name: 'ql4',
      color: {
        main: '#0E0E52',
      },
    }),
    ok: palette.augmentColor({
      name: 'ok',
      color: {
        main: green[700],
        light: green[200],
      },
    }),
    caution: palette.augmentColor({
      name: 'caution',
      color: {
        main: yellow[700],
        light: yellow[200],
      },
    }),
    warn: palette.augmentColor({
      name: 'warn',
      color: {
        main: orange[700],
        light: orange[200],
      },
    }),
    critical: palette.augmentColor({
      name: 'critical',
      color: {
        main: red[700],
        light: red[200],
      },
    }),
    menuBackground: palette.augmentColor({
      name: 'menuBackground',
      color: {
        main: '#eeeeeeff',
      },
    }),
    chartGrid: palette.augmentColor({
      color: {
        main: 'rgb(0, 0, 0, 0.08)',
      },
    }),
    mtu: palette.augmentColor({
      color: {
        main: '#1812A6',
      },
    }),
  },
  components: {
    MuiCardMedia: {
      styleOverrides: {
        root: {
          paddingRight: '0px !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          overflow: 'hidden',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: grey[200],
            '&:hover': {
              backgroundColor: grey[200],
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: paper,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 7px 14px 0px rgba(36, 36, 36, 0.1)',
          backgroundImage: 'none',
          borderRadius: '0px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: dialogBackground,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: dialogBackground,
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: dialogBackground,
        },
      },
    },
    MuiClockPicker: {
      styleOverrides: {
        root: {
          backgroundColor: dialogBackground,
        },
      } as any,
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: dialogBackground,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '--DataGrid-containerBackground': 'rgba(0, 0, 0, 0)',
        },
        row: {
          '&.clickable-row': {
            cursor: 'pointer',
          },
        },
        columnHeaderRow: {
          backdropFilter: 'blur(10px)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 'medium',
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'inlineIcon' },
          style: {
            minHeight: '64px',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'menuBackground.main',
            gap: '8px',
            '& .MuiTab-iconWrapper': {
              marginBottom: 0,
            },
          },
        },
      ],
    } as any,
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 400;
          src: local('RRPioneer'), local('RRPioneer-Regular'), url("/fonts/RRPioneer-Regular-v1.006.woff2") format('woff2');
        }
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 500;
          src: local('RRPioneer'), local('RRPioneer-Medium'), url("/fonts/RRPioneer-Medium-v1.006.woff2") format('woff2');
        }
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 500;
          src: local('RRPioneer'), local('RRPioneer-MediumItalic'), url("/fonts/RRPioneer-MediumItalic-v1.006.woff2") format('woff2');
          font-style: italic;
        }
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 700;
          src: local('RRPioneer'), local('RRPioneer-Bold'), url("/fonts/RRPioneer-Bold-v1.006.woff2") format('woff2');
        }
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 700;
          src: local('RRPioneer'), local('RRPioneer-BoldItalic'), url("/fonts/RRPioneer-BoldItalic-v1.006.woff2") format('woff2');
          font-style: italic;
        }
      `,
    },
  } as any,
  typography: {
    fontFamily: ['RRPioneer', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

responsiveFontSizes(lightTheme);

declare module '@mui/material/styles' {
  interface Palette {
    ql1: PaletteColor;
    ql2: PaletteColor;
    ql3: PaletteColor;
    ql4: PaletteColor;
    ok: PaletteColor;
    caution: PaletteColor;
    warn: PaletteColor;
    critical: PaletteColor;
    menuBackground: PaletteColor;
    chartGrid: PaletteColor;
    mtu: PaletteColor;
  }
  interface PaletteOptions {
    ql1: PaletteColor;
    ql2: PaletteColor;
    ql3: PaletteColor;
    ql4: PaletteColor;
    ok: PaletteColor;
    caution: PaletteColor;
    warn: PaletteColor;
    critical: PaletteColor;
    menuBackground: PaletteColor;
    chartGrid: PaletteColor;
    mtu: PaletteColor;
  }
}
