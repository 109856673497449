import { ChargingStationOutlined } from '@mui/icons-material';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import { Box, Theme, Typography, useMediaQuery, Skeleton } from '@mui/material';
import TitledPaper from 'components/TitledPaper';
import { BatteryConfig } from 'hooks/plant/useBatteryConfig';
import { TotalHealthGauge } from './TotalHealthGauge';

type OverviewProps = {
  plantName?: string;
  batteries: BatteryConfig[];
  totalAvailablePower?: number;
};
export const Overview = (props: OverviewProps) => {
  const largeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  return (
    <TitledPaper title={props.plantName ? `Plant Overview: ${props.plantName}` : 'Plant Overview'} icon={<QueryStatsOutlinedIcon />}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <Box>
          <TotalHealthGauge
            batteries={props.batteries}
            cursorWidth={15}
            radius={largeScreen ? 45 : 36}
            icon={<HealthAndSafetyOutlinedIcon fontSize="small" />}
          />
          <Typography>Total System Health</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'end' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 'auto' }}>
            <ChargingStationOutlined
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
            <Typography variant="h5" fontWeight={'600'}>
              {props.totalAvailablePower ? props.totalAvailablePower?.toFixed(0) + ' kW' : <Skeleton sx={{ width: 80 }} />}
            </Typography>
          </Box>
          <Typography>Total Available Power</Typography>
        </Box>
      </Box>
    </TitledPaper>
  );
};
