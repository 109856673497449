import { createContext, useContext } from 'react';

interface ZoomTimeRangeContextType {
  zoomRange: { min: number; max: number } | null | undefined;
  setZoomRange?: any;
  resetZoom: () => void;
}

export const ZoomTimeRangeContext = createContext<Partial<ZoomTimeRangeContextType>>({});

export const useZoomTimeRangeContext = () => useContext(ZoomTimeRangeContext);
