import { Impressum } from '../../components/Legal/Impressum';
import { Box } from '@mui/material';

export default function ImpressumView() {
  return (
    <Box sx={{ position: 'relative', p: 4, pb: '96px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
      <Impressum />
    </Box>
  );
}
