import { Box, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Color from 'color';

interface QualificationLevelProps {
  level: number;
}

function resolveColor(level: number, theme: Theme): string {
  switch (level) {
    case 1:
      return theme.palette.ql1.main;
    case 2:
      return theme.palette.ql2.main;
    case 3:
      return theme.palette.ql3.main;
    case 4:
      return theme.palette.ql4.main;
    default:
      return 'grey';
  }
}

const QualificationLevel = styled(Box, {
  shouldForwardProp: prop => prop !== 'level',
})<QualificationLevelProps>(({ theme, level }: { theme: Theme; level: number }) => ({
  backgroundColor: Color(resolveColor(level, theme)).alpha(0.3).string(),
  color: resolveColor(level, theme),
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  borderRadius: theme.shape.borderRadius,
}));

export default QualificationLevel;
