import React from 'react';

import { Box, Grid } from '@mui/material';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import VibrationMaxRmsEngine from './Charts/VibrationMaxRmsEngine';
import VibrationMaxRmsGearbox from './Charts/VibrationMaxRmsGearbox';
import VibrationRmsWaterJet from './Charts/VibrationRmsWaterJet';

export default function Vibration() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();

  return (
    <Box sx={{ my: 2 }}>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={12}>
          <VibrationMaxRmsEngine clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <VibrationMaxRmsGearbox clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <VibrationRmsWaterJet clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
      </Grid>
    </Box>
  );
}
