import { Box } from '@mui/material';
import ChartJS from 'chart.js/auto';
import 'chartjs-adapter-moment';

import { Scatter } from 'react-chartjs-2';
import { BaselineAxes, Baseline, BaselineThreshold, BaselineThresholdType, BaselinePoint } from '../types';

ChartJS.register([]);

const chartOptions = {
  animations: false as any,
  maintainAspectRatio: false,
  responsive: process.env.NODE_ENV !== 'test',
  elements: {
    point: { radius: 5 },
  },
  interaction: {
    intersect: false,
  },
  scales: {
    x: {},
    y: {},
  },
  plugins: {
    legend: { display: true },
  },
};

type BaselineDisplayChartProps = {
  lines: Baseline[];
  axes: BaselineAxes;
  threshold: BaselineThreshold;
  activeId?: string;
};

function offsetId(value: number, point: BaselinePoint) {
  return { ...point };
}

function offsetValue(value: number, point: BaselinePoint) {
  return { ...point, y: point.y + value };
}

function offsetPercent(value: number, point: BaselinePoint) {
  return { ...point, y: point.y + (point.y * value) / 100 };
}

const offsetFunc = (threshold?: BaselineThresholdType) => {
  if (threshold === 'percent') {
    return offsetPercent;
  } else if (threshold === 'value') {
    return offsetValue;
  } else {
    return offsetId;
  }
};

export function BaselineDisplayChart({ activeId, lines, axes, threshold }: BaselineDisplayChartProps) {
  const offset = offsetFunc(threshold.type);
  const selectedLine = activeId ? lines.find(baseline => baseline._id === activeId) : undefined;
  const chartData = {
    datasets: lines.map(line => ({
      label: line.name,
      data: line.data,
      showLine: true,
      ...(line._id === activeId
        ? { backgroundColor: 'rgb(0, 255, 0)', borderColor: 'rgb(0, 255, 0)' }
        : { backgroundColor: '#0000', borderColor: '#000' }),
    })),
  };
  if (selectedLine) {
    if (threshold.alarmMin !== undefined) {
      chartData.datasets.push({
        label: 'Alarm Low',
        data: selectedLine.data.map(point => offset(+(threshold.warnMax ?? 0), point)),
        showLine: true,
        backgroundColor: 'rgb(255, 0, 0)',
        borderColor: 'rgb(255, 0, 0)',
      });
    }
    if (threshold.warnMin) {
      chartData.datasets.push({
        label: 'Warn Low',
        data: selectedLine.data.map(point => offset(+(threshold.warnMax ?? 0), point)),
        showLine: true,
        backgroundColor: 'rgb(240, 130, 0)',
        borderColor: 'rgb(240, 130, 0)',
      });
    }
    if (threshold.warnMax) {
      chartData.datasets.push({
        label: 'Warn High',
        data: selectedLine.data.map(point => offset(+(threshold.warnMax ?? 0), point)),
        showLine: true,
        backgroundColor: 'rgb(240, 130, 0)',
        borderColor: 'rgb(240, 130, 0)',
      });
    }
    if (threshold.alarmMax) {
      chartData.datasets.push({
        label: 'Alarm High',
        data: selectedLine.data.map(point => offset(+(threshold.alarmMax ?? 0), point)),
        showLine: true,
        backgroundColor: 'rgb(255, 0, 0)',
        borderColor: 'rgb(255, 0, 0)',
      });
    }
  }

  const xTitle = {
    display: true,
    align: 'end',
    text: `${axes.x.label} [${axes.x.unit}]`,
  } as const;
  const yTitle = {
    display: true,
    align: 'end',
    text: `${axes.y.label} [${axes.y.unit}]`,
  } as const;
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Scatter data={chartData} options={{ ...chartOptions, scales: { x: { title: xTitle }, y: { title: yTitle } } }} />
    </Box>
  );
}
