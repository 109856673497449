import { Box } from '@mui/material';
import { OverallFleetStatistics } from './OverallFleetStatistics';
import { Watchlist } from './Watchlist';
import { NotificationsAndAlerts } from './NotificationsAndAlerts';
import { FleetMap } from './FleetMap';
import { useDemoAccessStatus } from '../../helpers/demoAccess';
import { useMyCompanies } from 'hooks/companies/useMyCompanies';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { VesselBatteryTable } from './VesselBatteryTable';

export default function FleetView() {
  useDemoAccessStatus();
  const { data: myCompanies, isLoading: companiesLoading } = useMyCompanies();
  const { user, isLoading: userLoading } = useAuth();

  const loadedNoCompanies = !companiesLoading && (!myCompanies || myCompanies.length === 0);
  const loadedNoSuperUser = !userLoading && user && user.isSuperUser !== true;

  if (loadedNoCompanies && loadedNoSuperUser) {
    return <Navigate to="/contact-admin" />;
  }

  return (
    <Box sx={{ position: 'relative', p: 4, pb: '96px', display: 'flex', flexDirection: 'column', gap: 4 }}>
      <FleetMap />
      <OverallFleetStatistics />
      <Watchlist />
      <VesselBatteryTable />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
        <NotificationsAndAlerts />
      </Box>
    </Box>
  );
}
