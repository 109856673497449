import { useState } from 'react';

import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import type { Baseline, BaselineGroup } from '../types';
import { BaselineEditor } from './BaselineEditor';
import { useBaselineEditor } from 'hooks/useBaselineEditor';

type BaselineAccordionProps = {
  clientId: string;
  baselineGroupId: string;
  baselineId: string;
  expandedId: string | undefined;
  setExpandedId: (val: string | undefined) => void;
};

function BaselineAccordion({ clientId, baselineGroupId, baselineId, expandedId, setExpandedId }: BaselineAccordionProps) {
  const { baseline: baselineQuery, removeBaseline } = useBaselineEditor({ clientId, baselineGroupId, baselineId });
  const baseline: Baseline | undefined = baselineQuery.data;

  return (
    <Accordion
      sx={{ marginBottom: 1 }}
      expanded={expandedId === baselineId}
      onChange={(event, isExpanded) => setExpandedId(isExpanded ? baselineId : undefined)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${baselineId}-content`} id={`${baselineId}-header`}>
        <Typography sx={{ width: '50%', flexShrink: 0 }}>{baseline?.name ?? ''}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>{baseline ? `${baseline.data?.length} Points` : 'Baseline not loaded yet'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <BaselineEditor clientId={clientId} baselineGroupId={baselineGroupId} baselineId={baselineId} />
      </AccordionDetails>
      <AccordionActions>
        <Button variant="outlined" aria-label="delete" color="error" onClick={() => removeBaseline()} endIcon={<DeleteForeverIcon />}>
          Delete Baseline
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

export function BaselineGroupEditor({ clientId, group }: { clientId: string; group: BaselineGroup }) {
  const [expanded, setExpanded] = useState<string | undefined>(undefined);

  return (
    <Box sx={{ height: '100%', maxHeight: '100%', padding: 1, overflow: 'visible', overflowY: 'scroll' }}>
      {group.baselines.map(baseline => (
        <BaselineAccordion
          key={baseline._id}
          clientId={clientId}
          baselineGroupId={group._id}
          baselineId={baseline._id}
          expandedId={expanded}
          setExpandedId={setExpanded}
        />
      ))}
    </Box>
  );
}
