import { QueryStatsOutlined } from '@mui/icons-material';
import { Box, Paper, Stack, Typography } from '@mui/material';
import TitledPaper from 'components/TitledPaper';
import GensetRun from 'assets/genset_run.svg';
import LinearGauge from 'components/Gauges/LinearGauge';
import ValueBox from 'components/ValueBox';
import SingleLineCircuitLine, { LineHorizontalGrey } from 'app/Plant/SingleLine/SingleLineCircuitLine';
import CircuitBreaker from 'app/Plant/SingleLine/CircuitBreaker';
import { ConsumerLabel, TransformerLabel } from './Labels';
import TransformerGrey from 'assets/singleline/transformer.svg';

type GeneratorProps = {
  id: string;
  power: string;
  voltage: string;
  current: string;
};

type RoomProps = {
  title: string;
  subtitle?: string;
};

function GeneratorPaper({ id, power, voltage, current }: GeneratorProps) {
  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pb: 3,
        pl: 0.25,
        gap: 2,
      }}>
      <Box sx={{ maxWidth: '100%', height: 'auto', flexShrink: 1, margin: '0 auto' }}>
        <img src={GensetRun} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>
      <Box sx={{ color: 'text.secondary', px: 0.5 }}>
        <Typography variant="h5">{id}</Typography>
        <Typography variant="h3" sx={{ whiteSpace: 'nowrap' }}>
          {power}
        </Typography>
        <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
          {voltage} {current}
        </Typography>
      </Box>
    </Paper>
  );
}

function RoomPaper({ title, subtitle }: RoomProps) {
  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text.secondary',
        minHeight: 144,
      }}>
      <Typography variant="h4" align="center">
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h5" align="center">
          {subtitle}
        </Typography>
      )}
    </Paper>
  );
}

export default function EPowerPlantOverview() {
  return (
    <TitledPaper title="Overview" icon={<QueryStatsOutlined />}>
      <Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '2.25fr 0.75fr 2.25fr',
            },
            gap: 4,
          }}>
          <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'end' }}>
            <RoomPaper title="DE 1" subtitle="Engine Room" />
            <SingleLineCircuitLine height="4px" width="32px" />
            <GeneratorPaper id="G1" power="1505 kW" voltage="690 V" current="126 A" />
          </Stack>

          <RoomPaper title="Shore" />

          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <GeneratorPaper id="G2" power="1505 kW" voltage="690 V" current="126 A" />
            <SingleLineCircuitLine height="4px" width="32px" />
            <RoomPaper title="DE 2" subtitle="Steering Room" />
          </Stack>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1.5fr 0.75fr 0.75fr 0.75fr 1.5fr',
            },
            gap: 4,
            alignItems: 'start',
          }}>
          {/* G1 */}
          <Stack sx={{ py: 8 }}>
            <Stack spacing={2} sx={{ width: '66%', justifyContent: 'end', alignSelf: 'end' }}>
              <ValueBox
                values={[
                  { label: 'Voltage V1-V2', value: 0, unit: 'V' },
                  { label: 'Voltage V2-V3', value: 0, unit: 'V' },
                  { label: 'Voltage V3-V1', value: 0, unit: 'V' },
                ]}
              />

              <Paper
                elevation={3}
                sx={{
                  p: 1,
                }}>
                <LinearGauge value={0} label="Power Factor" unit="cap" />
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                }}>
                <LinearGauge value={0} label="Total Power" unit="kW" />
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                }}>
                <LinearGauge value={0} label="Power Selection" unit="%" />
              </Paper>
            </Stack>
          </Stack>

          {/* DE 1 Engine Room */}
          <Stack sx={{ height: '100%' }}>
            <Stack sx={{ justifyContent: 'start', alignItems: 'center', mt: '62px', flexGrow: 1 }}>
              <Box sx={{ position: 'relative' }}>
                <CircuitBreaker label="GCB1" closed={false} direction="column" />
                <Box sx={{ position: 'absolute', height: '152px', width: '40px', bottom: '26px', right: '100%' }}>
                  <SingleLineCircuitLine height="100%" />
                  <SingleLineCircuitLine height="4px" width="100%" />
                </Box>
              </Box>
              <SingleLineCircuitLine height="100%" marginLeft="4px" />
            </Stack>
          </Stack>

          {/* Shore  */}

          <Stack sx={{ height: '100%' }}>
            <Stack sx={{ justifyContent: 'start', alignItems: 'center', mt: '62px', flexGrow: 1 }}>
              <Box sx={{ position: 'relative' }}>
                <CircuitBreaker label="SCB1" closed={false} direction="column" />

                <Box sx={{ position: 'absolute', height: '152px', width: '16px', bottom: '26px', right: '100%' }}>
                  <SingleLineCircuitLine height="100%" />
                  <SingleLineCircuitLine height="4px" width="100%" />
                </Box>
              </Box>
              <SingleLineCircuitLine height="100%" marginLeft="4px" />
            </Stack>
          </Stack>

          {/* DE 2 Steering Room */}

          <Stack sx={{ height: '100%' }}>
            <Stack sx={{ justifyContent: 'start', alignItems: 'center', mt: '62px', flexGrow: 1 }}>
              <Box sx={{ position: 'relative' }}>
                <CircuitBreaker label="GCB2" closed={false} direction="column" align="end" />
                <Box sx={{ position: 'absolute', height: '152px', width: '40px', bottom: '26px', left: '100%' }}>
                  <SingleLineCircuitLine height="100%" sx={{ margin: '0 0 0 auto' }} />
                  <SingleLineCircuitLine height="4px" width="100%" />
                </Box>
              </Box>
              <SingleLineCircuitLine height="100%" marginLeft="-4px" />
            </Stack>
          </Stack>

          {/* G2  */}
          <Stack sx={{ py: 8 }}>
            <Stack spacing={2} sx={{ width: '66%', alignSelf: 'start' }}>
              <ValueBox
                values={[
                  { label: 'Current L1', value: 0, unit: 'A' },
                  { label: 'Current L2', value: 0, unit: 'A' },
                  { label: 'Current L3', value: 0, unit: 'A' },
                ]}
              />

              <Paper
                elevation={3}
                sx={{
                  p: 1,
                  textAlign: 'center',
                  minHeight: 138,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Typography variant="h4" color="textSecondary">
                  Off
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Loads Drop
                </Typography>
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                }}>
                <LinearGauge value={0} label="Total Power" unit="kW" />
              </Paper>

              <ValueBox
                values={[
                  { label: 'Gen Insulation', value: 'Normal' },
                  { label: 'Insulation Level', value: '0', unit: ' kΩ' },
                ]}
                direction="column"
              />
            </Stack>
          </Stack>
        </Box>

        <LineHorizontalGrey />

        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 14 }}>
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
              <ConsumerLabel voltageField={440} />

              <SingleLineCircuitLine height="240px" />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'end', position: 'relative' }}>
              <img src={TransformerGrey} alt="" style={{ transform: 'translateX(50%)', marginRight: '2px' }} />
              <Box sx={{ position: 'absolute', right: '36px', top: '50%', transform: 'translateY(-50%)' }}>
                <TransformerLabel frequency={60} primaryVoltage={440} secondaryVoltage={115} />
              </Box>
            </Box>

            <SingleLineCircuitLine height="90px" alignSelf="end" />

            <LineHorizontalGrey />
            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
              <ConsumerLabel voltageField={115} />
            </Box>
          </Stack>
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
              <SingleLineCircuitLine height="240px" />
              <ConsumerLabel voltageField={440} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'start', position: 'relative' }}>
              <img src={TransformerGrey} alt="" style={{ transform: 'translateX(-50%)', marginLeft: '2px' }} />
              <Box sx={{ position: 'absolute', left: '36px', top: '50%', transform: 'translateY(-50%)' }}>
                <TransformerLabel frequency={60} primaryVoltage={440} secondaryVoltage={115} />
              </Box>
            </Box>

            <SingleLineCircuitLine height="90px" />

            <LineHorizontalGrey />
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <ConsumerLabel voltageField={220} />
            </Box>
          </Stack>
        </Box>

        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1, py: 8 }}>
          <Stack
            direction="row"
            useFlexGap
            sx={{
              flexWrap: 'wrap',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ValueBox
              values={[
                { label: 'Voltage V1-V2', value: 0, unit: 'V' },
                { label: 'Voltage V2-V3', value: 0, unit: 'V' },
                { label: 'Voltage V3-V1', value: 0, unit: 'V' },
              ]}
            />
            <ValueBox
              values={[
                { label: 'Current L1', value: 0, unit: 'A' },
                { label: 'Current L2', value: 0, unit: 'A' },
                { label: 'Current L3', value: 0, unit: 'A' },
              ]}
            />

            <Box sx={{ flexBasis: '100%', height: 0 }} />

            <ValueBox values={[{ label: 'Insulation Level', value: '0', unit: ' kΩ' }]} direction="column" />
          </Stack>
          <Stack
            direction="row"
            useFlexGap
            sx={{
              flexWrap: 'wrap',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ValueBox
              values={[
                { label: 'Voltage V1-V2', value: 0, unit: 'V' },
                { label: 'Voltage V2-V3', value: 0, unit: 'V' },
                { label: 'Voltage V3-V1', value: 0, unit: 'V' },
              ]}
            />

            <ValueBox
              values={[
                { label: 'Current L1', value: 0, unit: 'A' },
                { label: 'Current L2', value: 0, unit: 'A' },
                { label: 'Current L3', value: 0, unit: 'A' },
              ]}
            />

            <Box sx={{ flexBasis: '100%', height: 0 }} />

            <ValueBox values={[{ label: 'Insulation Level', value: '0', unit: ' kΩ' }]} direction="column" />
          </Stack>
        </Box>
      </Box>
    </TitledPaper>
  );
}
