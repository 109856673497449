import { Box, Paper, Typography } from '@mui/material';
import SwitchOpen from 'assets/singleline/switch_open.svg';
import SwitchClosed from 'assets/singleline/switch_closed.svg';
import SwitchVerticalOpen from 'assets/singleline/switch_vertical_open.svg';
import { grey } from '@mui/material/colors';

type CircuitBreakerProps = {
  label: string;
  closed: boolean;
  direction?: 'row' | 'column';
  align?: 'start' | 'end';
};

const CircuitBreaker = ({ label, closed, direction = 'row', align = 'start' }: CircuitBreakerProps) => {
  const switchImage = closed ? SwitchClosed : direction === 'column' ? SwitchVerticalOpen : SwitchOpen;

  return (
    <Paper
      elevation={3}
      sx={{
        textAlign: 'center',
        transform: direction === 'row' ? 'translateY(26px)' : 'none',
        display: 'flex',
        flexDirection: 'column',
        width: 80,
        flexShrink: 0,
      }}>
      <Typography variant="h5" sx={{ mb: 3, py: 2 }}>
        <strong>{label}</strong>
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: align, pb: direction === 'row' ? 2 : 0 }}>
        <img src={switchImage} alt={closed ? 'Closed Switch' : 'Open Switch'} style={align === 'end' ? { transform: 'scaleX(-1)' } : {}} />

        {direction === 'row' && (
          <Box
            sx={{
              width: '100%',
              height: '4px',
              backgroundColor: closed ? grey[600] : '#D32F2F',
              mt: '2px',
            }}
          />
        )}
      </Box>
    </Paper>
  );
};

export default CircuitBreaker;
