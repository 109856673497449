import { Box, Typography } from '@mui/material';

type Threshold = {
  // name of the TBO, not displayed if undefined
  name?: string;
  LI: number;
  LF: number;
};

type WearParameterProps = {
  currentLI: number;
  currentLF: number;
  thresholds: Threshold[];
  // If set it's used as the upper bound for the top TBO. Default is 100.
  customUpperBound?: number;
};

// Iitially copied from https://github.com/MAC-SS/core-ui-v1/pull/176/commits/4b5876343893f984ae107d9310caadec479fb9c5, not changed yet
export default function WearParameter({ currentLF, currentLI, thresholds, customUpperBound }: WearParameterProps) {
  currentLF = Math.round(currentLF);
  currentLI = Math.round(currentLI);
  return (
    /* the mt is required since the labels are absolute positioned and can max be 9 px above boxed */
    <Box display="flex" gap={3} mt="9px">
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        {/* this height is set to align the threshold at the exact center of the gap */}
        <Box height={14} />
        {thresholds.map(t => (
          <Typography key={t.LI} color="text.disabled" lineHeight={1} textAlign="center">
            {t.LI}
          </Typography>
        ))}
      </Box>
      <Box gap="2px" display="flex" flexDirection="column">
        {thresholds.map((threshold, i) => {
          let bg = '#D9D9D9';
          let color: string | undefined = undefined;
          let isCurrentHighlighted = false;

          const unit = currentLF > currentLI ? 'LF' : 'LI';
          const value = Math.max(currentLF, currentLI);

          if (value >= threshold[unit] && (i !== 0 ? value < thresholds[i - 1][unit] : true)) {
            color = 'white';
            bg = i === 0 ? 'critical.main' : 'primary.main';
            isCurrentHighlighted = true;
          }

          const Label = () => {
            if (!isCurrentHighlighted) {
              return null;
            }

            const upperBound = i !== 0 ? thresholds[i - 1][unit] : customUpperBound ?? 100;
            const lowerBound = threshold[unit];
            const currentLabelPerc = percentageBetweenTwoNum(value, lowerBound, upperBound);
            return (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'end',
                    bottom: currentLabelPerc + '%',
                    left: -57,
                  }}>
                  <Typography
                    sx={{
                      width: 47,
                      lineHeight: 1,
                      bgcolor: bg,
                      color: 'white',
                      textAlign: 'center',
                    }}>
                    {currentLI} %
                  </Typography>
                  <Box
                    sx={{
                      height: 2,
                      width: 10,
                      bgcolor: bg,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'end',
                    bottom: currentLabelPerc + '%',
                    right: -57,
                  }}>
                  <Box
                    sx={{
                      height: 2,
                      width: 10,
                      bgcolor: bg,
                    }}
                  />
                  <Typography
                    sx={{
                      width: 47,
                      lineHeight: 1,
                      bgcolor: bg,
                      color: 'white',
                      textAlign: 'center',
                    }}>
                    {currentLF} %
                  </Typography>
                </Box>
              </>
            );
          };

          return (
            <div key={i}>
              {i === 0 && (
                <Box
                  sx={{
                    width: 0,
                    height: 0,
                    border: 14,
                    borderTop: 0,
                    borderBottom: 7,
                    borderColor: 'transparent',
                    borderBottomColor: bg,
                  }}
                />
              )}
              <Box
                sx={{
                  position: 'relative',
                  bgcolor: bg,
                  width: 28,
                  height: 80,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  ...(color && { color }),
                }}>
                {threshold.name && <Typography variant="body2">{threshold.name}</Typography>}
                {isCurrentHighlighted && <Label />}
              </Box>
            </div>
          );
        })}
        <Box height={7} />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        {/* this height is set to align the threshold at the exact center of the gap */}
        <Box height={14} />
        {thresholds.map(t => (
          <Typography key={t.LF} color="text.disabled" lineHeight={1} textAlign="center">
            {t.LF}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

/**
 * Calculates the position in percentage of `x` between `a` and `b`.
 */
export function percentageBetweenTwoNum(x: number, a: number, b: number): number {
  const min = Math.min(a, b);
  const max = Math.max(a, b);

  return ((x - min) * 100) / (max - min);
}
