import { useTotalBatteryHealthLiveData } from 'hooks/plant/useTotalBatteryHealthLiveData';
import { BatteryConfig } from 'hooks/plant/useBatteryConfig';
import { PerformanceGauge, PerformanceGaugeProps } from 'components/Gauges/PerformanceGauge';

type PropsHealthGaugePlant = Omit<
  PerformanceGaugeProps & {
    batteries: BatteryConfig[];
  },
  'indicatorValue'
>;

export const TotalHealthGauge = ({ batteries, radius, strokeWidth, cursorWidth, icon, caption }: PropsHealthGaugePlant) => {
  const indicatorValue = useTotalBatteryHealthLiveData(batteries);
  return PerformanceGauge({ indicatorValue, radius, strokeWidth, cursorWidth, icon, caption });
};

TotalHealthGauge.defaultProps = {
  strokeWidth: 8,
  cursorWidth: 18,
  radius: 30,
};
