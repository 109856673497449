import { green, orange, red, yellow } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const { palette } = createTheme();

const dialogBackground = '#1d1d1f';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#27272B',
    },
    secondary: {
      main: red[300], // tab highlight text text color
    },
    ql1: palette.augmentColor({
      color: {
        main: '#449DD1',
      },
    }),
    ql2: palette.augmentColor({
      color: {
        main: '#192BC2',
      },
    }),
    ql3: palette.augmentColor({
      color: {
        main: '#150578',
      },
    }),
    ql4: palette.augmentColor({
      name: 'ql4',
      color: {
        main: '#0E0E52',
      },
    }),
    ok: palette.augmentColor({
      name: 'ok',
      color: {
        main: green[700],
        light: green[200],
      },
    }),
    caution: palette.augmentColor({
      name: 'caution',
      color: {
        main: yellow[700],
        light: yellow[200],
      },
    }),
    warn: palette.augmentColor({
      name: 'warn',
      color: {
        main: orange[700],
        light: orange[200],
      },
    }),
    critical: palette.augmentColor({
      name: 'critical',
      color: {
        main: red[700],
        light: red[200],
      },
    }),
    menuBackground: palette.augmentColor({
      name: 'menuBackground',
      color: {
        main: '#161618',
        dark: '#161618',
      },
    }),
    chartGrid: palette.augmentColor({
      color: {
        main: 'rgb(255, 255, 255, 0.08)',
      },
    }),
    mtu: palette.augmentColor({
      color: {
        main: '#1812A6',
      },
    }),
  },
  components: {
    MuiCardMedia: {
      styleOverrides: {
        root: {
          paddingRight: '0px !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          overflow: 'hidden',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0)',
          backgroundImage: 'none',
          boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.25)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: dialogBackground,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: dialogBackground,
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: dialogBackground,
        },
      },
    },
    MuiClockPicker: {
      styleOverrides: {
        root: {
          backgroundColor: dialogBackground,
        },
      } as any,
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: dialogBackground,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '--DataGrid-containerBackground': 'rgba(0, 0, 0, 0)',
        },
        row: {
          '&.clickable-row': {
            cursor: 'pointer',
          },
        },
        menu: {
          backgroundColor: dialogBackground,
        },
        columnHeaderRow: {
          backdropFilter: 'blur(10px)',
        },
      },
    },
    MuiTab: {
      variants: [
        {
          props: { variant: 'inlineIcon' },
          style: {
            minHeight: '64px',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'menuBackground.main',
            gap: '8px',
            '& .MuiTab-iconWrapper': {
              marginBottom: 0,
            },
          },
        },
      ],
    } as any,
    /*MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: grey[200],
            '&:hover': {
              backgroundColor: grey[200],
            },
          },
        },
      },
    },*/
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0)',
          backdropFilter: 'blur(12px)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 400;
          src: local('RRPioneer'), local('RRPioneer-Regular'), url("/fonts/RRPioneer-Regular-v1.006.woff2") format('woff2');
        }
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 500;
          src: local('RRPioneer'), local('RRPioneer-Medium'), url("/fonts/RRPioneer-Medium-v1.006.woff2") format('woff2');
        }
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 500;
          src: local('RRPioneer'), local('RRPioneer-MediumItalic'), url("/fonts/RRPioneer-MediumItalic-v1.006.woff2") format('woff2');
          font-style: italic;
        }
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 700;
          src: local('RRPioneer'), local('RRPioneer-Bold'), url("/fonts/RRPioneer-Bold-v1.006.woff2") format('woff2');
        }
        @font-face {
          font-family: 'RRPioneer';
          font-display: swap;
          font-weight: 700;
          src: local('RRPioneer'), local('RRPioneer-BoldItalic'), url("/fonts/RRPioneer-BoldItalic-v1.006.woff2") format('woff2');
          font-style: italic;
        }
      `,
    },
  } as any,
  typography: {
    fontFamily: ['RRPioneer', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

responsiveFontSizes(darkTheme);
