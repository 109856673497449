import type { ClientId } from 'context/clientId';
import { chartColors } from 'theme/chartColors';

export type ChartProps = {
  from: Date;
  to: Date;
  clientId: ClientId;
  resolution: number;
  aspectRatio?: number;
  renderIndex?: number;
};

//todo rename to e.g. chartColorsMain
const color = chartColors.main;

export type Tsp = number;
export type ScatterMap = Map<Tsp, { [label: string]: number }>;
export type Datapoint = { x: Tsp; y: number };

function addDataToScatterMap(map: ScatterMap, label: string, data: Datapoint[]) {
  // Group values (y) by timestamp (x),
  data.forEach(elem => {
    map.set(elem.x, { ...map.get(elem.x), [label]: elem.y });
  });
}

function scatterMapFromData(labels: string[], datas: (undefined | Datapoint[])[]): ScatterMap {
  const map = new Map();
  datas.forEach((data, i) => addDataToScatterMap(map, labels[i], data ?? []));
  return map;
}

function dataSeriesFromScatterMap(map: ScatterMap, xLabel: string, yLabel: string) {
  return Array.from(map, ([key, value]) => ({ tsp: key, x: value[xLabel], y: value[yLabel] }));
}

export { scatterMapFromData, dataSeriesFromScatterMap, addDataToScatterMap, color };
