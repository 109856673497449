import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard, { TimeLineCardOptions } from 'components/TimeLineCard';
import { useTargetUnits } from 'context/settings';

function useCurrentLogFactorOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const queries: TimeseriesSequenceQuery[] = [
    {
      clientId,
      module: 'calculated',
      objectId: 'environment',
      objectIdNo: 0,
      pvId: 'current',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'kn',
      targetUnit: units.vesselSpeed,
    },
    {
      clientId,
      module: 'calculated',
      objectId: 'environment',
      objectIdNo: 0,
      pvId: 'logFactor',
      pvIdNo: 0,
      resolution,
      from,
      to,
    },
  ];

  const [timeseriesData, loading, err] = useSequencesData(queries);

  const data = {
    datasets: [
      {
        label: `Current [${units.vesselSpeed}]`,
        data: timeseriesData[0] ?? [],
        borderColor: color[4],
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'LogFactor [%]',
        data: timeseriesData[1] ?? [],
        borderColor: color[5],
        borderWidth: 1,
        yAxisID: 'y1',
      },
    ],
  };

  const options: TimeLineCardOptions = {
    yAxisLabel: `Current [${units.vesselSpeed}]`,
    minYAxisValue: -10,
    maxYAxisValue: 10,
    legendPosition: 'top',
    title: 'Current and Log',
    xAxisLabel: 'Time',
    additionalYAxis: [
      {
        id: 'y1',
        type: 'linear',
        display: true,
        position: 'right',
        label: 'Log Factor [%]',
        minYAxisValue: -100,
        maxYAxisValue: 100,
      },
    ],
  };
  return { data, loading, err, options };
}

function CurrentLogFactorOverTime({ clientId, from, to, resolution, aspectRatio }: ChartProps) {
  const { data, loading, err, options } = useCurrentLogFactorOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={options} data={data} loading={loading} err={err} aspectRatio={aspectRatio} />;
}

export default CurrentLogFactorOverTime;
