import { Skeleton, Alert } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import type { ClientId } from 'context/clientId';
import AspectRatioBox from 'components/AspectRatioBox';
import { useLiveData, DatumString } from 'hooks/useLiveData';
import { chartColors } from 'theme/chartColors';
import { useTargetUnits } from 'context/settings';
import valueConversion from 'helpers/valueConversion';
import type { EngineConfig } from 'hooks/useEngineConfig';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const color = chartColors.main;

function getBarChartOptions(engine: EngineConfig, withLabels: boolean, temperatureUnit: string) {
  let title = {};
  let xTitle = { display: true, text: `${engine.name} [Injector No.]` };
  let yTitle = { display: true, text: `Temperature [${temperatureUnit}]` };
  if (!withLabels) {
    title = {
      display: true,
      text: `Temperature [${temperatureUnit}] / [Injector No.]`,
    };
    xTitle = { display: false, text: '' };
    yTitle = { display: false, text: '' };
  }

  return {
    responsive: process.env.NODE_ENV !== 'test',
    animations: false as any,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: xTitle,
        ticks: { display: true, maxRotation: 0 },
        grid: { display: false, drawBorder: false },
      },
      y: {
        title: yTitle,
        beginAtZero: false,
        suggestedMin: 400,
        suggestedMax: 600,
        ticks: { display: true, beginAtZero: false },
      },
    },
    plugins: {
      legend: { display: false },
      title: title,
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }

            const precision = 2;
            if (context.parsed.y) {
              label += Math.round(context.parsed.y * 10 ** precision) / 10 ** precision;
            }
            return label;
          },
        },
      },
    },
    borderWidth: 2,
    borderColor: color[0],
    minBarLength: 8,
  };
}

const InjectorBarCard = ({
  loading,
  data,
  engine,
  err,
  withLabels,
  temperatureUnit,
}: {
  err?: string;
  loading?: boolean;
  data: ChartData<'bar', number[], unknown>;
  engine: EngineConfig;
  withLabels: boolean;
  temperatureUnit: string;
}) => {
  const barChartOptions = getBarChartOptions(engine, withLabels, temperatureUnit);
  return (
    <Card>
      <CardMedia component="div" sx={{ pr: '8px' }}>
        <AspectRatioBox aspectRatio={12 / 8}>
          {loading || err ? <Skeleton variant="rectangular" width="100%" height="100%" /> : <Bar data={data} options={barChartOptions} />}
        </AspectRatioBox>
      </CardMedia>
      {err ? <Alert severity="error">{err}</Alert> : null}
    </Card>
  );
};

function InjectorLiveCard({
  clientId,
  engineNo,
  engine,
  withLabels,
}: {
  clientId: ClientId;
  engineNo: number;
  engine: EngineConfig;
  withLabels: boolean;
}) {
  const units = useTargetUnits();
  const numberOfInjectors = 16;
  const buildGasTopic = (num: number) => `ioconnect/measurement/${clientId}/combustion/${engineNo}/tempExhaustgas/${num}` as DatumString;
  const gasTopics = Array.from({ length: numberOfInjectors }, (_, i) => buildGasTopic(i));
  const meanATopic: DatumString = `ioconnect/calculated/${clientId}/combustion/${engineNo}/tempExhaustgasMeanBankA/0`;
  const meanBTopic: DatumString = `ioconnect/calculated/${clientId}/combustion/${engineNo}/tempExhaustgasMeanBankB/0`;
  const [tdata, terr] = useLiveData([...gasTopics, meanATopic, meanBTopic]);

  const meanA = tdata?.[meanATopic];
  const meanB = tdata?.[meanBTopic];
  const topicData = gasTopics.map(topic => tdata?.[topic]);

  const datasetA: { x: string; y?: number }[] = topicData
    .filter((topic, i) => {
      return i < 8;
    })
    .map((topic, i) => {
      return { x: `A${i + 1}`, y: topic?.value };
    });

  const datasetB: { x: string; y?: number }[] = topicData
    .filter((topic, i) => {
      return i >= 8;
    })
    .map((topic, i) => {
      return { x: `B${i + 1}`, y: topic?.value };
    });

  const data = {
    datasets: [
      {
        label: 'Exhaustgas Temparature A',
        data: datasetA.map(data => ({ ...data, y: valueConversion(data.y, 'degC', units.temperature) })) as any,
        base: valueConversion(meanA?.value, 'degC', units.temperature),
        backgroundColor: [color[0]],
      },
      {
        label: 'Exhaustgas Temparature B',
        data: datasetB.map(data => ({ ...data, y: valueConversion(data.y, 'degC', units.temperature) })) as any,
        base: valueConversion(meanB?.value, 'degC', units.temperature),
        backgroundColor: [color[2]],
      },
    ],
  };

  return (
    <InjectorBarCard
      loading={tdata === null}
      data={data}
      engine={engine}
      err={terr}
      withLabels={withLabels}
      temperatureUnit={units.temperature}
    />
  );
}

export default InjectorLiveCard;
