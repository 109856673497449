import { useEffect, useRef, useState } from 'react';

import ChartJS, { ChartOptions } from 'chart.js/auto';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';

import { Chart } from 'react-chartjs-2';

import { Alert, Card, CardMedia, Skeleton, Theme } from '@mui/material';
import AspectRatioBox from 'components/AspectRatioBox';
import { useTheme } from '@mui/system';
import { calcBins } from 'helpers/bins';

import { useZoomTimeRangeContext } from 'context/zoom/ZoomTimeRangeContext';

ChartJS.register(annotationPlugin);

export type options = {
  title?: string;
  yAxisLabel?: string;
  xAxisLabel?: string;
  maxYAxisValue?: number;
  minYAxisValue?: number;
  warningLevel?: number;
  criticalLevel?: number;
  tooltipPrecision?: number;
  legendPosition?: 'center' | 'left' | 'top' | 'right' | 'bottom' | 'chartArea';
};

type Properties = {
  data: { datasets: { label: string; data: { x: number; y: number }[] }[] };
  loading: boolean;
  err?: string;
  aspectRatio?: string | number;
  options?: options;
  binWidth?: number;
  binCount?: number;
  distributionType?: 'count' | 'value';
  minValue?: number;
};

const HistogramCard = ({
  data,
  loading,
  err,
  options,
  binWidth = 1,
  binCount = 1,
  aspectRatio = 7,
  distributionType = 'count',
  minValue,
}: Properties) => {
  const chartRef = useRef<ChartJS>(null);
  const { zoomRange } = useZoomTimeRangeContext();
  const [filteredData, setFilteredData] = useState<any>(data);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const { current: chart } = chartRef;
    const tmp: Properties['data'] & { labels?: any[] } = JSON.parse(JSON.stringify(data));
    let maxScale: number | null = null;

    if (zoomRange) {
      for (const item of tmp.datasets) {
        const clipToZoomRange = (point: { x: number; y: number }) => point.x >= zoomRange.min && point.x <= zoomRange.max;
        item.data = item.data.filter(clipToZoomRange);
      }
    }

    const dataArr: number[] = [];
    for (const set of tmp.datasets) {
      for (const item of set.data) {
        if (minValue ? item.y >= minValue : true) dataArr.push(item.y);
      }

      const bins = calcBins(dataArr, binWidth, binCount);
      if (bins !== null) {
        const distributions = distributionType === 'value' ? bins?.valueDistribution : bins?.counterDistribution;
        for (const bin of distributions) {
          if (maxScale == null) maxScale = Math.max(bin);
          if (maxScale !== null && Math.max(bin) > maxScale) maxScale = Math.max(bin);
        }

        //this is not my change, I just added the type error
        set.data = distributions as any;
      }

      //this is not my change, i just added the type error
      tmp.labels = bins?.labels as any;
    }
    if (maxScale !== null) setScale(maxScale);
    setFilteredData(tmp);

    chart?.render();
  }, [zoomRange, data, binCount, binWidth, distributionType, minValue]);

  function getChartOptions(theme: Theme, options?: options): ChartOptions<any> {
    const chartOptions: ChartOptions = {
      responsive: true,
      animations: false as any,
      maintainAspectRatio: false,

      scales: {
        x: {
          min: minValue,
          title: {
            display: true,
            text: options?.xAxisLabel,
          },
        },
        y: {
          grid: {
            color: theme.palette.chartGrid.main,
            borderColor: theme.palette.chartGrid.main,
          },
          beginAtZero: true,
          suggestedMax: scale * 1.25,
          ticks: {
            display: true,
          },
        },
      },
      plugins: {
        legend: { display: false, align: 'center', position: options?.legendPosition ?? 'right' },
        title: {
          display: true,
          text: options?.title,
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }

              const precision = options?.tooltipPrecision || 2;
              if (context.parsed.y) {
                label += Math.round(context.parsed.y * 10 ** precision) / 10 ** precision;
              }
              return label;
            },
          },
        },
      },
    };
    chartOptions.plugins ??= {};

    return chartOptions;
  }

  const theme: Theme = useTheme();

  const chartOptions = getChartOptions(theme, options);

  return (
    <Card sx={{ px: 1, height: '100%' }}>
      <CardMedia component="div">
        <AspectRatioBox aspectRatio={aspectRatio} minHeight="200px">
          {loading || err ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <Chart ref={chartRef} type="bar" data={filteredData} options={chartOptions} style={{ height: '100%' }} />
          )}
        </AspectRatioBox>
      </CardMedia>
      {err ? <Alert severity="error">{err}</Alert> : null}
    </Card>
  );
};

export default HistogramCard;
