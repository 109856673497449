import { Box, Paper, Tab, Tabs } from '@mui/material';
import usePathChild from 'hooks/usePathChild';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { FEEDBACK_HEIGHT } from 'helpers/constants';

function a11yProps(index: number | string) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export function CompaniesPage() {
  const navigate = useNavigate();
  const tab = usePathChild(false);
  const { isAuthenticated, user, isLoading } = useAuth();

  if (isLoading) return <></>;
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Box>
      <Box sx={{ position: 'relative', p: 4, pb: `${FEEDBACK_HEIGHT}px`, display: 'flex', flexDirection: 'column', gap: 4 }}>
        {user?.isSuperUser ? (
          <>
            <Paper sx={{ backgroundColor: 'background.default' }}>
              <Tabs
                value={tab || 'company-list'}
                onChange={(e, value) => navigate(value)}
                variant="fullWidth"
                aria-label="full width tabs example">
                <Tab {...a11yProps('company-list')} label="Company List" value="company-list" />
                <Tab {...a11yProps('create-company')} label="Create Company" value="create-company" />
              </Tabs>
            </Paper>
            <Outlet />
          </>
        ) : (
          <p>You don't have the required permission to view this page.</p>
        )}
      </Box>
    </Box>
  );
}
