import { Box, Button, Checkbox, Container, FormControlLabel, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'context/snackbar/SnackbarContext';
import { db } from 'helpers/api';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { APP_BAR_HEIGHT } from '../../helpers/constants';
import { useQueryClient } from 'react-query';
import { useState } from 'react';

interface RegisterValues {
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
}

function RegisterForm({ email, token }: { email: string; token: string }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbar();
  const {
    register: registerForm,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<RegisterValues>();
  const [hasAcceptedPrivacyPolicy, setHasAcceptedPrivacyPolicy] = useState(false);

  const onSubmit = (data: RegisterValues) => {
    db.post('/users/accept-invite', data, { params: { token } })
      .then(async data => {
        await queryClient.invalidateQueries('me');
        await queryClient.invalidateQueries('my-companies');
        navigate('/');
        return data;
      })
      .catch(e => {
        const message = ['Failed to register...', 'Reason:', e?.response?.data?.message].join(' ');
        addSnackbar({ variant: 'error', message });
      });
  };

  return (
    <Box>
      <form
        method="post"
        action="#"
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', gap: 24, marginBottom: 24 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <TextField
            {...registerForm('firstName', {
              required: {
                value: true,
                message: 'First name is required',
              },
            })}
            id="first-name"
            label="First Name"
            fullWidth
            variant="standard"
            type="string"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
          <TextField
            {...registerForm('lastName', {
              required: {
                value: true,
                message: 'Last name is required',
              },
            })}
            id="last-name"
            label="Last Name"
            fullWidth
            variant="standard"
            type="string"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </Box>
        <TextField id="email" label="Email" disabled fullWidth variant="standard" type="string" defaultValue={email} />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <TextField
            {...registerForm('password', {
              required: {
                value: true,
                message: 'Password is required',
              },
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long',
              },
            })}
            id="password"
            name="password"
            label="Password"
            fullWidth
            variant="standard"
            type="password"
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <TextField
            {...registerForm('passwordConfirmation', {
              required: {
                value: true,
                message: 'Password confirmation is required',
              },
              validate: value => value === getValues('password') || 'Passwords do not match',
            })}
            id="password-confirmation"
            label="Repeat Password"
            fullWidth
            variant="standard"
            type="password"
            error={!!errors.passwordConfirmation}
            helperText={errors.passwordConfirmation?.message}
          />
        </Box>
        <FormControlLabel
          control={<Checkbox />}
          checked={hasAcceptedPrivacyPolicy}
          onChange={(_, value) => setHasAcceptedPrivacyPolicy(value)}
          label={
            <span>
              I accept the{' '}
              <a href="https://www.macsystemsolutions.com/privacy-policy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .*
            </span>
          }
        />
        <Button type="submit" variant="contained" sx={{ width: '100%' }} disabled={!hasAcceptedPrivacyPolicy}>
          Register
        </Button>
      </form>
      <Typography variant="body2">
        Already have an account? <Link to="/login">Login!</Link>
      </Typography>
    </Box>
  );
}

export function CompleteRegistration() {
  const [queryParams] = useSearchParams();
  const email = queryParams.get('email');
  const token = queryParams.get('token');
  if (!email) {
    return <>Missing Email Param. As a user, you shouldn't see this page.</>;
  }

  if (!token) {
    return <>Missing verification Token. As a user, you shouldn't see this page</>;
  }

  return (
    <Container maxWidth="sm">
      <Box minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="h4"
          sx={{
            mb: 3,
            fontWeight: 'bold',
          }}>
          Register
        </Typography>
        <RegisterForm email={email} token={token} />
      </Box>
    </Container>
  );
}
