import React, { createContext, useContext } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';

export const ThemeSettingsContext = createContext<{
  theme: 'light' | 'dark';
  setTheme: React.Dispatch<React.SetStateAction<'light' | 'dark'>>;
}>({
  theme: 'light',
  setTheme: () => {
    console.warn('No ThemeSettingsContext.Provider found above component');
  },
});

function validateThemeSettings(value: any) {
  if (value === 'light') {
    return 'light';
  }

  if (value === 'dark') {
    return 'dark';
  }

  return undefined;
}

export function useThemeSettings() {
  return useContext(ThemeSettingsContext);
}

const ThemeSettingsProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useLocalStorage<'light' | 'dark'>('theme-settings', 'light', validateThemeSettings);
  return <ThemeSettingsContext.Provider value={{ theme, setTheme }}>{children}</ThemeSettingsContext.Provider>;
};

export default ThemeSettingsProvider;
