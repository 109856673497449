import { AvgQueryResponse } from 'hooks/useQueriedData';

/*
 * The following function is pretty bad and shoud be removed when the mqtt values have been fixed
 * and don't return strings anymore.
 */
export function toDisplayValue(value: number | string | undefined | null, places: number): string | undefined {
  if (value === null) {
    return undefined;
  }
  if (value === undefined) {
    return undefined;
  }

  if (typeof value === 'number') {
    return value.toFixed(places);
  }

  const num = Number(value);
  if (!isNaN(num)) {
    return num.toFixed(places);
  }

  return value;
}

function toDegreesMinutesAndSeconds(coordinate: number) {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return { degrees, minutes, seconds };
}

export function convertDMS(lat: number, lon: number) {
  const latDMS = toDegreesMinutesAndSeconds(lat);
  const latitudeCardinal = lat >= 0 ? 'N' : 'S';

  const longDMS = toDegreesMinutesAndSeconds(lon);
  const longitudeCardinal = lon >= 0 ? 'E' : 'W';

  return {
    latDMS,
    latitudeCardinal,
    longDMS,
    longitudeCardinal,
  };
}

export function getMaxVibrationRmsValue(transversal?: number, vertical?: number, longtitudal?: number): number | undefined {
  let result;
  if (Number.isFinite(transversal) || Number.isFinite(vertical) || Number.isFinite(longtitudal)) {
    result = Math.max(transversal ?? 0, vertical ?? 0, longtitudal ?? 0);
  }
  return result;
}

export function maxVibrationFromQueryData(
  transversalDatas: (AvgQueryResponse | undefined)[],
  verticalDatas: (AvgQueryResponse | undefined)[],
  longitudinalDatas: (AvgQueryResponse | undefined)[],
  engineNos: string[]
): AvgQueryResponse[] {
  const datas = engineNos.map(_engineNo => {
    const engineNo = Number(_engineNo);
    const data: AvgQueryResponse = [];
    const transversalData = transversalDatas[engineNo] ?? [];
    const verticalData = verticalDatas[engineNo] ?? [];
    const longitudinalData = longitudinalDatas[engineNo] ?? [];
    const maxLength = Math.max(transversalData.length, verticalData.length, longitudinalData.length);

    for (let i = 0; i < maxLength; i++) {
      const transversalDataItem = transversalData.length > i ? transversalData[i] : null;
      const verticalDataItem = verticalData.length > i ? verticalData[i] : null;
      const longitudalDataItem = longitudinalData.length > i ? longitudinalData[i] : null;

      const transversalAvg = transversalDataItem?.avg;
      const verticalAvg = verticalDataItem?.avg;
      const longtitudalAvg = longitudalDataItem?.avg;

      const maxAvgValue = getMaxVibrationRmsValue(transversalAvg, verticalAvg, longtitudalAvg);
      const maxId =
        transversalAvg === maxAvgValue
          ? transversalDataItem?._id
          : verticalAvg === maxAvgValue
          ? verticalDataItem?._id
          : longitudinalData[i]._id;

      if (maxAvgValue && maxId) {
        data.push({ avg: maxAvgValue, _id: maxId });
      }
    }

    return data;
  });
  return datas;
}

/**
 * Calculates the percentage of a number within a range.
 * If `num` is below or above the range it returns `0`.
 */
export function percentageOfRange(num: number, range: [number, number]) {
  const perc = ((num - range[0]) / (range[1] - range[0])) * 100;
  if (perc < 0 || perc > 100) {
    return 0;
  } else {
    return perc;
  }
}

export function cumulativeToNonCumulative(arr: (number | undefined)[]) {
  if (arr.length < 2) {
    return arr;
  }

  const nonCumulative = [arr[0]];
  for (let i = 1; i < arr.length; i++) {
    const cur = arr[i];
    const prev = arr[i - 1];
    if (cur && prev) {
      nonCumulative[i] = cur - prev;
    } else {
      nonCumulative[i] = undefined;
    }
  }

  return nonCumulative;
}
