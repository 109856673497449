import React, { useRef, useEffect } from 'react';
import { useTheme } from '@mui/material';
import HexagonOverviewLight from 'assets/singleline/hexagon_overview_light.png';
import HexagonOverviewDark from 'assets/singleline/hexagon_overview_dark.png';
import { useIsDarkTheme } from 'hooks/useThemeMode';
import { BatteryStats } from 'app/Plant/BatteryTable';

const CONTAINER_WIDTH = 1289;
const CONTAINER_HEIGHT = 521;
const NA_VALUE = 'N/A';

interface HexagonOverviewCanvasProps {
  batteryStats?: BatteryStats;
}

const HexagonOverviewCanvas: React.FC<HexagonOverviewCanvasProps> = ({ batteryStats }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const theme = useTheme();
  const isDarkTheme = useIsDarkTheme();

  const getDisplayValue = (value: number | undefined, unit: string): string => {
    return Number.isFinite(value) ? `${value}${unit}` : NA_VALUE;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const backgroundImage = new Image();
    backgroundImage.src = isDarkTheme ? HexagonOverviewDark : HexagonOverviewLight;

    backgroundImage.onload = () => {
      ctx.clearRect(0, 0, CONTAINER_WIDTH, CONTAINER_HEIGHT);
      ctx.drawImage(backgroundImage, 0, 0, CONTAINER_WIDTH, CONTAINER_HEIGHT);

      if (batteryStats) {
        const h1Size = `${theme.typography.h1.fontSize}` || '96px';
        const h2Size = `${theme.typography.h2.fontSize}` || '60px';
        const h4Size = `${theme.typography.h4.fontSize}` || '34px';
        const h5Size = `${theme.typography.h5.fontSize}` || '24px';
        const body1Size = `${theme.typography.body1.fontSize}` || '16px';

        // Define value-label pairs for horizontal text
        const horizontalTextItems = [
          { label: 'L2', value: getDisplayValue(batteryStats.voltageL2n, ' V'), x: 300, y: 65 },
          { label: 'L3', value: getDisplayValue(batteryStats.voltageL3n, ' V'), x: 300, y: 135 },
          { label: 'L23', value: getDisplayValue(batteryStats.voltageL23, ' V'), x: 300, y: 230 },
          { label: 'L31', value: getDisplayValue(batteryStats.voltageL31, ' V'), x: 300, y: 298 },
          { label: 'L2', value: getDisplayValue(batteryStats.currentL2, ' A'), x: 300, y: 390 },
          { label: 'L3', value: getDisplayValue(batteryStats.currentL3, ' A'), x: 300, y: 460 },
        ];

        // Define value-label pairs for regular text
        const regularTextItems = [
          {
            value: getDisplayValue(batteryStats.voltageL1n, ' V'),
            label: 'L1 Voltage',
            x: 95,
            y: 90,
            valueFontSize: h4Size,
            labelFontSize: body1Size,
            labelYOffset: 24,
          },
          {
            value: getDisplayValue(batteryStats.voltageL12, ' V'),
            label: 'L12 Voltage',
            x: 95,
            y: 255,
            valueFontSize: h4Size,
            labelFontSize: body1Size,
            labelYOffset: 24,
          },
          {
            value: getDisplayValue(batteryStats.currentL1, ' A'),
            label: 'L1 Current',
            x: 95,
            y: 415,
            valueFontSize: h4Size,
            labelFontSize: body1Size,
            labelYOffset: 24,
          },
          {
            value: getDisplayValue(batteryStats.totalApparentPower, ' kVA'),
            label: 'Total Apparent Power',
            x: 1110,
            y: 75,
            valueFontSize: h2Size,
            labelFontSize: h5Size,
            labelYOffset: 32,
          },
          {
            value: getDisplayValue(batteryStats.totalReactivePower, ' kVAR'),
            label: 'Total Reactive Power',
            x: 1110,
            y: 265,
            valueFontSize: h2Size,
            labelFontSize: h5Size,
            labelYOffset: 32,
          },
          {
            value: getDisplayValue(batteryStats.totalEffectivePower, ' kW'),
            label: 'Total Effective Power',
            x: 1110,
            y: 440,
            valueFontSize: h2Size,
            labelFontSize: h5Size,
            labelYOffset: 32,
          },
          {
            value: getDisplayValue(batteryStats.powerFactor, ''),
            label: 'Power Factor',
            x: 680,
            y: 240,
            valueFontSize: h1Size,
            labelFontSize: h2Size,
            labelYOffset: 80,
          },
        ];

        // Render horizontal text items
        horizontalTextItems.forEach(({ label, value, x, y }) => {
          drawTextHorizontal(ctx, label, value, x, y, h5Size, theme.palette.text.secondary, theme.palette.text.primary);
        });

        // Render regular text items
        regularTextItems.forEach(({ value, label, x, y, valueFontSize, labelFontSize, labelYOffset }) => {
          drawText(
            ctx,
            value,
            label,
            x,
            y,
            valueFontSize,
            labelFontSize,
            theme.palette.text.primary,
            theme.palette.text.secondary,
            labelYOffset
          );
        });
      }
    };
  }, [batteryStats, theme, isDarkTheme]);

  const drawText = (
    ctx: CanvasRenderingContext2D,
    value: string,
    label: string,
    x: number,
    y: number,
    valueFontSize: string,
    labelFontSize: string,
    valueColor: string,
    labelColor: string,
    labelYOffset: number
  ) => {
    // Center-align value text
    ctx.fillStyle = valueColor;
    ctx.font = `${valueFontSize} RRPioneer, Helvetica, Arial, sans-serif`;
    const valueWidth = ctx.measureText(value).width;
    ctx.fillText(value, x - valueWidth / 2, y); // Adjust x to center the value

    // Center-align label text if present
    if (label && labelFontSize) {
      ctx.fillStyle = labelColor;
      ctx.font = `${labelFontSize} RRPioneer, Helvetica, Arial, sans-serif`;
      const labelWidth = ctx.measureText(label).width;
      ctx.fillText(label, x - labelWidth / 2, y + labelYOffset); // Adjust x to center the label
    }
  };

  const drawTextHorizontal = (
    ctx: CanvasRenderingContext2D,
    label: string,
    value: string,
    x: number,
    y: number,
    fontSize: string,
    labelColor: string,
    valueColor: string
  ) => {
    ctx.fillStyle = labelColor;
    ctx.font = `${fontSize} RRPioneer, Helvetica, Arial, sans-serif`;

    const labelWidth = ctx.measureText(label).width;
    const valueWidth = ctx.measureText(value).width;

    // Center the entire label+value text horizontally
    const totalWidth = labelWidth + 5 + valueWidth;
    const centeredX = x - totalWidth / 2;

    ctx.fillText(label, centeredX, y);
    ctx.fillStyle = valueColor;
    ctx.fillText(value, centeredX + labelWidth + 5, y); // 5px padding between label and value
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <canvas ref={canvasRef} width={CONTAINER_WIDTH} height={CONTAINER_HEIGHT} style={{ width: '100%', height: 'auto' }} />
    </div>
  );
};

export default HexagonOverviewCanvas;
