import { SettingsBackupRestoreOutlined } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TitledPaper from 'components/TitledPaper';

const columns: GridColDef[] = [
  {
    field: 'loadBand',
    headerName: 'Load Band',
    flex: 1,
  },
  {
    field: 'current',
    headerName: 'Current',
    renderCell: params => params.value + ' h',
    flex: 1,
  },
  {
    field: 'profile',
    headerName: 'Profile',
    renderCell: params => params.value + ' %',
    flex: 1,
  },
  {
    field: 'tbo27k',
    headerName: 'TBO 27K',
    renderCell: params => params.value + ' %',
    flex: 1,
  },
  {
    field: 'tbo24k',
    headerName: 'TBO 24K',
    renderCell: params => params.value + ' %',
    flex: 1,
  },
  {
    field: 'tbo18k',
    headerName: 'TBO 18K',
    renderCell: params => params.value + ' %',
    flex: 1,
  },
  {
    field: 'tbo9k',
    headerName: 'TBO 9K',
    renderCell: params => params.value + ' %',
    flex: 1,
  },
];

export function DistributionTable() {
  const rows = [
    {
      id: 1,
      loadBand: '100 - 110 %',
      current: 0,
      profile: 0.3,
      tbo27k: 0,
      tbo24k: 0,
      tbo18k: 1,
      tbo9k: 1,
    },
    {
      id: 2,
      loadBand: '90 - 100 %',
      current: 1,
      profile: 0.9,
      tbo27k: 8,
      tbo24k: 10,
      tbo18k: 30,
      tbo9k: 65,
    },
    {
      id: 3,
      loadBand: '80 - 90 %',
      current: 81,
      profile: 89,
      tbo27k: 0,
      tbo24k: 30,
      tbo18k: 30,
      tbo9k: 25,
    },
    {
      id: 4,
      loadBand: '65 - 80 %',
      current: 1,
      profile: 0.7,
      tbo27k: 20,
      tbo24k: 0,
      tbo18k: 0,
      tbo9k: 0,
    },
    {
      id: 5,
      loadBand: '50 - 65 %',
      current: 1,
      profile: 0.7,
      tbo27k: 0,
      tbo24k: 35,
      tbo18k: 20,
      tbo9k: 0,
    },
    {
      id: 6,
      loadBand: '30 - 50 %',
      current: 1,
      profile: 1,
      tbo27k: 52,
      tbo24k: 15,
      tbo18k: 19,
      tbo9k: 9,
    },
    {
      id: 7,
      loadBand: '5 - 30 %',
      current: 6,
      profile: 6.5,
      tbo27k: 20,
      tbo24k: 10,
      tbo18k: 0,
      tbo9k: 0,
    },
    {
      id: 8,
      loadBand: '0 - 5 %',
      current: 0,
      profile: 0.4,
      tbo27k: 0,
      tbo24k: 0,
      tbo18k: 0,
      tbo9k: 0,
    },
  ];

  return (
    <TitledPaper icon={<SettingsBackupRestoreOutlined />} title="Load Distribution">
      <DataGrid rows={rows} columns={columns} sx={{ border: 'none', flex: 1, cursor: 'pointer' }} hideFooterPagination autoHeight />
    </TitledPaper>
  );
}
