import { AppBar, Toolbar, Box, Paper } from '@mui/material';
import { RollsRoyceLogo, NautIQLogo } from 'app/NavigationBoilerplate/BoilerplateV2';
import { APP_BAR_HEIGHT } from 'helpers/constants';
import BrightnessSlider from 'components/BrightnessSlider';
import { ThemeToggleTextButton } from 'components/ThemeToggleTextButton';

export function BridgeAppBar() {
  return (
    <AppBar sx={{ zIndex: theme => theme.zIndex.drawer + 2, boxShadow: 'none', backdropFilter: 'blur(12px)', position: 'inherit' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ flexGrow: 1, width: '100%' }}>
          <RollsRoyceLogo />
        </Box>
        <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
          <NautIQLogo />
        </Box>
        <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', justifyContent: 'right', color: 'text.primary', gap: 4, pr: 4 }}>
          <ThemeToggleTextButton lightText="Night Mode" darkText="Day Mode" />
          <BrightnessSlider />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export const BridgeContainer: React.FC<Record<string, unknown>> = ({ children }) => {
  return (
    <>
      <Paper>
        <BridgeAppBar />
      </Paper>
      <Box id="route-box" sx={{ height: `calc(100vh - ${APP_BAR_HEIGHT}px)` }}>
        {children}
      </Box>
    </>
  );
};
