import React from 'react';

import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import usePathChild from 'hooks/usePathChild';

import ViewHeader, { TabLabelToPath } from 'components/ViewHeader';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';

const tabs: TabLabelToPath[] = [
  { label: 'General', toPath: 'general' },
  { label: 'Route', toPath: 'route' },
  { label: 'Speed', toPath: 'speed' },
  { label: 'Consumption', toPath: 'consumption' },
];

export default function Vessel() {
  const value = usePathChild(false);
  const dateRange = useAnalysisDateRange();

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mt: 2, mb: 2 }}>
        <ViewHeader tabs={tabs} value={value || false} />
      </Box>
      <Outlet context={dateRange} />
    </Box>
  );
}
