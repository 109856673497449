import { useQuery } from 'react-query';
import { db } from 'helpers/api';

import type { ClientId } from '../context/clientId';
import type { Vessel } from './fleetConfig';

async function getVessel(clientId: string): Promise<Vessel | undefined> {
  const res = await db.post<[Vessel]>(`/config/vessels/get`, [clientId]);
  if (res.data?.[0] === undefined) {
    throw new Error('No Vessel Config found');
  }
  return res.data?.[0];
}

export function useVesselQuery(clientId: ClientId) {
  return useQuery(['vesselConfig', clientId], () => getVessel(clientId));
}

export function useVessel(clientId: ClientId) {
  return useVesselQuery(clientId).data;
}
