import React from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import BrightnessLowOutlinedIcon from '@mui/icons-material/BrightnessLowOutlined';

import { useThemeSettings } from 'context/themeSettings';

interface ThemeToggleTextButtonProps {
  darkText?: string;
  lightText?: string;
}

export function ThemeToggleTextButton({ darkText, lightText }: ThemeToggleTextButtonProps) {
  const { theme, setTheme } = useThemeSettings();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'begin',
        justifyContent: 'center',
        color: 'text.primary',
        borderRadius: 1,
        p: 1,
      }}>
      <Button
        startIcon={theme === 'dark' ? <BrightnessLowOutlinedIcon /> : <DarkModeOutlinedIcon />}
        onClick={() => setTheme((prev: 'light' | 'dark') => (prev === 'light' ? 'dark' : 'light'))}>
        {theme === 'dark' ? darkText : lightText}
      </Button>
    </Box>
  );
}
