import { useLocalStorage } from 'hooks/useLocalStorage';
import React, { useContext, useState, useCallback, useMemo } from 'react';

import { Pipe, Pipeline } from 'react-pipeline-component';
import valueConversion from 'helpers/valueConversion';

// All (Base-) Units we support in settings.
// For derived Units, like SFOC, look in TargetUnitSettings / genQuantityUnitMap.
//
// The default unit is the first in the list of variants
// unit variants should be compatible with math.js units
//
// If you want to add or remove quantities/units, you have
// to fix the compile errors in QUANTITY_DISPLAY_NAMES and
// UNIT_DISPLAY_NAMES by adding/removing the according entries.
// Do not change their type declarations!
export const UNITS = {
  temperature: ['°C', '°F'],
  pressure: ['bar', 'psi'],
  mass: ['kg', 'lbs'],
  volume: ['ltr', 'gal'],
  distance: ['nm', 'km', 'm'],
  vesselSpeed: ['kn', 'km/h'],
  windSpeed: ['m/s', 'ft/s'],
  depth: ['m', 'ft'],
  power: ['kW', 'hp'],
} as const;

// Can be used if you want to display the Quantities of UNITS
export const QUANTITY_DISPLAY_NAMES: {
  readonly [Property in keyof typeof UNITS]: string;
} = {
  temperature: 'Temperature',
  pressure: 'Pressure',
  mass: 'Mass',
  volume: 'Volume',
  distance: 'Distance',
  vesselSpeed: 'Vessel Speed',
  windSpeed: 'Wind Speed',
  depth: 'Depth',
  power: 'Power',
};

// Can be used if you want to display the units of UNITS
export const UNIT_DISPLAY_NAMES: {
  readonly [Property in (typeof UNITS)[keyof typeof UNITS][number]]: string;
} = {
  '°C': 'Celsius',
  '°F': 'Fahrenheit',
  bar: 'Bar',
  psi: 'Pounds / inch²',
  kg: 'Kilograms',
  lbs: 'Pounds',
  ltr: 'Litres',
  gal: 'Gallons',
  nm: 'Nautical Miles',
  km: 'Kilometers',
  m: 'Meters',
  ft: 'Feet',
  kn: 'Knots',
  kW: 'kW',
  hp: 'Horsepower',
  'km/h': 'km/h',
  'm/s': 'm/s',
  'ft/s': 'ft/s',
};

// Type containing all unit settings.
// If we want to support more units/variants, edit the UNITS constant
type UnitSettings = {
  [Property in keyof typeof UNITS]: (typeof UNITS)[Property][number];
};

// Type of all extended unit settings
export type TargetUnitSettings = ReturnType<typeof genQuantityUnitMap>;

// Quantities describe a group of units.
// they can be used together with the `quantityTargetUnit` function
// to get the targetUnit of a spcific quantity.
export type Quantity = keyof TargetUnitSettings;

// This is a function that maps Quantities to targetUnits
// ATTENTION!: In a component, use the useTargetUnits hook instead (memoized)
//
// The benefit of using Quantities over sourceUnit/targetUnit pairs is that
// code gets more declarative.
// A function using source/Target unit typically looks like:
//  function exampleUnitPairs() {
//    const units = useUnitSettings();
//    const sourceUnit = 'kn';
//    const targetUnit = units.vesselSpeed;
//  }
//
// You have to choose the correct target unit right there, which requires a lookup in unit settings.
// Using quantities, you can delay that lookup to a later component.
//
// For Chart support, there are still some quantities missing, e.g. there is no quantity for various
// kg/h charts.
function genQuantityUnitMap(units: UnitSettings) {
  return {
    ...units,
    volumeConsumption: `${units.volume}/h`,
    massConsumption: `${units.mass}/h`,
    massPerEnergyConsumption: `g/kWh`,
    vibrationDistance: 'mm/sec',
    fuelMassPerLength: `${units.mass}/${units.distance}`,
    energyPerLength: `kWh/${units.distance}`,
  } as const;
}

// convert a single value using quantity and sourceUnit.
// returns the convertedValue (if present), and the unit of the value.
// if no conversion could be done, it returns [originalValue, sourceUnit].
// IMPORTANT: this function may throw if the sourceUnit is not of the same dimensions as the quantity choosen!
export function useConvertedQuantity(value: number, quantity: Quantity, sourceUnit: string): [number, string];
export function useConvertedQuantity(value: number, quantity?: Quantity, sourceUnit?: string): [number, string | undefined];
export function useConvertedQuantity(value?: number, quantity?: Quantity, sourceUnit?: string): [number | undefined, string | undefined];
export function useConvertedQuantity(value?: number, quantity?: Quantity, sourceUnit?: string): [number | undefined, string | undefined] {
  const units = useTargetUnits();
  if (quantity === undefined) {
    return [value, sourceUnit];
  }

  if (sourceUnit === undefined) {
    return [value, undefined];
  }

  const targetUnit = units[quantity];

  return [valueConversion(value, sourceUnit, targetUnit), targetUnit];
}

// default UnitSettings.
// Takes the first variant found in UNITS
export const defaultUnitSettings = Object.fromEntries(Object.entries(UNITS).map(([k, v]) => [k, v[0]])) as UnitSettings;

const UnitSettingsContext: React.Context<UnitSettings> = React.createContext(defaultUnitSettings);
const RawUnitSettingsContext: React.Context<{
  units: Partial<UnitSettings>;
  setUnits: React.Dispatch<React.SetStateAction<Partial<UnitSettings>>>;
}> = React.createContext({
  units: {},
  setUnits: (() => {
    console.warn('No RawUnitSettingsContext.Provider found above component');
  }) as React.Dispatch<React.SetStateAction<Partial<UnitSettings>>>,
});

// unit editor should use this hook.
// value should be saved in local store upon setUnits call.
export function useRawUnitSettings() {
  return useContext(RawUnitSettingsContext);
}

// Consumers of Unit settings should use this hook.
// Can be used without a UnitSettingsContext.Provider, because we provide default units
export function useBaseUnitSettings() {
  return useContext(UnitSettingsContext);
}

export function useTargetUnits() {
  const baseUnits = useBaseUnitSettings();
  const derivedUnits = useMemo(() => genQuantityUnitMap(baseUnits), [baseUnits]);

  return derivedUnits;
}

// this function should be called after JSON.parse-ing the Unit settings from local storage
function validateRawUnitSettings(value: any): Partial<UnitSettings> {
  if (typeof value !== 'object') {
    return {};
  }

  if (value === null) {
    return {};
  }

  const units: { [prop in string]?: string } = {};
  for (const quantity of Object.keys(UNITS) as (keyof typeof UNITS)[]) {
    const unit = value[quantity];
    const valid_units = UNITS[quantity];
    if ((valid_units as readonly string[]).includes(unit)) {
      units[quantity] = unit as (typeof UNITS)[typeof quantity][number];
    }
  }

  // console.log(units);
  return units;
}

// should only be called to pass to a RawUnitContext.Provider
function useRawUnitCtx(): {
  units: Partial<UnitSettings>;
  setUnits: React.Dispatch<React.SetStateAction<Partial<UnitSettings>>>;
} {
  const [value, setValue] = useLocalStorage('unit-settings', {}, validateRawUnitSettings);

  return {
    units: value,
    setUnits: setValue,
  };
}

const SettingsDialogContext = React.createContext({
  open: false,
  setSettingsOpen: (() => {
    console.warn('No SettingsDialogContext.Provider found above component');
  }) as React.Dispatch<React.SetStateAction<boolean>>,
  openDialog: () => {
    console.warn('No SettingsDialogContext.Provider found above component');
  },
  closeDialog: () => {
    console.warn('No SettingsDialogContext.Provider found above component');
  },
});

export function useSettingsDialog() {
  return useContext(SettingsDialogContext);
}

// private function for SettingsDialogState
// should be paired wtih SettingsDialogContext.Provider
function useSettingsDialogCtx() {
  const [open, setOpen] = useState(false);
  const closeDialog = useCallback(() => setOpen(false), []);
  const openDialog = useCallback(() => setOpen(true), []);
  return {
    open,
    setSettingsOpen: setOpen,
    closeDialog,
    openDialog,
  };
}

const SettingsProvider: React.FC = ({ children }) => {
  const settingsDialogCtx = useSettingsDialogCtx();
  const rawUnitCtx = useRawUnitCtx();
  const unitSettings = { ...defaultUnitSettings, ...rawUnitCtx.units };

  return (
    <Pipeline
      components={[
        <SettingsDialogContext.Provider value={settingsDialogCtx} children={<Pipe />} />,
        <RawUnitSettingsContext.Provider value={rawUnitCtx} children={<Pipe />} />,
        <UnitSettingsContext.Provider value={unitSettings} children={<Pipe />} />,
      ]}>
      <>{children}</>
    </Pipeline>
  );
};

export default SettingsProvider;
