import { SingleBar } from './SingleBar';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatumString, useLiveData } from 'hooks/useLiveData';
import useClientId from 'context/clientId';
import { useAlarmThreshold } from 'hooks/useAlarmThreshold';

export function VibrationRmsChart({ objectId, objectIdNo }: { objectId: string; objectIdNo: number }) {
  const clientId = useClientId();
  const pvIdNos = [
    { value: 2, label: 'L' },
    { value: 1, label: 'T' },
    { value: 0, label: 'V' },
  ];

  const thresholdTopic = { clientId, objectId, objectNo: objectIdNo.toString(), pvId: 'vibrationMax' };
  const { data: vibrationThreshold } = useAlarmThreshold(thresholdTopic);

  const warningLevel = vibrationThreshold?.warning ?? 20;
  const criticalLevel = vibrationThreshold?.critical ?? 30;
  const maxLevel = vibrationThreshold?.critical ?? 30;

  const theme = useTheme();
  const warningColor = theme.palette.warning.main;
  const criticalColor = theme.palette.error.main;

  const getTopicFromPvIdNo = (pvIdNo: number) =>
    `ioconnect/measurement/${clientId}/${objectId}/${objectIdNo}/vibrationRms/${pvIdNo}` as DatumString;
  const topics = pvIdNos.map(pvIdNo => getTopicFromPvIdNo(pvIdNo.value));
  const [data] = useLiveData(topics);

  return (
    <Box
      sx={{
        height: '12em',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        gap: '0.3em',
        width: 'min-content',
        paddingLeft: '1.5em',
      }}>
      {pvIdNos.map(pvIdNo => (
        <SingleBar
          key={pvIdNo.value}
          value={data?.[getTopicFromPvIdNo(pvIdNo.value)]?.value ?? 0}
          warningLevel={warningLevel}
          criticalLevel={criticalLevel}
          maxLevel={maxLevel}
          label={pvIdNo.label}
        />
      ))}
      <Box sx={{ height: 'calc(100% - 2.5em)', position: 'absolute', top: 0, left: 0, right: 0 }}>
        <ThresholdLine value={warningLevel} color={warningColor} maxLevel={maxLevel} />
        <ThresholdLine value={criticalLevel} color={criticalColor} maxLevel={maxLevel} />
      </Box>
    </Box>
  );
}

function ThresholdLine({ value, color, maxLevel }: { value: number; color: string; maxLevel: number }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'end',
        gap: '0.2em',
        position: 'absolute',
        bottom: `${(value / maxLevel) * 100}%`,
        paddingRight: '0.1em',
      }}>
      <Typography variant="body2" color={color} sx={{ transform: 'translateY(50%)', width: '100%', flex: 30, textAlign: 'left' }}>
        {value}
      </Typography>
      <Box sx={{ height: '2px', width: '100%', flex: 70, backgroundColor: color }} />
    </Box>
  );
}
