import * as React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Tab, Tabs } from '@mui/material';

export interface TabLabelToPath {
  label: string;
  toPath: string;
}

type ViewHeaderProps = {
  value?: string | false;
  tabs: TabLabelToPath[];
};

export default function ViewHeader(props: ViewHeaderProps) {
  return (
    <Paper sx={{ bgcolor: 'background.default' }}>
      <Tabs value={props.value}>
        {props.tabs.map((tab: TabLabelToPath) => {
          return <Tab key={tab.label} label={tab.label} component={Link} to={tab.toPath} value={tab.toPath} />;
        })}
      </Tabs>
    </Paper>
  );
}
