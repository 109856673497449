import { Navigate } from 'react-router-dom';
import { useStatusTabs } from './useStatusTabs';

export function StatusNavigate() {
  const tabs = useStatusTabs();

  if (tabs.length === 0) {
    return <p>Status has no tabs enabled</p>;
  }
  return <Navigate to={tabs[0].toPath} replace />;
}
