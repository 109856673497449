import { db } from 'helpers/api';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'context/snackbar/SnackbarContext';

async function removeUserFromCompany(company_id: string, user_id: string) {
  await db.delete(`company/${company_id}/member/${user_id}`);
}

export function useRemoveUserMutation(company_id: string) {
  const queryClient = useQueryClient();
  const { addSnackbar } = useSnackbar();

  const mutation = useMutation((user_id: string) => removeUserFromCompany(company_id, user_id), {
    onSettled: () => {
      queryClient.invalidateQueries(['company_members', company_id]);
    },
    onSuccess: () => {
      addSnackbar({ variant: 'success', message: 'Removed user from Company!' });
    },
    onError: () => {
      addSnackbar({ variant: 'error', message: 'Could not remove user...' });
    },
  });
  return mutation;
}
