import React from 'react';

import type { ClientId } from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { useSequencesData } from 'hooks/useQueriedData';

import { addDataToScatterMap, ChartProps, color, dataSeriesFromScatterMap } from '.';
import SimpleScatterCard from './SimpleScatterCard';
import { useChartConfig } from 'context/baselineConfig';
import { useTargetUnits } from 'context/settings';

function useTExhaustGasCombined(
  clientId: ClientId,
  from: Date,
  to: Date,
  resolution: number,
  bank: number,
  configName: 'TExhaustAOverLoad' | 'TExhaustBOverLoad'
) {
  const units = useTargetUnits();
  const chartConfig = useChartConfig(configName);
  const vesselConfig = useVesselConfig();
  const meta = Object.keys(vesselConfig.engines).map(engineId => {
    const engineNo = Number(engineId);
    return {
      engineNo,
      loadLabel: `load${engineNo}`,
      valueLabel: vesselConfig.engines[engineNo].name,
    };
  });

  const loadQueries = meta.map(
    ({ engineNo }) =>
      ({
        module: 'calculated',
        objectId: 'combustion',
        objectIdNo: engineNo,
        pvId: 'load',
        pvIdNo: 0,
        clientId,
        resolution,
        from,
        to,
      } as const)
  );

  const valueQueries = meta.map(
    ({ engineNo }) =>
      ({
        module: 'measurement',
        clientId,
        objectId: 'combustion',
        objectIdNo: engineNo,
        pvId: 'tempExhaustgasCombined',
        pvIdNo: bank,
        resolution,
        from,
        to,
        sourceUnit: 'degC',
        targetUnit: units.temperature,
      } as const)
  );

  const [loadData, loadLoading, loadErr] = useSequencesData(loadQueries);
  const [datas, loading, err] = useSequencesData(valueQueries);

  const map = new Map();

  meta.forEach(({ valueLabel, loadLabel }, i) => {
    addDataToScatterMap(map, valueLabel, datas[i] ?? []);
    addDataToScatterMap(map, loadLabel, loadData[i] ?? []);
  });

  const data = {
    datasets: meta.map(({ valueLabel, loadLabel }, i) => ({
      label: valueLabel,
      data: dataSeriesFromScatterMap(map, loadLabel, valueLabel),
      borderColor: color[i],
      borderWidth: 0.5,
      fill: false,
      radius: 2,
    })),
  };

  return { data, loading: loading || loadLoading, err: err || loadErr, chartConfig };
}

function TExhaustGasCombinedA({ clientId, from, to, resolution }: ChartProps) {
  const { data, loading, err, chartConfig } = useTExhaustGasCombined(clientId, from, to, resolution, 0, 'TExhaustAOverLoad');

  return <SimpleScatterCard name="Exhaust Gas Temp. - Bank A" data={data} loading={loading} err={err} options={chartConfig?.axes} />;
}

function TExhaustGasCombinedB({ clientId, from, to, resolution }: ChartProps) {
  const { data, loading, err, chartConfig } = useTExhaustGasCombined(clientId, from, to, resolution, 1, 'TExhaustBOverLoad');

  return <SimpleScatterCard name="Exhaust Gas Temp. - Bank B" data={data} loading={loading} err={err} options={chartConfig?.axes} />;
}

export { TExhaustGasCombinedA, TExhaustGasCombinedB };
