import { db } from 'helpers/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ClientId } from 'context/clientId';
import { useSnackbar } from 'context/snackbar/SnackbarContext';

export interface WorkplanItem {
  _id: string;
  wpCode: string;
  title: string;
  description: string;
  level: number;
  intervalDays?: number;
  intervalRunninghours?: number;
  link?: string;
}

export interface Workplan {
  _id: string;
  created: string;
  clientId: ClientId;
  objectId: string;
  objectIdNo: number;
  userId: string;
  items: WorkplanItem[];
  description: string;
  archived: boolean;
  __v: number;
}

type WorkplanDue = {
  wpId: string;
  taskId?: string;
  dueRunninghours?: number;
};

export type WorkplanDueMap = {
  [key: string]: {
    taskId: string;
    dueRunninghours: number;
  };
};

export interface workplanFilter {
  limit?: number;
  skip?: number;
  archived: boolean;
  clientId?: ClientId;
  objectId?: string;
  objectIdNo?: number;
}

const getWorkplansRequest = async (params: workplanFilter) => {
  const { data } = await db.get<Workplan[]>('workplan', { params: params });
  return data;
};

const updateWorkplanRequest = async (workplan: Workplan) => {
  const { data } = await db.patch<Workplan>(`workplan/${workplan._id}`, workplan);
  return data;
};

const createWorkplanRequest = async (workplan: Workplan) => {
  const { data } = await db.post<Workplan>('workplan', workplan);
  return data;
};

const getWorkplansDueRequest = async (params: workplanDueFilter) => {
  const { data } = await db.get<WorkplanDue[]>('workplan/due', { params: params });
  const dueData: WorkplanDueMap = {};
  data.forEach(({ dueRunninghours, taskId, wpId }) => {
    if (taskId) {
      dueData[wpId] = {
        taskId: taskId,
        dueRunninghours: dueRunninghours ?? 0,
      };
    }
  });
  return dueData;
};

export interface workplanDueFilter {
  limit?: number;
  skip?: number;
  archived?: boolean;
  clientId?: ClientId;
  objectId?: string;
  objectIdNo?: number;
}

export function useWorkplansDueAt(params: workplanDueFilter) {
  const workplansDue = useQuery(['workplansDueAt', params], () => getWorkplansDueRequest(params));
  return workplansDue;
}

export default function useWorkplans(params: workplanFilter) {
  const queryClient = useQueryClient();
  const { addSnackbar } = useSnackbar();

  const workplans = useQuery(['workplans', params], () => getWorkplansRequest(params));

  const { mutateAsync: updateWorkplan } = useMutation(updateWorkplanRequest, {
    onMutate: async updatedWorkplan => {
      await queryClient.cancelQueries(['workplans', params]);

      // for rollback if mutation call fails
      const previousWorkplans = queryClient.getQueryData<Workplan[]>(['workplans', params]);

      queryClient.setQueryData<Workplan[] | undefined>(['workplans', params], old =>
        old?.map(workplan => (workplan._id === updatedWorkplan._id ? updatedWorkplan : workplan))
      );

      return { previousWorkplans };
    },
    onError: (err, updatedWorkplan, context: any) => {
      if (context?.previousWorkplans) {
        // rollback the optimistic update if mutation call fails
        queryClient.setQueryData<Workplan[]>(['workplans', params], context.previousWorkplans);
      }
      addSnackbar({ variant: 'error', message: 'Failed to update Workplan' });
    },
    onSuccess: () => {
      addSnackbar({ variant: 'success', message: 'Update successfull' });
    },
  });

  const { mutateAsync: addWorkplan } = useMutation(createWorkplanRequest, {
    onMutate: async newWorkplan => {
      await queryClient.cancelQueries(['workplans', params]);

      const previousWorkplans = queryClient.getQueryData<Workplan[]>(['workplans', params]);

      if (previousWorkplans) {
        queryClient.setQueryData<Workplan[]>(['workplans', params], [...previousWorkplans, newWorkplan]);
      }

      return { previousWorkplans };
    },
    onError: (err, variables, context: any) => {
      if (context?.previousWorkplans) {
        queryClient.setQueryData<Workplan[]>(['workplans', params], context.previousWorkplans);
      }
      addSnackbar({ variant: 'error', message: 'Failed to create Workplan' });
    },
    onSuccess: () => {
      addSnackbar({ variant: 'success', message: 'Workplan created' });
    },
  });

  return {
    workplans,
    updateWorkplan,
    addWorkplan,
  };
}
