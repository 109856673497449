import { create, all } from 'mathjs';

// create a mathjs instance
const math = create(all);

// isValidAlpha Hack to support units with degree symbol
const isAlphaOriginal = (math as any).Unit.isValidAlpha;
(math as any).Unit.isValidAlpha = function (c: any) {
  return isAlphaOriginal(c) || c === '°';
};
export default math;

math.createUnit('knot', { definition: '1.852 km/h', aliases: ['knots', 'kn'] });
math.createUnit('ltr', '1 liter');
// nautical miles
math.createUnit('nm', { definition: '1852 m' });

// degrees
// need to patch isValidAlpha for this to work with degree symbol
// has to be defined in terms of kelvin for correct conversions
math.createUnit('°C', { definition: '1 kelvin', offset: 273.15 });
math.createUnit('°F', { definition: '0.555556 kelvin', offset: 459.67 });

// use prefixes to use krpm because we can't set 1000rpm from TCPowerOverTime otherwise
math.createUnit('rpm', { definition: '1 / min', prefixes: 'short' });
