import React from 'react';

import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import NavigationIcon from '@mui/icons-material/Navigation';
import useClientId from 'context/clientId';
import { useLiveDataMap } from 'hooks/useLiveData';
import { useThemeMode } from 'hooks/useThemeMode';

const MapLive: React.FC = ({ children }) => {
  const themeMode = useThemeMode();
  const clientId = useClientId();
  const [{ lat, lon, sog, cog }] = useLiveDataMap(
    {
      lat: { source: 'measurement', objectId: 'position', pvId: 'lat' },
      lon: { source: 'measurement', objectId: 'position', pvId: 'lon' },
      sog: { source: 'measurement', objectId: 'speed', pvId: 'sog' },
      cog: { source: 'measurement', objectId: 'direction', pvId: 'cog' },
    },
    { clientId }
  );

  const speedOverGround = sog?.value ? Math.round(sog?.value) : 0;
  const cogCorrected = speedOverGround > 0 ? cog?.value ?? 0 : 0;
  const defaultLatitude = 53.54013;
  const defaultLongtitude = 9.98756;

  return (
    <ReactMapGL
      latitude={lat?.value ?? defaultLatitude}
      longitude={lon?.value ?? defaultLongtitude}
      initialViewState={{
        longitude: defaultLongtitude,
        latitude: defaultLatitude,
        zoom: 8,
      }}
      onLoad={e => {
        e.target.resize();
      }}
      cooperativeGestures={true}
      maxZoom={16}
      mapStyle={`mapbox://styles/mapbox/${themeMode}-v10`}
      mapboxAccessToken={'pk.eyJ1IjoiZ2JlcmtlcyIsImEiOiJja3Yzc3J3ZXkwaDh4Mm9waHlnOXVsN2swIn0.fC1KfJL4JzkqB3GRYzNv8Q'}>
      {lat && lon ? (
        <Marker key={'HJ'} latitude={lat?.value ?? 0} longitude={lon?.value ?? 0}>
          <NavigationIcon style={{ transform: `rotate(${cogCorrected}deg)` }} color="primary" />
        </Marker>
      ) : null}
      {children}
    </ReactMapGL>
  );
};

export default MapLive;
