import React from 'react';

import type { ClientId } from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { TimeseriesQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';
import { useTargetUnits } from 'context/settings';

function useCoolantPressureOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const vesselConfig = useVesselConfig();
  const queries: TimeseriesQuery[] = Object.keys(vesselConfig.engines).map(engineNo => ({
    clientId,
    objectId: 'combustion',
    objectIdNo: Number(engineNo),
    pvId: 'pressureCoolant',
    pvIdNo: 0,
    resolution,
    from,
    to,
    sourceUnit: 'bar',
    targetUnit: units.pressure,
  }));

  const [datas, loading, err] = useSequencesData(queries);

  const data = {
    datasets: datas.map((data, i) => ({
      label: vesselConfig.engines[i].name,
      data: data ?? [],
      borderColor: color[i],
      borderWidth: 1,
    })),
  };

  const options = { yAxisLabel: `Pressure [${units.pressure}]`, title: 'Coolant Pressure', xAxisLabel: 'Time' };
  return {
    data,
    loading,
    err,
    chartConfig: { axes: options },
  };
}

function CoolantPressureOverTime({ clientId, from, to, resolution }: ChartProps) {
  const { data, loading, err, chartConfig } = useCoolantPressureOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={chartConfig?.axes} data={data} loading={loading} err={err} />;
}

export default CoolantPressureOverTime;
