import { useEffect, useRef, useState } from 'react';

import ReactMapGL, { Layer, LayerProps, LngLatBoundsLike, MapRef, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { useZoomTimeRangeContext } from 'context/zoom/ZoomTimeRangeContext';
import { useThemeMode } from 'hooks/useThemeMode';
import { chartColors } from 'theme/chartColors';

interface MapProps {
  defaultLon?: number;
  defaultLat?: number;
  zoom?: number;
  coordinates: any;
  maxZoom?: number;
  scrollZoom?: boolean;
  dragPan?: boolean;
  touchPitch?: boolean;
  touchZoomRotate?: boolean;
  lineColor?: string;
  lineWidth?: number;
}
const Map = ({
  defaultLat,
  defaultLon,
  coordinates,
  zoom = 8,
  maxZoom = 16,
  scrollZoom = true,
  dragPan = true,
  touchPitch = true,
  touchZoomRotate = true,
  lineColor = chartColors.main[0],
  lineWidth = 2,
}: MapProps) => {
  const themeMode = useThemeMode();
  const mapRef = useRef<MapRef>(null);
  const { zoomRange } = useZoomTimeRangeContext();
  const [filteredCoordinates, setFilteredCCoordinates] = useState<number[][]>([]);
  const [bounds, setBounds] = useState<LngLatBoundsLike | null>();

  const defaultLatitude = defaultLat ? defaultLat : 53.54013;
  const defaultLongtitude = defaultLon ? defaultLon : 9.98756;

  const routeLayer: LayerProps = {
    id: 'route',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-width': lineWidth,
    },
  };

  const geoJson: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: filteredCoordinates,
        },
      },
    ],
  };

  useEffect(() => {
    if (!coordinates) return;

    const valuesLon: number[] = [];
    const valuesLat: number[] = [];
    const tmpCoordinates: number[][] = [];
    for (const item of coordinates) {
      if (!zoomRange || (new Date(item.tsp) >= new Date(zoomRange.min) && new Date(item.tsp) <= new Date(zoomRange.max))) {
        valuesLon.push(item.lon);
        valuesLat.push(item.lat);
        tmpCoordinates.push([item.lon, item.lat]);
      }
    }

    const lonMin = Math.min(...valuesLon);
    const lonMax = Math.max(...valuesLon);
    const latMin = Math.min(...valuesLat);
    const latMax = Math.max(...valuesLat);

    if (lonMin !== Infinity && lonMax !== Infinity && latMin !== Infinity && latMax !== Infinity) {
      setBounds([
        [Math.min(...valuesLon), Math.min(...valuesLat)],
        [Math.max(...valuesLon), Math.max(...valuesLat)],
      ]);
    }

    setFilteredCCoordinates(tmpCoordinates);
  }, [zoomRange, coordinates]);

  if (bounds) mapRef.current?.fitBounds(bounds, { padding: 40, duration: 1000 });
  return (
    <ReactMapGL
      ref={mapRef}
      initialViewState={{
        longitude: defaultLon ? defaultLon : defaultLongtitude,
        latitude: defaultLat ? defaultLat : defaultLatitude,
        zoom: zoom,
      }}
      maxZoom={maxZoom}
      scrollZoom={scrollZoom}
      dragPan={dragPan}
      touchPitch={touchPitch}
      touchZoomRotate={touchZoomRotate}
      cooperativeGestures={true}
      mapStyle={`mapbox://styles/mapbox/${themeMode}-v10`}
      mapboxAccessToken={'pk.eyJ1IjoiZ2JlcmtlcyIsImEiOiJja3Yzc3J3ZXkwaDh4Mm9waHlnOXVsN2swIn0.fC1KfJL4JzkqB3GRYzNv8Q'}>
      <Source id="my-data" type="geojson" data={geoJson}>
        <Layer {...routeLayer} />
      </Source>
    </ReactMapGL>
  );
};

export default Map;
