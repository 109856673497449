import { useQuery } from 'react-query';
import type { BaselineFamily, BaselineGroup } from 'app/VesselConfig/Baselines/types';

import { db } from 'helpers/api';

export type BaselineGroupsReq = {
  clientId: string;
};

export type BaselineItems = {
  family: BaselineFamily;
  groups: BaselineGroup[];
};

async function getBaselineGroupsRequest({ clientId }: BaselineGroupsReq) {
  const { data } = await db.get<BaselineItems[]>(`config/baselines/getGroups`, { params: { clientId } });
  return data;
}

export function useBaselineGroups(params: BaselineGroupsReq) {
  const baselineGroups = useQuery(['baselineGroups', params], () => getBaselineGroupsRequest(params));
  return baselineGroups;
}

type BaselineGroupReq = {
  clientId: string;
  baselineGroupId: string;
};

async function getBaselineGroupConfig(params: BaselineGroupReq) {
  const { data } = await db.get<BaselineGroup>(`config/baselines/getGroup`, { params });
  return data;
}

export function useBaselineGroup(params: BaselineGroupReq) {
  const baselineGroup = useQuery(['baselineGroupConfig', params], () => getBaselineGroupConfig(params));
  return baselineGroup;
}
