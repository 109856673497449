import { Box, Skeleton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { EmissionConfig, EmissionStats, ciiCalculation, emissionWindowAggregation, ciiToRating, Rating } from 'helpers/emissions';
import { RatingIcon } from './RatingBox';

export function Forecast({ emissionData, emissionConfig }: { emissionData: EmissionStats[]; emissionConfig?: EmissionConfig }) {
  const currentYear = useMemo(() => new Date().getUTCFullYear(), []);
  const cii = useMemo(() => {
    const curentYearData = emissionData.filter(v => v && new Date(v.time).getUTCFullYear() === currentYear) ?? [];
    const aggregated = emissionWindowAggregation(curentYearData);
    return ciiCalculation(
      {
        fuelConsumptionLiters: aggregated.liters,
        travelledDistanceNm: aggregated.travelledDistance,
      },
      emissionConfig
    );
  }, [currentYear, emissionData, emissionConfig]);

  const predictions = useMemo(() => {
    const boundaries = emissionConfig?.ciiBoundaries;
    if (!cii) {
      return undefined;
    }
    if (!boundaries) {
      return undefined;
    }
    const forcast: [string, Rating][] = Object.entries(boundaries)
      .filter(([year]) => Number(year) >= currentYear && Number(year) < currentYear + 5)
      .map(([year, boundary]) => [year, ciiToRating(cii, boundary)]);
    return forcast;
  }, [currentYear, cii, emissionConfig]);

  if (!predictions) {
    const skeletonYears = Array.from({ length: 5 })
      .fill(0)
      .map((_, i) => i + Number(currentYear));
    return (
      <Box sx={{ display: 'flex', gap: 2 }}>
        {skeletonYears.map(year => (
          <Box
            key={year}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Skeleton
              variant="rectangular"
              sx={{
                width: 105,
                height: 105,
              }}></Skeleton>
            <Typography>Ship Rating {year}</Typography>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
      }}>
      {(predictions ?? []).map(([year, rating]) => (
        <Box
          key={year}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <RatingIcon
            sx={{
              width: 105,
              height: 105,
            }}
            rating={rating}
          />
          <Typography>Ship Rating {year}</Typography>
        </Box>
      ))}
    </Box>
  );
}
