import { useState } from 'react';
import { Box, Button, Paper } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { addDays, differenceInDays, startOfToday, subDays } from 'date-fns';
import { DateRange, Range } from 'react-date-range';
import ZoomTimeRangeReset from './ZoomTimeRangeReset';
import { TimeButton } from './DateRangeBar';
import { TimeRangeResult } from 'hooks/useTimeRange';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const MIN_DATE = new Date('2022-04-01');
const MAX_DATE = new Date();
const RESOLUTION_DEFAULT_SECONDS = 60;

function getStartAndEndFromRange(range: number, initDate: Date): { start: Date; end: Date } {
  const start = subDays(initDate, range);
  return { start: start, end: initDate };
}

type TimeRangeSelectProps = TimeRangeResult & {
  isDatePickerDisabled?: boolean;
  setResolution: React.Dispatch<React.SetStateAction<number>>;
};

export default function TimeRangeSelect({
  activeRange,
  end,
  initDate,
  setActiveRange,
  setEnd,
  setStart,
  start,
  isDatePickerDisabled,
  setResolution,
}: TimeRangeSelectProps) {
  const initRange = {
    startDate: initDate,
    endDate: initDate,
    key: 'selection',
  } as Range;

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [pickerRange, setPickerRange] = useState(initRange);

  const rangeButtonAction = (activeRange: number) => {
    const { start, end } = getStartAndEndFromRange(activeRange, initDate);
    setActiveRange(activeRange);
    setStart(start);
    setEnd(end);
    setOpenDatePicker(false);
    setResolution(activeRange * RESOLUTION_DEFAULT_SECONDS);
  };

  const customDoneButtonAction = () => {
    const start = pickerRange.startDate ?? startOfToday();
    const end = addDays(pickerRange.endDate ?? startOfToday(), 1);
    const diffInDays = differenceInDays(end, start);

    setActiveRange(0);
    setStart(start);
    setEnd(end);
    setOpenDatePicker(false);
    setResolution(diffInDays * RESOLUTION_DEFAULT_SECONDS);
  };

  const customResetButtonAction = () => {
    setPickerRange(initRange);
    setOpenDatePicker(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        backgroundColor: 'green',
        pointerEvents: isDatePickerDisabled ? 'none' : 'initial',
        opacity: isDatePickerDisabled ? 0.5 : 1,
        filter: isDatePickerDisabled ? 'grayscale(1)' : 'none',
      }}>
      <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', gap: 1, bgcolor: 'background.default' }}>
        <ZoomTimeRangeReset start={start} end={end} />
        <TimeButton active={activeRange === 1} onClick={() => rangeButtonAction(1)} icon={<TodayIcon />} minWidth="85px" text="1 Day" />
        <TimeButton
          active={activeRange === 5}
          onClick={() => rangeButtonAction(5)}
          icon={<CalendarTodayIcon />}
          minWidth="92px"
          text="5 Days"
        />
        <TimeButton
          active={activeRange === 10}
          onClick={() => rangeButtonAction(10)}
          icon={<DateRangeIcon />}
          minWidth="100px"
          text="10 Days"
        />
        <TimeButton
          active={![1, 5, 10].includes(activeRange)}
          onClick={() => {
            setOpenDatePicker(!openDatePicker);
          }}
          icon={<DateRangeIcon />}
          minWidth="100px"
          text="CUSTOM"
        />
      </Paper>
      {openDatePicker ? (
        <Box sx={{ position: 'absolute', right: 0, marginTop: 7, zIndex: 5 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateRange
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              weekStartsOn={1}
              editableDateInputs={true}
              onChange={item => setPickerRange(item.selection)}
              moveRangeOnFirstSelection={false}
              ranges={[pickerRange]}
            />
          </LocalizationProvider>
          <Paper sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', p: 1, pt: 0 }}>
            <Button size="small" variant="outlined" onClick={() => customResetButtonAction()}>
              Reset
            </Button>
            <Button size="small" variant="contained" onClick={() => customDoneButtonAction()}>
              Done
            </Button>
          </Paper>
        </Box>
      ) : null}
    </Box>
  );
}
