import { Typography, Box, Paper } from '@mui/material';
import { useBatteryConfigs } from 'hooks/plant/useBatteryConfig';
import { useParams } from 'react-router-dom';
import { BatteryTable } from './BatteryTable';
import { Overview } from './Overview';
import { useBatteryStats } from 'hooks/plant/useBatteryStats';
import { usePlantInfo } from '../../hooks/plant/usePlantInfo';
import { PlantTabBar } from './PlantTabBar';

const PlantView = () => {
  const { clientId } = useParams();
  const { data: batteries } = useBatteryConfigs(clientId);
  const stats = useBatteryStats(batteries ?? []);
  const { data: plantInfo } = usePlantInfo(clientId);

  const powerSum = stats.reduce(function (prev, a) {
    return prev + (a.availablePower || 0);
  }, 0);

  if (clientId === undefined) {
    return <Typography>No clientId provided</Typography>;
  }

  return (
    <Box>
      <Paper sx={{ position: 'sticky', top: 0, zIndex: theme => theme.zIndex.drawer + 1, backdropFilter: 'blur(12px)' }}>
        <PlantTabBar />
      </Paper>
      <Box sx={{ p: 4, gap: 4, display: 'flex', flexDirection: 'column' }}>
        <Overview plantName={plantInfo?.name} totalAvailablePower={powerSum} batteries={batteries || []} />
        <BatteryTable stats={stats} />
      </Box>
    </Box>
  );
};

export default PlantView;
