import { AddUserToCompanyDto } from 'dtos/companies/add-user-to-company.dto';
import { db } from 'helpers/api';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'context/snackbar/SnackbarContext';

async function addUserToCompany(company_id: string, user_email: string, role: 'user' | 'admin') {
  const dto: AddUserToCompanyDto = {
    email: user_email,
    role: role,
  };
  await db.post(`company/${company_id}/member`, dto);
}

export function useAddUserMutation(company_id: string) {
  const queryClient = useQueryClient();
  const { addSnackbar } = useSnackbar();
  return useMutation(
    ({ user_email, role }: { user_email: string; role: 'user' | 'admin' }) => addUserToCompany(company_id, user_email, role),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['company_members', company_id]);
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'Added User!' });
      },
      onError: () => {
        addSnackbar({ variant: 'error', message: 'Failed to add user...' });
      },
    }
  );
}
