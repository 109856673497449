import React, { useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Link,
  TextField,
} from '@mui/material';
import TitledPaper from './TitledPaper';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { DemoAccessStatus } from '../helpers/demoAccess';

import { db } from 'helpers/api';
import { PrivacyStatement } from './Legal/PrivacyStatement';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const MarketingLabel =
  'I want to receive emails about MTU NautIQ Foresight and related products and feature updates, news, and promotions from MTU NautIQ Foresight.';

function LoginErrorAlert() {
  return (
    <Alert severity="error" variant="filled">
      <AlertTitle>Login Failed</AlertTitle>
      Your link either expired or you already used it before. Please request a new login link.
    </Alert>
  );
}

function KeyErrorAlert() {
  return (
    <Alert severity="error" variant="filled">
      <AlertTitle>Login Failed</AlertTitle>
      There seems to be a problem on our side. Please contact demorequest@io-dock.com or try again later.
    </Alert>
  );
}

function CheckInbox() {
  return (
    <>
      <DialogTitle sx={{ fontSize: '60px', padding: 1, lineHeight: '72px' }}>Check your inbox</DialogTitle>
      <p style={{ fontSize: '16px' }}>
        We'll email you a login that's both unique and temporary. It'll give your account an extra boost of security and save you from
        having to remember <b>another</b> password.
      </p>

      <p style={{ textAlign: 'center', fontSize: '12px' }}>
        Email, what email? First, confirm it didn't sneak into your spam folder. If you can't find it then try re-entering your email
        address. If we had a € for every time we wrote .con instead of .com, we'd have retired by now. &#x1F60E;
      </p>

      <p style={{ textAlign: 'center', fontSize: '12px' }}>
        Nothing's working? The struggle remains very real? Reach out to us at demorequest@io-dock.com and we'll help you out.
      </p>
    </>
  );
}

function SubmitEmail({
  demoAccessStatus,
  setCheckInbox,
  setShowPrivacyStatement,
}: {
  demoAccessStatus: DemoAccessStatus;
  setCheckInbox: (checkInbox: boolean) => void;
  setShowPrivacyStatement: (showPrivacyStatement: boolean) => void;
}) {
  const navigate = useNavigate();
  const error = ['token_expired', 'invalid_token'].includes(demoAccessStatus);
  const keyError = demoAccessStatus === 'key_unavailable';
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    await db.post('demo-access/requestAccess', { email: formData.get('email'), marketing_optin: formData.get('marketing_optin') });
    setCheckInbox(true);
  };

  return (
    <>
      <DialogTitle sx={{ fontSize: '60px', padding: 1, lineHeight: '72px' }}>Get instant access to our demo</DialogTitle>
      {error ? <LoginErrorAlert /> : undefined}
      {keyError ? <KeyErrorAlert /> : undefined}
      <form style={{ display: 'contents' }} method="post" action="" onSubmit={onSubmit} name="demo-access-form">
        <TextField sx={{ width: '100%' }} id="email" label="Work Email" variant="outlined" type="email" name="email" required />
        <span style={{ fontSize: '14px' }}>
          By creating an account, you confirm to have read and acknowledged the{' '}
          <Link onClick={() => setShowPrivacyStatement(true)} underline="none">
            Global Privacy Statement
          </Link>
          . For more information see also our{' '}
          <Link onClick={() => navigate('/impressum')} underline="none">
            Imprint
          </Link>
          .
        </span>
        <FormControl>
          <FormControlLabel
            name="marketing_optin"
            label={<span style={{ fontSize: '14px' }}>{MarketingLabel}</span>}
            control={<Checkbox />}
          />
        </FormControl>
        <Box sx={{ textAlign: 'center' }}>
          <Button sx={{ alignSelf: 'center' }} type="submit" variant="contained">
            Get Access
          </Button>
        </Box>
      </form>
    </>
  );
}

export default function DemoAccessModal({ open, demoAccessStatus }: { open: boolean; demoAccessStatus: DemoAccessStatus }) {
  const [checkInbox, setCheckInbox] = useState(false);
  const [showPrivacyStatement, setShowPrivacyStatement] = useState(false);

  if (demoAccessStatus === 'success') {
    return <> </>;
  }

  if (demoAccessStatus === 'check_in_progress') {
    return (
      <>
        <Dialog open={open} sx={{ backdropFilter: 'blur(16px)' }} BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0)' } }}>
          <TitledPaper title="Instant Access" icon={<MarkEmailReadOutlinedIcon fontSize="small" />}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <DialogTitle sx={{ fontSize: '60px', padding: 1, lineHeight: '72px' }}>Get instant access to our demo</DialogTitle>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            </Box>
          </TitledPaper>
        </Dialog>
      </>
    );
  }

  if (showPrivacyStatement) {
    return (
      <Dialog open={open} sx={{ backdropFilter: 'blur(16px)' }} BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0)' } }}>
        <TitledPaper title="Instant Access" icon={<MarkEmailReadOutlinedIcon fontSize="small" />}>
          <Button
            onClick={() => {
              setShowPrivacyStatement(false);
            }}
            startIcon={<ChevronLeft />}
            variant="outlined">
            Back
          </Button>
          <PrivacyStatement />
        </TitledPaper>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog open={open} sx={{ backdropFilter: 'blur(16px)' }} BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0)' } }}>
        <TitledPaper title="Instant Access" icon={<MarkEmailReadOutlinedIcon fontSize="small" />}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {checkInbox ? (
              <CheckInbox />
            ) : (
              <SubmitEmail
                demoAccessStatus={demoAccessStatus}
                setCheckInbox={setCheckInbox}
                setShowPrivacyStatement={setShowPrivacyStatement}
              />
            )}
          </Box>
        </TitledPaper>
      </Dialog>
    </>
  );
}
