import { Box, Button, Grid, Paper, Skeleton } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import {
  convertToNotificationItem,
  Notification,
  Occurrence,
  useOccurrencesOfNotification,
  useSingleNotification,
} from 'hooks/useNotifications';
import moment from 'moment';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import useClientId from 'context/clientId';
import { useVesselConfig } from 'context/vesselConfig';
import { useCallback, useMemo, useState } from 'react';
import { ChevronLeft, ViewInArRounded } from '@mui/icons-material';
import { formatMomentDuration } from 'helpers/format';
import checkHasEnv, { Env } from '../../helpers/checkHasEnv';

type OccurrenceItem = {
  id: string;
  status: string;
  turnedOnTime?: string;
  ackTime?: string;
  turnedOffTime?: string;
  duration?: number;
  turnedOnRunninghours?: number;
};

type NotificationDetailsProps = {
  clientId: string;
  id?: string;
  navigate?: NavigateFunction;
  isEnergyAlarm?: boolean;
};

const columns: GridColDef[] = [
  {
    field: 'turnedOnTime',
    headerName: 'Turned On Time',
    minWidth: 150,
    flex: 1.5,
    renderCell: cellValues => {
      return formatTimestamp(cellValues.value);
    },
  },
  {
    field: 'ackTime',
    headerName: 'Acknowledged Time',
    minWidth: 150,
    flex: 1.5,
    renderCell: cellValues => {
      return formatTimestamp(cellValues.value);
    },
  },
  {
    field: 'turnedOffTime',
    headerName: 'Turned Off Time',
    minWidth: 150,
    flex: 1.5,
    renderCell: cellValues => {
      return formatTimestamp(cellValues.value);
    },
  },
  {
    field: 'turnedOnRunninghours',
    headerName: 'Running Hours',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'duration',
    renderCell: cellValues => {
      if (cellValues.value === undefined) {
        return '';
      }
      return formatMomentDuration(moment.duration(cellValues.value));
    },
    headerName: 'Duration',
    minWidth: 150,
    flex: 1,
  },
];

export function calcDurationOfOccurrence(occurrence: Occurrence, notification: Notification): number | undefined {
  if (!occurrence.duration && occurrence._id !== notification.latestOccurrence?._id) {
    return undefined;
  }
  if (occurrence.duration) {
    return occurrence.duration;
  }

  const turnedOnTime = moment(occurrence.turnedOnTime);
  const now = moment();
  return now.diff(turnedOnTime);
}

function formatTimestamp(time: string | undefined): string {
  if (!time) {
    return '-';
  }
  return moment(time).format('HH:mm:ss, DD.MM.YYYY');
}

function formatOccurrence(occurrence: Occurrence, notification: Notification): OccurrenceItem {
  const duration = calcDurationOfOccurrence(occurrence, notification);
  return {
    id: occurrence._id,
    status: occurrence.status,
    turnedOnTime: occurrence.turnedOnTime,
    ackTime: occurrence.ackTime,
    turnedOffTime: occurrence.turnedOffTime,
    turnedOnRunninghours: occurrence.turnedOnRunninghours,
    duration,
  };
}

export default function NotificationDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const clientId = useClientId();

  return <NotificationDetails clientId={clientId} id={id} navigate={navigate} />;
}

export function NotificationDetails(props: NotificationDetailsProps) {
  // TODO Should not use nonDismissedOccurrences but all occurrences in the future

  const { id, navigate, clientId, isEnergyAlarm } = props;

  const { notification: rawNotification } = useSingleNotification({ notificationId: id ?? '', clientId });
  const { isLoading, isError, data } = rawNotification;
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [sortingOptions, setSortingOptions] = useState({});
  const { isLoading: isLoadingOccurrences, data: occurrences } = useOccurrencesOfNotification(
    { notificationId: id ?? '', clientId },
    { page: paginationModel.page, pageSize: paginationModel.pageSize, ...sortingOptions }
  );

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortingOptions({
      sortField: sortModel[0]?.field,
      sortOrder: sortModel[0]?.sort,
    });
  }, []);

  const backButton = (
    <Button
      onClick={() => {
        if (navigate) navigate(-1);
      }}
      startIcon={<ChevronLeft />}
      variant="outlined">
      Back
    </Button>
  );

  if (isLoading) {
    return (
      <Box>
        {backButton}
        <Paper sx={{ mt: 2 }}>
          <Skeleton variant="rectangular" height="125px" />
        </Paper>
        <Paper sx={{ mt: 2 }}>
          <Skeleton variant="rectangular" height="60vh" />
        </Paper>
      </Box>
    );
  }

  if (isError || !data) {
    return (
      <Box>
        <h3>Notification not found</h3>
      </Box>
    );
  }

  const formattedRows = occurrences?.map(occurrence => formatOccurrence(occurrence, data));

  return (
    <Box>
      {backButton}
      {isEnergyAlarm ? <EnergyNotificationHeader notification={data} /> : <NotificationHeader notification={data} />}
      <Paper sx={{ height: '60vh', mt: 2 }}>
        <DataGrid
          sx={{ border: 'none' }}
          columns={columns}
          rows={formattedRows ?? []}
          loading={isLoadingOccurrences}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10]}
          rowCount={data.events}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}></DataGrid>
      </Paper>
    </Box>
  );
}

type NotificationHeaderProps = {
  notification: Notification;
};

function NotificationHeader(props: NotificationHeaderProps) {
  const vesselConfig = useVesselConfig();

  const notification = useMemo(() => {
    return convertToNotificationItem(props.notification, Object.values(vesselConfig.engines));
  }, [props.notification, vesselConfig]);

  const useArButton = checkHasEnv(Env.Development, Env.Demo);
  const arButton = useArButton ? (
    <Button variant="outlined" startIcon={<ViewInArRounded />}>
      View in AR
    </Button>
  ) : (
    <div></div>
  );

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Grid container>
        <Grid item xs={8} container direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              width: '10px',
              height: '75%',
              backgroundColor: getPriorityColor(notification.priority),
            }}
          />
          <h2>{notification.alarmTitle}</h2>
        </Grid>
        <Grid item xs={4} container alignItems="center">
          {arButton}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          <Box>Engine Name: {notification.engineName}</Box>
          <Box>Serial Number: {notification.serialNo}</Box>
        </Grid>
        <Grid item xs={4}>
          <Box>Events: {notification.events}</Box>
          <Box>Total Duration: {formatMomentDuration(moment.duration(notification.totalDuration))}</Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

type EnergyNotificationHeaderProps = {
  notification: Notification;
};

function EnergyNotificationHeader(props: EnergyNotificationHeaderProps) {
  const notification = useMemo(() => {
    return convertToNotificationItem(props.notification);
  }, [props.notification]);

  const useArButton = checkHasEnv(Env.Development, Env.Demo);
  const arButton = useArButton ? (
    <Button variant="outlined" startIcon={<ViewInArRounded />}>
      View in AR
    </Button>
  ) : null;

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Grid container>
        <Grid item xs={8} container direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              width: '10px',
              height: '75%',
              backgroundColor: getPriorityColor(notification.priority),
            }}
          />
          <h2>{notification.alarmTitle}</h2>
        </Grid>
        <Grid item xs={4} container alignItems="center">
          {arButton}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          {/* <Box>Group Name: TODO: get group name</Box>*/}
          {/* <Box>Location: TODO: get location</Box>*/}
        </Grid>
        <Grid item xs={4}>
          <Box>Events: {notification.events}</Box>
          <Box>Total Duration: {formatMomentDuration(moment.duration(notification.totalDuration))}</Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export function getPriorityColor(prio: Notification['priority']) {
  switch (prio) {
    case 'critical':
      return 'critical.main';
    case 'warning':
      return 'warn.main';
    case 'success':
      return 'ok.main';
    case 'unknown':
      return 'grey.500';
  }
}
