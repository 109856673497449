import Box from '@mui/material/Box';
import Cruising from './Cruising';

export default function Cockpit() {
  return (
    <Box sx={{ minWidth: '500px', height: '95%' }}>
      <Cruising />
    </Box>
  );
}
