import { APP_BAR_HEIGHT } from '../../helpers/constants';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ClientIdContext } from '../../context/clientId';
import { useVesselConfigQuery, VesselConfigContext } from '../../context/vesselConfig';
import { CenteredNotFoundSection } from '../../components/NotFoundSection';
import { MapPerformance } from './MapPerformance';
import PerformanceGaugeVessel from '../../components/Gauges/PerformanceGauge';
import SpeedIcon from '@mui/icons-material/Speed';
import React from 'react';
import { PerformanceHistory } from './PerformanceHistory';
import { Indicator } from './Indicator';
import TitledPaper from 'components/TitledPaper';
import { useTheme } from '@mui/system';
import NotificationList from 'components/Notification/NotificationList';
import { useIsDarkTheme } from '../../hooks/useThemeMode';

const backgroundBlur = {
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  mx: 0.5,
};

export function BridgeView() {
  const { clientId } = useParams();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const isDarkTheme = useIsDarkTheme();
  const background = isDarkTheme ? '' : 'linear-gradient(180deg, #FBFBFF 18%, rgba(255, 255, 255, 0.3) 30%, rgba(255, 255, 255, 0) 100%)';
  const { config, isVesselLoading, failureCount } = useVesselConfigQuery(clientId ?? '');

  if (clientId === undefined) {
    return <Typography>No clientId provided</Typography>;
  }

  if (failureCount > 0) {
    return <CenteredNotFoundSection />;
  }

  if (isVesselLoading) {
    return <></>;
  }

  return (
    <ClientIdContext.Provider value={clientId}>
      <VesselConfigContext.Provider value={config}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            minHeight: `calc(100vh - (${APP_BAR_HEIGHT}px))`,
          }}>
          <Box sx={{ flexGrow: 1, minWidth: '50%', zIndex: -1, position: 'relative' }}>
            <Box
              sx={{
                zIndex: 99,
                position: 'absolute',
                width: '100%',
                height: '100%',
                background,
              }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 100,
                  mt: 1,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  pointerEvents: 'none',
                  ...backgroundBlur,
                  pb: 0,
                }}>
                <PerformanceGaugeVessel
                  clientId={clientId}
                  icon={<SpeedIcon fontSize="large" />}
                  cursorWidth={20}
                  strokeWidth={15}
                  radius={90}
                  caption="Performance"
                />
              </Box>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                zIndex: 100,
                bottom: 0,
                pointerEvents: 'none',
                ...backgroundBlur,
              }}>
              <PerformanceHistory />
            </Box>
            <MapPerformance />
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flexGrow: 1, p: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Indicator />
            </Box>
            <TitledPaper sx={{ height: 'min-content', justifySelf: 'flex-end', width: '100%' }} title="Notification">
              <NotificationList
                autoHeight
                rowCount={3}
                clientIds={[clientId]}
                rowsPerPageOptions={[]}
                defaultPageSize={3}
                hideColumns={['vesselName', 'engineName', 'serialNo']}
                hideFooter
                density={isSmall ? 'compact' : 'standard'}
                disableColumnControls={true}
              />
            </TitledPaper>
          </Box>
        </Box>
      </VesselConfigContext.Provider>
    </ClientIdContext.Provider>
  );
}
