export default class GaugeCalculator {
  private static readonly CIRCLE_DEGREES = 360;
  private static readonly CIRCLE_OFFSET_DEGREES = 90;

  dashDegrees: number;
  spaceDegrees: number;
  gapTickCount: number;
  value: number;
  max: number;

  constructor(dashDegrees: number, spaceDegrees: number, gapTickCount: number, value: number, max: number) {
    this.dashDegrees = dashDegrees;
    this.spaceDegrees = spaceDegrees;
    this.gapTickCount = gapTickCount;
    this.value = value > max ? max : value;
    this.max = max;
  }

  get degreesPerTick(): number {
    return this.dashDegrees + this.spaceDegrees;
  }

  get backgroundGapDegrees(): number {
    return this.gapTickCount * this.degreesPerTick;
  }

  get backgroundTickDegrees(): number {
    return GaugeCalculator.CIRCLE_DEGREES - this.backgroundGapDegrees;
  }

  get indicatorPercentage(): number {
    return this.value / this.max;
  }

  get indicatorTickDegrees(): number {
    return this.backgroundTickDegrees * this.indicatorPercentage;
  }

  get indicatorGapDegrees(): number {
    return GaugeCalculator.CIRCLE_DEGREES - this.indicatorTickDegrees;
  }

  get indicatorStrokeDashArray(): string {
    return this.getDashArrayAsString(this.indicatorTickDegrees, this.indicatorGapDegrees);
  }

  get backgroundStrokeDashArray(): string {
    return this.getDashArrayAsString(this.backgroundTickDegrees, this.backgroundGapDegrees);
  }

  /** rotation calculation ****/
  get rotationDegrees(): number {
    return GaugeCalculator.CIRCLE_OFFSET_DEGREES + (this.backgroundGapDegrees + this.spaceDegrees) / 2;
  }

  getDashArrayAsString(tickDegrees: number, gapDegrees: number): string {
    const dashes = [];

    let currentSize = 0;
    while (currentSize < tickDegrees) {
      dashes.push(this.dashDegrees);
      dashes.push(this.spaceDegrees);
      currentSize += this.degreesPerTick;
    }

    dashes.push(0);
    dashes.push(gapDegrees);
    return dashes.join(', ');
  }

  get cursorStrokeDashArray(): string {
    const dashes = [];
    dashes.push(0);

    // if true than cursor would fil whole gauge, since dash would be negative. See https://trello.com/c/LTCtXp9D/348-rpm-gauge-displaying-weird-values.
    if (this.indicatorTickDegrees - this.dashDegrees / 2 < 0) {
      dashes.push(this.indicatorGapDegrees - this.dashDegrees);
      dashes.push(this.dashDegrees);
    } else {
      dashes.push(this.indicatorTickDegrees - this.dashDegrees / 2);
      dashes.push(this.dashDegrees);
      dashes.push(this.indicatorGapDegrees - this.dashDegrees / 2);
    }

    return dashes.join(', ');
  }
}
