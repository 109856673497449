import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import HistogramCard from 'components/HistogramCard';
import { useTargetUnits } from 'context/settings';

function useSpeedData(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const qry: TimeseriesSequenceQuery[] = [
    {
      clientId,
      objectId: 'environment',
      objectIdNo: 0,
      pvId: 'waterDepth',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'm',
      targetUnit: units.depth,
    },
  ];

  const [datas, loading, err] = useSequencesData(qry);

  const data = {
    datasets: [
      {
        label: 'Water Depth',
        data: datas[0] ?? [],
        borderColor: color[2],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    title: 'Water Depth Histogram',
    xAxisLabel: `Depth [${units.depth}]`,
  };
  return { data, loading, err, options };
}

function WaterDepthHistogram({ clientId, from, to, resolution, aspectRatio }: ChartProps) {
  const { data, loading, err, options } = useSpeedData(clientId, from, to, resolution);

  return (
    <HistogramCard
      options={options}
      data={data}
      loading={loading}
      err={err}
      aspectRatio={aspectRatio}
      binWidth={1}
      binCount={40}
      minValue={1}
    />
  );
}

export default WaterDepthHistogram;
