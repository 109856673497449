import React, { ReactNode } from 'react';
// import propeller from "assets/vertical_propeller.gif";
import propeller from 'assets/vertical_propeller_nobg.gif';
import { Box, Card, CardContent, CardHeader, Skeleton, Typography } from '@mui/material';
import { byEngineDisplayOrder, useVesselConfig } from 'context/vesselConfig';
import useClientId from 'context/clientId';
import { useLiveDataMap } from 'hooks/useLiveData';
import { grey } from '@mui/material/colors';
import { getMaxVibrationRmsValue } from 'helpers/utils';
import { Quantity, useConvertedQuantity } from 'context/settings';
import { useVesselFeatures } from 'hooks/useVesselFeatures';
import { useAlarmThreshold } from '../../hooks/useAlarmThreshold';

enum AlarmStatus {
  ok = 'ok',
  warning = 'warning',
  critical = 'critical',
}

const statusColor = {
  ok: 'ok.main',
  warning: 'warning.main',
  critical: 'critical.main',
};

const textBackgroundColor = {
  ok: undefined,
  warning: 'warning.light',
  critical: 'critical.light',
};

const statusText = {
  ok: 'Good',
  warning: 'Warning!',
  critical: 'Critical!!',
};

function itemStatus(warningLevel: number, criticalLevel: number, data?: number, invert = false): AlarmStatus {
  let result: AlarmStatus;
  if (invert) {
    result = !data || data > warningLevel ? AlarmStatus.ok : data > criticalLevel ? AlarmStatus.warning : AlarmStatus.critical;
  } else {
    result = !data || data < warningLevel ? AlarmStatus.ok : data < criticalLevel ? AlarmStatus.warning : AlarmStatus.critical;
  }
  return result;
}

function overAllStatus({
  activeAlarmsStatus,
  vibrationStatus,
  oilQualityStatus,
  consumptionStatus,
  vesselProperties,
}: {
  vibrationStatus: AlarmStatus;
  activeAlarmsStatus?: AlarmStatus;
  oilQualityStatus?: AlarmStatus;
  consumptionStatus?: AlarmStatus;
  vesselProperties?: string[];
}) {
  let result: AlarmStatus;
  if (
    (oilQualityStatus === AlarmStatus.critical && vesselProperties?.includes('oilSensor')) ||
    (vibrationStatus === AlarmStatus.critical && vesselProperties?.includes('vibrationSensor')) ||
    consumptionStatus === AlarmStatus.critical ||
    activeAlarmsStatus === AlarmStatus.critical
  ) {
    result = AlarmStatus.critical;
  } else if (
    (oilQualityStatus === AlarmStatus.warning && vesselProperties?.includes('oilSensor')) ||
    (vibrationStatus === AlarmStatus.warning && vesselProperties?.includes('vibrationSensor')) ||
    consumptionStatus === AlarmStatus.warning ||
    activeAlarmsStatus === AlarmStatus.warning
  ) {
    result = AlarmStatus.warning;
  } else {
    result = AlarmStatus.ok;
  }
  return result;
}

type StatusCardProps = {
  status: AlarmStatus;
  name: string | ReactNode;
  sx?: any;
};

const StatusCard: React.FC<StatusCardProps> = ({ status, name, children }) => {
  const subHeaderContent = (
    <Typography
      variant="h6"
      sx={{
        color: statusColor[status],
        fontWeight: 600,
      }}>
      {statusText[status]}
    </Typography>
  );

  return (
    <Card sx={{ height: 'min-content' }}>
      <CardHeader title={name} subheader={subHeaderContent} sx={{ padding: '8px 32px', borderBottom: `1px ${grey[500]} solid` }} />
      <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>{children}</CardContent>
    </Card>
  );
};

function PropellerIcon() {
  return (
    <div
      style={{
        width: '100%',
        height: '213px',
        background: `no-repeat url(${propeller}) top 1px right 50%`,
      }}
    />
  );
}

function Shaft() {
  return (
    <div style={{ height: '100px', width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          height: '100%',
          width: '100%',
          background: `no-repeat url(${propeller}) top 1px right 50%`,
        }}
      />
    </div>
  );
}

export function DataFields({
  name,
  data,
  quantity,
  sourceUnit,
  bgColor,
  places,
}: {
  name: string;
  data?: number;
  quantity?: Quantity;
  sourceUnit?: string;
  bgColor?: string;
  places?: number;
}) {
  places = places ?? 0;
  const [convertedData, unit] = useConvertedQuantity(data, quantity, sourceUnit);
  const value = convertedData?.toFixed(places);

  return (
    <>
      <Typography
        color="text.secondary"
        variant="body2"
        sx={{
          textAlign: 'right',
          backgroundColor: bgColor,
          padding: '1px 8px 1px 8px',
          borderBottom: `1px ${grey[200]} solid`,
        }}>
        {name}:{' '}
      </Typography>
      <Typography
        color="text.secondary"
        variant="body2"
        sx={{
          textAlign: 'right',
          backgroundColor: bgColor,
          padding: '1px 8px 1px 0',
          borderBottom: `1px ${grey[200]} solid`,
        }}>
        {value ?? <Skeleton sx={{ display: 'inline-block' }} variant="text" width="3em" />}
      </Typography>
      <Typography
        color="text.secondary"
        variant="body2"
        sx={{ backgroundColor: bgColor, padding: '1px 8px 1px 0', borderBottom: `1px ${grey[200]} solid` }}>
        {unit}
      </Typography>
    </>
  );
}

function EngineStatus({ engineId }: { engineId: number }) {
  const clientId = useClientId();
  const vesselConfig = useVesselConfig();
  const vesselProperties = useVesselFeatures(clientId);
  const engineConfig = vesselConfig?.engines[engineId];
  const [data] = useLiveDataMap(
    {
      alarmCounter: { source: 'calculated', objectId: 'combustion', pvId: 'activeAlarms' },
      injectionQuantity: { source: 'measurement', objectId: 'combustion', pvId: 'injectionQuantity' },
      oilQuality: { source: 'measurement', objectId: 'combustion', pvId: 'oilQuality' },
      sfoc: { source: 'calculated', objectId: 'combustion', pvId: 'focMassSpecificToPower' },
      sfocError: { source: 'calculated', objectId: 'combustion', pvId: 'sfocPercentageError' },
      vibrationRmsT: { source: 'measurement', objectId: 'combustion', pvId: 'vibrationRms', pvIdNo: 0 },
      vibrationRmsV: { source: 'measurement', objectId: 'combustion', pvId: 'vibrationRms', pvIdNo: 1 },
      vibrationRmsL: { source: 'measurement', objectId: 'combustion', pvId: 'vibrationRms', pvIdNo: 2 },
    },
    { clientId, objectIdNo: engineId }
  );
  const thresholdTopic = { clientId, objectId: 'combustion', objectNo: `${engineId}`, pvId: `vibrationMax` };
  const { data: vibrationThreshold } = useAlarmThreshold(thresholdTopic);

  const vibrationData = getMaxVibrationRmsValue(data?.vibrationRmsT?.value, data?.vibrationRmsV?.value, data?.vibrationRmsL?.value);
  const alarmCounterData = data?.alarmCounter?.value;
  const consumptionData = data?.sfoc?.value;
  const consumptionErrorData = data?.sfocError?.value;
  const oilQualityData = data?.oilQuality?.value;

  // todo need to move limits to vessele data
  const consumptionStatus: AlarmStatus = itemStatus(5, 10, consumptionErrorData);
  const vibrationStatus: AlarmStatus = itemStatus(vibrationThreshold?.warning ?? 25, vibrationThreshold?.critical ?? 30, vibrationData);
  const oilQualityStatus: AlarmStatus = itemStatus(14, 18, oilQualityData);
  const activeAlarmsStatus: AlarmStatus = itemStatus(10, 10, alarmCounterData);

  const status: AlarmStatus = overAllStatus({
    activeAlarmsStatus: consumptionStatus,
    vibrationStatus: vibrationStatus,
    oilQualityStatus: oilQualityStatus,
    consumptionStatus: activeAlarmsStatus,
    vesselProperties: vesselProperties,
  });

  const consumptionColor = textBackgroundColor[consumptionStatus];
  const vibrationColor = textBackgroundColor[vibrationStatus];
  const oilQualityColor = textBackgroundColor[oilQualityStatus];
  const activeAlarmsColor = textBackgroundColor[activeAlarmsStatus];

  const engineName = (
    <>
      <Typography variant="h6" component="span">
        {engineConfig.name}
      </Typography>
      <Typography variant="body1" component="span">
        {' '}
        {engineConfig.serialNo}
      </Typography>
    </>
  );

  return (
    <div>
      <StatusCard name={engineName} status={status}>
        <Box
          sx={{
            display: 'grid',
            width: 'max-content',
            gridTemplateColumns: 'auto min-content auto',
            margin: '8px 12px 16px',
          }}>
          <DataFields
            name="SFOC"
            bgColor={consumptionColor}
            data={consumptionData}
            quantity="massPerEnergyConsumption"
            sourceUnit="g/kWh"
            places={1}
          />
          {vesselProperties.includes('vibrationSensor') && (
            <DataFields name="Vibration" bgColor={vibrationColor} data={vibrationData} quantity="vibrationDistance" sourceUnit="mm/sec" />
          )}
          {vesselProperties.includes('oilSensor') && <DataFields name="Oil Quality" bgColor={oilQualityColor} data={oilQualityData} />}
          <DataFields name="Active Alarms" bgColor={activeAlarmsColor} data={alarmCounterData} />
        </Box>
      </StatusCard>
      <Shaft />
    </div>
  );
}

function GearboxStatus({ gearboxId }: { gearboxId: number }) {
  const clientId = useClientId();
  const vesselProperties = useVesselFeatures(clientId);
  const [data] = useLiveDataMap(
    {
      vibrationRmsT: { source: 'measurement', objectId: 'gearbox', pvId: 'vibrationRms', pvIdNo: 0 },
      vibrationRmsV: { source: 'measurement', objectId: 'gearbox', pvId: 'vibrationRms', pvIdNo: 1 },
      vibrationRmsL: { source: 'measurement', objectId: 'gearbox', pvId: 'vibrationRms', pvIdNo: 2 },
    },
    { clientId, objectIdNo: gearboxId }
  );

  const thresholdTopic = { clientId, objectId: 'gearbox', objectNo: `${gearboxId}`, pvId: `vibrationMax` };
  const { data: vibrationThreshold } = useAlarmThreshold(thresholdTopic);
  const vibrationData = getMaxVibrationRmsValue(data?.vibrationRmsT?.value, data?.vibrationRmsV?.value, data?.vibrationRmsL?.value);

  const vibrationStatus: AlarmStatus = itemStatus(vibrationThreshold?.warning ?? 5.5, vibrationThreshold?.critical ?? 7, vibrationData);

  const status = overAllStatus({ vibrationStatus: vibrationStatus, vesselProperties: vesselProperties });

  const vibrationColor = textBackgroundColor[vibrationStatus];

  return (
    <div>
      <StatusCard name="Gearbox" status={status}>
        <Box
          sx={{
            display: 'grid',
            width: 'max-content',
            gridTemplateColumns: 'auto min-content auto',
            margin: '8px 16px 16px',
          }}>
          {vesselProperties.includes('vibrationSensor') && (
            <DataFields name="Vibration" bgColor={vibrationColor} data={vibrationData} quantity="vibrationDistance" sourceUnit="mm/sec" />
          )}
        </Box>
      </StatusCard>
    </div>
  );
}

function VesselHealth() {
  const vesselConfig = useVesselConfig();
  const engines = Object.entries(vesselConfig.engines).sort(byEngineDisplayOrder);

  return (
    <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(280px, 1fr))" gap={2}>
      {engines.map(([key], i) => (
        <Box key={i}>
          <EngineStatus engineId={Number.parseInt(key)} />
          <GearboxStatus gearboxId={Number.parseInt(key)} />
          <PropellerIcon />
        </Box>
      ))}
    </Box>
  );
}

export default VesselHealth;
