import React from 'react';

import { useVesselConfig } from 'context/vesselConfig';
import type { ClientId } from 'context/clientId';
import { TimeseriesQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';

export enum Bank {
  A = 'A',
  B = 'B',
}

type ChartEngineProps = ChartProps & {
  bank: Bank;
};

function useTCSpeedOverTime(clientId: ClientId, from: Date, to: Date, resolution: number, bank: Bank) {
  const vesselConfig = useVesselConfig();
  const queries: TimeseriesQuery[] = Object.keys(vesselConfig.engines).map(engineNo => ({
    clientId,
    objectId: 'combustion',
    objectIdNo: Number(engineNo),
    pvId: 'tcRpm',
    pvIdNo: bank === 'A' ? 0 : 1,
    resolution,
    from,
    to,
    sourceUnit: 'krpm', // 1000 rpm, but mathjs compatible
    targetUnit: 'krpm',
  }));

  const [datas, loading, err] = useSequencesData(queries);

  const data = {
    datasets: datas.map((data, i) => ({
      label: vesselConfig.engines[i].name,
      data: data ?? [],
      borderColor: color[i],
      borderWidth: 1,
    })),
  };

  const options = { yAxisLabel: 'Rpm [1000/min]', xAxisLabel: 'Time', title: `T/C speed Bank ${bank}` };
  return { data, loading, err, options };
}

function TCSpeedOverTime({ clientId, from, to, resolution, bank }: ChartEngineProps) {
  const { data, loading, err, options } = useTCSpeedOverTime(clientId, from, to, resolution, bank);
  return (
    <div style={{ marginBottom: 8 }}>
      <TimeLineCard options={options} aspectRatio={9} data={data} loading={loading} err={err} />
    </div>
  );
}

export default TCSpeedOverTime;
