import React from 'react';

import { useVesselConfig } from 'context/vesselConfig';
import type { ClientId } from 'context/clientId';
import { TimeseriesQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';

function useEngineSpeedOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const vesselConfig = useVesselConfig();
  const queries: TimeseriesQuery[] = Object.keys(vesselConfig.engines).map(engineNo => ({
    clientId,
    objectId: 'combustion',
    objectIdNo: Number(engineNo),
    pvId: 'rpm',
    pvIdNo: 0,
    resolution,
    from,
    to,
    sourceUnit: 'rpm',
  }));

  const [datas, loading, err] = useSequencesData(queries);

  const data = {
    datasets: datas.map((data, i) => ({
      label: vesselConfig.engines[i].name,
      data: data ?? [],
      borderColor: color[i],
      borderWidth: 1,
    })),
  };

  const options = { yAxisLabel: 'Rpm [1/min]', xAxisLabel: 'Time', title: 'Engine speed' };
  return { data, loading, err, options };
}

function EngineSpeedOverTime({ clientId, from, to, resolution }: ChartProps) {
  const { data, loading, err, options } = useEngineSpeedOverTime(clientId, from, to, resolution);
  return (
    <div style={{ marginBottom: 8 }}>
      <TimeLineCard options={options} aspectRatio={9} data={data} loading={loading} err={err} />
    </div>
  );
}

export default EngineSpeedOverTime;
