import Joi from 'joi';
import { timeseries, db } from '../helpers/api';

import { useQuery } from 'react-query';
import { EmissionConfig } from 'helpers/emissions';

const emissionConfigSchema = Joi.object({
  ciiBoundaries: Joi.object().pattern(Joi.number().integer(), Joi.array().items(Joi.number()).length(4).required()).required(),
  co2ConversionFactor: Joi.number().required(),
  capacity: Joi.number().required(),
  fuelLiquidDensity: Joi.number().required(),
});

async function getEmissionConfig(clientId: string) {
  const { data } = await db.get(`emissions/${clientId}`);
  const validated = Joi.attempt(data, emissionConfigSchema);
  return validated;
}

export function useEmissionConfig(clientId: string) {
  const query = useQuery<EmissionConfig>(['emissionConfig', clientId], () => getEmissionConfig(clientId), {
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  return query;
}

const emissionStatsSchema = Joi.array()
  .items(
    Joi.object({
      time: Joi.string().isoDate().required(),
      liters: Joi.number().allow(null).required(),
      travelledDistance: Joi.number().allow(null).required(),
    })
  )
  .required();

async function getEmissions(clientId: string, from: Date, to: Date) {
  const { data } = await timeseries.post(`/api/emissions/${clientId}/emissions`, {}, { params: { from, to } });
  const validated = Joi.attempt(data, emissionStatsSchema);
  return validated;
}

export function useEmissions(clientId: string) {
  const to = new Date();
  const from = new Date(to.toISOString());
  from.setDate(from.getDate() - 365);
  const query = useQuery(['emissions', clientId], () => getEmissions(clientId, from, to));
  return query;
}
