import { Box, Button, TextField } from '@mui/material';
import { useSnackbar } from 'context/snackbar/SnackbarContext';
import { useForm } from 'react-hook-form';
import { db } from 'helpers/api';

interface CreateCompanyDto {
  name: string;
}

export function CreateCompanyForm() {
  const { addSnackbar } = useSnackbar();
  const {
    register: registerForm,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCompanyDto>();

  const onSubmit = (data: CreateCompanyDto) => {
    db.post('/company', data)
      .then(() => {
        addSnackbar({ variant: 'success', message: 'Created Company!' });
      })
      .catch(e => {
        const message = ['Failed to register...', 'Reason: ', e?.response?.data?.message].join(' ');
        addSnackbar({ variant: 'error', message });
      });
  };
  return (
    <Box>
      <form
        method="post"
        action="#"
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', gap: 24, marginBottom: 24 }}>
        <TextField
          {...registerForm('name', {
            required: {
              value: true,
              message: 'Company Name is required',
            },
          })}
          id="name"
          label="Company Name"
          fullWidth
          variant="standard"
          type="string"
          error={!!errors.name}
          helperText={errors.name?.message}
        />

        <Button type="submit" variant="contained" sx={{ width: '100%' }}>
          Create Company
        </Button>
      </form>
    </Box>
  );
}
