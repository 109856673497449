import { Box, Typography, useTheme } from '@mui/material';
import { usePerformanceHistory } from '../../hooks/usePerformanceHistory';
import useClientId from '../../context/clientId';
import { useCallback, useMemo } from 'react';
import { RatingDisplayColumn } from './RatingDisplayColumn';

export function dateRangeIncludesDate(currentTime: Date, stride: number, offset: number, timestamp: Date) {
  const start = new Date(currentTime.getTime() - stride * (offset + 1));
  const end = new Date(currentTime.getTime() - stride * offset);
  return timestamp >= start && timestamp < end;
}

export function extendToValuesByMinute(data: { time: string; value: number }[]) {
  const now = new Date();
  return Array.from({ length: 30 }, (_, i) => {
    return data.find(el => {
      return dateRangeIncludesDate(now, 60 * 1000, i, new Date(el.time));
    })?.value;
  });
}

export function averageIfNotEmpty(data: number[]) {
  const filtered = data.filter(el => el !== undefined);
  if (filtered.length === 0) return undefined;
  return filtered.reduce((acc, el) => acc + el, 0) / filtered.length;
}

export function aggregateToTenMinuteSteps(data: { time: string; value: number }[]) {
  const now = new Date();
  return Array.from({ length: 3 }, (_, i) => {
    return averageIfNotEmpty(
      data
        .filter(el => {
          return dateRangeIncludesDate(now, 10 * 60 * 1000, i, new Date(el.time));
        })
        .map(el => el.value)
    );
  });
}

export function PerformanceHistory() {
  const clientId = useClientId();
  const theme = useTheme();
  const { data } = usePerformanceHistory({ clientId, aggregationSec: 60, minSpeed: 5 }, { refetchInterval: 60 * 1000 });

  const performanceByMinute = useMemo(() => {
    return extendToValuesByMinute(data ?? []);
  }, [data]);

  const performanceByTenMinuteSteps = useMemo(() => {
    return aggregateToTenMinuteSteps(data ?? []);
  }, [data]);

  const performanceToColor = useCallback(
    (performance?: number) => {
      if (performance === undefined) return 'rgba(0, 0, 0, 0)';
      const colorIndex = Math.floor(performance / 2.5);
      if (colorIndex >= 3) return theme.palette.ok.main;
      if (colorIndex === 2) return theme.palette.caution.main;
      if (colorIndex === 1) return theme.palette.warn.main;
      return theme.palette.critical.main;
    },
    [theme]
  );

  return (
    <Box>
      <Box sx={{ position: 'relative', px: 12, mt: 2 }}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
            width: '0.5em',
            height: '60vh',
          }}>
          {performanceByMinute.map((el, index) => (
            <Box
              key={index}
              sx={{
                position: 'absolute',
                top: `${(index / performanceByMinute.length) * 100}%`,
                backgroundColor: performanceToColor(el),
                width: '0.5em',
                height: `${100 / performanceByMinute.length}%`,
                transform: 'translateX(0.5em)',
              }}
            />
          ))}
        </Box>
        {Array.from({ length: 4 }).map((el, i) => (
          <Box
            key={i}
            sx={{
              position: 'absolute',
              top: `${5 + 30 * i}%`,
              transform: 'translate(calc(-50% + 0.25em), -50%)',
              backgroundColor: 'background.default',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
              width: '3em',
              height: '0.5em',
            }}
          />
        ))}
        {performanceByTenMinuteSteps.map((cp, i) => (
          <Box
            key={i}
            sx={{
              position: 'absolute',
              top: `${20 + 30 * i}%`,
              transform: 'translate(-100%, -50%)',
              width: 'auto',
              pr: '1.5em',
            }}>
            <RatingDisplayColumn value={cp} ratingColour={performanceToColor(cp)} />
            <Typography sx={{ minWidth: '3.5em', textAlign: 'center' }}>{(i + 1) * 10} min. ago</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
