import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useZoomTimeRangeContext } from 'context/zoom/ZoomTimeRangeContext';

import { Alert, Card, CardMedia, Skeleton } from '@mui/material';

import ChartJS from 'chart.js/auto';
import { Scatter } from 'react-chartjs-2';
import AspectRatioBox from 'components/AspectRatioBox';
import { AxisOptions } from 'context/baselineConfig';

ChartJS.register([]);

type SimpleScatterCardProps = {
  name?: string;
  data: any;
  loading: boolean;
  err?: string;
  aspectRatio?: string | number;
  options?: AxisOptions;
};

function buildOptions(axis: AxisOptions, title?: string): any {
  return {
    animations: false as any,
    maintainAspectRatio: false,
    responsive: process.env.NODE_ENV !== 'test',
    mode: 'nearest',
    elements: {
      point: { radius: 2 },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      xAxes: {
        min: axis.xMin,
        max: axis.xMax,
        suggestedMin: axis.xSuggestedMin,
        suggestedMax: axis.xSuggestedMax,
        beginAtZero: false,
        title: {
          display: true,
          text: axis.xLabel,
        },
      },
      yAxes: {
        min: axis.yMin,
        max: axis.yMax,
        suggestedMin: axis.ySuggestedMin,
        suggestedMax: axis.ySuggestedMax,
        beginAtZero: false,
        title: {
          display: true,
          text: axis.yLabel,
        },
      },
    },
    plugins: {
      title: {
        display: title ? true : false,
        text: title,
      },
      legend: { display: true, align: 'center', position: 'top' },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = '(';

            const precision = 2;
            if (context.parsed.x) {
              label += Math.round(context.parsed.x * 10 ** precision) / 10 ** precision;
            }
            label += ', ';
            if (context.parsed.y) {
              label += Math.round(context.parsed.y * 10 ** precision) / 10 ** precision;
            }
            label += ')';
            return label;
          },
        },
      },
    },
  };
}

function SimpleScatterCard({ name, data, loading, err, options = {}, aspectRatio = 7 }: SimpleScatterCardProps) {
  const chartRef = useRef<ChartJS<'scatter', number[], string>>(null);
  const { zoomRange } = useZoomTimeRangeContext();
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    const { current: chart } = chartRef;
    if (zoomRange) {
      const tmp = JSON.parse(JSON.stringify(data));
      for (const item of tmp.datasets) {
        item.data = item.data.filter((obj: any) => {
          return obj.tsp >= zoomRange.min && obj.tsp <= zoomRange.max;
        });
      }
      setFilteredData(tmp);
    }
    chart?.render();
  }, [zoomRange, data]);

  const chartOptions = useMemo(() => {
    const opt = buildOptions(options, name);
    return opt;
  }, [name, options]);

  return (
    <Card>
      <CardMedia component="div">
        <AspectRatioBox aspectRatio={aspectRatio} minHeight="200px">
          {loading || err ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <Scatter ref={chartRef} data={zoomRange && filteredData ? filteredData : data} options={chartOptions} />
          )}
        </AspectRatioBox>
      </CardMedia>
      {err ? <Alert severity="error">{err}</Alert> : null}
    </Card>
  );
}

export default SimpleScatterCard;
