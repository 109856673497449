import { MenuItem, TextField } from '@mui/material';
import { useVesselConfig } from 'context/vesselConfig';

interface EnginePickerProps {
  engine?: number;
  setEngine: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const EnginePicker: React.FC<EnginePickerProps> = ({ engine, setEngine, disabled }) => {
  const vesselconfig = useVesselConfig();
  return (
    <TextField
      variant="standard"
      size="small"
      label="Engine"
      value={engine ?? ''}
      inputProps={{
        name: 'level',
      }}
      onChange={setEngine}
      sx={{
        mb: 1,
      }}
      disabled={disabled}
      required
      fullWidth
      select>
      {Object.entries(vesselconfig.engines).map(([k, engine]) => (
        <MenuItem key={k} value={k}>
          {engine.name} {engine.serialNo}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default EnginePicker;
