import { Skeleton } from '@mui/material';

type SkeletonOnUndefinedProps = {
  value: any;
  children: React.ReactNode;
};
export default function SkeletonOnUndefined({ value, children }: SkeletonOnUndefinedProps) {
  return value === undefined ? (
    <Skeleton variant="text" width="100%" height="100%" sx={{ display: 'flex', alignItems: 'center' }} />
  ) : (
    <>{children}</>
  );
}
