import { ReactChild } from 'react';

import { Box, Theme } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import NorthEastIcon from '@mui/icons-material/NorthEast';
import EastIcon from '@mui/icons-material/East';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import { useTheme } from '@mui/system';

import { RatingDisplay } from './RatingDisplay';
import TitledPaper from '../../components/TitledPaper';
import Color from 'color';
import { useFleetConfig } from 'hooks/fleetConfig';
import { usePerformanceLiveDataFleet } from '../../hooks/usePerformanceLiveData';
import { useHealthLiveDataFleet } from '../../hooks/useHealthLiveData';
import { getRatingColor } from 'helpers/getRatingColor';

type Trend = 'up' | 'down' | 'stable';

const trendIconTable: { [key in Trend]: ReactChild } = {
  up: <NorthEastIcon />,
  stable: <EastIcon />,
  down: <SouthEastIcon />,
};

const trendColorTable: { [key in Trend]: string } = {
  up: 'ok.main',
  stable: 'warning.main',
  down: 'critical.main',
};

type TrendDisplayProps = {
  trend: Trend;
};

function TrendDisplay({ trend }: TrendDisplayProps) {
  const trendIcon = trendIconTable[trend];
  const trendColor = trendColorTable[trend];
  return <Box sx={{ color: trendColor }}>{trendIcon}</Box>;
}

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width: 90 },
  { field: 'name', headerName: 'Name', width: 130 },
  {
    field: 'performance',
    headerName: 'Performance',
    width: 110,
    align: 'center',
    headerAlign: 'center',
    renderCell: cellValue => <RatingDisplay icon={<SpeedIcon />} value={cellValue.value} ratingColour={getRatingColor(cellValue.value)} />,
  },
  {
    field: 'health',
    headerName: 'Health',
    width: 90,
    align: 'center',
    headerAlign: 'center',
    renderCell: cellValue => (
      <RatingDisplay icon={<HealthAndSafetyOutlinedIcon />} value={cellValue.value} ratingColour={getRatingColor(cellValue.value)} />
    ),
  },
  {
    field: 'trend',
    headerName: 'Trend',
    width: 64,
    align: 'center',
    renderCell: cellValue => <TrendDisplay trend={cellValue.value} />,
  },
];

export function Fleet() {
  const navigate = useNavigate();
  const theme = useTheme<Theme>();
  const fleetConfig = useFleetConfig();

  const performanceData = usePerformanceLiveDataFleet();
  const healthData = useHealthLiveDataFleet();

  const rows = fleetConfig.map(vessel => ({
    id: vessel.clientId,
    name: vessel.name,
    performance: performanceData[vessel.clientId]?.toFixed(1),
    health: healthData[vessel?.clientId]?.toFixed(1),
    trend: 'up',
  }));

  const onRowClick: GridEventListener<'rowClick'> = params => {
    navigate(`/vessel/${params.row.id}`);
  };

  return (
    <TitledPaper
      sx={{ height: '100%', bgcolor: Color(theme.palette.background.default).alpha(0.5).toString(), backdropFilter: 'blur(10px)' }}
      title="Fleet"
      icon={<HealthAndSafetyOutlinedIcon />}
      noPadding>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: columns.map(col => col.width).reduce((v: number, acc) => v + (acc ?? 0), 0) }}>
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
              pagination: {
                paginationModel: { pageSize: 5 },
              },
            }}
            sx={{ border: 'none' }}
            classes={{
              row: 'clickable-row',
            }}
            rows={rows}
            pageSizeOptions={[5]}
            columns={columns}
            onRowClick={onRowClick}
            autoHeight
          />
        </Box>
      </Box>
    </TitledPaper>
  );
}
