import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { Quantity, useConvertedQuantity } from 'context/settings';
import SpeedIcon from '@mui/icons-material/Speed';

type RatingDisplayProps = {
  value?: number;
  quantity?: Quantity;
  sourceUnit?: string;
  ratingColour?: string;
};

function truncValue(value?: number | string): number | string | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (typeof value === 'number') {
    return Math.round(value);
  }
  return value;
}

export function RatingDisplayColumn({ value, quantity, sourceUnit, ratingColour = 'ok.main' }: RatingDisplayProps) {
  const [convertedValue, unit] = useConvertedQuantity(value, quantity, sourceUnit);
  if (value === undefined) {
    return (
      <Box
        component="span"
        sx={{
          display: 'grid',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Skeleton sx={{ width: 24 }}></Skeleton>
      </Box>
    );
  }

  return (
    <Box
      component="span"
      sx={{
        display: 'grid',
        gridTemplateAreas: '"icon" "text"',
        alignItems: 'center',
        color: ratingColour,
      }}>
      <Box
        sx={{
          gridArea: 'icon',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <SpeedIcon fontSize="large" />
      </Box>
      <Box sx={{ gridArea: 'text', display: 'flex', justifyContent: 'center', fontSize: '44px', fontWeight: 600, lineHeight: 1 }}>
        {truncValue(convertedValue)} {unit}
      </Box>
    </Box>
  );
}
