import React, { ReactChild, ReactFragment, ReactPortal } from 'react';
import { Box, Skeleton } from '@mui/material';
import { Quantity, useConvertedQuantity } from 'context/settings';

type RatingDisplayProps = {
  icon?: boolean | ReactFragment | ReactPortal | ReactChild | null;
  value?: number;
  quantity?: Quantity;
  sourceUnit?: string;
  ratingColour?: string;
};

function truncValue(value?: number | string): number | string | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (typeof value === 'number') {
    return Math.round(value * 100) / 100;
  }
  return value;
}

export function RatingDisplay({ icon, value, quantity, sourceUnit, ratingColour = 'ok.main' }: RatingDisplayProps) {
  const [convertedValue, unit] = useConvertedQuantity(value, quantity, sourceUnit);
  if (value === undefined) {
    return <Skeleton sx={{ width: 24 }}></Skeleton>;
  }

  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 0.5,
        color: ratingColour,
      }}>
      {icon}
      <Box sx={{ minWidth: '3.5em' }}>
        {truncValue(convertedValue)} {unit}
      </Box>
    </Box>
  );
}
