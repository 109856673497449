import { Box } from '@mui/material';

export type Pos = number | `${number}%`;
export type ProgressBar3DProps = {
  left: Pos;
  right: Pos;
  bgColor?: string;
  barColor: string;
  perspective?: string;
};

const Box3D: React.FC<{ backgroundColor: string }> = ({ children, backgroundColor }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        backfaceVisibility: 'visible',
        transformStyle: 'preserve-3d',
      }}>
      <Box
        sx={{
          position: 'absolute',
          transform: 'rotateX(-90deg)',
          transformOrigin: 'top',
          left: 0,
          right: 0,
          top: '0%',
          width: '100%',
          backgroundColor,
          height: '100%',
        }}></Box>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          width: '100%',
          backgroundColor,
          height: '100%',
        }}></Box>
      <Box
        sx={{
          transform: 'rotateX(90deg) translateY(-100%) rotateX(90deg)',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          backgroundColor,
          height: '100%',
        }}></Box>
      <Box
        sx={{
          position: 'absolute',
          transform: 'rotateX(90deg)',
          transformOrigin: 'bottom',
          left: 0,
          right: 0,
          top: 0,
          width: '100%',
          backgroundColor,
          height: '100%',
          boxShadow: `0 0.1em 20px 8px ${backgroundColor}, 0.6em -0.5em 3em ${backgroundColor}`,
        }}></Box>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          height: '100%',
          aspectRatio: '1 / 1',
          backgroundColor: backgroundColor,
          transformOrigin: 'left',
          transform: 'rotateY(90deg)',
        }}></Box>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          height: '100%',
          aspectRatio: '1 / 1',
          backgroundColor: backgroundColor,
          transformOrigin: 'right',
          transform: 'rotateY(-90deg)',
        }}></Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          transformStyle: 'preserve-3d',
          backfaceVisibility: 'visible',
        }}>
        {children}
      </Box>
    </Box>
  );
};

export function ProgressBar3D({ left, right, bgColor = 'rgba(222,222,222, 0.2)', barColor, perspective = '400px' }: ProgressBar3DProps) {
  const backgroundColor = bgColor;
  const backgroundColorInner = barColor;
  return (
    <Box
      sx={{
        perspective,
        perspectiveOrigin: '50% 0%',
        backfaceVisibility: 'visible',
        width: '100%',
        height: '100%',
        transform: 'rotateX(-20deg) rotateY(0deg) rotateZ(0deg)',
        transformOrigin: 'center',
        transformStyle: 'preserve-3d',
      }}>
      <Box3D backgroundColor={backgroundColor}>
        <Box
          sx={{
            position: 'absolute',
            left: left,
            right: right,
            height: '100%',
            transformStyle: 'preserve-3d',
            transition: 'all 0.5s ease',
            backfaceVisibility: 'visible',
          }}>
          <Box3D backgroundColor={backgroundColorInner}></Box3D>
        </Box>
      </Box3D>
    </Box>
  );
}
