import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { APP_BAR_HEIGHT, EMAIL_REGEX } from '../../../helpers/constants';
import { useForm } from 'react-hook-form';
import { db } from '../../../helpers/api';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../context/snackbar/SnackbarContext';

interface PasswordResetRequestValues {
  email: string;
}

export function RequestPasswordReset() {
  const {
    register: requestPasswordResetForm,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetRequestValues>();
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbar();

  const onSubmit = (data: PasswordResetRequestValues) => {
    db.post('/users/request-password-reset', data)
      .then(data => {
        navigate('/check-inbox-to-reset');
        return data;
      })
      .catch(_e => {
        const message = 'A problem occurred while trying to reset your password. Please contact your company admin.';
        addSnackbar({ variant: 'error', message });
      });
  };

  return (
    <Container maxWidth="sm">
      <Box minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="h4"
          sx={{
            mb: 3,
            fontWeight: 'bold',
          }}>
          Request Password Reset
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Enter your email address and we will send you a link to reset your password.
        </Typography>
        <Box>
          <form
            method="post"
            action="#"
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', flexDirection: 'column', gap: 24, marginBottom: 24 }}>
            <TextField
              {...requestPasswordResetForm('email', {
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email',
                },
              })}
              id="email"
              label="Email"
              fullWidth
              variant="standard"
              type="string"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <Button type="submit" variant="contained" sx={{ width: '100%' }}>
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
}
