import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { APP_BAR_HEIGHT } from 'helpers/constants';

export function EmailVerified() {
  return (
    <Container maxWidth="sm">
      <Box minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex" flexDirection="row" alignItems="baseline" gap="1em">
          <MarkEmailReadIcon fontSize="large" />
          <Box display="flex" flexDirection="column">
            <Typography
              variant="h4"
              sx={{
                mb: 3,
                fontWeight: 'bold',
              }}>
              Email verified
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 3,
              }}>
              Congratulations! Your email has been successfully verified. To complete your registration,{' '}
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                you need to contact the company admin and provide them with your registered email address.
              </Box>
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Thank you for choosing our platform, and we are excited to have you onboard!
            </Typography>
            <Link to="/login">Back to login</Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
