import { useLiveData, DatumString } from './useLiveData';
import { useFleetConfig } from './fleetConfig';

export function useHealthLiveDataFleet(): { [clientId: string]: number | undefined } {
  const fleetConfig = useFleetConfig();

  const healthTopics: { [key: string]: DatumString } = {};
  fleetConfig
    .map(vessel => vessel.clientId)
    .forEach(clientId => {
      healthTopics[clientId] = `ioconnect/calculated/${clientId}/health/0/vessel/0` as DatumString;
    });

  const [data] = useLiveData(Object.values(healthTopics));

  Object.entries(healthTopics);

  const result: { [clientId: string]: number | undefined } = {};
  Object.entries(healthTopics).forEach(([clientId, topic]) => {
    result[clientId] = calculateHealthData(data?.[topic]?.value);
  });

  return result;
}

function calculateHealthData(vesselHealthValue: number | undefined): number | undefined {
  if (Number.isNaN(vesselHealthValue) || vesselHealthValue === undefined || vesselHealthValue === null) {
    return;
  }

  const bounded = (vesselHealthValue < 0 ? 0 : vesselHealthValue > 1 ? 1 : vesselHealthValue) * 10;
  return 10 - bounded;
}
