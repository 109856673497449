import { useAuth } from '../useAuth';
import { useMyCompanies } from './useMyCompanies';

type ManagementPermission = 'loading' | 'permitted' | 'denied' | 'unauthenticated';

export function useHasPermissionToManageCompany(company_id: string): ManagementPermission {
  const { user, isAuthenticated, isLoading: isLoadingAuth } = useAuth();
  const { data: memberships, isLoading: isLoadingMyCompanies } = useMyCompanies();

  if (isLoadingAuth) return 'loading';
  if (!isAuthenticated) return 'unauthenticated';
  if (user?.isSuperUser) return 'permitted';
  if (isLoadingMyCompanies) return 'loading';

  return memberships?.some(m => m.company_id === company_id && m.role === 'admin') ? 'permitted' : 'denied';
}
