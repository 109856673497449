import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { addDataToScatterMap, ChartProps, color, dataSeriesFromScatterMap } from '.';
import { AxisOptions } from '../../../context/baselineConfig';
import ScatteredBaselineCard from './ScatteredBaselineCard';
import { ChartData } from 'chart.js';
import { ScatterDataPoint } from 'chart.js/auto';
import { useBaselineThresholds } from 'hooks/useBaseline';
import { useTargetUnits } from 'context/settings';

function useSfocOverLoad(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();

  // TODO fix family
  const { data: baselineThresholds, error: baselineErr } = useBaselineThresholds(
    { clientId, familyKey: 'speed_consumption' },
    {
      xSourceUnit: 'kn',
      xTargetUnit: units.vesselSpeed,
      ySourceUnit: 'kg/h',
      yTargetUnit: units.massConsumption,
    }
  );

  const queries: TimeseriesSequenceQuery[] = [
    {
      module: 'calculated',
      objectId: 'total',
      objectIdNo: 0,
      pvId: 'focMass',
      pvIdNo: 0,
      clientId,
      resolution,
      from,
      to,
      sourceUnit: 'kg/h',
      targetUnit: units.massConsumption,
    },
    {
      module: 'measurement',
      objectId: 'speed',
      objectIdNo: 0,
      pvId: 'sog',
      pvIdNo: 0,
      clientId,
      resolution,
      from,
      to,
      sourceUnit: 'kn',
      targetUnit: units.vesselSpeed,
    },
  ];

  const [queryData, loading, err] = useSequencesData(queries);
  const CONSUMPTION = 'CONSUMPTION' as const;
  const SPEED = 'SPEED' as const;

  const map = new Map();
  addDataToScatterMap(map, CONSUMPTION, queryData[0] ?? []);
  addDataToScatterMap(map, SPEED, queryData[1] ?? []);

  const chartData: ChartData<any, ScatterDataPoint[], any> = {
    datasets: [
      {
        label: `Fuel Consumption`,
        data: dataSeriesFromScatterMap(map, SPEED, CONSUMPTION),
        type: 'scatter',
        borderColor: color[0],
        borderWidth: 0.5, //todo define in theme
        fill: false,
        radius: 2, //todo define in theme
      },
    ],
  };

  const config: AxisOptions = {
    // xMin: 0,
    // xMax: 40,
    xLabel: `SOG [${units.vesselSpeed}]`,
    yLabel: `Consumption [${units.massConsumption}]`,
  };

  return {
    data: chartData,
    loading,
    err,
    chartConfig: { axes: config },
    baselineThresholds,
    baselineErr,
  };
}

/*
const baselineGroup: BaselineCardProps = {
  baseline: [
    { x: 5, y: 225 },
    { x: 10, y: 250 },
    { x: 15, y: 350 },
    { x: 20, y: 500 },
    { x: 25, y: 750 },
    { x: 30, y: 1100 },
    { x: 35, y: 1600 },
  ],
  threshold: {
    warnMin: -5,
    warnMax: 5,
    alarmMin: -10,
    alarmMax: 10,
  },
};
*/

function ConsumptionOverSog({ clientId, from, to, resolution, aspectRatio }: ChartProps) {
  const { data, loading, err, chartConfig, baselineThresholds, baselineErr } = useSfocOverLoad(clientId, from, to, resolution);

  return (
    <ScatteredBaselineCard
      name="Fuel Consumption / Speed"
      data={data}
      loading={loading}
      err={err}
      options={chartConfig?.axes}
      baselineGroup={baselineErr ? undefined : baselineThresholds}
      aspectRatio={aspectRatio}
    />
  );
}

export default ConsumptionOverSog;
