import React from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { db } from 'helpers/api';
import { useSnackbar } from 'context/snackbar/SnackbarContext';

type FeedbackDialogProps = {
  open: boolean;
  onClose?: () => void;
};

function FeedbackDialog({ open, onClose }: FeedbackDialogProps) {
  const { addSnackbar } = useSnackbar();
  const location = useLocation();
  const { user } = useAuth();
  const today = new Date().toISOString().substr(0, 10);
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const params = new URLSearchParams(formData as any);
    db.post('/feedback', params)
      .then(data => {
        addSnackbar({ variant: 'success', message: 'Feedback sent!' });
        if (onClose) {
          onClose();
        }
        return data;
      })
      .catch(e => {
        const message = ['Failed to submit feedback...', e.message, JSON.stringify(e.response.data)].join(', ');
        addSnackbar({ variant: 'error', message });
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form method="post" action="" onSubmit={onSubmit}>
        <DialogTitle>We want your Feedback</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', gap: 1, marginTop: 1 }}>
            <TextField
              InputProps={{ readOnly: user !== null }}
              aria-readonly
              margin="dense"
              name="userName"
              id="user"
              label="Name"
              type="string"
              variant="standard"
              defaultValue={user?.firstName && user.lastName && `${user.firstName} ${user.lastName}`}
              required
            />
            <TextField
              InputProps={{ readOnly: true }}
              aria-readonly
              margin="dense"
              name="sitePath"
              id="site"
              label="Site"
              type="string"
              variant="standard"
              defaultValue={location.pathname}
            />
            <TextField
              disabled
              name="date"
              margin="dense"
              id="date"
              label="Date"
              type="date"
              variant="standard"
              defaultValue={today}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <TextField
            required
            autoFocus
            margin="normal"
            name="subject"
            id="subject"
            label="Subject"
            type="string"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            multiline
            name="feedbackText"
            margin="normal"
            id="feedback"
            label="Feedback"
            placeholder="Type your feedback here..."
            type="string"
            fullWidth
            minRows={4}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Send Feedback</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FeedbackDialog;
