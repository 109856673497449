import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { getEnv, printEnv } from './helpers/environment'; // theme css file

async function init() {
  printEnv();

  if (process.env.NODE_ENV === 'development' && getEnv('REACT_APP_USE_MOCKS')) {
    const { worker } = await import('./mocks/browser');
    worker.start({
      quiet: false,
      onUnhandledRequest(req, print) {
        // specify routes to exclude
        const excludedHosts = ['api.mapbox.com', 'events.mapbox.com'];
        const excludedRoutes = ['favicon.ico', 'manifest.json', 'logo192.png'];
        // check if the req.url.pathname contains excludedRoutes
        const isExcludedHost = excludedHosts.some(host => req.url.hostname === host);
        const isExcludedRoute = excludedRoutes.some(route => req.url.pathname.includes(route));
        if (isExcludedRoute || isExcludedHost) {
          return;
        }
        print.warning();
      },
    });
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
