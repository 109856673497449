import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import NotificationList from './NotificationList';
import { EngineConfig, useMultiEngineConfigs } from 'hooks/useEngineConfig';
import { useNotifications } from '../../hooks/useNotifications';
import { Vessel } from 'hooks/fleetConfig';

type ActiveInactiveNotificationListProps = {
  clientIds: string[];
  hideColumns?: string[];
  vesselInfos?: Vessel[];
};

export default function ActiveInactiveNotificationList(props: ActiveInactiveNotificationListProps) {
  const activeNotifications = useNotifications({ clientIds: props.clientIds, status: ['TURNED_ON'] });

  const engineConfigs = useMultiEngineConfigs(props.clientIds);
  const engineConfigsData = engineConfigs
    .map(e => e.data)
    .flat()
    .filter(e => e !== undefined) as EngineConfig[];

  return (
    <>
      <Box gap={1}>
        <Typography
          sx={{
            p: 1,
            ...(activeNotifications.data && activeNotifications.data.length > 0 && { color: 'primary.main' }),
          }}>
          ACTIVE ALARMS ({activeNotifications.data?.length})
        </Typography>
        <NotificationList
          clientIds={props.clientIds}
          status={['TURNED_ON', 'ACK']}
          autoHeight
          defaultPageSize={5}
          followNotificationOnClick
          hideColumns={props.hideColumns}
          vesselInfos={props.vesselInfos}
          engines={engineConfigsData}
        />
      </Box>
      <Box gap={1}>
        <Typography sx={{ p: 1 }}>INACTIVE ALARMS</Typography>
        <NotificationList
          clientIds={props.clientIds}
          status={['TURNED_OFF']}
          autoHeight
          defaultPageSize={5}
          followNotificationOnClick
          hideColumns={props.hideColumns}
          vesselInfos={props.vesselInfos}
          engines={engineConfigsData}
        />
      </Box>
    </>
  );
}
