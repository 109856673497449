import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';
import { useTargetUnits } from 'context/settings';

function useSpeedOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const queries: TimeseriesSequenceQuery[] = [
    {
      clientId,
      objectId: 'speed',
      objectIdNo: 0,
      pvId: 'sog',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'kn',
      targetUnit: units.vesselSpeed,
    },
    {
      clientId,
      objectId: 'speed',
      objectIdNo: 0,
      pvId: 'stw',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'kn',
      targetUnit: units.vesselSpeed,
    },
  ];

  const [timeseriesData, loading, err] = useSequencesData(queries);

  const data = {
    datasets: [
      {
        label: 'SOG',
        data: timeseriesData[0] ?? [],
        borderColor: color[4],
        borderWidth: 1,
      },
      {
        label: 'STW',
        data: timeseriesData[1] ?? [],
        borderColor: color[5],
        borderWidth: 1,
      },
    ],
  };

  const options = { yAxisLabel: `Speed [${units.vesselSpeed}]`, minYAxisValue: 0, title: 'Vessel Speed', xAxisLabel: 'Time' };
  return { data, loading, err, options };
}

function FocOverTime({ clientId, from, to, resolution, aspectRatio }: ChartProps) {
  const { data, loading, err, options } = useSpeedOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={options} data={data} loading={loading} err={err} aspectRatio={aspectRatio} />;
}

export default FocOverTime;
