export const chartColors = {
  main: ['#a499e2', '#303bb5', '#319caa', '#3e7548', '#cca566', '#cc667c'],
  secondary: ['#ccc5f1', '#b2b6e8', '#cae9ed', '#3e7548', '#3e7548', '#3e7548'],
  baseline: ['#7A871E', '#f9e26e'],
  baselineGroup: {
    baseline: '#96a816',
    warn: '#b98d53',
    alarm: '#fd8b60',
  },
};
