import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { getPriorityColor } from 'app/Notifications/NotificationDetails';
import { formatMomentDuration } from 'helpers/format';
import { convertToNotificationItem, NotificationFilter, NotificationItem, useNotifications } from 'hooks/useNotifications';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const initialColumns: GridColDef[] = [
  {
    field: 'priority',
    headerName: '',
    width: 10,
    renderCell: param => {
      return (
        <Box
          sx={{
            width: '10px',
            height: '100%',
            backgroundColor: getPriorityColor(param.value),
            transform: 'translateX(-10px)',
          }}
        />
      );
    },
  },
  {
    field: 'alarmTitle',
    headerName: 'Alarm Title',
    flex: 1,
  },
  {
    field: 'alarmDescription',
    headerName: 'Alarm Description',
    flex: 1,
  },
  {
    field: 'latestOccurrenceTime',
    headerName: 'Latest Occurrence',
    minWidth: 200,
    flex: 1,
    renderCell: cellValues => {
      return moment(cellValues.value).format('HH:mm:ss, DD.MM.YYYY');
    },
  },
  {
    field: 'events',
    headerName: 'Events',
    minWidth: 100,
    flex: 0.5,
  },
  {
    field: 'totalDuration',
    headerName: 'Total Duration',
    minWidth: 150,
    flex: 0.5,
    renderCell: cellValues => {
      return formatMomentDuration(moment.duration(cellValues.value));
    },
  },
];

type AlarmListProps = {
  clientId: string;
  status?: NotificationFilter['status'];
  topic?: string;
  defaultPageSize: number;
};
export function AlarmList({ clientId, status, topic, defaultPageSize }: AlarmListProps) {
  const navigate = useNavigate();
  const notifications = useNotifications({ clientIds: [clientId], status, topic });
  const { isLoading, isError, data } = notifications;
  const rows = useMemo(() => {
    if (isLoading || isError || !data) {
      return [];
    }

    return data
      .map(notification => {
        return convertToNotificationItem(notification);
      })
      .sort((firstItem: NotificationItem, nextItem: NotificationItem) => {
        const firstItemLatestOccurrenceTime = firstItem?.latestOccurrenceTime?.getTime() ?? 0;
        const nextItemLatestOccurrenceTime = nextItem?.latestOccurrenceTime?.getTime() ?? 0;
        return nextItemLatestOccurrenceTime - firstItemLatestOccurrenceTime;
      });
  }, [data, isError, isLoading]);

  if (isLoading) {
    return <Skeleton variant="rectangular" height="30vh" />;
  }

  const onRowClick: GridEventListener<'rowClick'> = params => {
    navigate(`/plant/${params.row.clientId}/alarms/${params.row.id}`);
  };

  const defaultPagination = Array.from(new Set<number>([defaultPageSize, 10, 20, 50, 100].sort((a, b) => a - b)));

  return (
    <DataGrid
      sx={{ border: 'none' }}
      columns={initialColumns}
      rows={rows}
      classes={{ row: 'clickable-row' }}
      initialState={{
        pagination: { paginationModel: { pageSize: defaultPageSize } },
      }}
      pageSizeOptions={defaultPagination}
      onRowClick={onRowClick}
    />
  );
}
