import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import type { Baseline } from 'app/VesselConfig/Baselines/types';

import { useSnackbar } from 'context/snackbar/SnackbarContext';
import { db } from 'helpers/api';

import { useBaselineGroup } from './useBaselineConfig';

type GroupReq = {
  clientId: string;
  baselineGroupId: string;
};

type LineReq = GroupReq & { baselineId: string };
type PointReq = LineReq & { pointId: string };

type BaselineEditorHook = LineReq;

async function updateBaselineNameRequest(params: LineReq, name: string) {
  const { data } = await db.post('config/baselines/updateBaselineName', { name }, { params });
  return data;
}

async function removeBaselineRequest(params: LineReq) {
  const { data } = await db.delete(`config/baselines/deleteBaseline`, { params });
  return data;
}

async function removePointRequest(params: PointReq) {
  const { data } = await db.delete('config/baselines/deletePoint', { params });
  return data;
}

async function addPointRequest(params: LineReq, x: number, y: number) {
  const { data } = await db.post('config/baselines/addPoint', { x, y }, { params });
  return data;
}

async function updateBaselinePointRequest(params: PointReq, x: number, y: number) {
  const { data } = await db.post('config/baselines/updatePoint', { x, y }, { params });
  return data;
}

export function useBaselineEditor({ clientId, baselineGroupId, baselineId }: BaselineEditorHook) {
  const queryClient = useQueryClient();
  const { addSnackbar } = useSnackbar();

  const baselineGroup = useBaselineGroup({ clientId, baselineGroupId });
  const baseline = useMemo(
    () => baselineGroup.data?.baselines?.find((line: Baseline) => line._id === baselineId),
    [baselineGroup.data, baselineId]
  );
  const query = useMemo(
    () => ({
      ...baselineGroup,
      data: baseline,
    }),
    [baselineGroup, baseline]
  );

  const { mutateAsync: removeBaseline } = useMutation(() => removeBaselineRequest({ clientId, baselineGroupId, baselineId }), {
    onError: () => {
      addSnackbar({ variant: 'error', message: 'Failed to remove Baseline' });
    },
    onSuccess: () => {
      addSnackbar({ variant: 'success', message: 'Removed Baseline' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
    },
  });

  const { mutateAsync: updateName } = useMutation(
    ({ name }: { name: string }) => updateBaselineNameRequest({ clientId, baselineGroupId, baselineId }, name),
    {
      onError: () => {
        addSnackbar({ variant: 'error', message: 'Failed to update Baseline Name ' });
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'Updated Baseline Name ' });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
      },
    }
  );

  const { mutateAsync: updatePoint } = useMutation(
    ({ pointId, x, y }: { pointId: string; x: number; y: number }) =>
      updateBaselinePointRequest({ clientId, baselineGroupId, baselineId, pointId }, x, y),
    {
      onError: () => {
        addSnackbar({ variant: 'error', message: 'Failed to update point ' });
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'Updated Point' });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
      },
    }
  );

  const { mutateAsync: addPoint } = useMutation(
    ({ x, y }: { x: number; y: number }) => addPointRequest({ clientId, baselineGroupId, baselineId }, x, y),
    {
      onError: () => {
        addSnackbar({ variant: 'error', message: 'Failed to add point' });
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'Added point' });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
      },
    }
  );

  const { mutateAsync: removePoint } = useMutation(
    ({ pointId }: { pointId: string }) => removePointRequest({ clientId, baselineGroupId, baselineId, pointId }),
    {
      onError: () => {
        addSnackbar({ variant: 'error', message: 'Failed to remove point' });
      },
      onSuccess: () => {
        addSnackbar({ variant: 'success', message: 'Removed point' });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['baselineGroupConfig', { clientId, baselineGroupId }]);
      },
    }
  );

  return {
    baseline: query,
    removeBaseline,
    updateName,
    updatePoint,
    addPoint,
    removePoint,
  };
}
