import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useBaselineGroups } from 'hooks/useBaselineConfig';
import useClientId from 'context/clientId';

import { BaselineFamily, BaselineGroup } from './types';
import { BaselineList } from './BaselineList';
import { BaselineConfigEditor } from './BaselineConfigEditor';

export function BaselineDetailsConfig() {
  const clientId = useClientId();
  const { baselineGroupId } = useParams();

  if (!baselineGroupId) {
    return <Box>Some internal error occured, please try again;</Box>;
  }

  return <BaselineConfigEditor clientId={clientId} baselineGroupId={baselineGroupId} />;
}

export function BaselineConfig() {
  const clientId = useClientId();
  const baselineGroup = useBaselineGroups({ clientId });

  const baselineItems = (baselineGroup.data ?? [])
    .map(({ family, groups }) => groups.map((group): [BaselineGroup, BaselineFamily] => [group, family]))
    .flat();

  return <BaselineList baselineItems={baselineItems} />;
}
