import { useNavigate } from 'react-router-dom';

import { Paper } from '@mui/material';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';

import { BaselineFamily, BaselineGroup } from './types';

const baselineColumns = [
  { field: 'name', headerName: 'Name', minWidth: 200 },
  { field: 'remark', headerName: 'Remark', minWidth: 200 },
];

export function BaselineList({ baselineItems }: { baselineItems: [BaselineGroup, BaselineFamily][] }) {
  const navigate = useNavigate();
  const baselineRows = baselineItems.map(([group, family]) => ({
    name: family.name,
    remark: group.remark ?? family.remark,
    id: group._id,
  }));

  const onRowClick: GridEventListener<'rowClick'> = params => {
    navigate(`../details/${params.row.id}`);
  };

  return (
    <Paper sx={{ minHeight: '200px', height: 'calc(100% - 128px)' }}>
      <DataGrid
        sx={{ border: 'none', height: '100%' }}
        classes={{ row: 'clickable-row' }}
        rows={baselineRows}
        columns={baselineColumns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10]}
        onRowClick={onRowClick}
      />
    </Paper>
  );
}
