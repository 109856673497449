import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import PolarCard from 'components/PolarCard';

function useSpeedWindOverPolar(clientId: ClientId, from: Date, to: Date, resolution: number): [any, boolean, string | undefined] {
  const qry: TimeseriesSequenceQuery[] = [
    {
      clientId,
      module: 'calculated',
      objectId: 'environment',
      objectIdNo: 0,
      pvId: 'trueWindDirection',
      pvIdNo: 0,
      resolution,
      from,
      to,
      sourceUnit: 'deg',
    },
  ];

  const [datas, loading, err] = useSequencesData(qry);

  const data = {
    datasets: datas.map(data => ({
      data: data ?? [],
      borderColor: color[0],
      borderWidth: 1,
    })),
  };
  return [data, loading, err];
}

const options = {
  title: 'Distribution of Wind Directions',
  pointLabelCallback: function (label: string, _: number) {
    return label + '°';
  },
  showTooltip: false,
};

function SpeedWindOverPolar({ clientId, from, to, resolution, aspectRatio }: ChartProps) {
  const [data, loading, error] = useSpeedWindOverPolar(clientId, from, to, resolution);
  return <PolarCard options={options} data={data} loading={loading} err={error} aspectRatio={aspectRatio} binWidth={45} binCount={8} />;
}

export default SpeedWindOverPolar;
