import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { ciiCalculation, ciiToRating, EmissionConfig, EmissionStats, emissionWindowAggregation } from 'helpers/emissions';
import { RatingIcon } from './RatingBox';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { AggregatedPvId, Resolution, useAggregatedData } from 'hooks/useAggregatedData';
import useClientId from 'context/clientId';
import SkeletonOnUndefined from 'components/SkeletonOnUndefined';
import { Typography } from '@mui/material';

const columnsConfig: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Month',
    width: 160,
    renderCell: ({ value }) => (
      <SkeletonOnUndefined value={value}>{value?.toLocaleString(undefined, { month: 'long', year: 'numeric' })}</SkeletonOnUndefined>
    ),
  },
  {
    field: 'rating',
    headerName: 'Rating',
    minWidth: 140,
    renderCell: params => (
      <div style={{ display: 'flex', gap: '8px', justifyContent: 'left', alignItems: 'center', height: '100%' }}>
        <SkeletonOnUndefined value={params.value}>
          <RatingIcon rating={params.value} sx={{ width: '2.5em' }} variant="body1" square />
        </SkeletonOnUndefined>
        <Typography>{params.row.index?.toFixed(2)}</Typography>
      </div>
    ),
  },
  {
    field: 'speed',
    headerName: 'Avg. Speed',
    align: 'right',
    renderCell: ({ value }) => <SkeletonOnUndefined value={value}>{Math.round(value)} kn</SkeletonOnUndefined>,
  },
  {
    field: 'distance',
    align: 'right',
    headerName: 'Sailed',
    renderCell: ({ value }) => <SkeletonOnUndefined value={value}>{Math.round(value)} nm</SkeletonOnUndefined>,
  },
  {
    align: 'right',
    field: 'power',
    headerName: 'Avg. Power',
    renderCell: ({ value }) => <SkeletonOnUndefined value={value}>{Math.round(value)} kW</SkeletonOnUndefined>,
  },
  {
    align: 'right',
    field: 'co2tons',
    headerName: 'CO₂ Emitted',
    valueFormatter: value => (value as number).toFixed(0) + ' t',
  },
  {
    align: 'right',
    field: 'liters',
    headerName: 'Fuel Consumption',
    renderCell: ({ value }) => <SkeletonOnUndefined value={value}>{Math.round(value)} ltr</SkeletonOnUndefined>,
  },
  {
    align: 'center',
    field: 'trend',
    headerName: 'Trend',
    valueFormatter: value => (value != null ? Math.round(value as number) : undefined),
    renderCell: params => {
      if (params.value === undefined) {
        return null;
      }
      const angle = Math.round(Math.tanh(params.value / 100) * 90);
      let color;
      if (angle < -10) {
        color = 'success.main';
      }
      if (angle > 10) {
        color = 'error.main';
      }
      return <TrendingFlatIcon sx={{ transform: `rotate(${-angle}deg)`, color }} />;
    },
  },
];

function groupBy<A>(as: A[], groupFn: (a: A) => string) {
  const groups: Record<string, A[]> = {};
  for (const a of as) {
    const group = groupFn(a);
    groups[group] ??= [];
    groups[group].push(a);
  }

  return groups;
}

function year_month(date: Date): Date {
  const month = new Date(date);
  month.setUTCFullYear(date.getUTCFullYear(), date.getUTCMonth(), 1);
  month.setUTCHours(0, 0, 0);
  return month;
}

function yesterday(date: Date | string): Date {
  const res = new Date(date);
  res.setDate(res.getDate() - 1);
  return res;
}

export function MonthlyEmissionsReport({
  emissionData,
  emissionConfig,
}: {
  emissionData?: EmissionStats[];
  emissionConfig?: EmissionConfig;
}) {
  const clientId = useClientId();
  const to = useMemo(() => new Date(), []);
  const from = new Date(to.toISOString());
  from.setDate(from.getDate() - 365);
  const powerMonthly = useAggregatedData({
    from,
    to,
    clientId,
    pvId: AggregatedPvId.Power,
    resolution: Resolution.Monthly,
  });
  const speedMonthly = useAggregatedData({
    from,
    to,
    clientId,
    pvId: AggregatedPvId.Sog,
    resolution: Resolution.Monthly,
  });

  const monthlyData = useMemo(() => groupBy(emissionData ?? [], point => year_month(yesterday(point.time)).toISOString()), [emissionData]);
  const rows = useMemo(() => {
    const rows = Object.entries(monthlyData).map(([key, value]) => {
      const { liters, travelledDistance } = emissionWindowAggregation(value);
      const index = ciiCalculation({ travelledDistanceNm: travelledDistance, fuelConsumptionLiters: liters }, emissionConfig);
      const month = new Date(key);
      const ciiBoundaries = emissionConfig?.ciiBoundaries?.[month.getUTCFullYear()];
      const rating = index && ciiBoundaries ? ciiToRating(index, ciiBoundaries) : undefined;

      const avgPower = powerMonthly.data?.find(v => {
        const date = new Date(v.time);
        date.setMonth(date.getMonth() - 1);
        return date.getFullYear() === month.getFullYear() && date.getMonth() === month.getMonth();
      });
      const avgSpeed = speedMonthly?.data?.find(v => {
        const date = new Date(v.time);
        date.setMonth(date.getMonth() - 1);
        return date.getFullYear() === month.getFullYear() && date.getMonth() === month.getMonth();
      });

      const co2tons = emissionConfig ? (liters * emissionConfig.fuelLiquidDensity * emissionConfig.co2ConversionFactor) / 1000 : undefined;

      const row = {
        id: key,
        month: new Date(key),
        index,
        rating,
        speed: avgSpeed?.value,
        distance: travelledDistance,
        power: avgPower?.value,
        liters: liters,
        trend: undefined as number | undefined,
        co2tons,
      };
      return row;
    });
    rows.sort((rowa, rowb) => rowa.month.getTime() - rowb.month.getTime());

    for (let i = 1; i < rows.length; i++) {
      const prev = rows[i - 1];
      const cur = rows[i];
      if (!cur.index || !prev.index) {
        continue;
      }
      const max_index = Math.max(cur.index, prev.index);
      const index_difference = cur.index - prev.index;
      cur.trend = (index_difference / max_index) * 100;
    }

    return rows.filter(row => !!row.rating);
  }, [monthlyData, emissionConfig, powerMonthly, speedMonthly]);

  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'month', sort: 'desc' }],
        },
      }}
      loading={!emissionData || !emissionConfig}
      sx={{ border: 'none' }}
      autoHeight
      columns={columnsConfig}
      rows={rows}
    />
  );
}
