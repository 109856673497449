import { useVesselConfig } from 'context/vesselConfig';
import type { ClientId } from 'context/clientId';
import { TimeseriesQuery, useSequencesData } from 'hooks/useQueriedData';
import { ChartProps, color } from '.';
import TimeLineCard from 'components/TimeLineCard';
import { useTargetUnits } from 'context/settings';

function useCoolantTempOverTime(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const vesselConfig = useVesselConfig();
  const queries: TimeseriesQuery[] = Object.keys(vesselConfig.engines).map(engineNo => ({
    clientId,
    objectId: 'combustion',
    objectIdNo: Number(engineNo),
    pvId: 'tempCoolant',
    pvIdNo: 0,
    resolution,
    from,
    to,
    sourceUnit: 'degC',
    targetUnit: units.temperature,
  }));

  const [datas, loading, err] = useSequencesData(queries);

  const data = {
    datasets: datas.map((data, i) => ({
      label: vesselConfig.engines[i].name,
      data: data ?? [],
      borderColor: color[i],
      borderWidth: 1,
    })),
  };

  const options = { yAxisLabel: `Temperature [${units.temperature}]`, title: 'Coolant Temperature', xAxisLabel: 'Time' };
  return { data, loading, err, options };
}

function CoolantTempOverTime({ from, to, clientId, resolution }: ChartProps) {
  const { data, loading, err, options } = useCoolantTempOverTime(clientId, from, to, resolution);
  return <TimeLineCard options={options} data={data} loading={loading} err={err} />;
}

export default CoolantTempOverTime;
