import { useQuery } from 'react-query';
import { cache } from 'helpers/api';
import { DatumString } from './useLiveData';

export interface FFTVibrationData {
  tsp: string;
  object: {
    refRpm: number;
    refPower: number;
    rms: number;
    fft: number[];
  };
  ttl?: number;
}

export function useFFTVibrationData(clientId: string, objectId: string, objectIdNo: number) {
  const pvIdNoToTopic = (pvIdNo: number) =>
    `ioconnect/measurement/${clientId}/${objectId}/${objectIdNo}/vibration/${pvIdNo}` as DatumString;
  const topics = Array(3)
    .fill(0)
    .map((_, i) => pvIdNoToTopic(i));
  return useQuery(
    ['fftvibration', objectId, objectIdNo],
    async () => {
      const res = (await cache.post('api/cache/mget', topics)).data;
      return Array(3)
        .fill(0)
        .map((_, i) => res[pvIdNoToTopic(i)] as FFTVibrationData);
    },
    { refetchInterval: 60000 }
  );
}
