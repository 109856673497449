import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { APP_BAR_HEIGHT } from 'helpers/constants';

export function CheckInboxToReset() {
  return (
    <Container maxWidth="sm">
      <Box minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex" flexDirection="row" alignItems="baseline" gap="1em">
          <MarkEmailUnreadIcon fontSize="large" />
          <Box display="flex" flexDirection="column">
            <Typography
              variant="h4"
              sx={{
                mb: 3,
                fontWeight: 'bold',
              }}>
              Check your inbox
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 3,
              }}>
              We have sent you an email with instructions to reset your password.
            </Typography>
            <Link to="/login">Back to login</Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
