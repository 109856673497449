import { useEffect, useState } from 'react';
import { useZoomTimeRangeContext } from '../context/zoom/ZoomTimeRangeContext';
import { isEqual } from 'date-fns';

interface Props {
  start: Date;
  end: Date;
}

/**
 * Resets the zoom range in of the `ZoomTimeRangeContext` after selected date range has changed.
 * @param {Props} props
 * @returns {null}
 */
const ZoomTimeRangeReset = (props: Props) => {
  const { resetZoom } = useZoomTimeRangeContext();

  const [lastStart, setLastStart] = useState(new Date());
  const [lastEnd, setLastEnd] = useState(new Date());

  const rangeChanged = !(isEqual(props.start, lastStart) && isEqual(props.end, lastEnd));

  // using effect to avoid bad setState() on render of ZoomTimeRangeContext
  useEffect(() => {
    if (rangeChanged) {
      setLastStart(props.start);
      setLastEnd(props.end);
      if (resetZoom) {
        resetZoom();
      }
    }
  }, [rangeChanged, props.start, props.end, resetZoom]);

  return null;
};

export default ZoomTimeRangeReset;
