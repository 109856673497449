import { Box, Typography } from '@mui/material';

import not_found_robot from 'assets/404-robot.png';

export function NotFoundSection() {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', p: 4, gap: 2, justifyContent: 'center', alignItems: 'center' }}>
      <img
        style={{ objectFit: 'contain', minWidth: '200px', width: '100%', height: 'auto', display: 'block' }}
        src={not_found_robot}
        width="372"
        height="372"
        alt="404 - Not found"
      />
      <Box sx={{ minWidth: '372px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
        <Typography variant="h2">Oops - Page Not Found</Typography>
        <Typography variant="h5">This Page isn't here, please don't stare!</Typography>
      </Box>
    </Box>
  );
}

export function CenteredNotFoundSection() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <NotFoundSection />
    </Box>
  );
}
