import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { differenceInDays, isEqual, isValid, subDays } from 'date-fns';

const MAX_DATE_RANGE = 30;

function searchParamToDate(param: string | null): Date | undefined {
  let result = undefined;
  if (param !== null) {
    const paramAsNumber = Number(param);

    if (isValid(paramAsNumber)) {
      result = new Date(paramAsNumber);
    }
  }
  return result;
}

function getInitialActiveRange(initDate: Date, start?: Date, end?: Date): number {
  if (!end || !isEqual(initDate, end)) {
    return 0;
  }
  if (start && isValid(start)) {
    return differenceInDays(initDate, start);
  }
  return 1;
}

function getStartDate(initDate: Date, end: Date, start?: Date): Date {
  let result = subDays(initDate, 1);
  if (start) {
    if (differenceInDays(end, start) > MAX_DATE_RANGE) {
      result = subDays(end, MAX_DATE_RANGE);
    } else {
      result = start;
    }
  }
  return result;
}

export type TimeRangeResult = {
  start: Date;
  end: Date;
  activeRange: number;
  initDate: Date;
  setStart: (start: Date) => void;
  setEnd: (end: Date) => void;
  setActiveRange: (range: number) => void;
};

function useTimeRange(): TimeRangeResult {
  const initDate = useMemo(() => new Date(), []);

  const [searchParams, setSearchParams] = useSearchParams({});
  const searchStart = searchParamToDate(searchParams.get('from'));
  const searchEnd = searchParamToDate(searchParams.get('to'));
  const initialActiveRange = getInitialActiveRange(initDate, searchStart, searchEnd);

  const [activeRange, setActiveRange] = useState<number>(initialActiveRange);
  const [end, setEnd] = useState(searchEnd ?? initDate);
  const [start, setStart] = useState(getStartDate(initDate, end, searchStart));

  useEffect(() => {
    searchParams.set('from', start.valueOf().toString());
    searchParams.set('to', end.valueOf().toString());
    setSearchParams(searchParams, { replace: true });
    setActiveRange(getInitialActiveRange(initDate, start, end));
  }, [start, end, searchParams, initDate, setSearchParams, setActiveRange]);

  return {
    start,
    end,
    activeRange,
    initDate,
    setStart,
    setEnd,
    setActiveRange,
  };
}

export default useTimeRange;
