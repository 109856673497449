import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { addDataToScatterMap, ChartProps, color, dataSeriesFromScatterMap } from '.';
import { AxisOptions } from '../../../context/baselineConfig';
import ScatteredBaselineCard from './ScatteredBaselineCard';
import { ChartData } from 'chart.js';
import { ScatterDataPoint } from 'chart.js/auto';
import { useTargetUnits } from 'context/settings';

function usePowerOverSog(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  const queries: TimeseriesSequenceQuery[] = [
    {
      module: 'measurement',
      objectId: 'speed',
      objectIdNo: 0,
      pvId: 'sog',
      pvIdNo: 0,
      clientId,
      resolution,
      from,
      to,
      sourceUnit: 'kn',
      targetUnit: units.vesselSpeed,
    },
    {
      module: 'calculated',
      objectIdNo: 0,
      objectId: 'total',
      pvId: 'power',
      pvIdNo: 0,
      clientId,
      resolution,
      from,
      to,
      sourceUnit: 'kW',
      targetUnit: units.power,
    },
  ];

  const [datas, loading, err] = useSequencesData(queries);

  const speedLabel = 'SOG';
  const powerLabel = 'Power';

  const map = new Map();
  addDataToScatterMap(map, speedLabel, datas[0] ?? []);
  addDataToScatterMap(map, powerLabel, datas[1] ?? []);

  const data: ChartData<any, ScatterDataPoint[], any> = {
    datasets: [
      {
        label: powerLabel,
        data: dataSeriesFromScatterMap(map, speedLabel, powerLabel),
        type: 'scatter',
        borderColor: color[0],
        borderWidth: 1, //todo define in theme
        fill: false,
        radius: 3, //todo define in theme
      },
    ],
  };

  const config: AxisOptions = {
    xLabel: `SOG [${units.vesselSpeed}]`,
    yLabel: `Power [${units.power}]`,
  };

  return {
    data,
    loading,
    err,
    chartConfig: { axes: config },
  };
}

function PowerOverSog({ clientId, from, to, resolution, aspectRatio }: ChartProps) {
  const { data, loading, err, chartConfig } = usePowerOverSog(clientId, from, to, resolution);

  return (
    <ScatteredBaselineCard
      name="Power / Speed"
      data={data}
      loading={loading}
      err={err}
      options={chartConfig?.axes}
      aspectRatio={aspectRatio}
    />
  );
}

export default PowerOverSog;
