import React, { FC, useState } from 'react';
import { ZoomTimeRangeContext } from './ZoomTimeRangeContext';

interface ZoomTimeRangeProviderProps {
  setStart?: (start: Date) => void;
  setEnd?: (end: Date) => void;
  setActiveRange?: (range: number) => void;
  setResolution?: (resolution: number) => void;
  children?: React.ReactNode;
}

export const ZoomTimeRangeProvider: FC<ZoomTimeRangeProviderProps> = ({ children, setResolution, setEnd, setStart }) => {
  // holding the state of the current range
  const [range, setRange] = useState<{ min: number; max: number } | null | undefined>(null);

  // setting the zoom range
  const setZoomRange = (range: { min: number; max: number } | null | undefined) => setRange(range);

  const resetZoom = () => setZoomRange(null);

  if (range) {
    // TODO (20.08.2024): useful range
    const diffSec = Math.round((range.max - range.min) / 1000);
    const res = Math.max(Math.round(diffSec / 3600), 1);
    if (setResolution && setStart && setEnd) {
      setResolution(res);
      setStart(new Date(range.min));
      setEnd(new Date(range.max));
    }
  }
  return (
    <ZoomTimeRangeContext.Provider
      value={{
        zoomRange: range,
        setZoomRange: setZoomRange,
        resetZoom: resetZoom,
      }}>
      {children}
    </ZoomTimeRangeContext.Provider>
  );
};
