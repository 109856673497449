import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type LinearGaugeProps = {
  value: number;
  label: string;
  unit?: string;
  min?: number;
  max?: number;
  type?: 'default' | 'reversed' | 'ends' | 'outerRed';
  align?: 'horizontal' | 'vertical';
};

const getPinColor = (index: number, type: 'default' | 'reversed' | 'ends' | 'outerRed', theme: any) => {
  switch (type) {
    case 'reversed':
      if (index === 0) return theme.palette.error.main;
      if (index <= 1) return theme.palette.warning.main;
      return theme.palette.success.main;
    case 'ends':
      if (index === 0 || index >= 9) return theme.palette.error.main;
      if (index === 1 || index === 8) return theme.palette.warning.main;
      return theme.palette.success.main;
    case 'outerRed':
      if (index === 0 || index === 9) return theme.palette.error.main;
      return theme.palette.success.main;
    case 'default':
    default:
      if (index >= 9) return theme.palette.error.main;
      if (index >= 7) return theme.palette.warning.main;
      return theme.palette.success.main;
  }
};

const LinearGauge: React.FC<LinearGaugeProps> = ({ value, label, unit = '', min = 0, max = 100, type = 'default', align = 'vertical' }) => {
  const theme = useTheme();
  const percentage = ((value - min) / (max - min)) * 100;
  const activeIndex = Math.floor(percentage / 10);
  const pinColor = getPinColor(activeIndex, type, theme);

  return (
    <Grid container spacing={0} alignItems="center" justifyContent="center">
      <Grid item xs={12} md={align === 'horizontal' ? 6 : 12}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: align === 'vertical' ? 40 : 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 0.5,
          }}>
          {[...Array(10)].map((_, index) => (
            <Box
              key={index}
              sx={{
                transition: 'all 0.3s ease',
                width: 9,
                height: index === activeIndex ? 40 : 20,
                backgroundColor: getPinColor(index, type, theme),
                opacity: index === activeIndex ? 1 : 0.5,
              }}
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} md={align === 'horizontal' ? 6 : 12}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" sx={{ color: pinColor }}>
            {value} {unit}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {label}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LinearGauge;
