import { useResolvedPath, useMatch } from 'react-router-dom';

function usePathChild(end?: boolean, caseSensitive?: boolean) {
  const path = useResolvedPath('.');
  const sep = path.pathname.endsWith('/') ? '' : '/';
  const childPattern = path.pathname + sep + ':child';

  const match = useMatch({ path: childPattern, caseSensitive, end });

  return match?.params?.child;
}

export default usePathChild;
