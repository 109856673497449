import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  DialogContent,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  QUANTITY_DISPLAY_NAMES,
  UNITS,
  UNIT_DISPLAY_NAMES,
  defaultUnitSettings,
  useRawUnitSettings,
  useSettingsDialog,
} from 'context/settings';
import ThemeToggleSwitch from 'components/ThemeToggleSwitch';

function useUnitSettingsEditor() {
  const { units: rawUnits, setUnits: setRawUnits } = useRawUnitSettings();
  const [editorUnits, setEditorUnits] = useState(rawUnits);

  const reset = useCallback(() => setEditorUnits(rawUnits), [rawUnits]);
  const save = useCallback(() => setRawUnits(editorUnits), [editorUnits, setRawUnits]);

  const setQuantity = <T extends keyof typeof UNITS>(quantity: T, value: (typeof UNITS)[T][number]) => {
    setEditorUnits(prev => ({ ...prev, [quantity]: value }));
  };
  const removeQuantity = (quantity: keyof typeof UNITS) => {
    setEditorUnits(prev => {
      const copy = { ...prev };
      delete copy[quantity];
      return copy;
    });
  };

  return {
    units: editorUnits,
    setQuantity,
    removeQuantity,
    rawUnits,
    reset,
    save,
  };
}

function UnitSettingsEditor({ editor }: { editor: ReturnType<typeof useUnitSettingsEditor> }) {
  const { units, setQuantity } = editor;
  const QUANTITIES = Object.keys(UNITS) as (keyof typeof UNITS)[];

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, flexDirection: 'row' }}>
      {QUANTITIES.map(quantity => (
        <Box key={quantity} sx={{ minWidth: 220 }}>
          <FormControl fullWidth>
            <InputLabel id={`unit-settings-${quantity}-label`}>{QUANTITY_DISPLAY_NAMES[quantity]}</InputLabel>
            <Select
              labelId={`unit-settings-${quantity}-label`}
              id={`unit-settings-${quantity}`}
              value={units[quantity] ?? defaultUnitSettings[quantity]}
              label={QUANTITY_DISPLAY_NAMES[quantity]}
              onChange={e => {
                const value = e.target.value as string;
                setQuantity(quantity, value as any);
              }}>
              {UNITS[quantity].map(unit => (
                <MenuItem key={unit} value={unit}>
                  {UNIT_DISPLAY_NAMES[unit]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ))}
    </Box>
  );
}

function SettingsDialog() {
  const { open, closeDialog } = useSettingsDialog();
  const unitSettingsEditor = useUnitSettingsEditor();

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="xl" fullWidth>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: 1 }}>
          <Box>
            <Typography sx={{ mb: 1 }}>Units</Typography>
            <UnitSettingsEditor editor={unitSettingsEditor} />
          </Box>
          <Box>
            <Typography sx={{ mb: 1 }}>Theme</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <ThemeToggleSwitch />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            unitSettingsEditor.save();
            closeDialog();
          }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SettingsDialog;
