import { Box, Paper, SxProps, Theme, Typography } from '@mui/material';
import { ReactChild, ReactFragment, ReactPortal } from 'react';

type TitledPaperProps = {
  title: string;
  icon?: boolean | ReactChild | ReactFragment | ReactPortal | null;
  noPadding?: boolean;
  sx?: SxProps<Theme>;
  children?: boolean | ReactChild | ReactFragment | ReactPortal | null;
};

export default function TitledPaper(props: TitledPaperProps) {
  return (
    <Paper sx={{ ...(props.sx ?? {}), display: 'flex', flexDirection: 'column' }}>
      <Paper sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, bgcolor: 'transparent' }}>
        {props.icon}
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          {props.title}
        </Typography>
      </Paper>
      <Box sx={{ pt: 2, pb: props.noPadding ? 0 : 2, px: props.noPadding ? 0 : 2, flexGrow: 1 }}>{props.children}</Box>
    </Paper>
  );
}
