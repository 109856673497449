import joi from 'joi';

export const vesselFeatureSchema = joi.object({
  key: joi.string().required(),
});

export const vesselSchema = joi.object({
  clientId: joi.string().required(),
  name: joi.string().required(),
  features: joi.array().items(vesselFeatureSchema).required(),
});
