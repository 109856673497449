import React from 'react';

import { Grid } from '@mui/material';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import MapPosition from '../MapPosition';
import SpeedWindOverTime from '../Charts/SpeedWindOverTime';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export default function VesselRoute() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();
  const vesselProperties = useVesselFeatures(clientId);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={12}>
          <SpeedWindOverTime clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={8} />
        </Grid>

        {vesselProperties.includes('cockpitMap') && (
          <Grid item xs={12} lg={12}>
            <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={5} />
          </Grid>
        )}
      </Grid>
    </>
  );
}
