import { Box } from '@mui/material';

export function Impressum() {
  return (
    <Box sx={{ maxWidth: '80em' }}>
      <h1>Impressum</h1>
      <p>
        M.A.C. Networks GmbH
        <br />
        Buchenring 14
        <br />
        21272 Egestorf
        <br />
        GERMANY
      </p>
      <p>Amtsgericht Hamburg HRB 175730</p>

      <p>
        Tel.: +49 (0) 41 75 / 80 89 02 -0
        <br />
        Fax: +49 (0) 41 75 / 80 89 02 -99
        <br />
        www.macsystemsolutions.com
      </p>
      <p>Geschäftsführung Goran Berkes</p>

      <h2>Haftungsausschluss</h2>
      <h3>Inhalt</h3>
      <p>
        M.A.C. Networks GmbH, nachstehend M.A.C. genannt, übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder
        Qualität der bereitgestellten Informationen. Haftungsansprüche gegen M.A.C., welche sich auf Schäden materieller oder ideeller Art
        beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
        unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens M.A.C. kein nachweislich
        vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. M.A.C. behält es sich
        ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder
        die Veröffentlichung zeitweise oder endgültig einzustellen.
      </p>
      <h3>Links</h3>
      <p>
        Bei direkten oder indirekten Verweisen auf fremde Internetseiten (Links), die außerhalb des Verantwortungsbereiches von M.A.C.
        liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem M.A.C. von den Inhalten Kenntnis hat und
        es technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. M.A.C. erklärt daher
        ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren. M.A.C. hat
        keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der gelinkten/verknüpften Seiten. Deshalb
        distanziert sich M.A.C. hiermit ausdrücklich von allen Inhalten aller verknüpften Seiten, die nach der Linksetzung verändert wurden.
        Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in
        evtl. von M.A.C. eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige
        Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet
        allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung
        lediglich verweist.
      </p>
      <h3>Urheberrecht</h3>
      <p>
        Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen
        uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
        Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter
        geschützt sind! Das Copyright für veröffentlichte, von M.A.C. selbst erstellte Objekte bleibt allein bei M.A.C. Eine
        Vervielfältigung oder Verwendung solcher Grafiken, Dokumente und Texte in anderen elektronischen oder gedruckten Publikationen ist
        ohne ausdrückliche Zustimmung durch M.A.C. nicht gestattet.
      </p>
      <h3>Rechtswirksamkeit</h3>
      <p>
        Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern
        Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
        sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
      </p>
    </Box>
  );
}
