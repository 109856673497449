import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TitledPaper from 'components/TitledPaper';
import { db } from 'helpers/api';
import { Vessel } from 'hooks/fleetConfig';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

async function getCompanyVessels(companyId: string): Promise<Vessel[]> {
  const { data } = await db.get(`company/${companyId}/vessels`);
  return data;
}

function useCompanyVessels(companyId: string) {
  const query = useQuery(['companyVessels', companyId], () => getCompanyVessels(companyId));
  return query.data ?? [];
}

const columns: GridColDef[] = [
  {
    field: 'clientId',
    headerName: 'Vessel ID',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'features',
    headerName: 'Features',
    flex: 1,
    valueGetter: (value: { key: any }[]) => (value ?? []).map((feature: any) => feature.key).join(', '),
  },
];

export function CompanyVesselsPage() {
  const { companyId } = useParams();
  const vessels = useCompanyVessels(companyId ?? '');
  return (
    <Box sx={{ p: 2 }}>
      <TitledPaper title="Vessels" noPadding>
        <DataGrid
          getRowId={row => row.clientId}
          rows={vessels ?? []}
          columns={columns}
          initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          autoHeight
          sx={{ border: 'none' }}
        />
      </TitledPaper>
    </Box>
  );
}
