import { LoadProfile } from 'hooks/useLoadProfile';

/**
 * Transforms the LoadProfile into cummulative x/y points, where every y point is a bin.
 * Load in % is y and cummulative Time in % is x.
 */
export function loadProfileToPoints(profile: LoadProfile, bins: number[]): { x: number; y: number }[] {
  const points: { x: number; y: number }[] = [];
  const entries = Object.entries(profile);
  entries
    .sort((b, a) => Number(a[0]) - Number(b[0])) // Sorts the entries by the bin key in descending order. So 110, 100, 90 ...
    .reduce((prev, [k, v]) => {
      const cummulativeLoad = prev + v;
      if (bins.includes(Number(k))) {
        points.push({ x: cummulativeLoad, y: Number(k) });
      }
      return prev + v;
    }, 0);

  return points;
}

export function calculateLoadFactor(profile: LoadProfile): number {
  const entries = Object.entries(profile);

  const totalLoad = entries.reduce((prev, [k, v]) => {
    return prev + v * Number(k);
  }, 0);

  return totalLoad / 100;
}

export function calculateLoadIndicator(profile: LoadProfile): number | undefined {
  const entries = Object.entries(profile);

  const totalLoad = entries.reduce((prev, [k, v]) => {
    const rounded = Math.round(Number(k));
    if (rounded >= 1 && rounded <= 110) {
      const weighted = LOAD_INDICATOR_FUNCTION[rounded];
      return prev + v * weighted;
    } else {
      return prev;
    }
  }, 0);

  return totalLoad / 100;
}

const LOAD_INDICATOR_FUNCTION: { [key: number]: number } = {
  1: 0.02,
  2: 0.04,
  3: 0.06,
  4: 0.08,
  5: 0.1,
  6: 0.12,
  7: 0.14,
  8: 0.17,
  9: 0.19,
  10: 0.21,
  11: 0.23,
  12: 0.25,
  13: 0.27,
  14: 0.29,
  15: 0.31,
  16: 0.33,
  17: 0.35,
  18: 0.37,
  19: 0.39,
  20: 0.41,
  21: 0.43,
  22: 0.46,
  23: 0.48,
  24: 0.5,
  25: 0.52,
  26: 0.54,
  27: 0.56,
  28: 0.58,
  29: 0.6,
  30: 0.62,
  31: 0.64,
  32: 0.66,
  33: 0.68,
  34: 0.7,
  35: 0.72,
  36: 0.75,
  37: 0.77,
  38: 0.79,
  39: 0.81,
  40: 0.83,
  41: 0.85,
  42: 0.87,
  43: 0.89,
  44: 0.91,
  45: 0.93,
  46: 0.95,
  47: 0.97,
  48: 0.99,
  49: 1.01,
  50: 1.04,
  51: 1.06,
  52: 1.08,
  53: 1.1,
  54: 1.12,
  55: 1.14,
  56: 1.16,
  57: 1.18,
  58: 1.2,
  59: 1.22,
  60: 1.24,
  61: 1.26,
  62: 1.28,
  63: 1.3,
  64: 1.33,
  65: 1.35,
  66: 1.57,
  67: 1.82,
  68: 2.11,
  69: 2.45,
  70: 2.82,
  71: 3.26,
  72: 3.74,
  73: 4.3,
  74: 4.92,
  75: 5.63,
  76: 6.43,
  77: 7.33,
  78: 8.34,
  79: 9.47,
  80: 10.74,
  81: 12.16,
  82: 13.74,
  83: 15.52,
  84: 17.49,
  85: 19.69,
  86: 22.13,
  87: 24.84,
  88: 27.85,
  89: 31.18,
  90: 34.87,
  91: 38.94,
  92: 43.44,
  93: 48.4,
  94: 53.86,
  95: 59.87,
  96: 66.48,
  97: 73.74,
  98: 81.71,
  99: 90.44,
  100: 100.0,
  101: 110.46,
  102: 121.9,
  103: 134.39,
  104: 148.02,
  105: 162.89,
  106: 179.08,
  107: 196.72,
  108: 215.89,
  109: 236.74,
  110: 259.37,
};
