import React, { useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { Card, CardContent, CardActions } from '@mui/material';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

import useClientId from 'context/clientId';
import useWorkplans, { useWorkplansDueAt } from 'hooks/useWorkplans';
import type { Workplan } from 'hooks/useWorkplans';
import useRunningHours from 'hooks/useRunningHours';
import { useVesselConfig, VesselConfig } from 'context/vesselConfig';

const unique = (value: any, index: number, self: Array<any>) => self.indexOf(value) === index;

function dueAt(interval: number, current: number): number {
  return Math.ceil((current - 1) / interval) * interval;
}

type WorkplanCardProps = {
  workplan: Workplan;
  runningHours: number;
  name?: string;
  dueHours?: number;
};

function WorkplanCard({ workplan, runningHours, name, dueHours }: WorkplanCardProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const itemdescriptions = workplan.items
    .map(item => item.title)
    .filter(unique)
    .join(', ');
  const nextDueDate = dueAt(workplan.items[0].intervalRunninghours ?? 0, runningHours);
  const hoursLeft = (dueHours ?? nextDueDate) - runningHours;
  return (
    <>
      <Card sx={{ minWidth: 230, maxWidth: 350 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14, display: 'flex', gap: 1 }} color="text.secondary" gutterBottom>
            {name}, {`QL ${workplan.items[0].level}`}
          </Typography>
          <Typography variant="h5" component="div">
            {hoursLeft} / {workplan.items[0].intervalRunninghours} hours left
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {workplan.items
              .map(item => item.wpCode)
              .filter(unique)
              .join(', ')}
          </Typography>
          <Typography variant="body2">{itemdescriptions}</Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setDialogOpen(true)}>
            Learn More
          </Button>
        </CardActions>
      </Card>
      <Dialog maxWidth="lg" fullWidth open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{workplan.items[0].intervalRunninghours} Hour Interval Details</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr', gridGap: 8 }}>
            {workplan.items.map(item => (
              <Box sx={{ display: 'contents' }} key={item.wpCode}>
                <span>{item.wpCode}</span>
                <span>{item.title}</span>
                <span>{item.description}</span>
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

type ColumnProps = {
  items: Workplan[];
  runningHours: number;
  name?: string;
  clientId: string;
};

function WorkplanColumn({ items, runningHours, name, clientId }: ColumnProps) {
  const workplanDueHours = useWorkplansDueAt({ clientId });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {items.map(item => (
        <Box key={item._id}>
          <WorkplanCard
            workplan={item}
            runningHours={runningHours}
            name={name}
            dueHours={workplanDueHours.data?.[item._id]?.dueRunninghours}
          />
        </Box>
      ))}
    </Box>
  );
}

function toPartition(acc: Record<string, Workplan[]>, wp: Workplan) {
  acc[wp.objectIdNo] ??= [];
  acc[wp.objectIdNo].push(wp);
  return acc;
}

function objectMap<B, C>(r: Record<string, B>, f: (b: B) => C): Record<string, C> {
  const entries = Object.entries(r);
  const mapped = entries.map(([key, value]) => [key, f(value)]);
  return Object.fromEntries(mapped);
}

function sortByInterval(items: Workplan[]) {
  items.sort((a, b) => (a.items[0].intervalRunninghours ?? 0) - (b.items[0].intervalRunninghours ?? 0));
  return items;
}

export default function WorkplanView() {
  const clientId = useClientId();
  const { workplans } = useWorkplans({ clientId, archived: false });
  const vesselConfig: VesselConfig = useVesselConfig();
  const runningHours = useRunningHours(clientId);
  const wps = useMemo(() => {
    const wps = workplans.data ?? [];
    const byEngines = wps.reduce(toPartition, {});
    const sorted = objectMap(byEngines, sortByInterval);
    const entries = Object.entries(sorted);
    const getDisplayOrder = (engineNo: number) => vesselConfig.engines[engineNo].displayOrder ?? 0;
    entries.sort((a, b) => getDisplayOrder(+a[0]) - getDisplayOrder(+b[0]));
    return entries;
  }, [workplans, vesselConfig]);
  return (
    <Box sx={{ marginTop: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
        {wps.map(([key, wps]) => (
          <WorkplanColumn
            clientId={clientId}
            key={key}
            items={wps}
            runningHours={runningHours[+key]}
            name={vesselConfig.engines[+key]?.name}
          />
        ))}
      </Box>
    </Box>
  );
}
