import useClientId from '../../context/clientId';
import { Box, Paper } from '@mui/material';
import ActiveInactiveNotificationList from 'components/Notification/ActiveInactiveNotificationList';

export default function NotificationPage() {
  const clientId = useClientId();
  return (
    <Box sx={{ mb: '72px' }}>
      <Paper sx={{ height: '100%', padding: 0 }}>
        <ActiveInactiveNotificationList clientIds={[clientId]} hideColumns={['vesselName']} />
      </Paper>
    </Box>
  );
}
