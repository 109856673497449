import React from 'react';

import { Grid } from '@mui/material';

//Exhaust
import MeanExhaustgasOverTime, { Bank } from '../Charts/MeanExhaustgasOverTime';
import { TExhaustGasCombinedA, TExhaustGasCombinedB } from '../Charts/TExhaustGasCombined';
import EnginePowerOverTime from '../Charts/EnginePowerOverTime';
import MapPosition from '../MapPosition';

import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';

export default function EngineExhaustgas() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();
  const vesselProperties = useVesselFeatures(clientId);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={6}>
          <EnginePowerOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        {vesselProperties.includes('cockpitMap') && (
          <Grid item xs={12} lg={6}>
            <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <MeanExhaustgasOverTime bank={Bank.A} clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={16} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TExhaustGasCombinedA clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MeanExhaustgasOverTime bank={Bank.B} clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={17} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TExhaustGasCombinedB clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={18} />
        </Grid>
      </Grid>
    </>
  );
}
